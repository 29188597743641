import { COLORS } from "./Colors";
import { getHash } from "./StringUtils";

export function getPlaceholderLogo(name: string): string {
  if (!name) {
    return ' ';
  }
  const words = name.split(' ').slice(0, 2);
  return (
    words[0].charAt(0).toUpperCase() +
    (!!words[1] ? words[1].charAt(0).toUpperCase() : '')
  );
}

export function getPlaceholderColor(name: string): string {
  return COLORS[Math.abs(getHash(name) % COLORS.length)];
}
