import { Authenticator } from 'app/library/Authenticator';
import { SlackbotType } from 'app/pages/InstallationChannelSelector/slice/type';
import { SlackIdentity, User } from 'types/User';
import { getOnboardingMetadata } from './slack-app-names';

export const getMapsWorkspace = (user?: User): Company | undefined => {
  const workspaceId = getCurrentWorkspaceId();
  const mapsWorkspaces = user?.slackWorkspaces.filter(_ =>
    _.name.includes('__MAPS__'),
  );
  console.log(
    'getMapsWorkspace: id: ',
    workspaceId,
    ' workspaces: ',
    mapsWorkspaces,
  );
  if (!workspaceId) {
    console.error('getMapsWorkspace: missing workspaceId');
    return;
  }
  const workspace = mapsWorkspaces?.find(_ => _.name.includes(workspaceId));
  if (!workspace) {
    console.error(
      `getMapsWorkspace: workspace with id(${workspaceId}) not found`,
    );
    //this should be removed and return undefined once we unlink Maps users in auth0
    return mapsWorkspaces?.[0];
  }
  return workspace;
};

export const getCurrentSlackIdentity = (
  user?: User,
): SlackIdentity | undefined => {
  const suSlackIdentity = getSuperUserSlackIdentity();
  if (suSlackIdentity) {
    console.log('Found super user slack identity: ', suSlackIdentity);
    return suSlackIdentity;
  }
  const token = Authenticator.getTokenPayload();
  const slackWorkspaceId = token?.slack_workspace_id as string;
  const workspaceId = getCurrentWorkspaceId();
  return user?.slackIdentities.find(
    _ => _.slackWorkspaceId === slackWorkspaceId || _.teamId === workspaceId,
  );
};

export const getCurrentSlackbotId = (): string | undefined => {
  const token = Authenticator.getTokenPayload();
  return token?.slb as string;
};

const getCurrentWorkspaceId = (): string | undefined => {
  const token = Authenticator.getTokenPayload();
  console.log('getCurrentWorkspaceId: token: ', token);
  if (Authenticator.isSpeakeasy()) {
    return token?.slw as string;
  } else {
    console.log('getCurrentWorkspaceId: sub: ', token?.sub);
    const { workspaceId } = parseAuth0SubjectString(token?.sub);
    console.log('getCurrentWorkspaceId: workspaceId: ', workspaceId);
    return workspaceId;
  }
};

export const getSlackRedirectUrl = (type: SlackbotType): string => {
  const appId = getOnboardingMetadata(type).appId;
  const workspaceId = getCurrentWorkspaceId();
  if (!!workspaceId) {
    return `https://slack.com/app_redirect?team=${workspaceId}&app=${appId}`;
  }
  return `https://slack.com/app_redirect?app=${appId}`;
};

function parseAuth0SubjectString(sub: string): {
  workspaceId: string;
} {
  const split = sub.split('|');
  const last = split.pop();
  const lastSplit = last?.split('-');
  return {
    workspaceId: lastSplit?.[0] ?? '',
  };
}

export const getSuperUserSlackIdentity = (): SlackIdentity | undefined => {
  const value = localStorage.getItem('SUPER_USER_SLACK_IDENTITY');
  if (value) {
    return JSON.parse(value) as SlackIdentity;
  }
};

export const setSuperUserSlackIdentity = (identity: SlackIdentity) => {
  const value = localStorage.setItem(
    'SUPER_USER_SLACK_IDENTITY',
    JSON.stringify(identity),
  );
};

export const clearSuperUserSlackIdentity = () => {
  const value = localStorage.removeItem('SUPER_USER_SLACK_IDENTITY');
};
