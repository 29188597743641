import { invitePeople } from 'app/library/UserAPI';
import { appActions } from 'app/slice';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { invitePeopleModalActions as actions } from '.';
import { selectEmails } from './selectors';

function* doSubmit() {
  yield put(appActions.setLoading(true));
  const emails: string[] = yield select(selectEmails);
  yield put(actions.setError(undefined));

  try {
    if (emails.length === 0) {
      yield put(
        actions.setError(
          'There must be at least one valid email from your corporate email domain',
        ),
      );
    } else {
      const responses = yield call(invitePeople, emails);
      console.log('Invite results');
      console.log(responses);
      yield put(
        appActions.setSuccess('Invitations have successfully been sent!'),
      );
      yield put(actions.hide());
    }
  } catch (err) {
    console.error('An unexpected error occured ' + JSON.stringify(err));
    yield put(
      appActions.setError('An unexpected error occured ' + JSON.stringify(err)),
    );
  }
  yield put(appActions.setLoading(false));
}

export function* invitePeopleModalSaga() {
  yield takeLatest(actions.submit.type, doSubmit);
}
