import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { adminAppsPageSaga } from './saga';
import { AdminAppsPageState } from './type';

export const initialState: AdminAppsPageState = {
  isAddChannelModalOpen: false,
  isAddAdminModalOpen: false,
  isSuperUserWorkspaceModalModalOpen: false,
};

const slice = createSlice({
  name: 'adminAppsPage',
  initialState,
  reducers: {
    setIsAddChannelModalOpen(state, action: PayloadAction<boolean>) {
      state.isAddChannelModalOpen = action.payload;
    },
    setIsAddAdminModalOpen(state, action: PayloadAction<boolean>) {
      state.isAddAdminModalOpen = action.payload;
    },
    setIsSuperUserWorkspaceModalOpen(state, action: PayloadAction<boolean>) {
      state.isSuperUserWorkspaceModalModalOpen = action.payload;
    },
  },
});

export const { actions: adminAppsPageActions } = slice;

export const useAdminAppsPageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: adminAppsPageSaga });
  return { actions: slice.actions };
};
