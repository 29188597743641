import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.invitePeopleModal || initialState;

export const selectEmails = createSelector(
  [selectSlice],
  state => state.componentEmailData.map(entry => entry.email),
);

export const selectError = createSelector(
  [selectSlice],
  state => state.error,
);

export const selectShow = createSelector(
  [selectSlice],
  state => state.show,
);

export const selectComponentEmailData = createSelector([selectSlice], state => state.componentEmailData);