/**
 *
 * App
 *
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { AnalyticsClientContext } from 'app/library/Analytics';
import history from 'app/library/History';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollToTop } from 'react-router-scroll-to-top';
import { AppContainer } from './components/AppContainer';
import { NotFoundPage } from './components/InterestsSearchBar/NotFoundPage/Loadable';
import { ContainerType } from './library/AppConstants';
import { Authenticator } from './library/Authenticator';
import { AnswerIceBreaker } from './pages/AnswerIceBreaker/Loadable';
import { EmailConfirmationPage } from './pages/EmailConfirmationPage/Loadable';
import { EventsPage } from './pages/EventsPage/Loadable';
import { IceBreakerAnswers } from './pages/IceBreakerAnswers/Loadable';
import { IceBreakerShare } from './pages/IceBreakerShare/Loadable';
import { InterestPage } from './pages/InterestPage/Loadable';
import { LoginCodePage } from './pages/LoginCodePage/Loadable';
import { LoginPage } from './pages/LoginPage/Loadable';
import { MobileRedirectPage } from './pages/MobileRedirectPage/Loadable';
import { CalendarRedirectPage } from './pages/CalendarRedirectPage/Loadable';
import { NthProfileViewPage } from './pages/NthProfileViewPage/Loadable';
import { OnboardingDonePage } from './pages/OnboardingDonePage/Loadable';
import { OnboardingPlayProfilePage } from './pages/OnboardingPlayProfilePage/Loadable';
import { OnboardingUsernamePage } from './pages/OnboardingUsernamePage/Loadable';
import { OnboardingWorkProfile } from './pages/OnboardingWorkProfile/Loadable';
import { ProfileCompletionInterestPage } from './pages/ProfileCompletionInterestPage/Loadable';
import { ProfileCompletionPage } from './pages/ProfileCompletionPage/Loadable';
import { ProfileCompletionQuestionPage } from './pages/ProfileCompletionQuestionPage/Loadable';
import { ProfileHomePage } from './pages/ProfileHomePage/Loadable';
import { ProfilePage } from './pages/ProfilePage/Loadable';
import { PublicProfileLandingPage } from './pages/PublicProfileLandingPage/Loadable';
import { PublicProfilePage } from './pages/PublicProfilePage/Loadable';
import { ReserveUsernameForm } from './pages/ReserveUsernameForm/Loadable';
import { ShoutOutPage } from './pages/ShoutOutPage/Loadable';
import { TeammatesPage } from './pages/TeammatesPage/Loadable';
import { UsernameSuccessPage } from './pages/UsernameSuccessPage/Loadable';
import { useAppSlice } from './slice';
import {
  selectAuthenticated,
  selectAuthorized,
  selectContainerParameters,
  selectContainerType,
  selectInitialLoading,
  selectUser,
} from './slice/selectors';
import { ErrorPage } from './pages/ErrorPage/Loadable';
import { MapPage } from './pages/MapPage/Loadable';
import { MapTopBar } from './pages/MapPage/components/layout/MapTopBar';
import { InstallationInstruction } from './pages/InstallationInstructions/Loadable';
import { InstallationChannelSelector } from './pages/InstallationChannelSelector/Loadable';
import { AddCelebrationsDates } from './pages/AddCelebrationsDates/Loadable';
import { CelebrationsGotoSlack } from './pages/CelebrationsGotoSlack/Loadable';
import { CreateIcebreaker } from './pages/CreateIcebreaker/Loadable';
import { IcebreakersGotoSlack } from './pages/IcebreakersGotoSlack';
import { CoffeeTalkMatching } from './pages/CoffeeTalkMatching/Loadable';
import { CoffeeTalkProfile } from './pages/CoffeeTalkProfile/Loadable';
import { CoffeeTalkSuccess } from './pages/CoffeeTalkSuccess/Loadable';
import { CreateIntrosTemplate } from './pages/CreateIntrosTemplate/Loadable';
import { IntrosGotoSlack } from './pages/IntrosGotoSlack/Loadable';
import { MapsOnboardingAddLocationPage } from './pages/MapsOnboardingAddLocationPage/Loadable';
import { MapsGotoSlack } from './pages/MapsGotoSlack/Loadable';
import { ShoutoutValues } from './pages/ShoutoutValues/Loadable';
import { ShoutoutGotoSlack } from './pages/ShoutoutGotoSlack';
import { InstallationError } from './pages/InstallationError/Loadable';
import { HrisConnectionsPage } from './pages/Admin/HrisConnectionsPage/Loadable';
import { AdminHomePage } from './pages/Admin/AdminHomePage/Loadable';
import { BillingPage } from './pages/Admin/Billing/Loadable';
import { CoffeeTalkImportChannel } from './pages/CoffeeTalkImportChannel/Loadable';
import { LeaderboardPage } from './pages/Leaderboards/LeaderboardPage/Loadable';
import { ShoutoutAwardsGenerate } from './pages/ShoutoutAwards/ShoutoutAwardsGenerate/Loadable';
import { ShoutoutAwardsPreview } from './pages/ShoutoutAwards/ShoutoutAwardsPreview/Loadable';
import { ShoutoutAwardsGenerateDone } from './pages/ShoutoutAwards/ShoutoutAwardsGenerateDone/Loadable';
import { AdminAppsPage } from './pages/Admin/AdminAppsPage/Loadable';

const PublicRoute = ({
  component: Component,
  container = ContainerType.MAIN,
  containerParameters = {},
  ...rest
}) => {
  const { actions } = useAppSlice();
  const dispatch = useDispatch();

  const containerType = useSelector(selectContainerType);

  const setContainerInfo = () => {
    dispatch(
      actions.setContainerInfo({
        containerType: container,
        containerParameters: containerParameters,
      }),
    );
  };

  React.useLayoutEffect(() => {
    setContainerInfo();
  }, [history.location.pathname]);

  return container === containerType ? <Component /> : null;
};

const PrivateRoute = ({
  component: Component,
  container = ContainerType.MAIN,
  containerParameters = {},
  ...rest
}) => {
  const authenticated = useSelector(selectAuthenticated);
  const authorized = useSelector(selectAuthorized);
  const user = useSelector(selectUser);
  const initialLoading = useSelector(selectInitialLoading);

  const { actions } = useAppSlice();
  const dispatch = useDispatch();

  const containerType = useSelector(selectContainerType);
  const analyticsClient = React.useContext(AnalyticsClientContext);

  const setContainerInfo = () => {
    dispatch(
      actions.setContainerInfo({
        containerType: container,
        containerParameters: containerParameters,
      }),
    );
  };

  const setAuthenticated = authenticated => {
    dispatch(actions.setAuthenticated(authenticated));
  };

  const setAuthorized = authorized => {
    dispatch(actions.setAuthorized(authorized));
  };

  const isAuthGood = () => {
    return authenticated && authorized;
  };

  React.useLayoutEffect(() => {
    setContainerInfo();
  }, [history.location.pathname]);

  useEffect(() => {
    if (!authenticated) {
      console.log('Checking authentication');
      if (Authenticator.isGoodToken()) {
        setAuthenticated(true);
        setAuthorized(true);
        analyticsClient.setUser(Authenticator.getUserId());
        console.log(`set analytics user to ${Authenticator.getUserId()}`);
      } else {
        console.log('Token expired');
        setAuthenticated(true);
        setAuthorized(false);
      }
    }
  }, []);

  useEffect(() => {
    if (!!user?.company?.id) {
      analyticsClient.setCompany(user.company.id);
      console.log(`set analytics user company to ${user.company.id}`);
    }
  }, [user]);

  if (!authenticated) {
    console.log('Need to check authentication');
    return null;
  }

  /**
   * Horrid Hack Alert -
   * If you are reading this and you are not me, save this code from the evil clutches of the dragons.
   * Figure out why this method is being called even for public routes.
   * This hack was added to ensure the correct redirect URL is passed to the login page.
   */
  const path = history.location.pathname;
  const isLoginOrSignup = path === '/login' || path === '/signup';

  return initialLoading ? null : (!!user && isAuthGood()) || isLoginOrSignup ? (
    container === containerType ? (
      <Component />
    ) : null
  ) : (
    <Navigate to={{ pathname: '/login' }} state={{ from: path }} />
  );
};

export const App = () => {
  const { i18n } = useTranslation();

  const { actions } = useAppSlice();

  const dispatch = useDispatch();
  const location = useLocation();

  const containerType = useSelector(selectContainerType);
  const containerParameters = useSelector(selectContainerParameters);

  const initAuth = () => {
    dispatch(actions.initAuth());
  };

  useEffect(() => {
    const path = history.location.pathname;
    if (path.indexOf('/ice-breaker') !== -1) {
      console.log("Skipping auth due to public route '/ice-breaker'");
    } else {
      // initAuth();
      //todo: check if this affects reloading the icebreaker answer success page
    }
  }, []);

  const auth0ReturnTo = new URL(
    Authenticator.getReturnTo() ?? window.location.href,
    window.location.origin,
  );
  const element = (
    <Navigate
      to={{
        ...location,
        hash: location.hash,
        search: auth0ReturnTo.search,
        pathname: auth0ReturnTo.pathname,
      }}
    />
  );

  const mapPageElement = (
    <PublicRoute
      exact
      component={MapPage}
      container={ContainerType.MAIN}
      containerParameters={{
        showSearchBar: true,
        showProfile: false,
        topBar: <MapTopBar />,
      }}
    />
  );

  const appContainerParams = {
    showSearchBar: false,
    showNotifications: false,
    showSettings: false,
    showShare: false,
    whiteDiv: false,
    showLogin: false,
  };

  return (
    <AppContainer
      containerType={containerType}
      containerParameters={containerParameters}
    >
      <>
        <Helmet
          titleTemplate="Airspeed"
          defaultTitle="Airspeed"
          htmlAttributes={{ lang: i18n.language }}
        >
          <meta name="description" content="Airspeed" />
        </Helmet>
        <ScrollToTop />
        <Routes>
          <Route path="/callback" element={element} />
          <Route
            path="/error"
            element={
              <PublicRoute
                exact
                component={ErrorPage}
                container={ContainerType.NONE}
              />
            }
          />
          <Route
            path="/login"
            element={<Navigate to={{ pathname: '/dashboard' }} />}
          />
          <Route
            path="/signup"
            element={
              <PublicRoute
                exact
                component={LoginPage}
                container={ContainerType.NONE}
              />
            }
          />
          <Route
            path="/code-login"
            element={
              <PublicRoute
                exact
                component={LoginPage}
                container={ContainerType.NONE}
              />
            }
          />
          <Route
            path="/login/:inviter"
            element={
              <PublicRoute
                exact
                component={LoginPage}
                container={ContainerType.NONE}
              />
            }
          />
          <Route
            path="/login/:email/verify"
            element={
              <PublicRoute
                exact
                component={LoginCodePage}
                container={ContainerType.NONE}
              />
            }
          />
          <Route
            path="/onboarding"
            element={
              <PrivateRoute
                exact
                component={ProfileCompletionPage}
                container={ContainerType.BUBBLE}
                containerParameters={{
                  whiteDiv: false,
                  blueBackground: true,
                }}
              />
            }
          />
          <Route
            path="/onboarding/work"
            element={
              <PrivateRoute
                exact
                component={OnboardingWorkProfile}
                container={ContainerType.NONE}
                containerParameters={{}}
              />
            }
          />

          <Route
            path="/onboarding/play"
            element={
              <PrivateRoute
                exact
                component={OnboardingPlayProfilePage}
                container={ContainerType.NONE}
                containerParameters={{}}
              />
            }
          />
          <Route
            path="/onboarding/username"
            element={
              <PrivateRoute
                exact
                component={OnboardingUsernamePage}
                container={ContainerType.NONE}
              />
            }
          />
          <Route
            path="/onboarding/done"
            element={
              <PublicRoute
                exact
                component={OnboardingDonePage}
                container={ContainerType.BUBBLE}
                containerParameters={{
                  whiteDiv: false,
                  useV3: true,
                }}
              />
            }
          />
          <Route
            path="/onboarding-interests"
            element={
              <PrivateRoute
                exact
                component={ProfileCompletionInterestPage}
                container={ContainerType.BUBBLE}
                containerParameters={{
                  margin: '4em',
                }}
              />
            }
          />
          <Route
            path="/onboarding-question"
            element={
              <PrivateRoute
                exact
                component={ProfileCompletionQuestionPage}
                container={ContainerType.BUBBLE}
                containerParameters={{
                  margin: '4em',
                }}
              />
            }
          />
          <Route
            path="/dashboard"
            element={
              <PublicRoute
                exact
                component={ProfileHomePage}
                container={ContainerType.MAIN}
                containerParameters={{
                  showSearchBar: false,
                }}
              />
            }
          />
          <Route
            path="/dashboard/:questionId/:userId"
            element={<PrivateRoute exact component={TeammatesPage} />}
          />
          <Route
            path="/profile-view/:type"
            element={
              <PrivateRoute
                exact
                component={NthProfileViewPage}
                container={ContainerType.BUBBLE}
                containerParameters={{
                  whiteDiv: false,
                  useV3: true,
                  showLogin: false,
                }}
              />
            }
          />
          <Route
            path="/profile/:handle"
            element={<PrivateRoute exact component={ProfilePage} />}
          />
          <Route
            path="/interests"
            element={<PrivateRoute exact component={InterestPage} />}
          />
          <Route
            path="/shoutouts"
            element={<PrivateRoute exact component={ShoutOutPage} />}
          />
          <Route
            path="/shoutouts"
            element={<PrivateRoute exact component={EventsPage} />}
          />
          <Route
            path="/shoutouts/awards/done"
            element={
              <PublicRoute
                exact
                component={ShoutoutAwardsGenerateDone}
                container={ContainerType.MAIN}
                containerParameters={appContainerParams}
              />
            }
          />
          <Route
            path="/shoutouts/awards/:summaryId"
            element={
              <PublicRoute
                exact
                component={ShoutoutAwardsPreview}
                container={ContainerType.MAIN}
                containerParameters={{
                  ...appContainerParams,
                  useShadows: false,
                }}
              />
            }
          />
          <Route
            path="/shoutouts/awards"
            element={
              <PublicRoute
                exact
                component={ShoutoutAwardsGenerate}
                container={ContainerType.MAIN}
                containerParameters={appContainerParams}
              />
            }
          />
          <Route
            path="/teammates"
            element={<PrivateRoute exact component={TeammatesPage} />}
          />
          <Route
            path="/mobile"
            element={
              <PublicRoute
                exact
                component={MobileRedirectPage}
                container={ContainerType.BUBBLE}
                containerParameters={{
                  whiteDiv: false,
                  showLogin: false,
                  showFooter: false,
                  logoRedirectLink: 'https://www.getairspeed.com',
                }}
              />
            }
          />
          <Route
            path="/calendar"
            element={
              <PublicRoute
                exact
                component={CalendarRedirectPage}
                container={ContainerType.BUBBLE}
                containerParameters={{
                  whiteDiv: false,
                  showLogin: false,
                  showFooter: false,
                  logoRedirectLink: 'https://www.getairspeed.com',
                }}
              />
            }
          />
          <Route
            path="/fun"
            element={
              <PublicRoute
                exact
                component={PublicProfileLandingPage}
                container={ContainerType.BUBBLE}
                containerParameters={{
                  whiteDiv: false,
                  showLogin: false,
                }}
              />
            }
          />
          <Route
            path="/fun/reserve/start"
            element={
              <PublicRoute
                exact
                component={ReserveUsernameForm}
                container={ContainerType.BUBBLE}
                containerParameters={{
                  whiteDiv: false,
                  showLogin: false,
                }}
              />
            }
          />
          <Route
            path="/fun/reserve/confirm"
            element={
              <PublicRoute
                exact
                component={EmailConfirmationPage}
                container={ContainerType.BUBBLE}
                containerParameters={{
                  whiteDiv: false,
                  showLogin: false,
                }}
              />
            }
          />
          <Route
            path="/fun/reserve/success"
            element={
              <PublicRoute
                exact
                component={UsernameSuccessPage}
                container={ContainerType.BUBBLE}
                containerParameters={{
                  whiteDiv: false,
                  showLogin: false,
                }}
              />
            }
          />
          <Route
            path="/fun/:username/:profileType"
            element={
              <PublicRoute
                component={PublicProfilePage}
                container={ContainerType.NONE}
                containerParameters={{
                  whiteDiv: false,
                  showLogin: false,
                }}
              />
            }
          />
          <Route
            path="/fun/:username"
            element={
              <PublicRoute
                component={PublicProfilePage}
                container={ContainerType.NONE}
                containerParameters={{
                  whiteDiv: false,
                  showLogin: false,
                }}
              />
            }
          />
          <Route
            path="/ice-breaker/:handle/:userId"
            element={
              <PublicRoute
                exact
                component={AnswerIceBreaker}
                container={ContainerType.MAIN}
                containerParameters={{
                  whiteDiv: false,
                  showLogin: false,
                  showSearchBar: false,
                  showProfile: false,
                  customLogo: true,
                }}
              />
            }
          />
          <Route
            path="/ice-breaker/:handle/success"
            element={
              <PublicRoute
                exact
                component={IceBreakerShare}
                container={ContainerType.MAIN}
                containerParameters={{
                  whiteDiv: false,
                  showLogin: false,
                  showSearchBar: false,
                  showProfile: false,
                  customLogo: true,
                }}
              />
            }
          />
          <Route
            path="/ice-breaker/:handle/answers"
            element={
              <PrivateRoute
                exact
                component={IceBreakerAnswers}
                container={ContainerType.MAIN}
                containerParameters={{
                  whiteDiv: false,
                  showLogin: false,
                  showSearchBar: false,
                  showProfile: false,
                }}
              />
            }
          />
          <Route
            element={
              <PublicRoute
                component={NotFoundPage}
                container={ContainerType.BUBBLE}
                containerParameters={{
                  whiteDiv: false,
                }}
              />
            }
          />
          <Route
            path="/installation/instructions"
            element={
              <PublicRoute
                exact
                component={InstallationInstruction}
                container={ContainerType.MAIN}
                containerParameters={{
                  whiteDiv: false,
                  showLogin: false,
                  showSearchBar: false,
                  showProfile: false,
                  customLogo: false,
                }}
              />
            }
          />
          <Route path="/map">
            <Route
              path="travel"
              element={<Navigate to="/map?show_travel_modal=1" replace />}
            />
            <Route path=":workspaceId/:userId" element={mapPageElement} />
            <Route path=":workspaceId" element={mapPageElement} />
            <Route path="" element={mapPageElement} />
          </Route>
          <Route path="/web-onboarding">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={InstallationChannelSelector}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                  }}
                />
              }
            />
          </Route>
          <Route path="/web-onboarding/celebrations-dates">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={AddCelebrationsDates}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                  }}
                />
              }
            />
          </Route>
          <Route path="/web-onboarding/celebrations-slack">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={CelebrationsGotoSlack}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                  }}
                />
              }
            />
          </Route>
          <Route path="/web-onboarding/create-icebreaker">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={CreateIcebreaker}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                  }}
                />
              }
            />
          </Route>
          <Route path="/web-onboarding/create-intros">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={CreateIntrosTemplate}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                  }}
                />
              }
            />
          </Route>
          <Route path="/web-onboarding/icebreakers-slack">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={IcebreakersGotoSlack}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                  }}
                />
              }
            />
          </Route>
          <Route path="/web-onboarding/coffee-talk-match">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={CoffeeTalkMatching}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                  }}
                />
              }
            />
          </Route>
          <Route path="/web-onboarding/coffee-talk-profile">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={CoffeeTalkProfile}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                  }}
                />
              }
            />
          </Route>
          <Route path="/web-onboarding/coffee-talk-done">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={CoffeeTalkSuccess}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                  }}
                />
              }
            />
          </Route>

          <Route path="/web-onboarding/coffee-talk-import">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={CoffeeTalkImportChannel}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                  }}
                />
              }
            />
          </Route>
          <Route path="/web-onboarding/intros-slack">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={IntrosGotoSlack}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                  }}
                />
              }
            />
          </Route>
          <Route path="/web-onboarding/maps-location">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={MapsOnboardingAddLocationPage}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                  }}
                />
              }
            />
          </Route>
          <Route path="/web-onboarding/maps-slack">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={MapsGotoSlack}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                  }}
                />
              }
            />
          </Route>
          <Route path="/web-onboarding/shoutout-values">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={ShoutoutValues}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                  }}
                />
              }
            />
          </Route>
          <Route path="/web-onboarding/shoutout-slack">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={ShoutoutGotoSlack}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                  }}
                />
              }
            />
          </Route>
          <Route path="/installation-error/:type">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={InstallationError}
                  container={ContainerType.MAIN}
                  containerParameters={{
                    whiteDiv: false,
                    showLogin: false,
                    showSearchBar: false,
                    showProfile: false,
                    customLogo: false,
                    showArcFooter: true,
                  }}
                />
              }
            />
          </Route>
          <Route path="/admin">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={AdminHomePage}
                  container={ContainerType.ADMIN}
                />
              }
            />
            <Route
              path="apps"
              element={
                <PublicRoute
                  exact
                  component={AdminAppsPage}
                  container={ContainerType.ADMIN}
                />
              }
            />
            <Route
              path="integrations/rippling/oauth"
              element={
                <PublicRoute
                  exact
                  component={HrisConnectionsPage}
                  container={ContainerType.ADMIN}
                />
              }
            />
            <Route
              path="billing"
              element={
                <PublicRoute
                  exact
                  component={BillingPage}
                  container={ContainerType.ADMIN}
                  containerParameters={{
                    showSearchBar: false,
                    showProfile: false,
                    // topBar: <MapTopBar />,
                  }}
                />
              }
            />
            <Route
              path="integrations"
              element={
                <PublicRoute
                  exact
                  component={HrisConnectionsPage}
                  container={ContainerType.ADMIN}
                  containerParameters={{
                    showSearchBar: false,
                    showProfile: false,
                    // topBar: <MapTopBar />,
                  }}
                />
              }
            />
          </Route>
          <Route path="/leaderboard">
            <Route
              path=""
              element={
                <PublicRoute
                  exact
                  component={LeaderboardPage}
                  container={ContainerType.MAIN}
                  containerParameters={appContainerParams}
                />
              }
            />
          </Route>
        </Routes>
        <GlobalStyle />
      </>
    </AppContainer>
  );
};
