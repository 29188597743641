/**
 *
 * Asynchronously loads the component for InstallationInstructions
 *
 */

import { lazyLoad } from 'utils/loadable';

export const InstallationError = lazyLoad(
  () => import('./index'),
  module => module.InstallationError,
);
