/**
 *
 * ProfileMenu
 *
 */
import { useOutsideAlerter } from 'app/library/OutsideClickHelper';
import React, { memo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import User from 'app/assets/user.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSlice } from 'app/slice';
import { selectCurrentUser } from 'app/slice/selectors';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import history from 'app/library/History';

interface Props {}

interface ImageContainerProps {
  selected?: boolean;
  height?: string;
  width?: string;
}

export const ImageContainer = styled.img<ImageContainerProps>`
  margin-left: 4px;
  background-color: ${props => (props.selected ? '#F2F2F2' : '#FFFFFF')};
  cursor: pointer;
  border-radius: 50%;
  border: ${props =>
    props.selected ? '2px solid #36A9E0' : '2px solid #FFFFFF'};
  height: ${props => (props.height ? props.height : '40px')};
  width: ${props => (props.width ? props.width : '40px')};
  object-fit: cover;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const ProfileMenu = memo((props: Props) => {
  const user = useSelector(selectCurrentUser);
  const location = useLocation();

  const [selected, setSelected] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setSelected(false);
  });

  const dispatch = useDispatch();
  const { actions } = useAppSlice();

  const onClick = () => {
    if (isMobile) {
      dispatch(actions.setShowLeftNav(true));
    }

    history.push({
      search: `?state=edit_profile`,
    });
  };

  return (
    <Wrapper ref={wrapperRef}>
      <ImageContainer
        src={!!user?.image ? user.image : User}
        alt="Profile"
        onClick={onClick}
        selected={selected || location.pathname.startsWith('/profile')}
      />
    </Wrapper>
  );
});
