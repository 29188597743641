import { PayloadAction } from '@reduxjs/toolkit';
import { MapItem } from 'app/library/LocationAPI';
import { UserLocation } from 'types/User';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { mapPageSaga } from './saga';
import { MapPageState } from './types';

export const initialState: MapPageState = {
  mapItems: [],
  isDemo: false,
};

const slice = createSlice({
  name: 'mapPage',
  initialState,
  reducers: {
    setMapItems(state, action: PayloadAction<MapItem[]>) {
      state.mapItems = action.payload;
    },
    setIsDemo(state, action: PayloadAction<boolean>) {
      state.isDemo = action.payload;
    },
    load(state, action: PayloadAction) {},
    submitLocation(state, action: PayloadAction<UserLocation>) {},
    editLocation(state, action: PayloadAction<UserLocation>) {},
    deleteLocation(state, action: PayloadAction<string>) {},
    messageInSlack(state, action: PayloadAction<string[]>) {},
    reset() {
      return initialState;
    },
  },
});

export const { actions: mapPageActions } = slice;

export const useMapPageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: mapPageSaga });
  return { actions: slice.actions };
};
