import NotificationIcon from 'app/assets/notification.svg';
import { NotificationType } from 'app/library/AppConstants';
import history from 'app/library/History';
import React, { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectNotifications } from '../Notifications/slice/selectors';
import { Wrapper } from '../PageContainers';
import { ImageContainer } from '../ProfileMenu';

interface Props {}

const Badge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: rgba(254, 95, 66, 1);
  border-radius: 5px;
`;

export const Notification = memo((props: Props) => {
  const wrapperRef = useRef(null);
  let notifications = useSelector(selectNotifications).filter(
    item => item.isNew === 'true',
  );
  const handleClick = () => {
    history.push({
      search: `?state=notifications`,
    });
  };
  return (
    <Wrapper ref={wrapperRef}>
      <ImageContainer
        selected={false}
        src={NotificationIcon}
        alt="Notifications"
        onClick={handleClick}
        height="32px"
        width="32px"
      />
      {notifications?.length > 0 && <Badge />}
    </Wrapper>
  );
});
