import React from 'react';

function PremiumIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 24}
      height={props.height || 24}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_5835_12709)">
        <path
          fill={props.color || '#fff'}
          d="M14.216 3.763A1.266 1.266 0 0012.99 5.07c.012.386.198.713.479.934-1.448 1.938-2.92 2.09-4.624-1.12.607-.304.946-1.074.502-1.845-.21-.374-.642-.56-1.074-.537H8.25h-.023c-.432-.012-.853.163-1.074.537-.444.77-.105 1.541.502 1.845-1.705 3.21-3.176 3.058-4.624 1.12.28-.221.467-.56.48-.934.022-.688-.538-1.296-1.227-1.307A1.254 1.254 0 001 5.023c0 .69.56 1.262 1.26 1.262.048 0 .106 0 .153-.012l.478 6.193c.035.374.35.654.724.654h9.27c.373 0 .689-.28.724-.654l.478-6.193c.047.012.094.012.152.012a1.26 1.26 0 10-.023-2.522z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_5835_12709">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default PremiumIcon;
