import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  }
  textarea {
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea:focus, input:focus{
    outline: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    text-decoration: none;
  }

  .red {
    color: red;
  }

  .dot-collision {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #F9B233;
  color: #F9B233;
}

.dot-collision::before, .dot-collision::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-collision::before {
  left: -12px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #0060BF;
  color: #0060BF;
  animation: dotCollisionBefore 2s infinite ease-in;
}

.dot-collision::after {
  left: 12px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #FE5F42;
  color: #FE5F42;
  animation: dotCollisionAfter 2s infinite ease-in;
  animation-delay: 1s;
}

  .smalldot-collision {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #F9B233;
  color: #F9B233;
}

.smalldot-collision::before, .smalldot-collision::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.smalldot-collision::before {
  left: -8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #0060BF;
  color: #0060BF;
  animation: dotCollisionBefore 2s infinite ease-in;
}

.smalldot-collision::after {
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FE5F42;
  color: #FE5F42;
  animation: dotCollisionAfter 2s infinite ease-in;
  animation-delay: 1s;
}

@keyframes dotCollisionBefore {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-20px);
  }
}

@keyframes dotCollisionAfter {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(20px);
  }
}

@keyframes smalldotCollisionBefore {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
}

@keyframes smalldotCollisionAfter {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10px);
  }
}

  ::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar {
  width: 20px;
}
`;
