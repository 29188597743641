/**
 *
 * BubbleContainer
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import Logo from 'app/assets/logo.svg';
import { RoundedDiv } from 'app/components/RoundedDiv';
import { Footer } from 'app/components/Footer';
import { selectLoading, selectError, selectSuccess } from 'app/slice/selectors';
import { useSelector } from 'react-redux';
import Background from 'app/assets/bg_v2.svg';
import BackgroundMobile from 'app/assets/bgm_v2.svg';
import BubbleMobile from './images/bg_mobile_v1.svg';
import Bubble1024 from './images/bg_1024_v1.svg';
import Bubble1280 from './images/bg_1280_v1.svg';
import Bubble1440 from './images/bg_1440_v1.svg';
import Bubble1680 from './images/bg_1680_v1.svg';
import { AlertMessage } from '../AlertMessage';
import { CSSTransition } from 'react-transition-group';
import { Button } from 'app/components/Button';
import { selectUser } from 'app/slice/selectors';
import { gotoLogin } from 'app/library/NavHelper';
import './styles.css';

interface Props {
  children: any;
  text?: string;
  whiteDiv: boolean;
  margin: string;
  useV3: boolean;
  showLogin: boolean;
  blueBackground: boolean;
  showFooter?: boolean;
  logoRedirectLink?: string;
}

interface HideProps {
  hidden: boolean;
}

interface TransitionWrapperProps {
  show: boolean;
}

interface BubbleProps {
  useV3: boolean;
  blueBackground: boolean;
}

const TransitionWrapper = styled.div<TransitionWrapperProps>`
  display: ${props => (props.show ? 'block' : 'none')};
`;

const LogoContainer = styled.img`
  margin: 1em 0 0 1em;

  @media (max-width: 768px) {
    width: 8rem;
  }
`;

const Text = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 84px;
  line-height: 100%;
  color: white;
  width: 480px;

  @media (max-width: 1024px) {
    font-size: 72px;
  }

  @media (max-width: 895px) {
    font-size: 48px;
  }

  @media (max-width: 768px) {
    width: 200px;
    font-weight: bold;
    font-size: 1.5rem;
    margin-left: 0.5em;
  }
`;

const PageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const FormContainer = styled.div`
  width: 481px;
  margin-top: 1em;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CenteredContainer = styled.div`
  width: 100%;
  margin-top: 1em;
  display: flex;
  justify-content: center;
  margin-bottom: 2em;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TextAndForm = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;

  @media (max-width: 1024px) {
    padding: 0 2em;
  }

  @media (max-width: 768px) {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Container = styled.div<BubbleProps>`
  flex: 1;
  background-image: ${props =>
    props.useV3 ? `url(${Bubble1680})` : `url(${Background})`};
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1440px) {
    background-size: ${props => (props.useV3 ? 'cover' : 'auto')};
    background-image: ${props =>
      props.useV3 ? `url(${Bubble1440})` : `url(${Background})`};
  }

  @media (max-width: 1280px) {
    background-image: ${props =>
      props.useV3 ? `url(${Bubble1280})` : `url(${Background})`};
  }

  @media (max-width: 1024px) {
    background-image: ${props =>
      props.useV3 ? `url(${Bubble1024})` : `url(${Background})`};
  }

  @media (max-width: 768px) {
    background-image: ${props =>
      props.useV3 ? `url(${BubbleMobile})` : `url(${BackgroundMobile})`};
    background-size: ${props => (props.useV3 ? 'cover' : '100%')};
    display: initial;
    justify-content: initial;
    align-items: initial;
  }

  background-color: ${props => (props.blueBackground ? '#36A9E0' : '#FFFFFF')};
`;

const LoadingContainer = styled.div`
  display: flex;
  min-height: 500px;
  justify-content: center;
  align-items: center;
  min-width: 481px;
`;

const HideableContainer = styled.div<HideProps>`
  display: ${props => (props.hidden ? 'none' : 'block')};
`;

const AirspeedLogo = styled.div`
  position: absolute;
  top: 0.2em;
  left: 0.2em;

  @media (max-width: 768px) {
    position: initial;
    top: initial;
    left: initial;
  }
`;

const LoginContainer = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
  min-width: 130px;
`;

/**
 * TODO
 * Passing the text as a parameter is unnecessary since it's only used by one page
 * In any case, the text needs special formatting, so hard-coding below is hacky way
 * of doing it.
 */
export const BubbleContainer = React.memo(
  ({
    children,
    text,
    whiteDiv,
    margin,
    useV3,
    showLogin,
    blueBackground,
    showFooter = false,
    logoRedirectLink = 'https://www.getairspeed.com',
  }: Props) => {
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);
    const success = useSelector(selectSuccess);
    const user = useSelector(selectUser);

    const nodeRef = React.useRef(null);

    return (
      <PageContainer>
        <Container useV3={useV3} blueBackground={blueBackground}>
          <TransitionWrapper show={!!error}>
            <CSSTransition
              in={!!error}
              timeout={500}
              classNames="alertmessage"
              nodeRef={nodeRef}
            >
              <AlertMessage success={false} top={true}>
                {error}
              </AlertMessage>
            </CSSTransition>
          </TransitionWrapper>
          <TransitionWrapper show={!!success}>
            <CSSTransition
              in={!!success}
              timeout={500}
              classNames="alertmessage"
              nodeRef={nodeRef}
            >
              <AlertMessage>{success}</AlertMessage>
            </CSSTransition>
          </TransitionWrapper>
          <AirspeedLogo>
            <a href={logoRedirectLink}>
              <LogoContainer src={Logo} alt="Logo" />
            </a>
          </AirspeedLogo>
          {!!user || !showLogin ? null : (
            <LoginContainer>
              <Button onClick={() => gotoLogin()}>Log in</Button>
            </LoginContainer>
          )}
          <TextAndForm>
            {!!text ? (
              <Text>
                Cure
                <br />
                Digital
                <br />
                Disconnect.
              </Text>
            ) : null}
            {whiteDiv && !!text ? (
              <FormContainer>
                <RoundedDiv margin={margin}>
                  {loading ? (
                    <LoadingContainer>
                      <div className="dot-collision"></div>
                    </LoadingContainer>
                  ) : null}
                  <HideableContainer hidden={loading}>
                    {children}
                  </HideableContainer>
                </RoundedDiv>
              </FormContainer>
            ) : whiteDiv ? (
              <CenteredContainer>
                <RoundedDiv margin={margin}>
                  {loading ? (
                    <LoadingContainer>
                      <div className="dot-collision"></div>
                    </LoadingContainer>
                  ) : null}
                  <HideableContainer hidden={loading}>
                    {children}
                  </HideableContainer>
                </RoundedDiv>
              </CenteredContainer>
            ) : (
              <CenteredContainer>{children}</CenteredContainer>
            )}
          </TextAndForm>
        </Container>
        {showFooter && <Footer />}
      </PageContainer>
    );
  },
) as any;

BubbleContainer.defaultProps = {
  whiteDiv: true,
  margin: '0',
  useV3: false,
  showLogin: true,
  blueBackground: false,
};
