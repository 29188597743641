import { LeaderboardPeriod } from 'app/pages/Leaderboards/LeaderboardPage/slice/types';
import moment from 'moment-timezone';
export const getLocalTime = (timezone?: string) => {
  try {
    const options: Intl.DateTimeFormatOptions = {
      timeZone: timezone?.length ? timezone : 'America/Los_Angeles',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      weekday: 'short',
    };
    const formatter = new Intl.DateTimeFormat([], options);
    const formatted = formatter.format(new Date());
    const components = formatted.split(',');
    const timeComponents = components[components.length - 1].trim().split(' ');
    const day = components[0];
    const time = timeComponents[0];
    const amPm = timeComponents[1]?.toLocaleLowerCase() ?? '';
    return `${day} ${time} ${amPm}`;
  } catch (e) {
    return '';
  }
};

export const convertUserDateTimeToUTC = (userTime: string | Date) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userDate = moment.tz(userTime, userTimeZone); // create a moment-timezone object for the input string and time zone
  const utcDate = userDate.utc(); // convert the moment-timezone object to UTC
  const utcDateStr = utcDate.format('YYYY-MM-DD HH:mm:ss');
  return utcDateStr;
};

export const getCurrentUTCDateTime = () => {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = ('0' + (now.getUTCMonth() + 1)).slice(-2);
  const day = ('0' + now.getUTCDate()).slice(-2);
  const hours = ('0' + now.getUTCHours()).slice(-2);
  const minutes = ('0' + now.getUTCMinutes()).slice(-2);
  const seconds = ('0' + now.getUTCSeconds()).slice(-2);
  const utcTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return utcTime;
};

export const isDateWithinOneMonth = (dateString: string) => {
  const currentDate = new Date().getTime();
  const givenDate = new Date(dateString).getTime();
  // Calculate the difference in milliseconds between the current date and the given date
  const timeDifference = currentDate - givenDate;
  // Calculate the number of days in the time difference
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  return daysDifference <= 30;
};

export const getDayOfWeek = (date: Date) => {
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const dayIndex = new Date(date).getDay();
  return daysOfWeek[dayIndex].toUpperCase();
};

export const getDateRangeForPeriod = (
  period: LeaderboardPeriod,
): { startDate: string; endDate: string } | undefined => {
  switch (period) {
    case LeaderboardPeriod.LastWeek:
      return {
        startDate: moment().subtract(1, 'w').startOf('w').toISOString(),
        endDate: moment().subtract(1, 'w').endOf('w').toISOString(),
      };
    case LeaderboardPeriod.LastMonth:
      return {
        startDate: moment().subtract(1, 'M').startOf('M').toISOString(),
        endDate: moment().subtract(1, 'M').endOf('M').toISOString(),
      };
    case LeaderboardPeriod.LastQuarter:
      return {
        startDate: moment().subtract(1, 'Q').startOf('Q').toISOString(),
        endDate: moment().subtract(1, 'Q').endOf('Q').toISOString(),
      };
    case LeaderboardPeriod.LastYear:
      return {
        startDate: moment().subtract(1, 'y').startOf('y').toISOString(),
        endDate: moment().subtract(1, 'y').endOf('y').toISOString(),
      };
    case LeaderboardPeriod.AllTime:
      return undefined;
  }
};
