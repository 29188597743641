import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  /* padding-top: 1.5em;
  padding-bottom: 2em; */
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    width: auto;
  }
`;

export const BigSection = styled.div`
  width: 680px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 1.5em;
  }

  @media (max-width: 480px) {
    margin: 0;
  }
`;

export const BigContent = styled.div`
  padding: 1.5em;
  margin-bottom: 1.5em;
  box-sizing: border-box;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background-color: white;
  @media (max-width: 480px) {
    border-radius: 0px;
  }
`;

export const HostPageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1.5em;
`;
