import DefaultUser from 'app/assets/user.svg';
import ClearWhiteIcon from 'app/components/Icons/ClearWhiteIcon';
import { Body3, TextXSmallMedium } from 'app/components/Text';
import { AnalyticsClientContext } from 'app/library/Analytics';
import { appActions } from 'app/slice';
import {
  selectCurrentUser,
  selectIfSubscribedToPaidPlan,
  selectSlackWorkspace,
} from 'app/slice/selectors';
import React, { memo, useLayoutEffect, useState } from 'react';
import {
  Menu,
  MenuItem,
  MenuItemStyles,
  Sidebar,
  SubMenu,
} from 'react-pro-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { SidebarHeader } from '../SidebarHeader';
import { BuoyIcon } from './assets/BuoyIcon';
import { GearIcon } from './assets/GearIcon';
import { HomeIcon } from './assets/HomeIcon';
import { LogoutIcon } from './assets/LogoutIcon';
import PremiumIcon from 'app/components/Icons/PremiumIcon';
import { AppsIcon } from './assets/AppsIcon';
import BankIcon from 'app/pages/Admin/Billing/Components/Icons/BankIcon';
import ServerIcon from 'app/components/Icons/ServerIcon';
import ChatIcon from 'app/components/Icons/ChatIcon';
import GraduationHatIcon from 'app/components/Icons/GraduationHatIcon';
import { useAdminAppsPageSlice } from 'app/pages/Admin/AdminAppsPage/slice';
import {
  clearSuperUserSlackIdentity,
  getSuperUserSlackIdentity,
} from 'utils/user-workspaces';

const themes = {
  dark: {
    sidebar: {
      backgroundColor: '#101828',
      color: '#8ba1b7',
    },
    menu: {
      menuContent: '#082440',
      icon: '#59d0ff',
      hover: {
        backgroundColor: '#00458b',
        color: '#b6c8d9',
      },
      disabled: {
        color: '#3e5e7e',
      },
    },
  },
};

type AvatarLabelDivProps = {
  collapsed: boolean;
};
const AvatarLabelDiv = styled.div<AvatarLabelDivProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.collapsed ? 'center' : 'flex-start')};
  gap: 12px;
  flex: 1 0 0;
  border-top: 1px solid #475467;
  margin-right: 16px;
  margin-left: 16px;
  padding: ${props => (props.collapsed ? '24px 0px' : '24px 8px')};
`;

const AvatarImg = styled.img`
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
`;

const AvatarLabel = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

const BetaDiv = styled.div`
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 16px;
  background: #36a9e0;
`;

const UpgradeDiv = styled.div`
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 16px;
  background: rgba(242, 244, 247, 1);
`;

const TermsServicesDiv = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-left: 16px;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 16px;
  padding: 4px;
  gap: 8px;
  position: relative;
  border-radius: 4px;
`;

const ClearIconContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 5px;
  cursor: pointer;
`;

const PremiumFeatureDiv = styled.div`
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 16px;
  background: rgba(242, 244, 247, 1);
`;

const UpgradeTag = () => {
  return (
    <UpgradeDiv>
      <TextXSmallMedium color="rgba(52, 64, 84, 1)">Upgrade</TextXSmallMedium>
    </UpgradeDiv>
  );
};

const PremiumFeatureTag = () => {
  return (
    <PremiumFeatureDiv>
      <PremiumIcon width={16} height={16} color="rgba(249, 178, 51, 1)" />
    </PremiumFeatureDiv>
  );
};

const IS_AGREED_TO_NEW_TC = 'isAgreedToNewTC';

export const AdminSidebar = memo(() => {
  const [isAgreedToNewTC, setIsAgreedToNewTC] = useState(
    localStorage.getItem(IS_AGREED_TO_NEW_TC),
  );
  const [collapsed, setCollapsed] = React.useState(true);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const analyticsClient = React.useContext(AnalyticsClientContext);
  const ifSubscribedToPaidPlan = useSelector(selectIfSubscribedToPaidPlan);
  const slackWorkspace = useSelector(selectSlackWorkspace);
  const hrisEnabled = slackWorkspace?.entitlements.hris.enabled ?? false;
  const { actions: adminAppsPageActions } = useAdminAppsPageSlice();
  const onLogoutClick = () => {
    if (!!getSuperUserSlackIdentity()) {
      clearSuperUserSlackIdentity();
      window.location.reload();
      return;
    }
    dispatch(appActions.logout());
    analyticsClient.logEvent({
      eventType: 'Admin console log out',
    });
  };
  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: '16px',
      fontWeight: 600,
      color: '#F2F4F7',
      lineHeight: '24px',
      paddingRight: '16px',
      paddingLeft: '16px',
      paddingBottom: '8px',
    },
    icon: {
      color: themes.dark.menu.icon,
      width: 'auto',
      marginRight: 0,
      marginLeft: 0,
    },
    button: () => ({
      padding: collapsed ? '0' : '8px 12px',
      borderRadius: '6px',
      height: '40px',
      alignItems: 'center',
      justifyContent: collapsed ? 'space-around' : 'flex-start',
      gap: '8px',
      alignSelf: 'stretch',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: '#344054',
      },
      '&.active': {
        backgroundColor: '#344054',
      },
    }),
    label: ({ open }) => ({
      display: collapsed ? 'none' : 'block',
      fontWeight: open ? 600 : undefined,
    }),
    SubMenuExpandIcon: {
      [`span`]: {
        width: '8px',
        height: '8px',
        color: 'rgba(208, 213, 221, 1)',
      },
    },
  };

  useLayoutEffect(() => {
    function updateSize() {
      setCollapsed(window.innerWidth < 768);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div>
      <Sidebar
        width="250px"
        collapsed={collapsed}
        backgroundColor={themes.dark.sidebar.backgroundColor}
        rootStyles={{
          color: themes.dark.sidebar.color,
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <SidebarHeader collapsed={collapsed} />
          <div style={{ flex: 1, marginBottom: '32px' }}>
            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                component={<NavLink to="/admin" end />}
                icon={<HomeIcon />}
              >
                Home
              </MenuItem>
              <MenuItem
                component={<NavLink to="/admin/apps" end />}
                icon={<AppsIcon />}
              >
                Apps
              </MenuItem>
            </Menu>
          </div>
          <div>
            <Menu menuItemStyles={menuItemStyles}>
              <SubMenu
                icon={<GearIcon />}
                label="Settings"
                rootStyles={{
                  color: '#fff',
                  justifyContent: 'space-between',
                  height: '100%',
                  [`.ps-submenu-content`]: {
                    backgroundColor: themes.dark.sidebar.backgroundColor,
                  },
                }}
                style={{
                  color: '#fff',
                  backgroundColor: themes.dark.sidebar.backgroundColor,
                }}
              >
                <MenuItem
                  component={<NavLink to="/admin/billing" />}
                  icon={<BankIcon color="white" width={24} height={24} />}
                  suffix={
                    !!slackWorkspace && !ifSubscribedToPaidPlan ? (
                      <UpgradeTag />
                    ) : undefined
                  }
                >
                  Billing
                </MenuItem>
                <MenuItem
                  icon={<ServerIcon />}
                  component={<NavLink to="/admin/integrations" />}
                  suffix={
                    !!slackWorkspace && !hrisEnabled ? (
                      <PremiumFeatureTag />
                    ) : undefined
                  }
                >
                  Integration
                </MenuItem>
                {!!user?.superUser && (
                  <MenuItem
                    onClick={() =>
                      dispatch(
                        adminAppsPageActions.setIsSuperUserWorkspaceModalOpen(
                          true,
                        ),
                      )
                    }
                  >
                    Change workspace
                  </MenuItem>
                )}
              </SubMenu>

              <SubMenu
                icon={<BuoyIcon />}
                label="Support"
                rootStyles={{
                  color: '#fff',
                  justifyContent: 'space-between',
                  height: '100%',
                  [`.ps-submenu-content`]: {
                    backgroundColor: themes.dark.sidebar.backgroundColor,
                  },
                }}
                style={{
                  color: '#fff',
                  backgroundColor: themes.dark.sidebar.backgroundColor,
                }}
              >
                <MenuItem
                  target="_blank"
                  href="https://www.getairspeed.com/contact-us/"
                  icon={<ChatIcon />}
                  suffix={
                    !!slackWorkspace && !ifSubscribedToPaidPlan ? (
                      <UpgradeTag />
                    ) : undefined
                  }
                >
                  Contact us
                </MenuItem>

                <MenuItem
                  target="_blank"
                  href="https://help.getairspeed.com/en"
                  icon={<GraduationHatIcon />}
                  suffix={
                    !!slackWorkspace && !ifSubscribedToPaidPlan ? (
                      <UpgradeTag />
                    ) : undefined
                  }
                >
                  Knowledge base
                </MenuItem>
              </SubMenu>
              {/* {user?.billing && ( */}
              {/* )} */}
              {/* <MenuItem icon={<FileIcon />}>Change log</MenuItem> */}
              <MenuItem icon={<LogoutIcon />} onClick={onLogoutClick}>
                Log out
              </MenuItem>
            </Menu>

            <AvatarLabelDiv collapsed={collapsed}>
              <AvatarImg src={user?.image ?? DefaultUser} />
              {!collapsed && (
                <>
                  <AvatarLabel>
                    {user?.firstName} {user?.lastName}
                  </AvatarLabel>
                </>
              )}
            </AvatarLabelDiv>
            {!collapsed && !isAgreedToNewTC && isAgreedToNewTC !== 'true' && (
              <TermsServicesDiv>
                <Body3 color="white">
                  We have updated our{' '}
                  <a
                    href="https://www.getairspeed.com/terms/"
                    style={{ color: 'white', textDecoration: 'underline' }}
                    target="_blank"
                  >
                    Terms of Service
                  </a>
                  . By continuing to use Airspeed's apps, you’re agreeing to the
                  updated Terms of Service.
                </Body3>
                <ClearIconContainer
                  onClick={() => {
                    setIsAgreedToNewTC('true');
                    localStorage.setItem(IS_AGREED_TO_NEW_TC, 'true');
                  }}
                >
                  <ClearWhiteIcon />
                </ClearIconContainer>
              </TermsServicesDiv>
            )}
          </div>
        </div>
      </Sidebar>
    </div>
  );
});
