export const channelSelectorBaseStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    textAlign: 'left',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    textAlign: 'left',
  }),
  multiValue: styles => ({
    ...styles,
    backgroundColor: 'rgba(54, 169, 224, 1)',
    borderRadius: '16px',
    color: 'white',
    padding: '2px 8px',
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: 'white',
    fontSize: '14px',
    fontWeight: '600',
    marginLeft: '4px',
    padding: '0px',
  }),
  valueContainer: styles => ({
    ...styles,
    borderRadius: '8px',
    padding: '4px 8px',
  }),
  multiValueRemove: styles => ({
    ...styles,
    '&:hover': {
      backgroundColor: 'rgba(54, 169, 224, 1)',
      color: 'rgba(0, 0, 0, 0.25)',
      cursor: 'pointer',
    },
  }),
};
