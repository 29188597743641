import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.mapPage || initialState;

export const selectMapItems = createSelector(
  [selectSlice],
  state => state.mapItems,
);

export const selectIsDemo = createSelector(
  [selectSlice],
  state => state.isDemo,
);
