import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { searchBarSaga } from './saga';
import { SearchBarState, SearchResult } from './types';

export const initialState: SearchBarState = {
  searchText: undefined,
  searchResults: undefined,
  selection: undefined,
};

const slice = createSlice({
  name: 'searchBar',
  initialState,
  reducers: {
    setSearchText(state, action: PayloadAction<string | undefined>) {
      state.searchText = action.payload;
    },
    setSearchResults(state, action: PayloadAction<SearchResult[] | undefined>) {
      state.searchResults = action.payload;
    },
    setSelection(state, action: PayloadAction<string | undefined>) {
      state.selection = action.payload;
    },
  },
});

export const { actions: searchBarActions } = slice;

export const useSearchBarSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: searchBarSaga });
  return { actions: slice.actions };
};
