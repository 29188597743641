/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';

// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { HelmetProvider } from 'react-helmet-async';

// Initialize languages
import './locales/i18n';

// Amplitude analytics
import {
  AmplitudeClient,
  AnalyticsClientContext,
} from './app/library/Analytics';

import { createReduxHistory } from './store/reducers';
import { store } from './store/configureStore';
import history from 'app/library/History';
import { createSsr } from './utils/@issr/core';
import ReactGA from 'react-ga4';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const root = createRoot(MOUNT_NODE);

Sentry.init({
  dsn: 'https://768eca1b18a14b6ea32b9842d74b691c@o967971.ingest.sentry.io/6065011',
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_VERSION,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const SSR = createSsr();

root.render(
  <AnalyticsClientContext.Provider value={AmplitudeClient.getInstance()}>
    <SSR>
      <Provider store={store}>
        <HelmetProvider>
          <Router history={history}>
            <App />
          </Router>
        </HelmetProvider>
      </Provider>
    </SSR>
  </AnalyticsClientContext.Provider>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

if (process.env.REACT_APP_GA4_ENABLED === 'true') {
  ReactGA.initialize(process.env.REACT_APP_GA4_ID || '');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
