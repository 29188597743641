import history from 'app/library/History';

interface HomebaseParams {
  queryQuestionId?: string;
  queryUserId?: string;
  anchor?: string;
  state?: string;
}

const noHomebaseParams: HomebaseParams = {
  queryQuestionId: undefined,
  queryUserId: undefined,
  anchor: undefined,
  state: undefined,
};

interface ProfileParams {
  queryUserId?: string;
}

const noProfileParams: ProfileParams = {
  queryUserId: undefined,
};

export function gotoHomebase(params?: HomebaseParams) {
  const { queryQuestionId, queryUserId, anchor, state } =
    params || noHomebaseParams;
  history.push(
    '/dashboard' +
      (!!queryQuestionId ? `/${queryQuestionId}` : '') +
      (!!queryUserId ? `/${queryUserId}` : '') +
      (!!state ? `?state=${state}` : ''),
    { anchor: anchor },
  );
}

export function gotoTeammates() {
  history.push('/teammates');
}

export function gotoProfile(params?: ProfileParams) {
  const { queryUserId } = params || noProfileParams;
  history.push('/profile' + (!!queryUserId ? `/${queryUserId}` : ''));
}

export function gotoLogin(state?: string) {
  history.push('/login' + (!!state ? `?state=${state}` : ''));
}

export function gotoOnboardingQuestion() {
  history.push('/onboarding-question');
}

export function gotoOnboardingInterests() {
  history.push('/onboarding-interests');
}

export function gotoOnboardingPlayProfile() {
  history.push('/onboarding/play');
}

export function gotoOnboardingWorkProfile() {
  history.push('/onboarding/work');
}

export function gotoOnboardingUsername() {
  history.push('/onboarding/username');
}

export function gotoOnboardingDone() {
  history.push('/onboarding/done');
}

export function goToMap() {
  history.push('/map');
}

export function goToMapUserEditableProfile(id: string) {
  history.push(`map?user_id=${id}&state=edit_profile`);
}

export function goToSlackConversation(conversationURL: URL) {
  setTimeout(() => {
    const _ = window.open(conversationURL, '_blank');
  });
}

export function goToUrl(url: URL, newTab: boolean = true) {
  setTimeout(() => {
    const _ = window.open(url, newTab ? '_blank' : '_self');
  });
}

export function pushHistory(params: Record<string, string>) {
  const updatedParams: Record<string, string> = {
    ...Object.fromEntries(new URLSearchParams(location.search)),
    ...params,
  };
  history.push({
    search: `?${new URLSearchParams(updatedParams).toString()}`,
  });
}
