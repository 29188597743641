import {
  ResponsiveBodyMediumBold,
  ResponsiveBodyMediumNormal,
} from 'app/components/Text';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSlackWorkspace } from "../../../slice/selectors";
import { UpgradeBanner } from "../../UpgradeBanner";
import { FileUpload } from '../Components/FileUpload';
import { useAppCustomizationsPanelSlice } from '../slice';
import {
  selectCustomizationStateCopy,
  selectWorkAnniversaryBanner,
  selectWorkAnniversaryTitle,
} from '../slice/selectors';
import { Banner } from '../slice/types';

const Title = styled.span<{}>`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

const AnniversaryTitleContainer = styled.div<{}>`
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  background: rgba(0, 0, 0, 0.05);
`;

const Description = styled.span<{}>`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const Image = styled.img`
  width: 100%;
`;

const Radio = styled.input`
  height: 20px;
  width: 20px;
`;

const BannerList = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const TitleInput = styled.input`
  padding: 10px 14px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--borders-color-border, rgba(0, 0, 0, 0.25));
  background: var(--surface-color-surface, #fff);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
`;

export enum BANNER_TYPE {
  CUSTOM,
  DEFAULT,
}

type PageState = {
  selectedBanner: BANNER_TYPE;
  customBannerSrc: string | undefined;
  title: string | undefined;
};

export const WorkAnniversaryCustomization = () => {
  const stateCopy = useSelector(selectCustomizationStateCopy);
  const currentBanner = useSelector(selectWorkAnniversaryBanner);
  const currentTitle = useSelector(selectWorkAnniversaryTitle);
  console.log('currentTitle', currentTitle);
  const { workAnniversaryBanner, workAnniversaryTitle } = stateCopy;
  console.log('workAnniversaryTitle', workAnniversaryTitle);
  const dispatch = useDispatch();
  const { actions } = useAppCustomizationsPanelSlice();
  const slackWorkspace = useSelector(selectSlackWorkspace);

  const onNewBannerUpload = (url: string) => {
    const previousBanners =
      workAnniversaryBanner && workAnniversaryBanner.length
        ? workAnniversaryBanner
        : [];
    const updatedworkAnniversaryBanners: Array<Banner> = [
      ...previousBanners.map(item => ({ ...item, isActive: false })),
      { url, isActive: true },
    ];
    dispatch(
      actions.setStateCopy({
        ...stateCopy,
        workAnniversaryBanner: updatedworkAnniversaryBanners,
      }),
    );
    dispatch(actions.setIsDataChanged(true));
  };

  const onSelectBanner = (url: string) => {
    let updatedBanners =
      workAnniversaryBanner && workAnniversaryBanner.length
        ? workAnniversaryBanner
        : [];
    if (url === '') {
      updatedBanners = updatedBanners.map(item => ({
        ...item,
        isActive: false,
      }));
      dispatch(
        actions.setStateCopy({
          ...stateCopy,
          workAnniversaryBanner: updatedBanners,
        }),
      );
    } else {
      updatedBanners = updatedBanners.map(item => {
        return { ...item, isActive: item.url === url ? true : false };
      });
      dispatch(
        actions.setStateCopy({
          ...stateCopy,
          workAnniversaryBanner: updatedBanners,
        }),
      );
    }
    if (
      !_.isEqual(updatedBanners, currentBanner) ||
      !_.isEqual(workAnniversaryTitle, currentTitle)
    ) {
      dispatch(actions.setIsDataChanged(true));
    } else {
      dispatch(actions.setIsDataChanged(false));
    }
  };

  const onWorkAnniversaryTitleChange = evt => {
    dispatch(
      actions.setStateCopy({
        ...stateCopy,
        workAnniversaryTitle: evt.target.value,
      }),
    );
    if (
      !_.isEqual(workAnniversaryBanner, currentBanner) ||
      !_.isEqual(evt.target.value, currentTitle)
    ) {
      dispatch(actions.setIsDataChanged(true));
    } else {
      dispatch(actions.setIsDataChanged(false));
    }
  };

  return (
    <>
      <UpgradeBanner enabled={!slackWorkspace?.entitlements.celebrations.customBanner}/>
      <Title>Customize anniversary title</Title>
      <AnniversaryTitleContainer>
        <div>
          <ResponsiveBodyMediumNormal>{'Happy '}</ResponsiveBodyMediumNormal>
          <ResponsiveBodyMediumBold>
            &#123;NUMBER&#125;
          </ResponsiveBodyMediumBold>
        </div>
        <div style={{ flex: 1 }}>
          <TitleInput
            type="text"
            value={workAnniversaryTitle}
            onChange={onWorkAnniversaryTitleChange}
          />
        </div>
        <div>
          <ResponsiveBodyMediumBold>
            ,&#123;FIRST NAME&#125;!
          </ResponsiveBodyMediumBold>
        </div>
      </AnniversaryTitleContainer>
      <Title>Anniversary Banners</Title>
      <Description>
        Upload custom banners to make it special and unique to your
        organization. We recommend PNG or JPG at 1280x 650px.
      </Description>
      <BannerList>
        <div>
          <Radio
            type="radio"
            value={BANNER_TYPE.DEFAULT}
            name="default"
            checked={
              !workAnniversaryBanner ||
              !workAnniversaryBanner.find(item => item.isActive)
            }
            onClick={() => onSelectBanner('')}
          />
        </div>
        <div>
          <Image src={`${location.origin}/celebrations/birthday_banner.png`} />
        </div>
      </BannerList>
      {workAnniversaryBanner &&
        workAnniversaryBanner.map(item => (
          <BannerList>
            <div>
              <Radio
                type="radio"
                value={item.url}
                checked={item.isActive}
                onClick={() => onSelectBanner(item.url)}
              />
            </div>
            <div>
              <Image src={item.url} />
            </div>
          </BannerList>
        ))}
      <FileUpload onFileUpload={onNewBannerUpload}
                  disabled={!slackWorkspace?.entitlements.celebrations.customBanner}
      />
    </>
  );
};
