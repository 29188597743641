import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { profileQuestionModalSaga } from './saga';
import { ProfileQuestionModalState } from './types';
import { Highlight, HighlightQuestion } from 'types/Highlight';

export const initialState: ProfileQuestionModalState = {
  existingHighlights: undefined,
  show: false,
  error: undefined,
  currentQuestion: undefined,
  answer: undefined,
  elaborate: undefined,
  highlightEdit: undefined,
  isPost: false,
};

const slice = createSlice({
  name: 'profileQuestionModal',
  initialState,
  reducers: {
    setCurrentQuestion(
      state,
      action: PayloadAction<HighlightQuestion | undefined>,
    ) {
      state.currentQuestion = action.payload;
    },
    setAnswer(state, action: PayloadAction<string>) {
      state.answer = action.payload;
    },
    setElaborate(state, action: PayloadAction<string>) {
      state.elaborate = action.payload;
    },
    setError(state, action: PayloadAction<string | undefined>) {
      state.error = action.payload;
    },
    validateAnswer() {},
    submit(state, action: PayloadAction<File | undefined>) {},
    reset(state) {
      Object.assign(state, initialState);
    },
    show(
      state,
      action: PayloadAction<
        [Highlight[] | undefined, Highlight | undefined, boolean]
      >,
    ) {
      state.isPost = action.payload[2];
      state.show = true;
      state.existingHighlights = action.payload[0]?.map(
        existingHighlight => existingHighlight.questionLabel,
      );
      if (!!action.payload[1]) {
        state.highlightEdit = action.payload[1];
      }
    },
    hide(state) {
      state.show = false;
    },
    load() {},
  },
});

export const { actions: profileQuestionModalActions } = slice;

export const useProfileQuestionModalSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: profileQuestionModalSaga });
  return { actions: slice.actions };
};
