import React, { forwardRef, useEffect } from 'react';
import Magnifier from 'app/assets/magnifier.svg';
import Cancel from './images/cancel.svg';
import Autocomplete from 'react-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearchText, selectSearchResults } from './slice/selectors';
import { useSearchBarSlice } from './slice';
import styled from 'styled-components/macro';
import { InvitePeopleModal } from 'app/modals/InvitePeopleModal';
import { useInvitePeopleModalSlice } from 'app/modals/InvitePeopleModal/slice';
import {AnalyticsAppNames, AnalyticsClientContext} from 'app/library/Analytics';
import { useLocation } from 'react-router-dom';
import { LogoContent } from 'app/components/LogoContent';
import {
  getPlaceholderColor,
  getPlaceholderLogo,
} from 'app/library/LogoHelper';
import { BodySmallNormal } from 'app/components/Text';
import useCopyToClipboard from 'app/library/hooks/useCopyToClipboard';
import { appActions } from 'app/slice';
import history from 'app/library/History';
import {SlackbotType} from "../../../pages/InstallationChannelSelector/slice/type";
import {selectUser} from "../../../slice/selectors";

interface Props {
  onClose: any;

  slackApp?: SlackbotType;
}

const searchInputStyle = {
  backgroundImage: `url(${Magnifier})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '19px 15px',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '20px',
  boxSizing: 'border-box',
  borderRadius: '8px',
  height: '48px',
  paddingLeft: '48px',
  paddingTop: '4px',
  paddingRight: '0.75em',
  width: '100%',
  maxWidth: '1000px',
};

const Div = styled.div`
  width: 100%;
`;

const menuStyle = {
  marginTop: '8px',
  borderRadius: '8px',
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.15)',
  background: 'rgba(255, 255, 255)',
  padding: '12px',
  fontSize: '90%',
  position: 'fixed',
  overflow: 'auto',
  maxHeight: '50%',
};

const Search = styled.div`
  width: 100%;
  max-width: 600px;
  height: 48px;

  & div:nth-child(-n + 1) {
    width: 100%;
  }

  @media (max-width: 480px) {
    position: absolute;
    top: 1em;
    left: 0;
    width: 95%;
    z-index: 10000;
    margin: 0 0.5em;
    display: none;
  }
`;

const CancelButton = styled.img`
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
  display: none;
  @media (max-width: 480px) {
    display: block;
  }
`;

const SearchLogo = styled.div`
  width: 24px !important;
  margin-right: 8px;
  border: 1px solid #ffffff;
  border-radius: 999px;
`;

export type Ref = HTMLDivElement;

export const SearchBar = forwardRef<Ref, Props>((props, ref) => {
  const { actions } = useSearchBarSlice();
  const searchText = useSelector(selectSearchText);
  const searchResults = useSelector(selectSearchResults);
  const modalActions = useInvitePeopleModalSlice().actions;
  const analyticsClient = React.useContext(AnalyticsClientContext);
  const user = useSelector(selectUser)
  const location = useLocation();
  const [isFocused, setIsFocused] = React.useState(false);
  const [_, copy] = useCopyToClipboard();
  const dispatch = useDispatch();

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setSearchText(evt.target.value));
  };

  useEffect(() => {
    if (isFocused) {
      history.push({
        search: '',
      });
    }
  }, [isFocused]);

  const onSelect = (value: string) => {
    if (value !== 'invite') {
      if (props.slackApp) {
        analyticsClient.setSlackIdentity(user, props.slackApp)

        analyticsClient.logEvent({
          eventType: `${AnalyticsAppNames[props.slackApp]} search completed`
        })
      } else {
        analyticsClient.logEvent(
            {
              eventType: 'clicked on teammate profile',
              properties: { source: 'search' },
            },
            location,
        );
      }
      dispatch(actions.setSelection(value));
    } else {
      analyticsClient.logEvent(
        {
          eventType: 'clicked on invite teammate',
          properties: { source: 'search' },
        },
        location,
      );
      copy(window.location.origin + `/map`);
      dispatch(appActions.setSuccess('Link copied to clipboard.'));
      dispatch(actions.setSearchText(''));
    }
  };

  const getSearchDropdown = () => {
    const invite = {
      id: 'invite',
      label: `😔 Didn’t find who you’re looking for? Click here to copy the invite 🔗.`,
    };
    const fromServer = searchResults || [];
    const results = [...fromServer, invite];
    const items = !!searchText ? results : [];
    return items;
  };

  const formatKeyword = (label, searchText) => {
    if (!!searchText) {
      const startBoldIndex = label
        .toLowerCase()
        .indexOf(searchText.toLowerCase());

      const length = searchText.length;
      if (startBoldIndex >= 0) {
        if (startBoldIndex > 0) {
          return label;
        } else {
          return (
            <span>
              <b>{label.substring(0, length)}</b>
              {label.substring(length)}
            </span>
          );
        }
      } else {
        return label;
      }
    } else {
      return label;
    }
  };

  const formatLabel = (label, searchText) => {
    if (searchText.includes(' ')) {
      return <>{formatKeyword(label, searchText)}</>;
    } else {
      return label
        .split(' ')
        .map(part => <>{formatKeyword(part, searchText)}&nbsp;</>);
    }
  };

  const toggle = () => {
    (
      ref as React.MutableRefObject<HTMLDivElement>
    ).current.style.removeProperty('display');
    props.onClose();
  };

  return (
    <Div>
      <InvitePeopleModal />
      <Search ref={ref}>
        <Autocomplete
          menuStyle={menuStyle}
          items={getSearchDropdown()}
          getItemValue={item => item.id}
          renderItem={(item, highlighted) => {
            console.log('Item: ', item);
            return (
              <div
                key={item.id}
                style={{
                  backgroundColor: highlighted ? '#F1F3F4' : 'transparent',
                  fontSize: '14px',
                  lineHeight: '18px',
                  height: '32px',
                  padding: '4px',
                  borderRadius: '999px',
                  color: '#000000',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                {item.id == 'invite' ? null : (
                  <SearchLogo>
                    <LogoContent
                      url={item.image}
                      width="24px"
                      height="24px"
                      divWidth="24px"
                      circle={true}
                      placeholderColor={getPlaceholderColor(item.label || '')}
                      placeholderLogo={getPlaceholderLogo(item.label || '')}
                      placeholderSize="12px"
                    />
                  </SearchLogo>
                )}
                <BodySmallNormal>
                  {item.id == 'invite'
                    ? item.label
                    : formatLabel(item.label, searchText)}
                </BodySmallNormal>
              </div>
            );
          }}
          value={searchText || ''}
          onChange={onChange}
          onSelect={onSelect}
          open={!!searchText?.length}
          inputProps={{
            style: {
              ...searchInputStyle,
              border: isFocused
                ? '1px solid #0060BF'
                : '1px solid rgba(0, 0, 0, 0.25)',
              backgroundColor: isFocused ? '#FFFFFF' : '#F1F3F4',
              color: isFocused ? 'rgba(0, 0, 0, 0.9)' : 'rgba(0, 0, 0, 0.9)',
            },
            placeholder: 'Search by person...',
            onFocus: () => setIsFocused(true),
            onBlur: () => setIsFocused(false),
          }}
        />
        <CancelButton src={Cancel} onClick={toggle} />
      </Search>
    </Div>
  );
});
