import { SlackbotType } from 'app/pages/InstallationChannelSelector/slice/type';

export const ContainerType = {
  NONE: 'NONE',
  BUBBLE: 'BUBBLE',
  MAIN: 'MAIN',
  ADMIN: 'ADMIN',
};

export enum NotificationType {
  QOTW = 'qow',
  MILESTONE = '/teammates',
  NEW_USERS = 'new_users',
  COMMENTS_AND_LIKES = 'comments_and_likes',
  ICEBREAKER_ASK_TO_ANSWER = 'ice_breaker_ask_to_answer',
  PUBLIC_PROFILE_VIEW = 'PUBLIC_PROFILE_VIEW',
}

export enum FeedItemType {
  POST = 'POST',
}

export enum QuestionCategories {
  PROFILE = 'PROFILE',
  ONBOARDING = 'ONBOARDING',
  QUESTION_OF_THE_WEEK = 'QUESTION_OF_THE_WEEK',
  BIRTHDAY = 'BIRTHDAY',
  ANNIVERSARY = 'WORK_ANNIVERSARY',
}

export enum CardMetadataType {
  BIRTHDAY = 'BIRTHDAY',
  WORK_ANNIVERSARY = 'WORK_ANNIVERSARY',
  KUDOS = 'KUDOS',
  CONGRATULATION = 'CONGRATULATION',
  ASK_A_QUESTION = 'ASK_A_QUESTION',
  NEW_HIRE = 'NEW_HIRE',
  POLL = 'POLL',
  HOLIDAY = 'HOLIDAY',
  CUSTOM_CELEBRATION = 'CUSTOM_CELEBRATION',
}

export enum CelebrationType {
  BIRTHDAY = 'BIRTHDAY',
  WORK_ANNIVERSARY = 'WORK_ANNIVERSARY',
  CUSTOM_CELEBRATION = 'CUSTOM_CELEBRATION',
  COMPANY_VALUES = 'COMPANY_VALUES',
}

export const APP_NAME_LONG: Map<SlackbotType, string> = new Map([
  [SlackbotType.INTROS, 'Intros 👋 by Airspeed'],
  [SlackbotType.BIRTHDAY, 'Celebrations 🎉 by Airspeed'],
  [SlackbotType.ICE_BREAKERS, 'Icebreakers 🧊 by Airspeed'],
  [SlackbotType.SHOUTOUT, 'Shoutouts 🏆 by Airspeed'],
  [SlackbotType.MAPS, 'Maps 📍 by Airspeed'],
  [SlackbotType.COFFEE_TALK, 'Coffee Talk ☕️ by Airspeed'],
]);

export const newMonthlyCoupon = 'EARLYBOARDING2';
export const newAnnuallyCoupon = 'EARLYBOARDING6';
