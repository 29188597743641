/**
 *
 * Asynchronously loads the component for InstallationInstructions
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CoffeeTalkImportChannel = lazyLoad(
  () => import('./index'),
  module => module.CoffeeTalkImportChannel,
);
