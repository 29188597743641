import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import querySearch from 'stringquery';
import { useAppSlice } from 'app/slice';
import { Jwt } from 'app/pages/LoginPage';
import jwt_decode from 'jwt-decode';
import { Authenticator } from '../Authenticator';
import history from 'app/library/History';
import { AnalyticsClientContext } from '../Analytics';
import { selectAuthenticated, selectUser } from 'app/slice/selectors';

function useAuth() {
  const dispatch = useDispatch();
  const { actions } = useAppSlice();
  const location = useLocation();
  const user = useSelector(selectUser);
  const authenticated = useSelector(selectAuthenticated);
  const analyticsClient = useContext(AnalyticsClientContext);
  const [requireLogin, setRequireLogin] = useState(false);
  const loadCurrentUser = () => {
    dispatch(actions.loadCurrentUser());
  };

  //Try to refresh token if needed and possible
  const checkTokens = async () => {
    try {
      if (!Authenticator.hasValidToken()) {
        dispatch(actions.setLoading(true));
        const refreshed = await Authenticator.getValidToken();
        if (!refreshed) {
          dispatch(actions.setLoading(false));
          Authenticator.clearTokens();
          dispatch(actions.setAuthenticated(false));
          dispatch(actions.setAuthorized(false));
          setRequireLogin(true);
          return;
        }
      }
      dispatch(actions.setAuthenticated(true));
      dispatch(actions.setAuthorized(true));
      analyticsClient.setUser(Authenticator.getUserId());
      loadCurrentUser();
    } catch (err) {
      console.error(err);
      setRequireLogin(true);
      dispatch(actions.setLoading(false));
    }
  };

  useEffect(() => {
    if (window !== window.parent) {
      const data = {
        type: 'auth0:silent-authentication',
        hash: window.location.hash,
      };
      window.parent.postMessage(data, '*');
    }

    const setError = (error: string) => {
      dispatch(actions.setError(error));
    };

    //flow for speakeasy
    const search = querySearch(location.search);
    if (!!search && !!search['speakeasy']) {
      console.log('Heyo: useAuth speakeasy flow');
      const token = search['speakeasy'];
      const decoded: Jwt = jwt_decode(token);
      const exp = decoded.exp;
      Authenticator.setTokens({
        accessToken: token,
        idToken: token,
        expiresAt: exp,
      });

      if (Authenticator.isSpeakeasy() && !Authenticator.hasValidToken()) {
        console.log('Heyo: useAuth speakeasy expired');
        analyticsClient.setUser(Authenticator.getUserId());
        analyticsClient.logEvent({
          eventType: `Speakeasy token expired`,
          properties: {
            exp: exp,
          },
        });
      }

      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('speakeasy');
      history.push({
        search: searchParams.toString(),
      });
    }

    //flow for Sign in with Slack
    const hash = querySearch(location.hash);
    if (!!hash && !!hash['id_token'] && !!hash['access_token']) {
      const idToken = hash['id_token'];
      const accessToken = hash['access_token'];
      const decoded: Jwt = jwt_decode(idToken);
      const exp = decoded.exp;
      Authenticator.setTokens({
        accessToken: accessToken,
        idToken: idToken,
        expiresAt: exp,
      });
      history.push({
        search: window.location.search,
        hash: '',
      });

      const errorDescription = hash['error_description'];
      if (!!errorDescription) {
        setError(
          `It looks like you did not use your work email. Please try again with your work email.`,
        );
      }
    }

    const errorDescription = hash['error_description'];
    if (!!errorDescription) {
      setError(
        `Something went wrong. Please try again. Error: ${errorDescription}`,
      );
    }

    checkTokens();
  }, [actions, dispatch]);

  return { user, authenticated, requireLogin };
}

export default useAuth;
