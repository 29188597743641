import axios, { AxiosResponse } from 'axios';
import { Authenticator } from './Authenticator';

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

const getConfig = async () => {
  if (!Authenticator.hasValidToken()) {
    console.warn('No good auth token');

    const refreshed = await Authenticator.getValidToken();
    if (!refreshed) {
      console.warn('Unable to refresh token');
      return undefined;
    }
  }

  const token = Authenticator.getIdToken();
  if (token === null) {
    console.warn('Auth token missing');
    return undefined;
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  return config;
};

export class Apollo {
  static async post(data): Promise<AxiosResponse<any>> {
    const config = await getConfig();
    if (!!config) {
      return axios.post(ENDPOINT, data, config);
    } else {
      window.location.reload();
      throw new Error('Invalid auth');
    }
  }

  static handleError(err, data) {
    console.error(
      'Pegasus call failed: ',
      err,
      '\nData: ',
      data,
      '\n Errors: ',
      err.response?.data?.errors,
    );
    if (!!err?.response?.data?.errors) {
      if (err.response.data.errors[0].extensions?.code === 'UNAUTHENTICATED') {
        Authenticator.logout();
      }
      throw Error(err.response.data.errors[0].message);
    }
    throw Error(err.message);
  }
}
