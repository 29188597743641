import { isEmailValid } from 'app/library/EmailHelper';
import { addSecondaryEmail, deleteSecondaryEmail } from 'app/library/UserAPI';
import { appActions } from 'app/slice';
import { selectUser } from 'app/slice/selectors';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { SecondaryEmail, User } from 'types/User';
import { settingsPanelActions as actions } from '.';
import { selectSecondaryEmail } from './selectors';

function* doValidateEmail() {
  const email: string = yield select(selectSecondaryEmail);
  yield put(actions.setSecondaryEmailError(''));

  const isValid = isEmailValid(email);
  if (isValid) {
    return;
  }

  yield put(actions.setSecondaryEmailError('Please enter a valid email'));
}

function* doSubmit() {
  const email: string = yield select(selectSecondaryEmail);
  const user: User = yield select(selectUser);
  if (!isEmailValid(email)) {
    return;
  }

  yield put(actions.setSecondaryEmailError(''));
  yield put(appActions.setLoading(true));

  try {
    yield call(resetSecondaryEmails, {
      emails: user?.secondaryEmails || [],
    });
    yield call(addSecondaryEmail, email);
    yield put(appActions.initAuth());
    yield put(appActions.setLoading(false));
    yield put(actions.setSecondaryEmailAdded(true));
  } catch (error) {
    console.log('error', JSON.stringify(error));
    console.error(`An unexpected error occur ${JSON.stringify(error)}`);

    yield put(
      actions.setSecondaryEmailError(
        'The email is already in use. Please try another email.',
      ),
    );
    yield put(appActions.setLoading(false));
  }
}

// TODO: Make API bulk replace
function resetSecondaryEmails({ emails = [] }: { emails: SecondaryEmail[] }) {
  for (const email of emails) {
    deleteSecondaryEmail(email.id);
  }
}

function* doLoad() {
  yield put(appActions.setLoading(true));
  const user: User = yield select(selectUser);

  if (user.secondaryEmail) {
    yield put(actions.setSecondaryEmail(user?.secondaryEmail?.email));
  }

  yield put(appActions.setLoading(false));
}

export function* settingsPanelSaga() {
  yield takeLatest(actions.setSecondaryEmail.type, doValidateEmail);
  yield takeLatest(actions.submit.type, doSubmit);
  yield takeLatest(actions.load.type, doLoad);
}
