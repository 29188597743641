import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { SlackWorkspace } from '../../../../../types/Slack';
import {
  BillingPageState,
  Country,
  CreditCardFormData,
  PageState,
  PaymentMethod,
  Plan,
  PlanType,
  SelectedDurationType,
  SubscriptionPreview,
} from './type';
import { billingPageSaga } from './saga';
import { Country as GetAllCountry, State } from 'country-state-city';
import { SubscriptionInvoice, SubscriptionType } from 'app/library/BillingAPI';
import { SlackChannel } from 'types/SlackChannel';

export function plans(workspace?: SlackWorkspace): Map<PlanType, Plan> {
  return new Map([
    [
      PlanType.COACH,
      {
        type: PlanType.COACH,
        title: 'Economy',
        ifFree: true,
        isPopular: false,
        description:
          'All the basics for supporting your culture.  Free forever.',
        pricing: {
          monthly: 0,
          annually: 0,
          description_monthly: `For under ${
            workspace?.entitlements.economySeatsUpperLimit || 25
          } users`,
          description_annually: `For under ${
            workspace?.entitlements.economySeatsUpperLimit || 25
          } users`,
          saving: undefined,
        },
        features: {
          title: 'Access basic feature, plus:',
          items: [
            'Suite of Airspeed apps',
            '90 day data history',
            'Email and chat support',
            'Add apps to a single channel',
          ],
        },
      },
    ],
    [
      PlanType.BUSINESS,
      {
        type: PlanType.BUSINESS,
        title: 'Business Class',
        ifFree: false,
        isPopular: true,
        description:
          'For teams or companies that want more customization, support, and analytics.',
        pricing: {
          monthly: 3,
          annually: 24,
          description_monthly: 'per user per month',
          description_annually: 'per user per month',
          saving: '33%',
        },
        features: {
          title: 'Everything in Basic, plus:',
          items: [
            'Add apps to unlimited channels',
            'App customization options',
            'Unlimited data history',
            'Airspeed AI',
            'Zoom support',
          ],
        },
      },
    ],
    [
      PlanType.FIRST,
      {
        type: PlanType.FIRST,
        title: 'First Class',
        ifFree: false,
        isPopular: false,
        description:
          'For companies requiring deep integrations, maximum support, and a robust culture platform.',
        pricing: {
          monthly: 5,
          annually: 36,
          description_monthly: 'per user per month',
          description_annually: 'per user per month',
          saving: '40% ',
        },
        features: {
          title: 'Everything in Pro, plus:',
          items: [
            'HRIS integration',
            'Data archiving and export',
            'Slack Enterprise Grid support',
            'Admin changelog',
            'Personalized onboarding',
            'Dedicated Slack support channel',
            'Dedicated Customer Success contact',
          ],
        },
      },
    ],
  ]);
}

const creditCardFormDataDefaultValue: CreditCardFormData = {
  countryOrRegion: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  cardNumber: '',
  expiration: '',
  cvc: '',
  firstName: '',
  lastName: '',
  email: '',
  couponCode: '',
};

export const initialState: BillingPageState = {
  selectedDurationType: SelectedDurationType.MONTHLY,
  selectedPlan: PlanType.COACH,
  pageState: PageState.INITIAL,
  selectedPaymentMethod: PaymentMethod.CREDIT_CARD,
  creditCardFormData: creditCardFormDataDefaultValue,
  chargifySecurityToken: '',
  chargifyPaymentToken: '',
  subscriptionPreview: undefined,
  countries: GetAllCountry.getAllCountries().map(item => ({
    id: item.isoCode,
    label: item.name,
  })),
  isLoading: false,
  states: [],
  showSuccessMessage: false,
  showRequestInvoiceSuccessMessage: false,
  subscriptionType: SubscriptionType.WORKSPACE,
  selectedChannelsForSubscription: [],
};

const slice = createSlice({
  name: 'billingPage',
  initialState,
  reducers: {
    refreshChargifySecurityToken(state, action: PayloadAction<void>) {},
    toggleSelectedDurationType(state) {
      state.selectedDurationType =
        state.selectedDurationType === SelectedDurationType.MONTHLY
          ? SelectedDurationType.ANNUALLY
          : SelectedDurationType.MONTHLY;
    },
    setSelectedPlan(state, action: PayloadAction<PlanType>) {
      state.selectedPlan = action.payload;
    },
    setSubscriptionType(state, action: PayloadAction<SubscriptionType>) {},
    updateSubscriptionType(state, action: PayloadAction<SubscriptionType>) {
      state.subscriptionType = action.payload;
    },
    setPageState(state, action: PayloadAction<PageState>) {
      state.pageState = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setSelectedPaymentMethod(state, action: PayloadAction<PaymentMethod>) {
      state.selectedPaymentMethod = action.payload;
    },
    setChargifySecurityToken(state, action: PayloadAction<string>) {
      state.chargifySecurityToken = action.payload;
    },
    setChargifyPaymentToken(state, action: PayloadAction<string>) {
      state.chargifyPaymentToken = action.payload;
    },
    setSubscriptionPreview(state, action: PayloadAction<SubscriptionPreview>) {
      state.subscriptionPreview = action.payload;
    },
    setCountries(state, action: PayloadAction<Array<Country>>) {
      state.countries = action.payload;
    },
    setShowSuccessMessage(state, action: PayloadAction<boolean>) {
      state.showSuccessMessage = action.payload;
    },
    setShowRequestInvoiceSuccessMessage(state, action: PayloadAction<boolean>) {
      state.showRequestInvoiceSuccessMessage = action.payload;
    },
    setCreditCardFormField(
      state,
      action: PayloadAction<{ key: string; value: string }>,
    ) {
      const { key, value } = action.payload;
      state.creditCardFormData[key] = value;
      if (key === 'countryOrRegion') {
        const _states = State.getStatesOfCountry(value);
        state.states = _states.map(item => ({
          id: item.isoCode,
          label: item.name,
        }));
      }
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
    loadInvoices(state, action: PayloadAction<void>) {},
    setSelectedChannelsForSubscription(
      state,
      action: PayloadAction<Array<SlackChannel>>,
    ) {},
    updateSelectedChannelsForSubscription(
      state,
      action: PayloadAction<Array<SlackChannel>>,
    ) {
      state.selectedChannelsForSubscription = action.payload;
    },
    setSubscriptionInvoices(
      state,
      action: PayloadAction<SubscriptionInvoice[]>,
    ) {
      state.subscriptionInvoices = action.payload;
    },
  },
});

export const { actions: billingActions } = slice;

export const useBillingPageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: billingPageSaga });
  return { actions: slice.actions };
};
