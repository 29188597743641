import { Apollo } from 'app/library/Apollo';
import { Highlight, PaginatedHighlights } from 'types/Highlight';
import { createProfileQuestion } from 'app/library/ProfileQuestionAPI';

// TODO made this arbitrarily long to workaround pagination bug
const PAGE_SIZE = 100;

export function getAnswersByUser(
  userId: string,
  offset: number,
): Promise<PaginatedHighlights> {
  const data = {
    query: `
      query($userId: UserId!, $first: Int, $offset: Int) {
        getProfileElementsByUser(userId: $userId, first: $first, offset: $offset) {
          items {
            id
            order
            sourceId
            createdAt
            user {
              firstName
              lastName
              title
              location
              image {
                url
              }
            }
            displayContent
          }
        }
      }
    `,
    variables: {
      userId: userId,
      first: PAGE_SIZE,
      offset: offset,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.getProfileElementsByUser)
    .then(result => {
      const highlights: Highlight[] = result.items.map(item =>
        convertProfileElementToHighlight(item),
      );
      const nextToken =
        result.nextToken != null ? +result.nextToken : undefined;
      return {
        highlights: highlights,
        nextToken: nextToken,
      };
    })
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve({
        highlights: [],
        nextToken: undefined,
      });
    });
}

function convertProfileElementToHighlight(profileElement): Highlight {
  const user = profileElement.user;
  const displayContent = !!profileElement.displayContent
    ? JSON.parse(profileElement.displayContent)
    : {};
  const createdAt: string = profileElement.createdAt;
  const questionLabel: string = displayContent.questionText;
  const order: number | undefined = profileElement.sourceId;
  const profileFirstName: string = user.firstName;
  const profileLastName: string = user.lastName;
  const profileName: string = `${user.firstName} ${user.lastName}`;
  const profileImage: string = user.image?.url;
  const profileTitle: string | undefined = user.title;
  const profileLocation: string | undefined = user.location;
  const likes = [];
  const comments = [];
  const userId: string = user.id;
  const categoryId: string = 'PROFILE';
  const questionId: string = profileElement.sourceId;
  const answerId: string | undefined = profileElement.id;
  const answer: string | undefined = displayContent.text;

  // TODOTODO these should just be undefined
  const feedId: string = '';
  const type: string = '';
  const endsAt: string | undefined = undefined;

  return {
    createdAt: createdAt,
    questionLabel: questionLabel,
    answer: answer,
    order: order,
    displayContent: displayContent,
    profileFirstName: profileFirstName,
    profileLastName: profileLastName,
    profileName: profileName,
    profileImage: profileImage,
    profileTitle: profileTitle,
    profileLocation: profileLocation,
    likes: likes,
    comments: comments,
    userId: userId,
    categoryId: categoryId,
    questionId: questionId,
    answerId: answerId,
    feedId: feedId,
    type: type,
    endsAt: endsAt,
  };
}

export function answerQuestion(
  categoryId: string,
  questionText: string,
  text: string,
  richMediaId?: string,
) {
  createProfileQuestion(categoryId, questionText).then(profileQuestion => {
    if (!!profileQuestion) {
      const questionId = profileQuestion.id;
      createProfileElementForProfileQuestion(questionId, text, richMediaId);
    }
  });
}

function createProfileElementForProfileQuestion(
  questionId: string,
  text: string,
  richMediaId?: string,
) {
  const data = {
    query: `
      mutation create($questionId: ProfileQuestionId!, $text: String!, $image: RichMediaId) {
        createProfileElementForProfileQuestion(input: { questionId: $questionId, text: $text, image: $image }) {
          id
        }
      }
    `,
    variables: {
      questionId: questionId,
      text: text,
      image: !!richMediaId ? richMediaId : null,
    },
  };

  Apollo.post(data)
    .then(response => response.data.data.createProfileElementForProfileQuestion)
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve(undefined);
    });
}
