import axios from 'axios';
import * as EmailValidator from 'email-validator';

const WAITLIST_ENDPOINT = process.env.REACT_APP_WAITLIST_ENDPOINT || '';
const ENDPOINT = process.env.REACT_APP_PEGASUS_URL || '';
const EMAIL_VALIDATION_API = `${ENDPOINT}/auth0/validate_email`;

export function isEmailValid(email: string): boolean {
  if (!email) {
    return false;
  }

  return EmailValidator.validate(email);
}

export function sendWaitListEmail(email, companyName) {
  return axios.get(
    WAITLIST_ENDPOINT +
      `?emailDomain=${encodeURIComponent(
        email,
      )}&companyName=${encodeURIComponent(companyName)}`,
  );
}

export function isWorkEmail(email: string): Promise<any> {
  return axios.post<any>(EMAIL_VALIDATION_API, {
    email,
  });
}
