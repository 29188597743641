import React, { ReactElement, memo } from 'react';
import styled from 'styled-components/macro';
import { Button } from '../Button';
import { ResponsiveHeadingXLarge } from '../Text';
import CloseIcon from 'app/assets/close.svg';
import GoBackIcon from 'app/assets/chevron-down.svg';

interface HeaderProps {
  title: string;
  canConfirm?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onGoBack?: () => void;
  onClearNotifications?: () => void;
  emptyLeftIcon?: boolean;
}

interface RenderProps {
  children: any;
}

interface Props extends HeaderProps, RenderProps {
  footer?: ReactElement;
}

const PanelContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #e5e5e5;
  overflow-y: auto;
  float: right;
  position: relative;

  @media (min-width: 768px) {
    max-width: 600px;
    right: 0;
  }
`;

const HeaderContainer = styled.div`
  position: sticky;
  top: 0px;
  z-index: 110;
  width: 100%;
  display: flex;
  background-color: white;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
`;

const ButtonContainer = styled.div``;

const ChildrenContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
`;

const Header = ({
  title,
  onClose,
  canConfirm,
  onConfirm,
  onGoBack,
  onClearNotifications,
  emptyLeftIcon = false,
}: HeaderProps) => {
  return (
    <HeaderContainer>
      {emptyLeftIcon ? <ButtonContainer></ButtonContainer> : null}
      {onClearNotifications !== undefined ? (
        <ButtonContainer>
          <Button
            color={'#fff'}
            disabled={onClearNotifications === null}
            size="compact"
            format="pill"
            onClick={onClearNotifications}
          >
            Clear all
          </Button>
        </ButtonContainer>
      ) : null}
      {onConfirm ? (
        <ButtonContainer>
          <Button
            disabled={!canConfirm}
            size="compact"
            format="pill"
            onClick={onConfirm}
          >
            Save
          </Button>
        </ButtonContainer>
      ) : null}
      {onGoBack && !onConfirm && !onClearNotifications ? (
        <ButtonContainer>
          <Button
            size="compact"
            format="compact"
            color="transparent"
            onClick={onGoBack}
            icon={GoBackIcon}
            iconStyles={{
              rotate: '90deg',
            }}
          />
        </ButtonContainer>
      ) : null}
      <ResponsiveHeadingXLarge color={'rgba(0, 0, 0, 0.90)'}>
        {title}
      </ResponsiveHeadingXLarge>
      <ButtonContainer>
        {onClose && (
          <Button
            size="compact"
            format="compact"
            color="#fff"
            onClick={onClose}
            icon={CloseIcon}
            border={'none'}
            borderColor={'transparent'}
          />
        )}
      </ButtonContainer>
    </HeaderContainer>
  );
};

export const Wrapper = memo((props: Props) => {
  const { children, footer, ...headerProps } = props;
  return (
    <PanelContainer>
      <Header {...headerProps} />
      <ChildrenContainer>{children}</ChildrenContainer>
      {footer}
    </PanelContainer>
  );
});
