import history from 'app/library/History';
import useCopyToClipboard from 'app/library/hooks/useCopyToClipboard';
import { useSettingsPanelSlice } from 'app/pages/ProfileHomePage/slice';
import { selectSecondaryEmailAdded } from 'app/pages/ProfileHomePage/slice/selectors';
import { appActions } from 'app/slice';
import { selectUser } from 'app/slice/selectors';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CalendarIcon from '../../assets/calendar.svg';
import LinkedinIcon from '../../assets/linkedin-v2.svg';
import SlackIcon from '../../assets/slack.svg';
import MailIcon from '../../assets/mail.svg';
import ChevronRight from '../../assets/chevron-right.svg';
import { Button } from '../Button';
import { Modal, ModalContentWrapper, ModalHeader } from '../Modal';
import { Panel } from '../Panel';
import { Separator } from '../Separator';
import { ContainerType } from '../ShareLinkedin';
import { Body2, BodyMediumBold, BodySmallBold, TextXSmallNormal } from '../Text';
import { AnalyticsClientContext } from 'app/library/Analytics';
import { useLocation } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  as?: ContainerType;
  reset: () => void;
}

interface ContainerProps {
  isModal: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 0.5em;
  padding: ${props => (props.isModal ? '0' : '2em 1em')};
  justify-content: flex-start;
`;

const IconContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: center;
  padding: 1em;
  border-radius: 8px;
  gap: 1em;
  &:hover {
    background-color: #e5e8eb;
    cursor: pointer;
  }
`;

const ShareOptionInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  min-width: 200px;
`;

const Icon = styled.img<{ height?: string; width?: string }>`
  height: ${props => props.height || '48px'};
  width: ${props => props.height || '48px'};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 1em 0;
  gap: 2em;
  width: 100%;
`;
const ShareOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface ShareOptionProps {
  icon: string;
  text: string;
  description: string;
  onClick: () => void;
}

interface ShareOptions extends ShareOptionProps {
  id: string;
}

const handleOptionSelect = (id: string) =>
  history.push({
    search: `?state=${id}`,
  });

const SHARE_OPTIONS: ShareOptions[] = [
  {
    id: 'email',
    icon: MailIcon,
    text: 'Add to your email signature',
    description:
      'Help people get an idea of who you are by adding your Airspeed Profile to your email signature.',
    onClick: () => handleOptionSelect('email'),
  },
  {
    id: 'calendar',
    icon: CalendarIcon,
    text: 'Add to your calendar events  ',
    description:
      'Attendees can discover what you have in common and see where you’re based before the meeting.',
    onClick: () => handleOptionSelect('calendar'),
  },
  {
    id: 'linkedin',
    icon: LinkedinIcon,
    text: 'Add to your LinkedIn profile',
    description:
      'Help colleagues get to know you beyond your work experience by adding a link to your Airspeed Profile on your LinkedIn profile.',
    onClick: () => handleOptionSelect('linkedin'),
  },
  {
    id: 'slack',
    icon: SlackIcon,
    text: 'Share in Slack',
    description:
      'Introduce yourself to your coworkers or a new hire. Whether they’ve known you for a day or a year, they can discover something new.',
    onClick: () => handleOptionSelect('slack'),
  },
];

export const ShareButton = ({
  handleCopy,
  text,
}: {
  handleCopy: () => void;
  text?: string;
}) => (
  <ButtonContainer>
    <Button
      color="blue"
      size="compact"
      textColor="#fff"
      onClick={handleCopy}
      border={'none'}
    >
      {!!text ? text : 'Copy your profile URL'}
    </Button>
  </ButtonContainer>
);

function ShareOption({ icon, text, onClick, description }: ShareOptionProps) {
  return (
    <>
      <IconContainer onClick={onClick}>
        <Icon width={'60px'} height={'60px'} src={icon} />
        <ShareOptionInfoContainer>
          <BodySmallBold>{text}</BodySmallBold>
          <TextXSmallNormal color={'rgba(0, 0, 0, 0.6)'}>
            {description}
          </TextXSmallNormal>
        </ShareOptionInfoContainer>
        <Separator />
        <Icon width={'24px'} height={'24px'} src={ChevronRight} />
      </IconContainer>
      <Separator marginTop={'0em'} marginBottom={'0em'} />
    </>
  );
}

function InnerContent({ isModal, handleCopy }) {
  return (
    <Container isModal={isModal}>
      <Header>
        <ShareButton handleCopy={handleCopy} />
        <BodySmallBold>
          Discover what you have in common by sharing your profile:
        </BodySmallBold>
      </Header>
      <ShareOptionContainer>
        {SHARE_OPTIONS.map(option => (
          <ShareOption key={option.id} {...option} />
        ))}
      </ShareOptionContainer>
    </Container>
  );
}

function PanelContent({ handleCopy, handleReset, handleGoBack }) {
  return (
    <Panel
      title="How to use your profile"
      onClose={handleReset}
      onGoBack={handleGoBack}
    >
      <InnerContent isModal={false} handleCopy={handleCopy} />
    </Panel>
  );
}

function ModalContent({ isOpen, handleCopy, handleReset }) {
  return (
    <Modal show={isOpen} maxWidth="700px" marginTop="5%">
      <ModalHeader title="How to use your profile" onClose={handleReset} />
      <ModalContentWrapper>
        <InnerContent isModal handleCopy={handleCopy} />
      </ModalContentWrapper>
    </Modal>
  );
}

export function SharePanel({ isOpen, as = ContainerType.Panel, reset }: Props) {
  const analyticsClient = React.useContext(AnalyticsClientContext);
  const reactLocation = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const secondaryEmailAdded = useSelector(selectSecondaryEmailAdded);
  const { actions } = useSettingsPanelSlice();

  const handleReset = useCallback(() => {
    dispatch(actions.reset());
    reset();
  }, [dispatch, actions, reset]);

  useEffect(() => {
    if (secondaryEmailAdded) {
      handleReset();
    }
  }, [handleReset, secondaryEmailAdded]);

  const inviteUrl = `${process.env.REACT_APP_PUBLIC_PROFILE_URL}/fun/${user?.username}`;
  const [_, copy] = useCopyToClipboard();

  const handleCopy = () => {
    analyticsClient.logEvent(
      {
        eventType: 'Public profile link copied',
        properties: {
          Source: 'GeneralProfileLink',
          'User ID': user?.id,
        },
      },
      reactLocation,
    );
    copy(inviteUrl);
    dispatch(appActions.setSuccess('Successfully copied to clipboard.'));
  };

  const handleGoBack = () => {
    history.back();
  };

  const isModal = as === ContainerType.Modal;
  if (isModal) {
    return (
      <ModalContent
        isOpen={isOpen}
        handleCopy={handleCopy}
        handleReset={handleReset}
      />
    );
  }

  return (
    <PanelContent
      handleCopy={handleCopy}
      handleReset={handleReset}
      handleGoBack={handleGoBack}
    />
  );
}
