/**
 *
 * TopBar
 *
 */
import React, { memo, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { TopRightIcons } from '../TopRightIcons';
import LogoOnly from 'app/assets/logo_48_v1.svg';
import { SearchBar } from 'app/components/search/SearchBar';
import LogoIcebreaker from 'app/assets/logo_icebreaker.svg';

interface Props {
  showSearchBar?: boolean;
  showProfile?: boolean;
  customLogo?: boolean;
  showNotifications?: boolean;
  showSettings?: boolean;
  showShare?: boolean;
  useShadows?: boolean;
}

const Div = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  height: 5em;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  box-shadow: ${props =>
    props.useShadows ? '0px 4px 24px rgba(0, 0, 0, 0.1)' : '0'};
  z-index: 100;
  padding: 0 2em;
`;

const TopLeftContainer = styled.div`
  flex: 0 0 180px;
  padding-left: 1em;

  @media (max-width: 1024px) {
    flex: 0 0 120px;
  }

  @media (max-width: 480px) {
    flex: 0 0 180px;
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  margin: 0 1.5em;
  max-width: 680px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    margin: 0;
  }
`;

const TopRightIconsContainer = styled.div`
  flex: 0 0 180px;
  display: flex;
  justify-content: flex-end;
  padding-right: 1em;

  @media (max-width: 1024px) {
    flex: 0 0 120px;
  }

  @media (max-width: 480px) {
    flex: 0 0 180px;
  }
`;

type LogoProps = {
  logo: string;
  width: string;
};

const LogoContainer = styled.div<LogoProps>`
  background-image: url(${props => props.logo});
  background-repeat: no-repeat;
  background-size: contain;
  width: ${props => props.width};
  height: 32px;

  @media (max-width: 480px) {
    background-image: url(${LogoOnly});
    width: 44px;
  }
`;

const LogoPointer = styled.span`
  cursor: pointer;
`;

const LogoBlock = ({ customLogo = false }: Props) => {
  const location = useLocation();
  let path = '/dashboard'; // default path
  if (location.pathname.includes('/leaderboard')) {
    path = '/leaderboard';
  } else if (location.pathname.includes('/shoutouts/awards')) {
    path = '#';
  }
  return !!customLogo ? (
    <LogoContainer logo={LogoIcebreaker} width="284px" />
  ) : (
    <Link to={path}>
      <LogoPointer>
        <LogoContainer logo={'/logo/logo-chevron-airspeed.png'} width="142px" />
      </LogoPointer>
    </Link>
  );
};

export const TopBar = memo(
  ({
    showSearchBar = true,
    showProfile = true,
    customLogo = false,
    showNotifications = true,
    showSettings = true,
    showShare = true,
    useShadows = true,
  }: Props) => {
    const searchBarRef = useRef<HTMLDivElement>(document.createElement('div'));
    const topLeftRef = useRef<HTMLDivElement>(document.createElement('div'));
    const topRightRef = useRef<HTMLDivElement>(document.createElement('div'));

    const toggleOut = () => {
      if (showProfile) {
        topRightRef.current.style.removeProperty('display');
      }
      topLeftRef.current.style.removeProperty('display');
    };

    return (
      <Div useShadows={useShadows}>
        <TopLeftContainer ref={topLeftRef}>
          <LogoBlock customLogo={customLogo} />
        </TopLeftContainer>
        {showSearchBar ? (
          <SearchContainer>
            <SearchBar ref={searchBarRef} onClose={toggleOut}></SearchBar>
          </SearchContainer>
        ) : null}
        {showProfile ? (
          <TopRightIconsContainer ref={topRightRef}>
            <TopRightIcons
              showNotifications={showNotifications}
              showSettings={showSettings}
              showShare={showShare}
            />
          </TopRightIconsContainer>
        ) : null}
      </Div>
    );
  },
);
