import React from 'react';
import styled from 'styled-components';
import { BodyMediumBold, BodyMediumNormal } from 'app/components/Text';
import { Shoutout } from 'types/Shoutouts';
import { formatShoutoutDate } from 'app/library/DateConstants';
import { AdminSidePanelState } from 'app/pages/Admin/AdminHomePage/slice/type';

interface Props {
  shoutout: Shoutout;
  state: AdminSidePanelState;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex-grow: 0;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  padding: 0.5em;
`;

const QuoteContainer = styled.div`
  display: flex;
  margin-left: 0.75em;
  margin-top: 1em;
`;

const QuoteLine = styled.div`
  width: 4px;
  background-color: #36a9e0;
  border-radius: 4px;
  flex-shrink: 0;
`;

const QuoteContent = styled.div`
  padding: 0.5em;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  width: 100%;
  margin-top: 1.25em;
  border: 0.5px solid rgba(0, 0, 0, 0.25);
`;

export function ShoutoutsByUserRow({ shoutout, state }: Props) {
  return (
    <Container>
      <BodyMediumBold>{formatShoutoutDate(shoutout.createdAt)}</BodyMediumBold>
      {state == AdminSidePanelState.VIEW_SHOUTOUTS_GIVEN && (
        <BodyMediumNormal>
          🏆 Shoutout given to{' '}
          {shoutout.recipients
            .map(_ => `${[_.firstName, _.lastName].filter(_ => !!_).join(' ')}`)
            .join(', ')}
          .
        </BodyMediumNormal>
      )}
      {state == AdminSidePanelState.VIEW_SHOUTOUTS_RECEIVED && (
        <BodyMediumNormal>
          🏆 Shoutout received from{' '}
          {`${[shoutout.sender.firstName, shoutout.sender.lastName]
            .filter(_ => !!_)
            .join(' ')}`}
          .
        </BodyMediumNormal>
      )}
      <QuoteContainer>
        <QuoteLine />
        <QuoteContent>
          <BodyMediumBold>🎉 Recognized for</BodyMediumBold>
          <BodyMediumNormal>{shoutout.recognizedFor}.</BodyMediumNormal>

          {!!shoutout.impact?.length && (
            <>
              <BodyMediumBold>💥 Impact</BodyMediumBold>
              <BodyMediumNormal>{shoutout.impact}</BodyMediumNormal>
            </>
          )}

          {!!shoutout.values?.length && (
            <>
              <BodyMediumBold>🙌 Values</BodyMediumBold>
              <BodyMediumNormal>{shoutout.values}</BodyMediumNormal>
            </>
          )}
        </QuoteContent>
      </QuoteContainer>
      <Divider />
    </Container>
  );
}
