import React, { memo } from 'react';
import { MapItemUser } from 'app/library/LocationAPI';
import styled from 'styled-components';
import { LogoContent } from 'app/components/LogoContent';
import {
  getPlaceholderColor,
  getPlaceholderLogo,
} from 'app/library/LogoHelper';
import {
  BodyLargeBold,
  BodyXSmallBold,
  BodyXXSmallBold,
  TextXSmallNormal,
} from 'app/components/Text';
import ChevronRight from 'app/assets/chevron-right.svg';
import {
  formatTravelEndDate,
  formatTravelStartDate,
} from 'app/library/DateConstants';
import Checkbox from 'app/components/CheckBox';

type Props = {
  user: MapItemUser;
  checked?: boolean;
  showCheckbox?: boolean;
  checkboxDisabled?: boolean;
  onClick: () => void;
  onToggle: (isSelected: Boolean) => void;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.5em 0;
  padding-right: 1em;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const ContainerLeft = styled.div`
  height: 100%;
  min-width: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
`;

const ContainerRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ProfileColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const LogoSection = styled.div`
  position: relative;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;

const Icon = styled.img`
  height: 16px;
  width: 16px;
`;

export const UserRow = memo(
  ({
    onClick,
    onToggle,
    user,
    checkboxDisabled = false,
    showCheckbox = false,
    checked = false,
  }: Props) => {
    const fullName = (
      (user.firstName || '') +
      ' ' +
      (user.lastName || '')
    ).trim();

    const homeOffice = user.locations
      ?.filter(l => l.type == 'HOME_CURRENT')
      .shift();

    const travels =
      user.locations
        ?.filter(l => l.type == 'TRAVEL')
        .sort(
          (a, b) =>
            new Date(a.startDate!).getTime() - new Date(b.startDate!).getTime(),
        ) ?? [];

    let travelDate = '';
    if (!!travels.length) {
      travelDate = `(${formatTravelStartDate(
        travels[0].startDate,
      )} - ${formatTravelEndDate(travels[0].endDate)})`;
    }

    const travelOverflow =
      travels.length > 1 ? `+ ${travels.length - 1} more` : '';

    return (
      <Container>
        <ContainerLeft onClick={() => !checkboxDisabled && onToggle(!checked)}>
          {showCheckbox && (
            <Checkbox checked={checked} disabled={checkboxDisabled} />
          )}
        </ContainerLeft>
        <ContainerRight onClick={onClick}>
          <ProfileColumn>
            <LogoSection>
              <LogoContent
                url={user.image}
                width={'64px'}
                circle={true}
                placeholderColor={getPlaceholderColor(fullName || '')}
                placeholderLogo={getPlaceholderLogo(fullName || '')}
              />
              {/* <EmojiContainer>🏠</EmojiContainer> */}
            </LogoSection>
            <InfoSection>
              <BodyLargeBold>{fullName}</BodyLargeBold>
              {!!user.title && <BodyXXSmallBold>{user.title}</BodyXXSmallBold>}
              {!!homeOffice && (
                <TextXSmallNormal>
                  🏠{' '}
                  <TextXSmallNormal color={'rgba(0,0,0,0.6)'}>
                    {homeOffice.name}
                  </TextXSmallNormal>
                </TextXSmallNormal>
              )}
              {!!travels.length && (
                <TextXSmallNormal>
                  ✈️{' '}
                  <TextXSmallNormal color={'rgba(0,0,0,0.6)'}>
                    {travels[0].name} {travelDate}{' '}
                    <BodyXSmallBold>{travelOverflow}</BodyXSmallBold>
                  </TextXSmallNormal>
                </TextXSmallNormal>
              )}
            </InfoSection>
          </ProfileColumn>
          <Icon src={ChevronRight} />
        </ContainerRight>
      </Container>
    );
  },
);
