import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  LEADERBOARD_TYPE,
  LeaderboardApp,
  LeaderboardPeriod,
  LeaderboardState,
  LeaderboardType,
} from './types';
import { LeaderboardItem } from 'types/Leaderboard';
import { leaderboardSaga } from './saga';
import { SlackChannel } from 'types/SlackChannel';
import { SlackbotType } from 'app/pages/InstallationChannelSelector/slice/type';
import { Shoutout } from 'types/Shoutouts';

export const initialState: LeaderboardState = {
  loading: false,
  channelsLoading: false,
  apps: [],
  items: [],
  channels: [],
  filterChannelIds: [],
  errorMsg: '',
  period: LeaderboardPeriod.AllTime,
  hasData: false,
  shoutouts: [],
};

const slice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    setLeaderboardLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setLeaderboardChannelsLoading(state, action: PayloadAction<boolean>) {
      state.channelsLoading = action.payload;
    },
    setLeaderboardHasData(state, action: PayloadAction<boolean>) {
      state.hasData = action.payload;
    },
    setLeaderboardItems(state, action: PayloadAction<Array<LeaderboardItem>>) {
      state.items = action.payload;
    },
    setLeaderboardCurrentApp(
      state,
      action: PayloadAction<LeaderboardApp | undefined>,
    ) {
      state.currentApp = action.payload;
      state.currentLeaderboardType = !!action
        ? LEADERBOARD_TYPE.get(action.type as SlackbotType)
        : undefined;
    },
    setLeaderboardCurrentType(
      state,
      action: PayloadAction<LeaderboardType | undefined>,
    ) {
      state.currentLeaderboardType = action.payload;
    },
    setLeaderboardPeriod(state, action: PayloadAction<LeaderboardPeriod>) {
      state.period = action.payload;
    },
    setLeaderboardApps(state, action: PayloadAction<LeaderboardApp[]>) {
      console.log('Leaderboard: setLeaderboardApps: action', action);
      state.apps = action.payload;
    },
    setLeaderboardChannels(state, action: PayloadAction<SlackChannel[]>) {
      console.log('Leaderboard: setLeaderboardChannels: action', action);
      state.channels = action.payload;
    },
    setLeaderboardFilterChannels(state, action: PayloadAction<string[]>) {
      state.filterChannelIds = action.payload;
    },
    setShoutouts(state, action: PayloadAction<Shoutout[]>) {
      state.shoutouts = action.payload;
    },
    loadShoutouts(
      state,
      action: PayloadAction<{ senderId?: string; recipientId?: string }>,
    ) {},
    load(state, action: PayloadAction<{ first?: number } | undefined>) {},
    refresh(state, action: PayloadAction<{ first?: number } | undefined>) {},
    loadChannels(state, action: PayloadAction<void>) {},
    setErrorMsg(state, action: PayloadAction<string>) {
      state.errorMsg = action.payload;
    },
  },
});

export const { actions: leaderboardActions } = slice;

export const useLeaderboardSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: leaderboardSaga });
  return { actions: slice.actions };
};
