import { InstallationInstructionsHeader } from 'app/components/InstallationInstructionsHeader';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'app/components/Button';
import { StepIndicator } from 'app/components/StepIndicator';
import OpenIcon from 'app/assets/open_new.svg';
import { selectUser } from 'app/slice/selectors';
import { useSelector } from 'react-redux';
import Confetti from 'react-confetti';
import { Helmet } from 'react-helmet-async';
import { SlackbotType } from '../InstallationChannelSelector/slice/type';
import { AnalyticsClientContext } from '../../library/Analytics';
import { getSlackRedirectUrl } from 'utils/user-workspaces';
import { GoToSlack } from 'app/components/GoToSlack';
import { Headline1Normal } from 'app/components/Text';

interface HideProps {
  hidden: boolean;
}

const MainContent = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s ease-in;
  @media (max-width: 768px) {
    padding-bottom: 10%;
    min-height: 550px;
    padding: 1em;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2em 2em;
  max-width: 800px;
  align-items: center;
  @media (max-width: 768px) {
    padding-bottom: 10%;
    min-height: 550px;
    padding: 0 1em;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  width: 50%;

  margin-bottom: 0.5em;
  @media (max-width: 768px) {
    width: 50%;
  }
`;
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 500px;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SkipText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const HideableContainer = styled.div<HideProps>`
  display: ${props => (props.hidden ? 'none' : 'block')};
`;

const Note = styled.div`
  font-size: 14px;
  text-align: left;
  margin-bottom: 1em;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  color: rgba(0, 0, 0, 0.6);
`;
const Bold = styled.span`
  font-weight: 700;
`;

const OpenLinkImg = styled.img``;

export function ShoutoutGotoSlack() {
  const analyticsClient = React.useContext(AnalyticsClientContext);
  const user = useSelector(selectUser);
  analyticsClient.setSlackIdentity(user, SlackbotType.SHOUTOUT);

  const [showConfetti, setShowConfetti] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);
  const openSlackApp = () => {
    analyticsClient.logEvent({
      eventType: 'Shoutouts onboarding completion page',
      properties: {
        Type: 'Go to Slack',
      },
    });
    window.location.href = getSlackRedirectUrl(SlackbotType.SHOUTOUT);
  };

  const openWeb = () => {
    analyticsClient.logEvent({
      eventType: 'Shoutouts onboarding completion page',
      properties: {
        Type: 'Install other Airspeed apps',
      },
    });
    window.location.href = `https://www.getairspeed.com/product/`;
  };

  return (
    <>
      <Helmet>
        <meta property="robots" content="noindex" />
      </Helmet>
      <GoToSlack
        appName="Shoutout"
        openSlackApp={openSlackApp}
        openWeb={openWeb}
        tips={[
          <Headline1Normal>
            Use /shoutout in the channel to send a quick Shoutout!
          </Headline1Normal>,
        ]}
        stepCount={3}
        currentStepCount={4}
        title="Nice! We’d like to give you a Shoutout for setting up the app. 🏆"
        description="Shoutouts is now added to your Slack workspace. Encourage your team to begin sending Shoutouts and recognizing their teammates in the channel."
      />
    </>
  );
}
