import { AdminAppsDashboard } from 'types/AdminDashboard';

export type AdminHomePageState = {
  dashboard?: AdminAppsDashboard;
};

export enum AdminSidePanelState {
  VIEW_SHOUTOUTS_GIVEN = 'view_shoutouts',
  VIEW_SHOUTOUTS_RECEIVED = 'view_shoutouts_received',
  CUSTOMIZE_SHOUTOUTS = 'customize_shoutouts',
  CUSTOMIZE_CELEBRATIONS = 'customize_celebrations',
}
