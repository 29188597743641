/**
 * Regex to check if username is valid:
 * 1. Alphanumeric + underscore + period
 * 2. Length <= 255
 */

export const USERNAME_REGEX = /^([a-zA-Z0-9_.]){1,255}$/;

export function getHash(input) {
  var hash = 0,
    len = input.length;
  for (var i = 0; i < len; i++) {
    hash = (hash << 5) - hash + input.charCodeAt(i);
    hash |= 0; // to 32bit integer
  }
  return hash;
}

export function formatBirthday(birthdayDay, birthdayMonth) {
  return `${birthdayMonth.length === 1 ? '0' : ''}${birthdayMonth}-${
    birthdayDay.length === 1 ? '0' : ''
  }${birthdayDay}`;
}

export function formatStartDate(
  anniversaryDay,
  anniversaryMonth,
  anniversaryYear,
) {
  return `${anniversaryYear}-${
    anniversaryMonth.length === 1 ? '0' : ''
  }${anniversaryMonth}-${
    anniversaryDay.length === 1 ? '0' : ''
  }${anniversaryDay}`;
}

export function getDayDiff(startDate: Date, endDate: Date): number {
  const msInDay = 24 * 60 * 60 * 1000;

  return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay);
}

export function formatDateToString(d) {
  let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}
