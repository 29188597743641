import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { Button } from '../Button';
import { BodySmallBold, HeadingXLarge } from '../Text';
import CloseIcon from 'app/assets/close.svg';
import GoBackIcon from 'app/assets/chevron-down.svg';

interface HeaderProps {
  title: string;
  canConfirm?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  onGoBack?: () => void;
  onClearNotifications?: () => void;
  emptyLeftIcon?: boolean;
}

interface RenderProps {
  children: any;
}

interface Props extends HeaderProps, RenderProps {}

const PanelContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #e5e5e5;
  overflow-y: auto;
  float: right;

  @media (min-width: 768px) {
    right: 0;
  }
`;

const HeaderContainer = styled.div`
  position: sticky;
  top: 0px;
  z-index: 110;
  width: 100%;
  display: flex;
  background-color: white;
  padding: 18px 8px 18px 16px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
`;

const ButtonContainer = styled.div``;

const ChildrenContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
`;

const Header = ({
  title,
  onClose,
  canConfirm,
  onConfirm,
  onGoBack,
  onClearNotifications,
  emptyLeftIcon = false,
}: HeaderProps) => {
  return (
    <HeaderContainer>
      {emptyLeftIcon ? <ButtonContainer></ButtonContainer> : null}
      {onClearNotifications !== undefined ? (
        <ButtonContainer>
          <Button
            color={'#fff'}
            disabled={onClearNotifications === null}
            size="compact"
            format="pill"
            onClick={onClearNotifications}
          >
            Clear all
          </Button>
        </ButtonContainer>
      ) : null}
      {onConfirm ? (
        <ButtonContainer>
          <Button
            disabled={!canConfirm}
            size="compact"
            format="pill"
            onClick={onConfirm}
          >
            Save
          </Button>
        </ButtonContainer>
      ) : null}
      {onGoBack && !onConfirm && !onClearNotifications ? (
        <ButtonContainer>
          <Button
            size="compact"
            format="compact"
            color="transparent"
            onClick={onGoBack}
            icon={GoBackIcon}
            iconStyles={{
              rotate: '90deg',
            }}
          />
        </ButtonContainer>
      ) : null}
      <HeadingXLarge color={'black'}>{title}</HeadingXLarge>
      <div style={{ flexGrow: 1 }}></div>
      <ButtonContainer>
        <Button
          size="compact"
          format="compact"
          borderColor="transparent"
          color="#fff"
          onClick={onClose}
          icon={CloseIcon}
        />
      </ButtonContainer>
    </HeaderContainer>
  );
};

export const Panel = memo((props: Props) => {
  const { children, ...headerProps } = props;
  return (
    <PanelContainer>
      <Header {...headerProps} />
      <ChildrenContainer>{children}</ChildrenContainer>
    </PanelContainer>
  );
});
