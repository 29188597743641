/**
 *
 * Asynchronously loads the component for ProfileCompletionPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ProfileCompletionPage = lazyLoad(
  () => import('./index'),
  module => module.ProfileCompletionPage,
);
