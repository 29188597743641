import { Button } from 'app/components/Button';
import { AnalyticsClientContext } from 'app/library/Analytics';
import { updateAppCustomizations } from 'app/library/SlackAPI';
import { selectLoading, selectSlackWorkspace } from 'app/slice/selectors';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { CancelConfirmationModal } from '../Components/CancelConfirmationModal';
import { CustomizationMenuItem } from '../Components/CustomizationMenuItem';
import { Wrapper } from '../Wrapper';
import { useAppCustomizationsPanelSlice } from '../slice';
import {
  selectActiveSlackbotId,
  selectCustomizationStateCopy,
  selectIfDataChanged,
} from '../slice/selectors';
import { CompanyValuesCustomization } from './CompanyValuesCustomization';
import { ShoutoutCustomization } from './ShoutoutCustomization';

enum ShoutOutFlowsType {
  SHOUTOUT = 'SHOUTOUT',
  FEEL_GOOD_FRIDAY = 'FEEL_GOOD_FRIDAY',
  COMPANY_VALUES = 'COMPANY_VALUES',
}

const Container = styled.div`
  padding: 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
  align-self: stretch;
  width: 100%;
  display: flex;
`;

const FooterContainer = styled.div`
  position: sticky;
  bottom: 0px;
  z-index: 110;
  width: 100%;
  display: flex;
  background-color: white;
  padding: 16px;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
`;

const LoaderContainer = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  top: 0;
`;
interface Props {
  onClose: () => void;
}

enum PageState {
  INITIAL = 'INITIAL',
  SHOUTOUT = 'SHOUTOUT',
  COMPANY_VALUES = 'COMPANY_VALUES',
}

const initialTitle = '🏆 Shoutouts App Customizations';

export const Shoutouts = ({ onClose }: Props) => {
  const stateCopy = useSelector(selectCustomizationStateCopy);
  const loading = useSelector(selectLoading);
  const currentSlackbotId = useSelector(selectActiveSlackbotId);
  const { actions } = useAppCustomizationsPanelSlice();
  const dispatch = useDispatch();
  const isDataChanged = useSelector(selectIfDataChanged);
  const [panelTitle, setPanelTitle] = useState(initialTitle);
  const [pageState, setPageState] = useState<PageState>(PageState.INITIAL);
  const slackWorkspace = useSelector(selectSlackWorkspace);
  const analyticsClient = useContext(AnalyticsClientContext);

  const { panel_state: urlPageState } = Object.fromEntries(
    new URLSearchParams(location.search),
  );

  useEffect(() => {
    if (!!urlPageState && urlPageState != pageState) {
      setPageState(urlPageState as PageState);
    }
  }, [urlPageState]);

  useEffect(() => {
    analyticsClient.logEvent({
      eventType: 'Admin console shoutouts customize app opened',
      properties: {
        Workspace: slackWorkspace?.id,
      },
    });
  }, []);

  const onMenuItemClick = (shoutOutFlowType: ShoutOutFlowsType) => {
    const newPageState: PageState = shoutOutFlowType as unknown as PageState;
    setPageState(newPageState);
    setPanelTitle(
      newPageState === PageState.SHOUTOUT
        ? 'Shoutout Banners'
        : newPageState === PageState.COMPANY_VALUES
        ? 'Company Values'
        : initialTitle,
    );
  };

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);

  const onGoBack = () => {
    if (pageState === PageState.COMPANY_VALUES) {
      setPageState(PageState.INITIAL);
      setPanelTitle(initialTitle);
    } else {
      setIsConfirmationModalOpen(true);
    }
  };

  const onCancelGoBack = () => {
    setIsConfirmationModalOpen(false);
  };

  const onConfirmGoBack = () => {
    setPageState(PageState.INITIAL);
    setPanelTitle(initialTitle);
    setIsConfirmationModalOpen(false);
  };
  const menuItems = () => {
    return (
      <>
        <CustomizationMenuItem
          title="Company values"
          description="Add or edit company values based on your unique organization."
          buttonText="Customize"
          onButtonClick={() =>
            onMenuItemClick(ShoutOutFlowsType.COMPANY_VALUES)
          }
          withPaidBadge={!slackWorkspace?.entitlements.shoutouts.requireValues}
        />
        <CustomizationMenuItem
          title="Shoutout Banners"
          description="Upload custom banners to make Shoutouts special and unique to your organization."
          buttonText="Customize"
          onButtonClick={() => onMenuItemClick(ShoutOutFlowsType.SHOUTOUT)}
          withPaidBadge={
            !slackWorkspace?.entitlements.celebrations.customBanner
          }
        />
      </>
    );
  };

  const onSaveShoutout = async () => {
    try {
      const slackbotId = currentSlackbotId;
      if (!slackbotId) {
        return;
      }
      const ifUpdatedSuccessfully: boolean = await updateAppCustomizations(
        slackbotId,
        { shoutOutBannerMultipleUser: stateCopy?.shoutOutBannerMultipleUser },
      );
      if (ifUpdatedSuccessfully) {
        dispatch(
          actions.setShoutOutBannerForMultipleUser(
            stateCopy?.shoutOutBannerMultipleUser || [],
          ),
        );
        dispatch(actions.setIsDataChanged(false));
        setPageState(PageState.INITIAL);
        const ifCustomBannerEnabled =
          !!stateCopy?.shoutOutBannerMultipleUser?.find(item => item.isActive);
        analyticsClient.logEvent({
          eventType: `Admin console shoutouts app customization ${
            ifCustomBannerEnabled ? 'enabled' : 'disabled'
          }`,
          properties: {
            Workspace: slackWorkspace?.id,
            type: 'Custom 2 or more shoutout image',
            imageCount: ifCustomBannerEnabled
              ? stateCopy?.shoutOutBannerMultipleUser?.length
              : undefined,
          },
        });
      }
    } catch (err) {}
  };

  return (
    <Wrapper
      onGoBack={pageState === PageState.INITIAL ? undefined : onGoBack}
      title={panelTitle}
      onClose={
        pageState === PageState.INITIAL ||
        pageState === PageState.COMPANY_VALUES
          ? onClose
          : undefined
      }
      footer={
        pageState === PageState.INITIAL ||
        pageState === PageState.COMPANY_VALUES ? undefined : (
          <FooterContainer>
            <Button
              color="white"
              borderColor="transparent"
              buttonStyles={{ width: 'auto', padding: '12px 16px' }}
              onClick={onGoBack}
            >
              Cancel
            </Button>
            <Button
              disabled={!isDataChanged}
              buttonStyles={{ width: 'auto', padding: '12px 16px' }}
              onClick={onSaveShoutout}
            >
              Save
            </Button>
          </FooterContainer>
        )
      }
    >
      <Container>
        {pageState === PageState.INITIAL && menuItems()}
        {pageState === PageState.SHOUTOUT && <ShoutoutCustomization />}
        {pageState === PageState.COMPANY_VALUES && (
          <CompanyValuesCustomization />
        )}
      </Container>
      {loading && (
        <LoaderContainer>
          <div className="smalldot-collision"></div>
        </LoaderContainer>
      )}
      <CancelConfirmationModal
        isOpen={isConfirmationModalOpen}
        onYesClick={onConfirmGoBack}
        onClose={onCancelGoBack}
      />
    </Wrapper>
  );
};
