/**
 *
 * MainContainer
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
// import { Footer } from '../Footer';
import { TopBar } from '../TopBar';
import { useSelector } from 'react-redux';
import {
  selectError,
  selectShowLeftNav,
  selectSuccess,
} from 'app/slice/selectors';
import { AlertMessage } from '../AlertMessage';
import { CSSTransition } from 'react-transition-group';
import { isMobile } from 'react-device-detect';
import './styles.css';
import { LeftNav } from '../LeftNav/LeftNav';

interface Props {
  children: any;
  showSearchBar?: boolean;
  showProfile?: boolean;
  customLogo?: boolean;
  topBar?: React.ReactNode;
  showArcFooter?: boolean;
  showNotifications?: boolean;
  showSettings?: boolean;
  showShare?: boolean;
  useShadows?: boolean;
}

interface TransitionWrapperProps {
  show: boolean;
}

const TransitionWrapper = styled.div<TransitionWrapperProps>`
  display: ${props => (props.show ? 'block' : 'none')};
`;

const MenuAndContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const Div = styled.div``;

const Content = styled.div`
  flex-grow: 1;
  padding-bottom: 2em;
  min-height: calc(100vh - 80px);
  @media (max-width: 480px) {
    padding-bottom: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  background-color: #f3f4f5;
  margin: 0;
  overflow: hidden;
`;

const PageWrapper = styled.div`
  display: block;
  padding-top: 80px;
`;

const FooterText = styled.div`
  color: white;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  padding: 1em;
  font-size: 0.8em;
  @media (max-width: 480px) {
    display: none;
  }
`;

const Footer = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background: #36a9e0;
  height: 2.5em;
  border-radius: 45%;
  @media (max-width: 480px) {
    display: none;
  }

  &:before {
    content: '';
    background: #36a9e0;
    width: 200%;
    position: absolute;
    left: 50%;
    top: -100%;
    transform: translateX(-50%);
    height: 1000%;
    border-radius: 45%;
  }
`;

export const MainContainer = memo(
  ({
    children,
    showSearchBar,
    showProfile,
    customLogo,
    topBar,
    showArcFooter,
    showNotifications,
    showSettings,
    showShare,
    useShadows,
  }: Props) => {
    const success = useSelector(selectSuccess);
    const error = useSelector(selectError);
    const showLeftNav = useSelector(selectShowLeftNav);
    const nodeRef = React.useRef(null);

    return (
      <Div>
        {topBar ? (
          topBar
        ) : (
          <TopBar
            showSearchBar={showSearchBar}
            showProfile={showProfile}
            customLogo={customLogo}
            showNotifications={showNotifications}
            showSettings={showSettings}
            showShare={showShare}
            useShadows={useShadows}
          />
        )}
        <Wrapper>
          <PageWrapper>
            <MenuAndContent>
              <Content>{children}</Content>
            </MenuAndContent>
          </PageWrapper>
          <TransitionWrapper show={!!error}>
            <CSSTransition
              in={!!error}
              timeout={500}
              classNames="alertmessage"
              nodeRef={nodeRef}
            >
              <AlertMessage success={false} top={false}>
                {error}
              </AlertMessage>
            </CSSTransition>
          </TransitionWrapper>
          <TransitionWrapper show={!!success}>
            <CSSTransition
              in={!!success}
              timeout={500}
              classNames="alertmessage"
              nodeRef={nodeRef}
            >
              <AlertMessage>{success}</AlertMessage>
            </CSSTransition>
          </TransitionWrapper>
          {!!showArcFooter && <Footer></Footer>}
          {!!showArcFooter && (
            <FooterText>
              © {new Date().getFullYear()} Airspeed, Inc. All rights reserved
            </FooterText>
          )}
        </Wrapper>
        {isMobile && <LeftNav visible={showLeftNav} />}
      </Div>
    );
  },
);
