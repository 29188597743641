import { MatchCadence } from 'app/pages/CoffeeTalkMatching/slice/type';
import {
  CoffeeTalkCalendarDetails,
  SlackChannel,
  SlackRawUser,
} from 'types/SlackChannel';
export enum SlackbotType {
  INTROS = 'INTROS',
  BIRTHDAY = 'BIRTHDAY',
  ICE_BREAKERS = 'ICE_BREAKERS',
  SHOUTOUT = 'SHOUTOUT',
  MAPS = 'MAPS',
  COFFEE_TALK = 'COFFEE_TALK',
}

export enum ChannelFilter {
  Any = 'ANY',
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type InstallationChannelSelectorState = {
  appType: SlackbotType;
  channels: SlackChannel[];
  selectedChannel: string;
  sendToUserChecked: boolean;
  channelName: string;
  channelLabel: string;
  users: SlackRawUser[];
  selectedUsers: string[];
  frequency: MatchCadence;
  coffeTalkFirstMatchDate?: Date;
  coffeTalkMatchCount?: number;
  channelToImport?: string;
  calendarDetails?: CoffeeTalkCalendarDetails;
};

export type SlackbotChannelGroupsFilterInput = {
  channelType?: ChannelFilter;
  slackbotTypes?: Array<SlackbotType>;
  prefix?: string;
};
