/**
 *
 * Asynchronously loads the component for AboutPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const OnboardingDonePage = lazyLoad(
  () => import('./index'),
  module => module.OnboardingDonePage,
);
