import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.adminAppsPage || initialState;

export const selectIsAddChannelModalOpen = createSelector(
  [selectSlice],
  state => state.isAddChannelModalOpen,
);

export const selectIsAddAminModalOpen = createSelector(
  [selectSlice],
  state => state.isAddAdminModalOpen,
);

export const selectIsSuperUserWorkspaceModalOpen = createSelector(
  [selectSlice],
  state => state.isSuperUserWorkspaceModalModalOpen,
);
