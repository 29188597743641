import { Apollo } from 'app/library/Apollo';
import { ProfileQuestion, ProfileQuestionAnswer } from 'types/ProfileQuestion';
import {
  Highlight,
  HighlightQuestion,
  PaginatedHighlights,
  HighlightQuestionActivity,
  HighlightProfile,
} from 'types/Highlight';
import {
  convertFeedItemToHighlight,
  convertProfileQuestionAnswerToHighlight,
  getFeedItemsGql,
} from 'app/library/FeedAPI';

// TODO made this arbitrarily long to workaround pagination bug
const PAGE_SIZE = 100;

export function getQuestions(categoryId): Promise<HighlightQuestion[]> {
  const data = {
    query: `
      query {
        getProfileQuestions(first: 100, offset: 0) {
          items {
            id
            questionText
            category
          }
        }
      }
    `,
  };

  return Apollo.post(data)
    .then(response => {
      return response.data.data.getProfileQuestions.items;
    })
    .then(items =>
      items.map(item => {
        const id: string = item.id;
        const label: string = item.questionText;
        const category: string = item.category;
        return {
          id: id,
          label: label,
          type: category,
        };
      }),
    )
    .then(questions => {
      return questions.filter(question => question.type === categoryId);
    })
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve([]);
    });
}

export function answerQuestion(
  categoryId: string,
  questionText: string,
  freeTextAnswer: string,
  richMediaId?: string,
): Promise<ProfileQuestionAnswer | undefined> {
  return createProfileQuestion(categoryId, questionText).then(
    profileQuestion => {
      if (!!profileQuestion) {
        const questionId = profileQuestion.id;
        return createProfileQuestionAnswer(
          questionId,
          freeTextAnswer,
          richMediaId,
        );
      }
    },
  );
}

export function createProfileQuestion(
  categoryId: string,
  questionText: string,
): Promise<ProfileQuestion | undefined> {
  const data = {
    query: `
      mutation create($questionText: String!, $category: ProfileQuestionCategory!) {
        createProfileQuestion(input: { questionText: $questionText, category: $category }) {
          id
          questionText
          createdAt
          updatedAt
          category
        }
      }
    `,
    variables: {
      questionText: questionText,
      category: categoryId,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.createProfileQuestion)
    .then(profileQuestion => {
      const id: string = profileQuestion.id;
      const questionText: string = profileQuestion.questionText;
      const createdAt: string = profileQuestion.createdAt;
      const updatedAt: string = profileQuestion.updatedAt;
      const categoryId: string = profileQuestion.category;
      return {
        id: id,
        questionText: questionText,
        createdAt: createdAt,
        updatedAt: updatedAt,
        categoryId: categoryId,
      };
    })
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve(undefined);
    });
}

function createProfileQuestionAnswer(
  questionId: string,
  freeTextAnswer: string,
  richMediaId?: string,
): Promise<ProfileQuestionAnswer | undefined> {
  const data = {
    query: `
      mutation create($questionId: ProfileQuestionId!, $freeTextAnswer: String!, $image: RichMediaId) {
        createProfileQuestionAnswer(input: { questionId: $questionId, freeTextAnswer: $freeTextAnswer, image: $image }) {
          id
          answerText
          image {
            url
          }
          order
          user {
            id
            firstName
            lastName
            title
            image {
              url
            }
          } 
        }
      }
    `,
    variables: {
      questionId: questionId,
      freeTextAnswer: freeTextAnswer,
      image: !!richMediaId ? richMediaId : null,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.createProfileQuestionAnswer)
    .then(profileQuestionAnswer => {
      const id: string = profileQuestionAnswer.id;
      const answerText: string = profileQuestionAnswer.answerText;
      const image: string | undefined = profileQuestionAnswer.image?.url;
      const order: number = profileQuestionAnswer.order;
      const user = profileQuestionAnswer.user;
      const userId: string = user.id;
      const userFirstName: string = user.firstName;
      const userLastName: string = user.lastName;
      const userTitle: string = user.title;
      const userImage: string | undefined = user.image?.url;
      return {
        id: id,
        questionId: questionId,
        answerText: answerText,
        image: image,
        order: order,
        user: {
          id: userId,
          firstName: userFirstName,
          lastName: userLastName,
          title: userTitle,
          image: userImage,
        },
      };
    })
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve(undefined);
    });
}

export function deleteAnswer(answerId?: string): void {
  if (!!answerId) {
    const data = {
      query: `
        mutation delete($id: ProfileQuestionAnswerId) {
          deleteProfileQuestionAnswer(id: $id) {
            id
          }
        }
      `,
      variables: {
        id: answerId,
      },
    };

    Apollo.post(data)
      .then(response => response.data.data.deleteProfileQuestionAnswer)
      .catch(err => {
        Apollo.handleError(err, data);
      });
  }
}

export function moveUp(answerId?: string): void {
  if (!!answerId) {
    const data = {
      query: `
        mutation move($id: ProfileQuestionAnswerId!) {
          moveProfileQuestionAnswerUp(id: $id) {
            id
            order
          }
        }
      `,
      variables: {
        id: answerId,
      },
    };

    Apollo.post(data)
      .then(response => response.data.data.deleteProfileQuestionAnswer)
      .catch(err => {
        Apollo.handleError(err, data);
      });
  }
}

export function moveDown(answerId?: string): void {
  if (!!answerId) {
    const data = {
      query: `
        mutation move($id: ProfileQuestionAnswerId!) {
          moveProfileQuestionAnswerDn(id: $id) {
            id
            order
          }
        }
      `,
      variables: {
        id: answerId,
      },
    };

    Apollo.post(data)
      .then(response => response.data.data.deleteProfileQuestionAnswer)
      .catch(err => {
        Apollo.handleError(err, data);
      });
  }
}

export function getQuestionAnswers(
  questionId: string,
  offset: number = 0,
): Promise<PaginatedHighlights> {
  const data = {
    query: `
      query($id: ProfileQuestionId!, $first: Int, $offset: Int) {
        getProfileQuestionAnswers(id: $id, first: $first, offset: $offset) {
          items {
            id
            createdAt
            user {
              id
              firstName
              lastName
              title
              location
              image {
                url
              }
            }
            question {
              id
              questionText
              category
            }
            order
            answerText
            image {
              url
            }
            feedItem ${getFeedItemsGql()}
          }
          totalCount
          nextToken
        }
      }
    `,
    variables: {
      id: questionId,
      first: 1000,
      offset: offset,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.getProfileQuestionAnswers)
    .then(result => {
      const highlights: Highlight[] = result.items.map(item => {
        const feedItem = item.feedItem;
        return !!feedItem
          ? convertFeedItemToHighlight(item.feedItem)
          : convertProfileQuestionAnswerToHighlight(item);
      });
      const nextToken =
        result.nextToken != null ? +result.nextToken : undefined;
      return {
        highlights: highlights,
        nextToken: nextToken,
      };
    })
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve({
        highlights: [],
        nextToken: undefined,
      });
    });
}

export function getQuestion(
  questionId,
): Promise<HighlightQuestion | undefined> {
  const data = {
    query: `
      query($id: ProfileQuestionId!) {
        profileQuestion(id: $id) {
          id
          questionText
          category
        }
      }
    `,
    variables: { id: questionId },
  };

  return Apollo.post(data)
    .then(response => response.data.data.profileQuestion)
    .then(profileQuestion => {
      const id: string = profileQuestion.id;
      const label: string = profileQuestion.questionText;
      const category: string = profileQuestion.category;
      return {
        id: id,
        label: label,
        type: category,
      };
    })
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve(undefined);
    });
}

export function getTeammatesWhoAnswered(
  questionId: string,
): Promise<HighlightQuestionActivity> {
  const data = {
    query: `
      query($id: ProfileQuestionId!, $first: Int, $offset: Int) {
        getProfileQuestionAnswers(id: $id, first: $first, offset: $offset) {
          items {
            user {
              id
              firstName
              lastName
              image {
                url
              }
            }
            question {
              id
              category
            }
            answerText
          }
        }
      }
    `,
    variables: {
      id: questionId,
      first: 1000,
      offset: 0,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.getProfileQuestionAnswers)
    .then(result => {
      const items = result.items;
      if (!!items && items.length > 0) {
        const highlightProfiles: HighlightProfile[] = items.map(item => {
          const user = item.user;
          const userId: string = user.id;
          const profileName: string = `${user.firstName} ${user.lastName}`;
          const profileImage: string = user.image?.url;

          return {
            userId: userId,
            profileName: profileName,
            profileImage: profileImage,
            answer: item.answerText,
          };
        });
        const question = items[0].question;
        return {
          questionId: question.id,
          categoryId: question.category,
          profiles: highlightProfiles,
        };
      } else {
        return {
          questionId: '',
          categoryId: '',
        };
      }
    })
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve({
        questionId: '',
        categoryId: '',
      });
    });
}
