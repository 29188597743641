import {
  selectError,
  selectSlackWorkspace,
  selectSuccess,
} from 'app/slice/selectors';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { appActions as actions } from '.';
import { getCurrentUser, getUser, logoutUser } from 'app/library/UserAPI';
import { Authenticator } from 'app/library/Authenticator';
import { getCurrentSlackIdentity } from 'utils/user-workspaces';
import {
  getCompanyValues,
  getPaymentProfiles,
  getSlackChannels,
  getSlackUsers,
  getSlackWorkspace,
} from 'app/library/SlackAPI';
import { getSubscriptionsPlan } from 'app/library/BillingAPI';
import { SlackChannel, SlackRawUser } from 'types/SlackChannel';
import { ListPaymentProfiles } from 'types/Slack';
import { CompanyValue } from './types';

const ERROR_SUCCESS_MESSAGE_DURATION = 5000;

function* doLoadCurrentUser() {
  console.log('doLoadCurrentUser()');
  yield put(actions.setLoading(true));
  if (!Authenticator.hasValidToken()) {
    yield put(actions.setLoading(false));
    return;
  }
  try {
    //todo: turn getCurrentUser, getSlackWorkspace, getSubscriptionsPlan into a single call
    const user = yield call(getCurrentUser);
    yield put(actions.setCurrentUser(user));
    const slackIdentity = getCurrentSlackIdentity(user);
    if (!!slackIdentity) {
      const slackWorkspace = yield call(
        getSlackWorkspace,
        slackIdentity.slackWorkspaceId,
      );
      yield put(actions.setSlackWorkspace(slackWorkspace));

      const subscriptions = yield call(
        getSubscriptionsPlan,
        slackIdentity.slackWorkspaceId,
      );
      yield put(actions.setSubscriptions(subscriptions || []));
      if (subscriptions && subscriptions.length > 0) {
        const listPaymentProfiles: ListPaymentProfiles = yield call(
          getPaymentProfiles,
          slackIdentity.slackWorkspaceId,
        );
        yield put(actions.updatePaymentProfile(listPaymentProfiles));
      }
    }
  } catch (err) {
    console.error(JSON.stringify(err));
    yield put(actions.setError('Error loading user.'));
  }
  yield put(actions.setInitialLoading(false));
  yield put(actions.setLoading(false));
}

//Very expensive operation. Fetches all slack users from Slack API.
//todo: investigate whether this is necessary
function* doLoadSlackUsers() {
  console.log('doLoadSlackUsers()');
  yield put(actions.setLoading(true));
  try {
    const slackWorkspace = yield select(selectSlackWorkspace);
    if (!slackWorkspace?.slackbots[0].id) {
      yield put(actions.setError('No workspace found.'));
      return;
    }
    const slackUsers: SlackRawUser[] = yield call(
      getSlackUsers,
      slackWorkspace?.slackbots[0].id,
    );
    yield put(actions.setSlackRawUsers(slackUsers));
  } catch (err) {
    console.error(JSON.stringify(err));
    yield put(actions.setError('Error loading Slack users.'));
  }
  yield put(actions.setLoading(false));
}

function* doLoadSlackChannels() {
  console.log('doLoadSlackChannels()');
  yield put(actions.setLoading(true));
  try {
    const slackWorkspace = yield select(selectSlackWorkspace);
    console.log('slackWorkspace', slackWorkspace);
    if (!slackWorkspace?.slackbots[0].id) {
      yield put(actions.setError('No workspace found.'));
      return;
    }
    const channels: SlackChannel[] = yield call(
      getSlackChannels,
      slackWorkspace?.slackbots[0].id,
    );
    if (channels.length > 0) {
      channels.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      yield put(actions.setChannels(channels));
    }
  } catch (err) {
    console.error(JSON.stringify(err));
    yield put(actions.setError('Error loading Slack channels.'));
  }
  yield put(actions.setLoading(false));
}

function* doInitAuth() {
  console.log('doInitAuth()');
  yield put(actions.setLoading(true));
  if (Authenticator.isGoodToken()) {
    try {
      const user = yield call(getUser);
      yield put(actions.onAuthInitiated(user));
      const slackIdentity = getCurrentSlackIdentity(user);
      if (!!slackIdentity) {
        const slackWorkspace = yield call(
          getSlackWorkspace,
          slackIdentity.slackWorkspaceId,
        );
        yield put(actions.setSlackWorkspace(slackWorkspace));

        const subscriptions = yield call(
          getSubscriptionsPlan,
          slackIdentity.slackWorkspaceId,
        );
        yield put(actions.setSubscriptions(subscriptions || []));

        if (slackWorkspace?.slackbots[0].id) {
          const channels: SlackChannel[] = yield call(
            getSlackChannels,
            slackWorkspace?.slackbots[0].id,
          );
          if (channels.length > 0) {
            channels.sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            });
            yield put(actions.setChannels(channels));
          }

          const slackUsers: SlackRawUser[] = yield call(
            getSlackUsers,
            slackWorkspace?.slackbots[0].id,
          );
          yield put(actions.setSlackRawUsers(slackUsers));
        }
      }
    } catch (err) {
      console.error(JSON.stringify(err));
      yield put(actions.setError('Error loading user.'));
    }
  } else {
    console.log('Initializing user but no auth');
  }
  yield put(actions.setInitialLoading(false));
  yield put(actions.setLoading(false));
}

function* doSetSuccess() {
  const success = yield select(selectSuccess);
  if (!!success) {
    yield delay(ERROR_SUCCESS_MESSAGE_DURATION);
    yield put(actions.setSuccess(undefined));
  }
}

function* doSetError() {
  const error = yield select(selectError);
  if (!!error) {
    yield delay(ERROR_SUCCESS_MESSAGE_DURATION);
    yield put(actions.setError(undefined));
  }
}

function* doLogout() {
  try {
    const logout = yield call(logoutUser);
    if (!logout) {
      throw new Error('User is logged in.');
    }
    Authenticator.logout();
  } catch (err) {
    console.error(JSON.stringify(err));
    yield put(actions.setError('Error logging out.'));
  }
}

function* doRefreshPaymentProfiles(action) {
  try {
    const listPaymentProfiles: ListPaymentProfiles = yield call(
      getPaymentProfiles,
      action.payload,
    );
    yield put(actions.updatePaymentProfile(listPaymentProfiles));
  } catch (err) {
    console.error(JSON.stringify(err));
    yield put(actions.setError('Error updating payment profiles'));
  }
}

function* doRefreshCompanyValues(action) {
  yield put(actions.setLoading(true));
  try {
    const slackbotId = action.payload;
    const companyValues: { items: Array<CompanyValue> } = yield call(
      getCompanyValues,
      slackbotId,
    );
    yield put(actions.setCompanyValues(companyValues.items));
  } catch (err) {
    console.error(`An unexpected error occured ${JSON.stringify(err)}`);
  }
  yield put(actions.setLoading(false));
}

export function* appSaga() {
  yield takeLatest(actions.initAuth.type, doInitAuth);
  yield takeLatest(actions.loadCurrentUser.type, doLoadCurrentUser);
  yield takeLatest(actions.loadSlackUsers.type, doLoadSlackUsers);
  yield takeLatest(actions.loadSlackChannels.type, doLoadSlackChannels);
  yield takeLatest(actions.setError.type, doSetError);
  yield takeLatest(actions.setSuccess.type, doSetSuccess);
  yield takeLatest(actions.logout.type, doLogout);
  yield takeLatest(
    actions.refreshPaymentProfiles.type,
    doRefreshPaymentProfiles,
  );
  yield takeLatest(actions.refreshCompanyValues.type, doRefreshCompanyValues);
}
