import { NotificationType } from './AppConstants';
import { Apollo } from 'app/library/Apollo';
import { CreateNotificationInput, Notification } from 'types/Notification';

export function getNotifications(userId: string) {
  const data = {
    query: `
        query list(
          $userId: UserId!
          $activeOnly: Boolean!
          $first: Int!
          $offset: Int!
        ) {
          listNotifications(
            input: {
              userId: $userId
              first: $first
              offset: $offset
              filter: { activeOnly: $activeOnly }
            }
          ) {
            items {
              id
              userId
              sourceId
              body
              active
              updatedAt
              eventData
            }
            first
            offset
            totalCount
            nextToken
          }
        }
      `,
    variables: {
      userId: userId,
      activeOnly: false,
      first: 100,
      offset: 0,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.listNotifications.items)
    .then(items => convertBackendNotificationsToNotifications(items))
    .catch(err => Apollo.handleError(err, data));
}

export function readNotifications(notificationIds) {
  return Promise.all(
    notificationIds.map(notificationId =>
      Apollo.post(createNotificationUpdateRequest(notificationId, false)),
    ),
  ).then(response => response);
}

function convertBackendNotificationsToNotifications(
  notifications,
): Notification[] {
  return notifications.map(notification => {
    const sourceId: string = notification.sourceId;

    const eventDataString: string = notification.eventData;
    const eventData = !!eventDataString
      ? JSON.parse(eventDataString)
      : undefined;
    const notificationType = sourceId.startsWith(NotificationType.QOTW)
      ? NotificationType.QOTW
      : eventData && eventData.type;

    return {
      id: notification.id,
      isNew: `${notification.active}`,
      notificationType: notificationType,
      userName: 'undefined',
      teamName: 'undefined',
      timestampMs: notification.updatedAt,
      body: notification.body,
      sourceId: sourceId,
      eventData: eventData,
      userId: notification.userId,
    };
  });
}

function createNotificationUpdateRequest(notificationId, active) {
  const data = {
    query: `
      query update($id: NotificationId!, $active: Boolean) {
        updateNotification(input: { id: $id, active: $active }) {
          id
          userId
          sourceId
          body
          active
        }
      }
    `,
    variables: {
      id: notificationId,
      active: active,
    },
  };

  return data;
}

export function createNotification({
  userId,
  sourceId,
  eventData,
}: {
  userId: string;
  sourceId: string;
  eventData?: string;
}): Promise<Notification> {
  const input: CreateNotificationInput = {
    userId: userId,
    sourceId: sourceId,
    body: '',
    eventData,
  };

  const data = {
    query: `
      mutation create($userId: UserId!, $sourceId: ID!, $body: String!, $eventData: String) {
        createNotification(input: { userId: $userId, sourceId: $sourceId, body: $body, eventData: $eventData }) {
          id
          userId
          sourceId
          body
          active
        }
      }
    `,
    variables: {
      ...input,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.createNotification)
    .then(
      notification =>
        convertBackendNotificationsToNotifications([notification])[0],
    );
}
