import { profileQuestionModalActions as actions } from '.';
import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import { appActions } from 'app/slice';
import { profileHighlightsActions } from 'app/pages/ProfilePage/ProfileHighlights/slice';
import {
  selectExistingHighlights,
  selectAnswer,
  selectCurrentQuestion,
  selectElaborate,
  selectHighlightEdit,
  selectIsPost,
} from './selectors';
import { uploadFile } from 'app/library/FileUploader';
import { HighlightQuestion, Highlight } from 'types/Highlight';
import { answerQuestion } from 'app/library/ProfileQuestionAPI';
import { createPost } from 'app/library/PostAPI';
import { getUploadUrl, createRichMedia } from 'app/library/RichMediaAPI';
import { RichMedia } from 'types/RichMedia';
import { selectUser } from 'app/slice/selectors';
import { User } from 'types/User';
import { getQuestions, getQuestion } from 'app/library/ProfileQuestionAPI';
import { QuestionCategories } from 'app/library/AppConstants';
import { shuffleQuestionActions } from 'app/components/ShuffleQuestion/slice';

function* doLoad() {
  yield put(appActions.setLoading(true));
  try {
    const highlightEdit: Highlight = yield select(selectHighlightEdit);
    const existingHighlights: string[] | undefined = yield select(
      selectExistingHighlights,
    );
    const questions: HighlightQuestion[] = yield call(
      getQuestions,
      QuestionCategories.PROFILE,
    );
    const shuffleQuestions = questions.filter(question => {
      return !existingHighlights?.includes(question.label);
    });
    if (!!highlightEdit) {
      const editAnswer = highlightEdit.answer;
      yield put(actions.setAnswer(editAnswer || ''));
      yield put(actions.setElaborate(highlightEdit.details || ''));
      yield put(
        shuffleQuestionActions.setQuestion([
          shuffleQuestions,
          {
            id: highlightEdit.questionId,
            label: highlightEdit.questionLabel,
            type: highlightEdit.type,
          },
        ]),
      );
    } else {
      const currentQuestion = yield select(selectCurrentQuestion);
      yield put(
        shuffleQuestionActions.setQuestion([shuffleQuestions, currentQuestion]),
      );
    }
  } catch (err) {
    console.error(`An unexpected error occur ${err}`);
    yield put(appActions.setError(JSON.stringify(err)));
  }
  yield put(appActions.setLoading(false));
}

function* doSubmit(action) {
  const freeTextAnswer = yield select(selectAnswer);
  const image = action.payload;
  const details = yield select(selectElaborate); // GIPHY
  const question: HighlightQuestion = yield select(selectCurrentQuestion);
  const isPost = yield select(selectIsPost);
  if (!isPost && !freeTextAnswer) {
    yield put(actions.setError('You left your answer blank!'));
    return;
  }

  if (isPost && !freeTextAnswer && !image && !details) {
    yield put(actions.setError('Text or media are required!'));
    return;
  }

  yield put(actions.setError(undefined));
  yield put(appActions.setLoading(true));

  const highlightEdit: Highlight = yield select(selectHighlightEdit);
  const user: User = yield select(selectUser);
  const company = user.company!;
  try {
    const richMedia: RichMedia | undefined = !!image
      ? yield call(getUploadUrl, image.type, company)
      : !!details
      ? yield call(createRichMedia, company, details)
      : undefined;
    const richMediaId: string | undefined = richMedia?.id;

    if (!!image) {
      yield call(uploadFile, richMedia?.uploadUrl, image);
    }

    // TODOTODO brute-force fix for editing QUESTION_OF_THE_WEEK, BIRTHDAY, ANNIVERSARY
    // Root issue: why is the type missing in the first place?
    if (!!question) {
      let questionType = question.type;
      if (questionType === '') {
        const pq: HighlightQuestion | undefined = yield call(
          getQuestion,
          question.id,
        );
        if (!!pq) {
          questionType = pq.type;
        }
      }

      yield call(
        answerQuestion,
        questionType,
        question.label,
        freeTextAnswer,
        richMediaId,
      );
    } else {
      const postText = freeTextAnswer || '';
      yield call(createPost, company.id, postText, richMediaId);
    }

    if (!!highlightEdit) {
      yield put(appActions.setSuccess(`Updated profile highlight.`));
    } else {
      const message =
        question?.type === QuestionCategories.BIRTHDAY ||
        question?.type === QuestionCategories.ANNIVERSARY
          ? 'Added your post to the card'
          : 'Added highlight to your profile.';
      yield put(appActions.setSuccess(message));
    }
    yield all([put(profileHighlightsActions.resetData())]);
    yield put(actions.reset());
    yield put(actions.hide());
  } catch (err) {
    console.error(
      'An unexpected error occurred while adding profile highlight ' +
        JSON.stringify(err),
    );
    yield put(actions.setError(`Hmm, that didn't work.  Please try again`));
  }
  yield put(appActions.setLoading(false));
}

export function* profileQuestionModalSaga() {
  yield takeLatest(actions.submit.type, doSubmit);
  yield takeLatest(actions.load.type, doLoad);
}
