import { PayloadAction } from '@reduxjs/toolkit';
import { Notification } from 'types/Notification';
import { QuestionOfTheWeek } from 'types/QuestionOfTheWeek';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { notificationsSliceSaga } from './saga';
import { NotificationsSliceState } from './types';

export const initialState: NotificationsSliceState = {
  notifications: [],
  qotw: [],
  questionId: undefined,
  userId: undefined,
};

const slice = createSlice({
  name: 'notificationsSlice',
  initialState,
  reducers: {
    loadNotifications(state, action: PayloadAction<void>) {},
    loadProfileViewNotifications(state, action: PayloadAction<void>) {},
    onNotificationsLoaded(state, action: PayloadAction<Notification[]>) {
      state.notifications = action.payload;
    },
    readNotifications(state, action: PayloadAction<void>) {},
    answerQotw() {},
    showQotw() {},
    addQotw(state, action: PayloadAction<QuestionOfTheWeek[]>) {
      state.qotw = state.qotw.concat(action.payload);
    },
    setQuestion(state, action: PayloadAction<string | undefined>) {
      state.questionId = action.payload;
    },
    setUserId(state, action: PayloadAction<string | undefined>) {
      state.userId = action.payload;
    },
    viewTeammates() {},
  },
});

export const { actions: notificationsSliceActions } = slice;

export const useNotificationsSliceSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: notificationsSliceSaga });
  return { actions: slice.actions };
};
