import React from 'react';
import styled from 'styled-components';
import CheckIcon from 'app/assets/check-icon-blue.svg';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.img`
  width: 12px;
  height: 12px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

interface StyledCheckboxProps {
  checked: boolean;
}

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 20px;
  height: 20px;
  border: ${props =>
    props.checked ? '1px solid #0060BF' : '1px solid #c3c3c3'};
  border-radius: 6px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const Checkbox = ({ className = undefined, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon src={CheckIcon} alt="" />
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
