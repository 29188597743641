import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { appCustomizationsPanelSaga } from './saga';
import { AppCustomizationType, AppCustomizationsState, Banner } from './types';
import { SlackbotType } from 'app/pages/InstallationChannelSelector/slice/type';

export const initialState: AppCustomizationsState = {
  birthdayBanner: undefined,
  shoutOutBannerSingleUser: undefined,
  shoutOutBannerMultipleUser: undefined,
  workAnniversaryBanner: undefined,
  workAnniversaryTitle: undefined,
  customCelebrationBanner: undefined,
  slackbotId: undefined,
  stateCopy: undefined,
  isDataChanged: false,
  shoutouts_value_required: false,
};

const slice = createSlice({
  name: 'appCustomizationsPanel',
  initialState,
  reducers: {
    setBirthdayBanner(state, action: PayloadAction<Array<Banner>>) {
      state.birthdayBanner = action.payload;
    },
    setShoutOutBannerForMultipleUser(
      state,
      action: PayloadAction<Array<Banner>>,
    ) {
      state.shoutOutBannerMultipleUser = action.payload;
    },
    setWorkAnniversaryBanner(state, action: PayloadAction<Array<Banner>>) {
      state.workAnniversaryBanner = action.payload;
    },
    setWorkAnniversaryTitle(state, action: PayloadAction<string>) {
      state.workAnniversaryTitle = action.payload;
    },
    setCustomCelebrationBanner(state, action: PayloadAction<Array<Banner>>) {
      state.customCelebrationBanner = action.payload;
    },
    setSlackbotId(state, action: PayloadAction<string>) {
      state.slackbotId = action.payload;
    },
    setAppCustomizations(state, action: PayloadAction<AppCustomizationType>) {
      Object.keys(action.payload).forEach(item => {
        state[item] = action.payload[item];
      });
    },
    setIsDataChanged(state, action: PayloadAction<boolean>) {
      state.isDataChanged = action.payload;
    },
    setStateCopy(state, action: PayloadAction<AppCustomizationType>) {
      state.stateCopy = action.payload;
    },
    setIfCompanyValueRequired(state, action: PayloadAction<boolean>) {
      state.shoutouts_value_required = action.payload;
    },
    resetStateCopy(state, action: PayloadAction) {
      const tempState = { ...state };
      delete tempState.stateCopy;
      state.stateCopy = tempState;
    },
    loadAppCustomizations(
      state,
      action: PayloadAction<{ slackbotId: string; slackbotType: SlackbotType }>,
    ) {},
  },
});

export const { actions: appCustomizationsActions } = slice;

export const useAppCustomizationsPanelSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: appCustomizationsPanelSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useProfileCompletionPageSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
