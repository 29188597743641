import React, { useEffect } from 'react';
import { CelebrationsCustomizations } from './Celebrations';
import { SlackbotType } from 'app/pages/InstallationChannelSelector/slice/type';
import { useDispatch } from 'react-redux';
import { useAppCustomizationsPanelSlice } from './slice';
import { appActions } from 'app/slice';
import { Shoutouts } from './Shoutouts';

interface Props {
  botType: SlackbotType;
  reset: () => void;
}

export const ShoutoutsCustomizationPanel = (props: any) => {
  return <AppCustomizationsPanel {...props} botType={SlackbotType.SHOUTOUT} />;
};

export const CelebrationsCustomizationPanel = (props: any) => {
  return <AppCustomizationsPanel {...props} botType={SlackbotType.BIRTHDAY} />;
};

export const AppCustomizationsPanel = ({ botType, reset }: Props) => {
  const { actions } = useAppCustomizationsPanelSlice();
  const dispatch = useDispatch();
  const { slackbotId } = Object.fromEntries(
    new URLSearchParams(location.search),
  );

  useEffect(() => {
    if (!slackbotId) {
      return;
    }
    dispatch(actions.setSlackbotId(slackbotId));
    dispatch(
      actions.loadAppCustomizations({ slackbotId, slackbotType: botType }),
    );
    dispatch(appActions.setLoading(false));
  }, [slackbotId]);

  return (
    <>
      {botType === SlackbotType.BIRTHDAY && (
        <CelebrationsCustomizations onClose={reset} />
      )}
      {botType === SlackbotType.SHOUTOUT && <Shoutouts onClose={reset} />}
    </>
  );
};
