import { User } from 'types/User';

export interface TeammatesPageState {
  users: User[];
  cursor?: number;
  sortBy: TeammatesSort;
  expandInterests: boolean;
  interestSearchText?: string;
  interestFilters: InterestFilter[];
}

export enum TeammatesSort {
  BIRTHDAY = 'BIRTHDAY',
  START_DATE = 'START_DATE',
  FIRSTNAME = 'FIRSTNAME',
  LASTNAME = 'LASTNAME',
}

export interface InterestFilter {
  interest: string;
  active: boolean;
  draft?: InterestFilterDraftState;
}

export enum InterestFilterDraftState {
  CHECKED = 'checked',
  UNCHECKED = 'unchecked',
}
