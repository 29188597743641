import {
  SubscriptionBillingCycle,
  SubscriptionInvoice,
  SubscriptionTier,
  SubscriptionType,
} from 'app/library/BillingAPI';
import { Channel } from 'app/library/SlackAPI';
import { SlackChannel } from 'types/SlackChannel';

export enum SelectedDurationType {
  MONTHLY = 'Monthly',
  ANNUALLY = 'Annually',
}

export type Plan = {
  type: PlanType;
  title: string;
  ifFree: boolean;
  isPopular: boolean;
  description: string;
  pricing: {
    monthly: number;
    annually: number;
    description_monthly: string;
    description_annually: string;
    saving?: string;
  };
  features: {
    title: string;
    items: Array<string>;
  };
};

export enum PlanType {
  COACH = 'Coach',
  BUSINESS = 'Business',
  FIRST = 'First',
}

export enum PageState {
  INITIAL,
  PAYMENT,
  SUBSCRIPTION_DETAILS,
}

export enum PaymentMethod {
  CREDIT_CARD,
  ACH,
}

export enum CreditCardFormFiled {
  COUNTRY_OR_REGION = 'countryOrRegion',
  ADDRESS_LINE_1 = 'addressLine1',
  ADDRESS_LINE_2 = 'addressLine2',
  CITY = 'city',
  STATE = 'state',
  ZIP = 'zip',
  CARD_NUMBER = 'cardNumber',
  EXPIRATION = 'expiration',
  CVC = 'cvc',
}
export type CreditCardFormData = {
  countryOrRegion: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  cardNumber: string;
  expiration: string;
  cvc: string;
  firstName: string;
  lastName: string;
  email: string;
  couponCode: string;
};

type Discount = {
  discountAmountInCents: number;
  memo: string;
  couponCode: string;
  durationPeriodCount: number;
  durationIntervalUnit: string;
};

export type SubscriptionPreview = {
  billingCycle: SubscriptionBillingCycle;
  nextBillingDate?: string;
  seats: number;
  subtotalInCents: number;
  tier: SubscriptionTier;
  totalDiscountInCents?: number;
  totalInCents: number;
  totalTaxInCents: number;
  type: SubscriptionType;
  channels: Array<Channel>;
  appliedDiscounts?: Array<Discount>;
};

export type Country = {
  id: string;
  label: string;
};
export type BillingPageState = {
  selectedDurationType: SelectedDurationType;
  selectedPlan: PlanType;
  pageState: PageState;
  selectedPaymentMethod: PaymentMethod;
  creditCardFormData: CreditCardFormData;
  chargifySecurityToken: string;
  chargifyPaymentToken: string;
  subscriptionPreview?: SubscriptionPreview;
  countries: Array<Country>;
  states: Array<Country>;
  isLoading: boolean;
  showSuccessMessage: boolean;
  showRequestInvoiceSuccessMessage: boolean;
  subscriptionInvoices?: Array<SubscriptionInvoice>;
  subscriptionType: SubscriptionType;
  selectedChannelsForSubscription: Array<SlackChannel>;
};
