/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';

import { InjectedReducersType } from 'utils/types/injector-typings';

import {createReduxHistoryContext} from "redux-first-history";
import {createBrowserHistory, createMemoryHistory} from "history";
import useSSR from 'use-ssr';

export const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: useSSR().isServer ? createMemoryHistory() : createBrowserHistory(),
  //other options if needed
});

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  return combineReducers({
    router: routerReducer,
    ...injectedReducers,
  });
}
