/**
 *
 * ModalHeader
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import Close from 'app/assets/close.svg';
import { Button } from 'app/components/Button';
import { HeadingLarge } from 'app/components/Text';

interface Props {
  onClose?: any;
  padding?: string;
  title: string;
  justifyContent: string;
  handleClearNotifications?: () => void;
}

interface WrapperProps {
  padding?: string;
  justifyContent: string;
}

const Wrapper = styled.div<WrapperProps>`
  padding: 1em;
  clear: right;
  display: flex;
  justify-content: ${props => props.justifyContent};
  align-items: center;
`;

const CloseIconContainer = styled.div`
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    top: 1em;
  }
`;
const ButtonContainer = styled.div``;

export const ModalHeader = memo(
  ({ onClose, title, justifyContent, handleClearNotifications }: Props) => {
    return (
      <Wrapper justifyContent={onClose ? 'space-between' : 'center'}>
        {handleClearNotifications !== undefined ? (
          <ButtonContainer>
            <Button
              color={'#fff'}
              disabled={handleClearNotifications === null}
              size="compact"
              format="pill"
              onClick={handleClearNotifications}
            >
              Clear all
            </Button>
          </ButtonContainer>
        ) : (
          <ButtonContainer />
        )}
        <HeadingLarge>{title}</HeadingLarge>
        {onClose ? <CloseIconContainer>
          <Button
            icon={Close}
            color="white"
            borderColor="transparent"
            format="circle"
            size="compact"
            onClick={() => onClose()}
          />
        </CloseIconContainer> : null}
      </Wrapper>
    );
  },
) as any;

ModalHeader.defaultProps = {
  justifyContent: 'center',
};
