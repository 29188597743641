import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};
const ClearWhiteIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 16}
    height={props.height || 16}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        fillOpacity={0.9}
        d="M8 1.333a6.667 6.667 0 1 0 0 13.334A6.667 6.667 0 0 0 8 1.334Zm2.06 7.907a.32.32 0 0 1 0 .467l-.353.353a.32.32 0 0 1-.467 0L8 8.827 6.76 10.06a.32.32 0 0 1-.467 0l-.353-.353a.32.32 0 0 1 0-.467L7.173 8 5.94 6.76a.32.32 0 0 1 0-.466l.353-.354a.32.32 0 0 1 .467 0L8 7.173 9.24 5.94a.32.32 0 0 1 .467 0l.353.353a.32.32 0 0 1 0 .467L8.827 8l1.233 1.24Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default ClearWhiteIcon;
