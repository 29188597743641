/**
 *
 * Asynchronously loads the component for InstallationInstructions
 *
 */

import { lazyLoad } from 'utils/loadable';

export const InstallationInstruction = lazyLoad(
  () => import('./index'),
  module => module.InstallationInstruction,
);
