/**
 *
 * ModalContentWrapper
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';

interface Props {
  alignItems: string;
  children: any;
  padding?: string;
}

const Div = styled.div<Props>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.alignItems};
  padding: ${props => (props.padding ? props.padding : '0 1.5em 1.5em')};

  @media (max-width: 480px) {
    padding: ${props => (props.padding ? props.padding : '0 1em')};
  }
`;

export const ModalContentWrapper = memo(
  ({ alignItems, children, padding }: Props) => {
    return (
      <Div alignItems={alignItems} padding={padding}>
        {children}
      </Div>
    );
  },
) as any;

ModalContentWrapper.defaultProps = {
  alignItems: 'center',
};
