import { AppCustomizationsState } from 'app/components/AppCustomizationsPanel/slice/types';
import { Apollo } from 'app/library/Apollo';
import {
  SlackbotChannelGroupsFilterInput,
  SlackbotType,
} from 'app/pages/InstallationChannelSelector/slice/type';
import { SlackWorkspace } from 'types/Slack';
import { SlackChannel } from 'types/SlackChannel';
import { SlackUser } from 'types/User';

export type Channel = {
  id: string;
  name: string;
};

export async function getSlackChannels(slackbotId: string) {
  const data = {
    query: `
        query channels($slackbotId: SlackbotId!) {
          getSlackChannels(input: { slackbotId: $slackbotId }) {
            items {
              id
              name
              isPrivate
            }
          }
        }
      `,
    variables: { slackbotId },
  };

  try {
    const response = await Apollo.post(data);

    return response.data.data.getSlackChannels.items;
  } catch (err) {
    return Apollo.handleError(err, data);
  }
}

export async function getSlackbotChannels(slackbotId: string) {
  const data = {
    query: `
        query channels($slackbotId: SlackbotId!) {
          getSlackbotChannels(input: { slackbotId: $slackbotId }) {
            items {
              id
              name
              channelId
            }
          }
        }
      `,
    variables: { slackbotId },
  };

  try {
    const response = await Apollo.post(data);
    return response.data.data.getSlackbotChannels.items;
  } catch (err) {
    return Apollo.handleError(err, data);
  }
}

export async function addBotToChannel(
  channelId: string,
  slackbotId: string,
): Promise<Boolean | undefined> {
  const data = {
    query: `
        mutation addBot($channelId: String!, $slackbotId: SlackbotId!) {
            addSlackbotToChannel(input: { channelId: $channelId, slackbotId: $slackbotId }) 
        }
      `,
    variables: {
      channelId,
      slackbotId,
    },
  };

  try {
    const response = await Apollo.post(data);
    return response.data.data.addSlackbotToChannel;
  } catch (err) {
    Apollo.handleError(err, data);
    return await Promise.resolve(undefined);
  }
}

export async function removeBotFromChannel(
  workSpaceId: string,
  slackbotChannelId: string,
): Promise<SlackWorkspace | undefined> {
  const data = {
    query: `
        query slackWorkspace($workSpaceId: SlackWorkspaceId!, $slackbotChannelId: SlackbotChannelId!) {
          slackWorkspace(id: $workSpaceId) {
            removeSlackbotFromChannel(input: { slackbotChannelId: $slackbotChannelId })
          }
        }
      `,
    variables: { workSpaceId, slackbotChannelId },
  };

  const response = await Apollo.post(data);
  if (!!response.data?.errors?.length) {
    throw new Error(response.data.errors[0].message);
  }
  return response.data?.data?.slackWorkspace.removeSlackbotFromChannel;
}

export function createNewSlackChannel(
  slackbotId: string,
  name: string,
  notifyUsers = false,
  channelId?: string,
  usersId?: string[],
): Promise<SlackChannel | undefined> {
  const data = {
    query: `
          mutation createChannel($slackbotId: SlackbotId!, $name: String!, $notifyUsers: Boolean!, $channelId: String, $usersId: [String!]) {
            createNewSlackChannel(input: {slackbotId: $slackbotId, name: $name, notifyUsers: $notifyUsers ,channelId: $channelId, usersId: $usersId}) {
                name
                id
            }
          }
        `,
    variables: {
      slackbotId,
      name,
      notifyUsers,
      channelId,
      usersId,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.createNewSlackChannel)
    .catch(err => Apollo.handleError(err, data));
}

export function createIcebreaker(
  question: string,
  slackbotId: string,
  channelId: string,
  generateNew = false,
  deadlineDate: string,
  revealDate: string,
  frequency: string,
): Promise<boolean | undefined> {
  const data = {
    query: `
          mutation createIcebreaker($question: String!, $slackbotId: SlackbotId!, $channelId: String!, $generateNew: Boolean!, $deadlineDate: String!, $revealDate: String!, $frequency: String!) {
            createIcebreaker(input: {question: $question, slackbotId: $slackbotId, channelId: $channelId, generateNew: $generateNew ,deadlineDate: $deadlineDate,revealDate: $revealDate, frequency: $frequency })
          }
        `,
    variables: {
      question,
      slackbotId,
      channelId,
      generateNew,
      deadlineDate,
      revealDate,
      frequency,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.createIcebreaker)
    .catch(err => Apollo.handleError(err, data));
}

export async function generateIcebreaker(tone?: string, keyword?: string) {
  const data = {
    query: `
        query generateIcebreaker($tone: String, $keyword: String) {
          generateIcebreaker(input: { tone: $tone, keyword: $keyword })
        }
      `,
    variables: { tone, keyword },
  };

  try {
    const response = await Apollo.post(data);
    return response.data.data.generateIcebreaker;
  } catch (err) {
    return Apollo.handleError(err, data);
  }
}

export async function createIntrosTemplate(
  channelId: string,
  slackbotId: string,
  questions: string[],
) {
  const data = {
    query: `
        query createIntrosTemplate($input: CreateIntrosTemplateInput!) {
          createIntrosTemplate(input: $input)
        }
      `,
    variables: {
      input: {
        slackbotId,
        channelId,
        questions,
      },
    },
  };

  try {
    const response = await Apollo.post(data);
    return response.data.data.createIntrosTemplate;
  } catch (err) {
    return Apollo.handleError(err, data);
  }
}

export async function createCompanyValues(values: string, slackbotId: string) {
  const data = {
    query: `
        query createCompanyValues($input: CreateCompanyValuesInput!) {
          createCompanyValues(input: $input)
        }
      `,
    variables: {
      input: {
        values,
        slackbotId,
      },
    },
  };

  try {
    const response = await Apollo.post(data);
    return response.data.data.createCompanyValues;
  } catch (err) {
    return Apollo.handleError(err, data);
  }
}

export async function getCompanyValues(slackbotId: string) {
  const data = {
    query: `
        query getCompanyValues($input: GetCompanyValuesInput!) {
          getCompanyValues(input: $input) {
            items {
              id
              value
              popularity
            }
          }
        }
      `,
    variables: {
      input: {
        slackbotId,
      },
    },
  };

  try {
    const response = await Apollo.post(data);
    return response.data.data.getCompanyValues;
  } catch (err) {
    return Apollo.handleError(err, data);
  }
}

export async function deleteCompanyValue(companyValueId: string) {
  const data = {
    query: `
        query removeCompanyValue($id: CompanyValueId!) {
          removeCompanyValue(id: $id) 
        }
      `,
    variables: {
      id: companyValueId,
    },
  };

  try {
    const response = await Apollo.post(data);
    if (response.data.errors) {
      throw new Error(response.data.errors[0].message);
    } else {
      return response.data.data.removeCompanyValue;
    }
  } catch (err) {
    throw err;
  }
}

export async function searchSlackUserByEmail(
  slackbotId: string,
  email: string,
): Promise<SlackUser | undefined> {
  const data = {
    query: `
        query searchSlackUserByEmail($slackbotId: SlackbotId!, $email: String!) {
          searchSlackUserByEmail(input: { slackbotId: $slackbotId, email: $email }) {
            id
            email
            name
            avatar
          }
        }
      `,
    variables: { slackbotId, email },
  };

  try {
    const response = await Apollo.post(data);
    return response.data?.data?.searchSlackUserByEmail;
  } catch (err) {
    Apollo.handleError(err, data);
    return await Promise.resolve(undefined);
  }
}

export async function getSlackUsers(slackbotId: string) {
  const data = {
    query: `
        query users($slackbotId: SlackbotId!) {
          getSlackUsers(input: { slackbotId: $slackbotId }) {
            users {
              id
              name
              real_name
              team_id
              icon
            }
          }
        }
      `,
    variables: { slackbotId },
  };

  try {
    const response = await Apollo.post(data);

    return response.data.data.getSlackUsers.users;
  } catch (err) {
    return Apollo.handleError(err, data);
  }
}

export async function getAppCustomizations(slackbotId: string) {
  const data = {
    query: `
        query appCustomizations($slackbotId: SlackbotId!) {
          getSlackPreferences(input: { slackbotId: $slackbotId }) {
            birthdayBanner {
              url
              isActive
            }
            workAnniversaryTitle
            customCelebrationBanner {
              url
              isActive
            }
            workAnniversaryBanner {
              url
              isActive
            }
            isShoutoutBannerActiveForSingleUser
            shoutOutBannerSingleUser {
              url
              isActive
            }
            shoutOutBannerMultipleUser {
              url
              isActive
            }
            shoutouts_value_required
          }
        }
      `,
    variables: { slackbotId },
  };

  try {
    const response = await Apollo.post(data);

    return response.data.data.getSlackPreferences;
  } catch (err) {
    return Apollo.handleError(err, data);
  }
}

export async function updateAppCustomizations(
  slackbotId: string,
  updatedData: AppCustomizationsState,
) {
  const data = {
    query: `
        mutation updateAppCustomizations($slackbotId: SlackbotId!, $customSetting: CustomSetting!) {
          updateSlackPreferences(input: { slackbotId: $slackbotId, customSetting: $customSetting })
        }
        `,
    variables: {
      slackbotId,
      customSetting: updatedData,
    },
  };

  try {
    const response = await Apollo.post(data);

    return response.data.data.updateSlackPreferences;
  } catch (err) {
    return Apollo.handleError(err, data);
  }
}

export async function updateCompanyValueRequired(
  slackbotId: string,
  ifRequired: boolean,
): Promise<Boolean | undefined> {
  const data = {
    query: `
        mutation updateCompanyValueRequired($slackbotId: SlackbotId!, $ifRequired: Boolean!) {
          updateCompanyValueRequired(input: { slackbotId: $slackbotId, ifRequired: $ifRequired }) 
        }
      `,
    variables: {
      slackbotId,
      ifRequired,
    },
  };

  try {
    const response = await Apollo.post(data);
    return response.data.data.updateCompanyValueRequired;
  } catch (err) {
    Apollo.handleError(err, data);
    return await Promise.resolve(undefined);
  }
}

export async function getSlackWorkspace(
  id: string,
): Promise<SlackWorkspace | undefined> {
  const data = {
    query: `
        query slackWorkspace($id: SlackWorkspaceId!) {
          slackWorkspace(id: $id) {
            id
            slackId
            name
            slackbots {
              id
              type
            }
            slackbotChannelGroups(first: 0, offset: 0) {
              totalCount
            }
            users(first: 0, offset: 0, filter: {isAirspeedUser: true}) {
              totalCount
            }
            admin(first: 0, offset: 0) {
              totalCount
            }
          }
          getEntitlements(input: { slackWorkspaceId: $id}) {
            tier,
            economySeatsUpperLimit,
            channelAllocationLimit,
            homeTab,
            ai {
                enabled
            },
            celebrations {
                customBanner
            },
            shoutouts {
              requireValues
            },
            hris {
                enabled
            },
            data {
                archive
                changelog
                export
            }
            leaderboards {
              enabled
            }
          }
        }
      `,
    variables: { id },
  };

  try {
    const response = await Apollo.post(data);
    const ret = response.data?.data?.slackWorkspace;
    ret.entitlements = response.data?.data?.getEntitlements;
    return ret;
  } catch (err) {
    Apollo.handleError(err, data);
    return await Promise.resolve(undefined);
  }
}

export async function getSlackbotChannelsGroup(
  id: string,
  first: number,
  offset: number,
  filter?: SlackbotChannelGroupsFilterInput,
): Promise<SlackWorkspace | undefined> {
  const data = {
    query: `
        query slackWorkspace($id: SlackWorkspaceId!, $first: Int!, $offset: Int!, $filter: SlackbotChannelGroupsFilterInput) {
          slackWorkspace(id: $id) {
            slackbotChannelGroups(first: $first, offset: $offset, filter: $filter) {
              totalCount
              items {
                name
                isCallerMemberOfTheChannel
                channels {
                  id
                  type
                  isPrivate
                  users(first: 10, offset: 0) {
                    totalCount
                  }
                }
              }
            }
          }
        }
      `,
    variables: { id, first, offset, filter },
  };

  try {
    const response = await Apollo.post(data);
    return response.data?.data?.slackWorkspace;
  } catch (err) {
    Apollo.handleError(err, data);
    return await Promise.resolve(undefined);
  }
}

export async function getSlackbotAdmins(
  id: string,
  offset: number,
): Promise<SlackWorkspace | undefined> {
  const data = {
    query: `
        query slackWorkspace($id: SlackWorkspaceId!, $offset: Int!) {
          slackWorkspace(id: $id) {
            admin(first: 10, offset: $offset) {
              first
              offset
              totalCount
              items {
                  id
                  isAdmin
                  slackId
                  updatedDate
                  user {
                    id
                    firstName
                    lastName
                    email
                    createdDate
                    image {
                      url
                    }
                  }
              }
            }
          }
        }
      `,
    variables: { id, offset },
  };

  try {
    const response = await Apollo.post(data);
    return response.data?.data?.slackWorkspace;
  } catch (err) {
    Apollo.handleError(err, data);
    return await Promise.resolve(undefined);
  }
}

export async function addAdmin(
  workSpaceId: string,
  slackId: string,
): Promise<SlackWorkspace | undefined> {
  const data = {
    query: `
        query slackWorkspace($workSpaceId: SlackWorkspaceId!, $slackId: String) {
          slackWorkspace(id: $workSpaceId) {
            addAdmin(slackId: $slackId)
          }
        }
      `,
    variables: { workSpaceId, slackId },
  };

  try {
    const response = await Apollo.post(data);
    return response.data?.data?.slackWorkspace.addAdmin;
  } catch (err) {
    Apollo.handleError(err, data);
    return await Promise.resolve(undefined);
  }
}

export async function removeAdmin(
  workSpaceId: string,
  userId: string,
): Promise<SlackWorkspace | undefined> {
  const data = {
    query: `
        query slackWorkspace($workSpaceId: SlackWorkspaceId!, $userId: UserId!) {
          slackWorkspace(id: $workSpaceId) {
            removeAdmin(userId: $userId)
          }
        }
      `,
    variables: { workSpaceId, userId },
  };

  try {
    const response = await Apollo.post(data);
    return response.data?.data?.slackWorkspace.removeAdmin;
  } catch (err) {
    Apollo.handleError(err, data);
    return await Promise.resolve(undefined);
  }
}

export async function searchChannels(
  id: string,
  prefix: string,
): Promise<
  | Array<{
      name: string;
      id: string;
    }>
  | undefined
> {
  const data = {
    query: `
        query slackWorkspace($id: SlackWorkspaceId!, $prefix: String!) {
          slackWorkspace(id: $id) {
            searchChannels(prefix: $prefix) {
              name
              id
            }
          }
        }
      `,
    variables: { id, prefix },
  };

  try {
    const response = await Apollo.post(data);
    return response.data?.data?.slackWorkspace?.searchChannels;
  } catch (err) {
    Apollo.handleError(err, data);
    return await Promise.resolve(undefined);
  }
}

export async function getPaymentProfiles(
  workSpaceId: string,
): Promise<SlackWorkspace | undefined> {
  const data = {
    query: `
        query slackWorkspace($workSpaceId: SlackWorkspaceId!) {
          slackWorkspace(id: $workSpaceId) {
            listPaymentProfiles(input: {first: 10, offset: 0}) {
              items {
                ...on CreditCardDetail {
                  id
                  paymentType
                  cardType
                  cardNumber
                  expirationMonth
                  expirationYear
                  subscriptions(input: {first: 10, offset: 0}) {
                    first
                    offset
                    nextToken
                    totalCount
                    items {
                      id
                    }
                  }
                }
                ...on BankAccountDetail {
                  id
                  paymentType
                  bankName
                  routingNumber
                  accountNumber
                  subscriptions(input: {first: 10, offset: 0}) {
                    first
                    offset
                    nextToken
                    totalCount
                    items {
                      id
                    }
                  }
                }
              }
              first
              offset
              nextToken
              totalCount
            }
          }
        }
      `,
    variables: { workSpaceId },
  };

  try {
    const response = await Apollo.post(data);
    return response.data?.data?.slackWorkspace?.listPaymentProfiles;
  } catch (err) {
    Apollo.handleError(err, data);
    return await Promise.resolve(undefined);
  }
}

export function createOnboardingEmail(
  appType: SlackbotType,
  email: string,
): Promise<boolean> {
  const data = {
    query: `
          mutation createOnboardingEmail($appType: String!, $email: String!) {
            createOnboardingEmail(input: {appType: $appType, email: $email}) 
          }
        `,
    variables: {
      appType,
      email,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.createOnboardingEmail)
    .catch(err => Apollo.handleError(err, data));
}

export async function announceToChannel(
  channelId: string,
  slackbotId: string,
  message: string,
): Promise<Boolean | undefined> {
  const data = {
    query: `
        mutation announceToChannel($channelId: String!, $slackbotId: SlackbotId!, $message: String!) {
          announceToChannel(input: { channelId: $channelId, slackbotId: $slackbotId, message: $message }) 
        }
      `,
    variables: {
      channelId,
      slackbotId,
      message,
    },
  };

  try {
    const response = await Apollo.post(data);
    if (!!response.data?.errors?.length) {
      throw new Error(response.data.errors[0].message);
    }
    return response.data.data.announceToChannel;
  } catch (err) {
    Apollo.handleError(err, data);
    return await Promise.resolve(undefined);
  }
}

export type SendShoutoutRequest = {
  channelId: string;
  slackbotId: string;
  message: string;
  receivers: Array<string>;
  impact?: string;
  values?: Array<string>;
};

export async function sendShoutout(
  sendShoutoutRequest: SendShoutoutRequest,
): Promise<Boolean | undefined> {
  const data = {
    query: `
        mutation sendShoutout($channelId: String!, $slackbotId: SlackbotId!, $message: String!, $receivers: [String!]!, $impact: String, $values: [CompanyValueId!]) {
          sendShoutout(input: { channelId: $channelId, slackbotId: $slackbotId, message: $message, receivers: $receivers, impact: $impact, values: $values}) 
        }
      `,
    variables: sendShoutoutRequest,
  };

  try {
    const response = await Apollo.post(data);
    if (!!response.data?.errors?.length) {
      throw new Error(response.data.errors[0].message);
    }
    return response.data.data.sendShoutout;
  } catch (err) {
    Apollo.handleError(err, data);
    return await Promise.resolve(undefined);
  }
}

export async function generateShoutoutMessage(
  input: string,
): Promise<string | undefined> {
  const data = {
    query: `
        query generateShoutoutMessage($input: String!) {
          generateShoutoutMessage(input: $input) 
        }
      `,
    variables: { input },
  };

  try {
    const response = await Apollo.post(data);
    if (!!response.data?.errors?.length) {
      throw new Error(response.data.errors[0].message);
    }
    return response.data.data.generateShoutoutMessage;
  } catch (err) {
    Apollo.handleError(err, data);
    return await Promise.resolve(undefined);
  }
}

export async function searchSlackWorkspaces(
  search: string,
): Promise<SlackWorkspace[]> {
  const data = {
    query: `
        query getSlackWorkspaces($search: String!) {
          getSlackWorkspaces(input: { search: $search }) {
            items {
              id
              name
              slackId
            }
          }
        }
      `,
    variables: { search },
  };

  try {
    const response = await Apollo.post(data);
    return response.data?.data?.getSlackWorkspaces.items;
  } catch (err) {
    Apollo.handleError(err, data);
    return await Promise.resolve([]);
  }
}
