import * as React from 'react';
const CheckIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 17}
    height={props.height || 16}
    fill="none"
    viewBox={`0 0 ${props.width || '17'} ${props.height || '16'}`}
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M3.724 7.526a1.083 1.083 0 0 0-1.532 1.532l3.5 3.5a1.083 1.083 0 0 0 1.532 0l7.584-7.584a1.083 1.083 0 1 0-1.532-1.532L6.458 10.26 3.724 7.526Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M1.5 1h14v14h-14z" />
      </clipPath>
    </defs>
  </svg>
);
export default CheckIcon;
