import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.notificationsSlice || initialState;

export const selectNotifications = createSelector(
  [selectSlice],
  state => state.notifications,
);

export const selectNewCount = createSelector(
  [selectSlice],
  state =>
    state.notifications.filter(notification => notification.isNew === 'true')
      .length,
);

export const selectQotw = createSelector([selectSlice], state => state.qotw);

export const selectQuestionId = createSelector(
  [selectSlice],
  state => state.questionId,
);

export const selectUserId = createSelector(
  [selectSlice],
  state => state.userId,
);
