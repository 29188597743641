/**
 *
 * Separator
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';

interface Props {
  marginTop?: string;
  marginBottom?: string;
  height?: string;
  marginLeft?: string;
  marginRight?: string;
  color?: string;
}

export function Separator({marginTop, marginBottom, marginLeft, marginRight, height, color}: Props) {
  return <Div marginTop={marginTop} marginBottom={marginBottom} marginLeft={marginLeft} marginRight={marginRight} height={height} color={color}></Div>;
}

const Div = styled.div<Props>`
  background-color: ${props => props.color};
  height: ${props => props.height};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft};
`;

Separator.defaultProps = {
  marginTop: '1em',
  marginBottom: '1em',
  marginLeft: '0em',
  marginRight: '0em',
  height: '1px',
  color: '#e0e0e0',
};
