import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from 'redux-saga/effects';
import styled from 'styled-components';
import { User } from '../../../../types/User';
import { getCurrentSlackIdentity } from '../../../../utils/user-workspaces';
import { selectSlackWorkspace, selectUser } from '../../../slice/selectors';
import { UpgradeBanner } from '../../UpgradeBanner';
import { FileUpload } from '../Components/FileUpload';
import { useAppCustomizationsPanelSlice } from '../slice';
import {
  selectBirthdayBanner,
  selectCustomizationStateCopy,
} from '../slice/selectors';
import { Banner } from '../slice/types';

const Title = styled.span<{}>`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

const Description = styled.span<{}>`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const Image = styled.img`
  width: 100%;
`;

const Radio = styled.input`
  height: 20px;
  width: 20px;
`;

const BannerList = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export enum BANNER_TYPE {
  CUSTOM,
  DEFAULT,
}

type PageState = {
  selectedBanner: BANNER_TYPE;
  customBannerSrc: string | undefined;
};

export const BirthdayCustomization = () => {
  const currentBanner = useSelector(selectBirthdayBanner);
  const stateCopy = useSelector(selectCustomizationStateCopy);
  const { birthdayBanner } = stateCopy;
  const dispatch = useDispatch();
  const { actions } = useAppCustomizationsPanelSlice();
  const slackWorkspace = useSelector(selectSlackWorkspace);

  const onNewBannerUpload = (url: string) => {
    const previousBanners =
      birthdayBanner && birthdayBanner.length ? birthdayBanner : [];
    const updatedBirthdayBanners: Array<Banner> = [
      ...previousBanners.map(item => ({ ...item, isActive: false })),
      { url, isActive: true },
    ];
    dispatch(
      actions.setStateCopy({
        ...stateCopy,
        birthdayBanner: updatedBirthdayBanners,
      }),
    );
    dispatch(actions.setIsDataChanged(true));
  };

  const onSelectBanner = (url: string) => {
    let updatedBanner =
      birthdayBanner && birthdayBanner.length ? birthdayBanner : [];
    if (url === '') {
      updatedBanner = updatedBanner.map(item => ({
        ...item,
        isActive: false,
      }));
      dispatch(
        actions.setStateCopy({
          ...stateCopy,
          birthdayBanner: updatedBanner,
        }),
      );
    } else {
      updatedBanner = updatedBanner.map(item => {
        return { ...item, isActive: item.url === url ? true : false };
      });
      dispatch(
        actions.setStateCopy({
          ...stateCopy,
          birthdayBanner: updatedBanner,
        }),
      );
    }
    if (!_.isEqual(updatedBanner, currentBanner)) {
      dispatch(actions.setIsDataChanged(true));
    } else {
      dispatch(actions.setIsDataChanged(false));
    }
    dispatch(actions.setIsDataChanged(true));
  };

  return (
    <>
      <UpgradeBanner
        enabled={!slackWorkspace?.entitlements.celebrations.customBanner}
      />
      <Title>Birthday Banners</Title>
      <Description>
        Upload custom banners to make it special and unique to your
        organization. We recommend PNG or JPG at 1280x 650px.
      </Description>
      <BannerList>
        <div>
          <Radio
            type="radio"
            value={BANNER_TYPE.DEFAULT}
            name="default"
            checked={
              !birthdayBanner || !birthdayBanner.find(item => item.isActive)
            }
            onClick={() => onSelectBanner('')}
          />
        </div>
        <div>
          <Image src={`${location.origin}/celebrations/birthday_banner.png`} />
        </div>
      </BannerList>
      {birthdayBanner &&
        birthdayBanner.map(item => (
          <BannerList>
            <div>
              <Radio
                type="radio"
                value={item.url}
                checked={item.isActive}
                onClick={() => onSelectBanner(item.url)}
              />
            </div>
            <div>
              <Image src={item.url} />
            </div>
          </BannerList>
        ))}
      <FileUpload
        onFileUpload={onNewBannerUpload}
        disabled={!slackWorkspace?.entitlements.celebrations.customBanner}
      />
    </>
  );
};
