/**
 *
 * InvitePeopleModal
 *
 */
import { Button } from 'app/components/Button';
import { EmailChips } from 'app/components/EmailChips';
import { Modal, ModalHeader, ModalContentWrapper } from 'app/components/Modal';
import { Body2, SubTitle1 } from 'app/components/Text';
import { selectLoading } from 'app/slice/selectors';
import React, { memo } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useInvitePeopleModalSlice } from './slice';
import {
  selectComponentEmailData,
  selectError,
  selectShow,
} from './slice/selectors';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Separator } from 'app/components/Separator';
import { selectUser } from 'app/slice/selectors';
import { useAppSlice } from 'app/slice';
import LinkIcon from './images/link_v1.svg';

interface Props {}
interface WrapperProps {
  hidden: boolean;
}

interface ButtonProps {
  active: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`;

const FormElement = styled.div`
  margin-bottom: 1.5em;
`;

const ButtonContainer = styled.div`
  width: 100%;
`;

const EmailChipsContainer = styled.div`
  width: 100%;
`;

const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const LinkImg = styled.img`
  margin-right: 0.5em;
`;

const SendButton = styled.div<ButtonProps>`
  opacity: ${props => (props.active ? 1.0 : 0.4)};
`;

const SeparatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const SeparatorDiv = styled.div`
  flex: 1;
`;

const SeparatorOr = styled.div`
  margin: 0 1em;
`;

export const InvitePeopleModal = memo((props: Props) => {
  const { actions } = useInvitePeopleModalSlice();

  const error = useSelector(selectError);
  const show = useSelector(selectShow);
  const componentEmailData = useSelector(selectComponentEmailData);
  const loading = useSelector(selectLoading);
  const user = useSelector(selectUser);
  const appActions = useAppSlice().actions;

  const dispatch = useDispatch();

  const hide = () => {
    dispatch(actions.hide());
  };

  const setComponentEmailData = data => {
    dispatch(actions.setComponentEmailData(data));
  };

  const reset = () => {
    dispatch(actions.reset());
  };

  const submit = () => {
    if (componentEmailData.length > 0) {
      dispatch(actions.submit());
    }
  };

  const showCopyToast = () => {
    dispatch(appActions.setSuccess('Link copied to clipboard'));
  };

  useEffect(() => {
    if (!show) {
      reset();
    }
  }, [show]);

  return (
    <Modal show={show}>
      {loading ? (
        <LoadingContainer>
          <div className="dot-collision"></div>
        </LoadingContainer>
      ) : null}
      <Wrapper hidden={loading}>
        <ModalHeader title="Invite people" onClose={hide} />
        <ModalContentWrapper>
          <FormElement>
            {!!error ? <Body2 color="red">{error}</Body2> : null}
          </FormElement>

          <ButtonContainer>
            <CopyToClipboard
              text={`https://www.getairspeed.com/share/${user?.id}`}
              onCopy={showCopyToast}
            >
              <Button color="white" textColor="black">
                <LinkImg src={LinkIcon} alt="Create invite link" /> Create
                invite link
              </Button>
            </CopyToClipboard>
            <SeparatorContainer>
              <SeparatorDiv>
                <Separator marginTop="2em" marginBottom="2em" />
              </SeparatorDiv>
              <SeparatorOr>Or</SeparatorOr>
              <SeparatorDiv>
                <Separator marginTop="2em" marginBottom="2em" />
              </SeparatorDiv>
            </SeparatorContainer>
          </ButtonContainer>
          <FormElement>
            <SubTitle1>
              Separate email addresses with commas, or press enter
            </SubTitle1>
          </FormElement>
          <EmailChipsContainer>
            <EmailChips
              emails={componentEmailData}
              limit={10}
              placeholder="Add emails..."
              onEmailChange={data => setComponentEmailData(data)}
            />
          </EmailChipsContainer>
          <ButtonContainer>
            <SendButton active={componentEmailData.length > 0}>
              <Button onClick={submit}>
                {componentEmailData.length > 0
                  ? 'Send'
                  : 'Type email addresses'}
              </Button>
            </SendButton>
          </ButtonContainer>
        </ModalContentWrapper>
      </Wrapper>
    </Modal>
  );
});
