/**
 *
 * Asynchronously loads the component for InstallationInstructions
 *
 */

import { lazyLoad } from 'utils/loadable';

export const MapsOnboardingAddLocationPage = lazyLoad(
  () => import('./index'),
  module => module.MapsOnboardingAddLocationPage,
);
