import React from 'react';
import 'react-sliding-side-panel/lib/index.css';
import styled from 'styled-components';
import {
  BodyMediumBold,
  BodySmallBold,
  TextXSmallNormal,
} from 'app/components/Text';
import { LogoContent } from 'app/components/LogoContent';
import {
  getPlaceholderColor,
  getPlaceholderLogo,
} from 'app/library/LogoHelper';
import ChevronRightIcon from 'app/components/Icons/ChevronRightIcon';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: #f9fafb;
  padding: 0.55em 0.75em;
  background: #fff;

  cursor: pointer;
  &:hover {
    background: rgb(236, 248, 255, 0.7);
  }
`;

const LogoSection = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
`;

const LeftColumn = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  align-items: center;
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailColumn = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

type Props = {
  name: string;
  imageUrl: string;
  title?: string;
  detailText?: string;
  onClick: () => void;
};

export function ShoutoutsUserRow({
  name,
  imageUrl,
  title,
  detailText,
  onClick,
}: Props) {
  return (
    <Container onClick={onClick}>
      <LeftColumn>
        <LogoSection>
          <LogoContent
            url={imageUrl}
            width={'36px'}
            circle={true}
            placeholderColor={getPlaceholderColor(name || '')}
            placeholderLogo={getPlaceholderLogo(name || '')}
          />
        </LogoSection>
        <InfoColumn>
          <BodyMediumBold color="#0060BF">{name}</BodyMediumBold>
          {title && (
            <TextXSmallNormal color="rgba(0,0,0,0.6)">{title}</TextXSmallNormal>
          )}
        </InfoColumn>
      </LeftColumn>
      <DetailColumn>
        <BodySmallBold color="rgba(0, 0, 0, 0.90)">{detailText}</BodySmallBold>
        <ChevronRightIcon />
      </DetailColumn>
    </Container>
  );
}
