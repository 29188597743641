import { nanoid } from '@reduxjs/toolkit';
import auth0js, { Auth0Callback } from 'auth0-js';
import jwt_decode from 'jwt-decode';
import { Jwt } from 'app/pages/LoginPage';

export interface AuthParams {
  [key: string]: string;
}

export interface PasswordLessParams {
  email: string;
  send?: 'link' | 'code' | undefined;
  callback: Auth0Callback<any>;
}

export interface PasswordLessCallbackResponse {
  accessToken: string;
  idToken: string;
  userInfo: any;
}

export enum AuthType {
  GOOGLE = 'google-oauth2',
  SLACK_AIRSPEED = 'slack-airspeed',
  SLACK_CELEBRATIONS = 'slack-celebrations',
  SLACK_ICEBREAKERS = 'slack-icebreakers',
  SLACK_COFFEE_TALK = 'slack-coffee-talk',
  SLACK_INTROS = 'slack-intros',
  SLACK_MAPS = 'slack-maps',
  SLACK_SHOUTOUTS = 'slack-shoutouts',
  MICROSOFT = 'windowslive',
  AUTH0 = 'Username-Password-Authentication',
}

const ID_TOKEN = 'pegasusIdToken3';
const ACCESS_TOKEN = 'pegasusAccessToken3';
const EXPIRES_AT = 'pegasusExpiresAt3';
const AUTH_REDIRECT_STORAGE = 'auth0RedirectUrl';
const REFRESH_BEFORE = 1000 * 60 * 5; // 5 minutes
const TOKEN_CUTOFF = () => new Date().getTime() + REFRESH_BEFORE;
export class Authenticator {
  private static auth0 = new auth0js.WebAuth({
    clientID: process.env.REACT_APP_AUTH0_CLIENT || '',
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    responseType: 'token id_token',
    scope: 'openid profile email offline_access',
  });

  //Getters
  public static getIdToken = () => {
    return localStorage.getItem(ID_TOKEN);
  };

  public static getUserId = () => {
    const idToken = localStorage.getItem(ID_TOKEN);
    if (idToken) {
      const decoded: object = jwt_decode(idToken);
      return decoded['sub'];
    }
    return null;
  };

  public static getReturnTo = () => {
    return localStorage.getItem(AUTH_REDIRECT_STORAGE);
  };

  public static getTokenPayload = (): Record<string, any> | undefined => {
    const idToken = localStorage.getItem(ID_TOKEN);
    if (idToken) {
      return jwt_decode(idToken);
    }
  };

  public static isSpeakeasy = (): Boolean => {
    const payload = this.getTokenPayload();
    return payload?.iss === 'pegasus';
  };

  //@deprecated: use hasValidToken and getValidToken
  public static isGoodToken = () => {
    if (!Authenticator.hasValidToken()) {
      Authenticator._renewAuth();
    } else {
      return true;
    }
  };

  public static hasValidToken = () => {
    const expiresAt = JSON.parse(localStorage.getItem(EXPIRES_AT) || '0');
    return expiresAt > TOKEN_CUTOFF() && !!localStorage.getItem(ID_TOKEN);
  };
  //

  //Setters
  public static setTokens = ({
    accessToken,
    idToken,
    expiresAt,
  }: {
    accessToken: string;
    idToken: string;
    expiresAt: number;
  }) => {
    let expiresAtMs = JSON.stringify(expiresAt * 1000);

    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(ID_TOKEN, idToken);
    localStorage.setItem(EXPIRES_AT, expiresAtMs);
  };

  public static storeAuthOptions = ({
    connection = AuthType.GOOGLE,
    redirect,
  }: {
    connection?: string;
    redirect: string;
  }) => {
    localStorage.setItem(connection, 'true');
    if (!!redirect) {
      localStorage.setItem(AUTH_REDIRECT_STORAGE, redirect);
    }
  };

  public static clearTokens = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ID_TOKEN);
    localStorage.removeItem(EXPIRES_AT);
    localStorage.removeItem(AUTH_REDIRECT_STORAGE);
  };
  //

  //OTP
  public static sendPasswordLessOtp = ({
    email,
    send = 'code',
    callback = () => {},
  }: PasswordLessParams): any => {
    return Authenticator.auth0.passwordlessStart(
      {
        connection: 'email',
        send,
        email,
        authParams: {
          responseType: 'token id_token',
          scope: 'openid profile email offline_access',
        },
      },
      callback,
    );
  };

  public static authenticateWithOtp = async ({
    email,
    otp,
    callback = () => {},
  }: {
    email: string;
    otp: string;
    callback: Auth0Callback<any>;
  }) => {
    return Authenticator.auth0.passwordlessLogin(
      {
        connection: 'email',
        scope: 'openid profile email offline_access',
        email,
        verificationCode: otp,
        redirectUri: Authenticator.getRedirectLoginUrl(),
      },
      callback,
    );
  };
  //

  //URLs
  public static getRedirectLoginUrl = () => {
    const pathSegments = window.location.pathname.split('/');
    const path = pathSegments[1];
    return process.env.REACT_APP_ENV === 'local'
      ? `https://${window.location.hostname}:${window.location.port}/${path}`
      : `https://${window.location.hostname}/${path}`;
  };

  public static getAuthUrl = (connection, redirect) => {
    //this is what we use to only have /callback registered with auth0
    //and still be able to redirect to multiple endpoints inside Airspeed
    //check src/app/index.ts for how we handle /callback
    const redirectURI = `${window.location.origin}/callback?redirect=${redirect}`;

    return `https://${
      process.env.REACT_APP_AUTH0_DOMAIN
    }/authorize?response_type=id_token%20token&client_id=${
      process.env.REACT_APP_AUTH0_CLIENT
    }&redirect_uri=${redirectURI}&connection=${connection}&nonce=${nanoid(
      10,
    )}&scope=openid%20profile%20email`;
  };
  //

  //Authentication
  public static authorize = ({
    connection = AuthType.GOOGLE,
    redirect,
    forceRedirect = false,
  }: {
    connection?: AuthType;
    redirect?: string;
    forceRedirect?: boolean;
  }) => {
    Authenticator.storeAuthOptions({
      connection,
      redirect: redirect ?? window.location.href,
    });
    Authenticator.auth0.authorize({
      domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
      clientID: process.env.REACT_APP_AUTH0_CLIENT || '',
      responseType: 'token id_token',
      scope: 'openid profile email offline_access',
      connection,
      redirectUri: forceRedirect
        ? redirect
        : window.location.origin + '/callback',
      nonce: nanoid(10),
    });
  };

  public static logout = (redirect?: string) => {
    Authenticator.clearTokens();
    //Speakeasy tokens don't rely on auth0
    if (this.isSpeakeasy()) {
      window.location.reload();
      return;
    }
    Authenticator.storeAuthOptions({
      redirect: redirect ?? window.location.href,
    });
    Authenticator.auth0.logout({
      returnTo: window.location.origin + '/callback',
      clientID: process.env.REACT_APP_AUTH0_CLIENT || '',
    });
  };
  //

  //Refresh
  //undefined means user needs to reauthenticate
  public static getValidToken = async (): Promise<string | undefined> => {
    const expiresAt = JSON.parse(localStorage.getItem(EXPIRES_AT) || '0');
    if (expiresAt > TOKEN_CUTOFF()) {
      //existing token is still valid
      return localStorage.getItem(ID_TOKEN) ?? undefined;
    }

    //Existing token has expired

    //Speakeasy tokens generated by pegasus can't be refreshed
    if (this.isSpeakeasy()) {
      return undefined;
    }

    return this._renewAuth();
  };

  private static _renewAuth = async (): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
      Authenticator.storeAuthOptions({
        redirect: window.location.href,
      });
      Authenticator.auth0.renewAuth(
        {
          domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
          clientID: process.env.REACT_APP_AUTH0_CLIENT || '',
          responseType: 'token id_token',
          scope: 'openid profile email offline_access',
          nonce: nanoid(10),
          // Redirect to current page or dashboard
          redirectUri: window.location.origin + '/callback',
          usePostMessage: true,
          postMessageDataType: 'auth0:silent-authentication',
        },
        (error, result) => {
          if (error) {
            resolve(undefined);
            return;
          }
          const decoded: Jwt = jwt_decode(result.idToken);
          const exp = decoded.exp;
          Authenticator.setTokens({
            accessToken: result.accessToken,
            idToken: result.idToken,
            expiresAt: exp,
          });
          resolve(result.idToken);
        },
      );
    });
  };
}
