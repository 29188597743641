/**
 *
 * Asynchronously loads the component
 *
 */

import { lazyLoad } from 'utils/loadable';

export const LeaderboardPage = lazyLoad(
  () => import('./index'),
  module => module.LeaderboardPage,
);
