import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { BillingPage } from '../../pages/Admin/Billing/Loadable';
import { Button } from '../Button';
import { BodyMediumBold } from '../Text';
import PremiumIcon from '../Icons/PremiumIcon';

interface UpgradeBannerProps {
  enabled: boolean;
}

const UpgradeBannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
`;

const StarIcon = styled.span``;

const BannerTexts = styled.div`
  flex-grow: 1;
`;

const UpgradeText = styled.span`
  font-weight: bold;
`;

const MoreInfo = styled.span`
  display: block;
  color: #666;
`;

const UpgradeButton = styled.div`
  padding: 6px 16px;
  background-color: rgba(54, 169, 224, 1);
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UpgradeBanner = memo(({ enabled }: UpgradeBannerProps) => {
  if (!enabled) {
    return null;
  }

  return (
    <UpgradeBannerContainer>
      <Content>
        <Title>
          <PremiumIcon width={16} height={16} color="rgba(249, 178, 51, 1)" />
          <UpgradeText>Upgrade to a premium plan</UpgradeText>
        </Title>
        <BannerTexts>
          <MoreInfo>Access this feature and much more.</MoreInfo>
        </BannerTexts>
      </Content>
      <NavLink
        to="/admin/billing"
        style={{
          color: 'white',
        }}
      >
        <UpgradeButton>
          <PremiumIcon width={16} height={16} color="rgba(249, 178, 51, 1)" />
          <BodyMediumBold>Upgrade</BodyMediumBold>
        </UpgradeButton>
      </NavLink>
    </UpgradeBannerContainer>
  );
});
