import React from 'react';
import styled from 'styled-components';
import ArrowBack from 'app/assets/chevron-back.svg';
import { pushHistory } from 'app/library/NavHelper';
import { BodyMediumBold } from 'app/components/Text';

interface Props {
  onBack?: () => void;
}

const NavHeader = styled.div`
  display: flex;
  background: rgba(241, 243, 244, 1);
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.15) inset;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  min-height: 56px;
`;

const BackButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const BackArrow = styled.img``;
export function PanelNavHeader({ onBack }: Props) {
  return (
    <NavHeader>
      {!!onBack && (
        <BackButtonContainer onClick={onBack}>
          <BackArrow src={ArrowBack} />
          <BodyMediumBold>Back</BodyMediumBold>
        </BackButtonContainer>
      )}
    </NavHeader>
  );
}
