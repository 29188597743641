import React from 'react';
import styled from 'styled-components/macro';
import PremiumIcon from '../Icons/PremiumIcon';

type Props = {
  disabled?: boolean;
};

const BadgeContainer = styled.div`
  display: inline-flex;
  text-align: center;
  padding: 3px 8px 3px 8px;
  gap: 2px;
  background-color: #f2f4f7;
  border-radius: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  color: #333;
  align-items: center;
`;

const PaidText = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
`;

const PaidBadge = ({ disabled }: Props) => {
  if (disabled) {
    return null;
  }
  return (
    <BadgeContainer>
      <PremiumIcon width={16} height={16} color="rgba(249, 178, 51, 1)" />
      <PaidText>Paid</PaidText>
    </BadgeContainer>
  );
};

export default PaidBadge;
