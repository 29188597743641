import { Apollo } from 'app/library/Apollo';
import { getUser } from 'app/library/UserAPI';

export function searchUsers(
  searchText: string,
  platforms?: string[],
  companyId?: string,
) {
  const data = {
    query: `
        query search($keyword: String!, $first: Int!, $offset: Int!, $platforms: [String!], $companyId: CompanyId) {
          searchUsers(input: { keyword: $keyword, first: $first, offset: $offset, platforms: $platforms, companyId: $companyId }) {
            items {
              id
              firstName
              lastName
              title
              image {
                url
              }
              locations {
                  id
                  type
                  geoLocation
                  name
                  startDate
                  endDate
                  primaryCompanyId
              }
              integrations {
                slack {
                  integrationId
                }
              }
            }
            first
            offset
            nextToken
            totalCount
          }
        }
      `,
    variables: {
      keyword: searchText.trim(),
      first: 10,
      offset: 0,
      platforms,
      companyId,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.searchUsers.items)
    .catch(err => Apollo.handleError(err, data));
}

export function getUsers(userId) {
  return getUser(userId);
}
