import { PayloadAction } from '@reduxjs/toolkit';
import { ContainerType } from 'app/library/AppConstants';
import { User } from 'types/User';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { appSaga } from './saga';
import { AppState, CompanyValue, ContainerInfo } from './types';
import {
  ChannelGroup,
  ListPaymentProfiles,
  SlackWorkspace,
  SlackWorkspaceUser,
} from 'types/Slack';
import {
  BankAccountDetail,
  CreditCardDetail,
  SubscriptionPlan,
} from 'app/library/BillingAPI';
import {
  ChannelFilter,
  SlackbotType,
} from 'app/pages/InstallationChannelSelector/slice/type';
import { SlackChannel, SlackRawUser } from 'types/SlackChannel';
import { DROPDOWN_ITEM } from 'app/pages/Admin/AdminAppsPage/ChannelsPanel/MultiSelectDropdown';

/**
 * EXAMPLE
 *
 * client.logEvent({
 *  event_type: 'Node.js Event',
 *  user_id: 'datamonster@gmail.com',
 *  location_lat: 37.77,
 *  location_lng: -122.39,
 *  ip: '127.0.0.1',
 *  event_properties: {
 *    keyString: 'valueString',
 *    keyInt: 11,
 *    keyBool: true
 *  }
 * });
 */

const apps_initial_data: Array<DROPDOWN_ITEM> = [
  {
    value: SlackbotType.BIRTHDAY,
    title: '🎉 Celebrations',
    checked: false,
  },
  {
    value: SlackbotType.COFFEE_TALK,
    title: '☕️ Coffee Talk',
    checked: false,
  },
  {
    value: SlackbotType.ICE_BREAKERS,
    title: '🧊 Icebreakers',
    checked: false,
  },
  {
    value: SlackbotType.INTROS,
    title: '👋 Intros',
    checked: false,
  },
  {
    value: SlackbotType.MAPS,
    title: '📍 Maps',
    checked: false,
  },
  {
    value: SlackbotType.SHOUTOUT,
    title: '🏆 Shoutouts',
    checked: false,
  },
];

const channel_initial_data: Array<DROPDOWN_ITEM> = [
  {
    value: ChannelFilter.Private,
    title: '🔒 Private',
    checked: false,
  },
  {
    value: ChannelFilter.Public,
    title: '# Public',
    checked: false,
  },
];

export const initialState: AppState = {
  loading: false,
  error: undefined,
  success: undefined,
  user: undefined,
  initialLoading: true,
  containerInfo: {
    containerType: ContainerType.NONE,
    containerParameters: {},
  },
  authenticated: false,
  authorized: false,
  showLeftNav: false,
  channelsGroupFilters: {
    byApp: apps_initial_data,
    byChannel: channel_initial_data,
  },
  channels: [],
  slackUsers: [],
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setInitialLoading(state, action: PayloadAction<boolean>) {
      state.initialLoading = action.payload;
    },
    setSuccess(state, action: PayloadAction<string | undefined>) {
      state.success = action.payload;
    },
    setError(state, action: PayloadAction<string | undefined>) {
      state.error = action.payload;
    },
    initAuth(state, action: PayloadAction<void>) {},
    onAuthInitiated(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    loadCurrentUser(state, action: PayloadAction<void>) {},
    setCurrentUser(state, action: PayloadAction<User>) {
      state.currentUser = action.payload;
      //pros: minimize side effects
      //cons: might silently fail
      state.user = action.payload;
    },
    loadSlackUsers(state, action: PayloadAction<void>) {},
    loadSlackChannels(state, action: PayloadAction<void>) {},
    logout(state, action: PayloadAction<void>) {},
    refreshPaymentProfiles(state, action: PayloadAction<string>) {},
    updateUser(state, action: PayloadAction<User | undefined>) {
      state.user = action.payload;
    },
    setSlackWorkspace(
      state,
      action: PayloadAction<SlackWorkspace | undefined>,
    ) {
      state.slackWorkspace = action.payload;
    },
    setContainerInfo(state, action: PayloadAction<ContainerInfo>) {
      state.containerInfo = action.payload;
    },
    setAuthenticated(state, action: PayloadAction<boolean>) {
      state.authenticated = action.payload;
    },
    setAuthorized(state, action: PayloadAction<boolean>) {
      state.authorized = action.payload;
    },
    setShowLeftNav(state, action: PayloadAction<boolean>) {
      state.showLeftNav = action.payload;
    },
    setSubscriptions(state, action: PayloadAction<Array<SubscriptionPlan>>) {
      state.subscriptions = action.payload;
    },
    setChannels(state, action: PayloadAction<Array<SlackChannel>>) {
      state.channels = action.payload;
    },
    setSlackRawUsers(state, action: PayloadAction<Array<SlackRawUser>>) {
      state.slackUsers = action.payload;
    },
    updateSubscription(state, action: PayloadAction<SubscriptionPlan>) {
      state.subscriptions = state.subscriptions?.map(sub =>
        sub.id === action.payload.id ? action.payload : sub,
      );
    },
    addSlackbotAdminInSlackWorkspace(
      state,
      action: PayloadAction<Array<SlackWorkspaceUser>>,
    ) {
      state.slackWorkspace!.admin.items = [
        ...(state.slackWorkspace?.admin.items || []),
        ...action.payload,
      ];
    },
    updateSlackbotAdminInSlackWorkspace(
      state,
      action: PayloadAction<Array<SlackWorkspaceUser>>,
    ) {
      state.slackWorkspace!.admin.items = action.payload;
    },
    addSlackbotChannelsGroupInSlackWorkspace(
      state,
      action: PayloadAction<Array<ChannelGroup>>,
    ) {
      state.slackWorkspace!.slackbotChannelGroups.items = [
        ...(state.slackWorkspace?.slackbotChannelGroups.items || []),
        ...action.payload,
      ];
    },
    updateSlackbotChannelsGroupInSlackWorkspace(
      state,
      action: PayloadAction<{ totalCount: number; items: Array<ChannelGroup> }>,
    ) {
      state.slackWorkspace!.slackbotChannelGroups = action.payload;
    },
    resetChannelsGroupFilters(state, action: PayloadAction<void>) {
      state.channelsGroupFilters = {
        byApp: apps_initial_data,
        byChannel: channel_initial_data,
      };
    },
    addPaymentProfile(
      state,
      action: PayloadAction<CreditCardDetail | BankAccountDetail>,
    ) {
      const existingProfiles =
        state.slackWorkspace?.listPaymentProfiles?.items || [];
      state.slackWorkspace!.listPaymentProfiles.items = [
        ...existingProfiles,
        action.payload,
      ];
    },
    updatePaymentProfile(state, action: PayloadAction<ListPaymentProfiles>) {
      state.slackWorkspace!.listPaymentProfiles = action.payload;
    },
    deletePaymentProfile(state, action: PayloadAction<string>) {
      const existingProfiles =
        state.slackWorkspace?.listPaymentProfiles?.items || [];
      state.slackWorkspace!.listPaymentProfiles.items = existingProfiles.filter(
        item => item.id !== action.payload,
      );
    },
    updateChannelsGroupFilters(
      state,
      action: PayloadAction<{
        byApp?: Array<DROPDOWN_ITEM>;
        byChannel?: Array<DROPDOWN_ITEM>;
      }>,
    ) {
      if (action.payload.byApp) {
        state.channelsGroupFilters.byApp = action.payload.byApp;
      }
      if (action.payload.byChannel) {
        state.channelsGroupFilters.byChannel = action.payload.byChannel;
      }
    },
    setCompanyValues(state, action: PayloadAction<Array<CompanyValue>>) {
      state.companyValues = action.payload;
    },
    refreshCompanyValues(state, action: PayloadAction<string>) {},
  },
});

export const { actions: appActions } = slice;

export const useAppSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: appSaga });
  return { actions: slice.actions };
};
