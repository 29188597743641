/**
 *
 * EmailChips
 *
 */
import React, { memo } from 'react';
import Chips from 'react-email-chip';
import { selectUser } from 'app/slice/selectors';
import { useSelector } from 'react-redux';
import './style.css';

interface Props {
  placeholder?: string;
  limit?: number;
  onEmailChange?: any;
  emails: any[];
}

export const EmailChips = memo(
  ({ placeholder, limit, onEmailChange, emails }: Props) => {
    const user = useSelector(selectUser);
    const pattern = new RegExp(
      `^(.+)@${user?.company?.emailDomain ?? '(.+)'}$`,
    );

    return (
      <Chips
        chips={emails}
        placeholder={placeholder}
        save={onEmailChange}
        pattern={pattern}
        required={false}
        limit={limit}
        limitMessage="You’ve hit the maximum number of emails"
      ></Chips>
    );
  },
) as any;

EmailChips.defaultProps = {
  limit: 5,
  onEmailChange: data => console.info('DATA ' + JSON.stringify(data)),
};
