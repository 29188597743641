/**
 *
 * Asynchronously loads the component for PublicProfileLandingPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const PublicProfileLandingPage = lazyLoad(
  () => import('./index'),
  module => module.PublicProfileLandingPage,
);
