import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.settingsPanel || initialState;

export const selectSecondaryEmail = createSelector(
  [selectSlice],
  state => state.secondaryEmail,
);

export const selectSecondaryEmailError = createSelector(
  [selectSlice],
  state => state.secondaryEmailError,
);

export const selectSecondaryEmailAdded = createSelector(
  [selectSlice],
  state => state.secondaryEmailAdded,
);
