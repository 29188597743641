import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSlackWorkspace } from "../../../slice/selectors";
import { UpgradeBanner } from "../../UpgradeBanner";
import { FileUpload } from '../Components/FileUpload';
import { useAppCustomizationsPanelSlice } from '../slice';
import { selectCustomizationStateCopy } from '../slice/selectors';
import { Banner } from '../slice/types';

const Title = styled.span<{}>`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

const Description = styled.span<{}>`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const Image = styled.img`
  width: 100%;
`;

const Radio = styled.input`
  height: 20px;
  width: 20px;
`;

const BannerList = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export enum BANNER_TYPE {
  CUSTOM,
  DEFAULT,
}

export const CustomCelebrationsCustomization = () => {
  const stateCopy = useSelector(selectCustomizationStateCopy);
  const { customCelebrationBanner } = stateCopy;
  const dispatch = useDispatch();
  const { actions } = useAppCustomizationsPanelSlice();
  const slackWorkspace = useSelector(selectSlackWorkspace);

  const onNewBannerUpload = (url: string) => {
    const previousBanners =
      customCelebrationBanner && customCelebrationBanner.length
        ? customCelebrationBanner
        : [];
    const updatedCustomCelebrationBanners: Array<Banner> = [
      ...previousBanners.map(item => ({ ...item, isActive: false })),
      { url, isActive: true },
    ];
    dispatch(
      actions.setStateCopy({
        ...stateCopy,
        customCelebrationBanner: updatedCustomCelebrationBanners,
      }),
    );
    dispatch(actions.setIsDataChanged(true));
  };

  const onSelectBanner = (url: string) => {
    let previousBanners =
      customCelebrationBanner && customCelebrationBanner.length
        ? customCelebrationBanner
        : [];
    if (url === '') {
      dispatch(
        actions.setStateCopy({
          ...stateCopy,
          customCelebrationBanner: previousBanners.map(item => ({
            ...item,
            isActive: false,
          })),
        }),
      );
    } else {
      dispatch(
        actions.setStateCopy({
          ...stateCopy,
          customCelebrationBanner: previousBanners.map(item => {
            return { ...item, isActive: item.url === url ? true : false };
          }),
        }),
      );
    }
    dispatch(actions.setIsDataChanged(true));
  };

  return (
    <>
      <UpgradeBanner enabled={!slackWorkspace?.entitlements.celebrations.customBanner}/>
      <Title>Custom Celebrations Banners</Title>
      <Description>
        Upload custom banners to make it special and unique to your
        organization. We recommend PNG or JPG at 1280x 650px.
      </Description>
      <BannerList>
        <div>
          <Radio
            type="radio"
            value={BANNER_TYPE.DEFAULT}
            name="default"
            checked={
              !customCelebrationBanner ||
              !customCelebrationBanner.find(item => item.isActive)
            }
            onClick={() => onSelectBanner('')}
          />
        </div>
        <div>
          <Image
            src={`${location.origin}/celebrations/generic_celebration.png`}
          />
        </div>
      </BannerList>
      {customCelebrationBanner &&
        customCelebrationBanner.map(item => (
          <BannerList>
            <div>
              <Radio
                type="radio"
                value={item.url}
                checked={item.isActive}
                onClick={() => onSelectBanner(item.url)}
              />
            </div>
            <div>
              <Image src={item.url} />
            </div>
          </BannerList>
        ))}
      <FileUpload onFileUpload={onNewBannerUpload}
                  disabled={!slackWorkspace?.entitlements.celebrations.customBanner}
      />
    </>
  );
};
