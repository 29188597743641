import {
  selectNotifications,
  selectQuestionId,
  selectUserId,
} from './selectors';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notificationsSliceActions as actions } from '.';
import { appActions } from 'app/slice';
import {
  getNotifications,
  readNotifications,
} from 'app/library/NotificationAPI';
import isOnline from 'is-online';
import { Notification } from 'types/Notification';
import { getQuestion } from 'app/library/ProfileQuestionAPI';
import { profileQuestionModalActions as modalActions } from 'app/modals/ProfileQuestionModal/slice';
import { gotoHomebase, gotoTeammates } from 'app/library/NavHelper';
import { NotificationType } from 'app/library/AppConstants';
import { selectAuthenticated, selectUser } from 'app/slice/selectors';

function isQotwAnswerOrResult(sourceId: string): boolean {
  return sourceId.startsWith('qow') && sourceId !== 'qow:0';
}

function* doLoadNotifications() {
  const online = yield call(isOnline);
  const authenticated = yield select(selectAuthenticated);
  const user = yield select(selectUser);
  if (online && authenticated && user) {
    try {
      const notifications: Notification[] = (yield call(
        getNotifications,
        user.id,
      )).filter(notification => notification.notificationType !== 'qow');
      yield put(actions.onNotificationsLoaded(notifications));
    } catch (err) {
      console.error(JSON.stringify(err));
      yield put(appActions.setError('Error loading notifications.'));
    }
  }
}

function* doReadNotifications() {
  try {
    const notifications = (yield select(selectNotifications)).filter(
      notification => notification.isNew === 'true',
    );
    yield call(
      readNotifications,
      notifications.map(notification => notification.id),
    );

    yield call(doLoadProfileViewNotifications);
  } catch (err) {
    console.error(JSON.stringify(err));
    yield put(appActions.setError('Error updating notifications.'));
  }
}

function* doAnswerQotw() {
  const questionId = yield select(selectQuestionId);
  const question = yield call(getQuestion, questionId);
  yield call(doReadNotifications);
  yield put(modalActions.setCurrentQuestion(question));
  yield put(modalActions.show([undefined, undefined, false]));
}

function* doShowQotw() {
  const questionId = yield select(selectQuestionId);
  const userId = yield select(selectUserId);
  yield call(doReadNotifications);
  yield call(gotoHomebase, {
    queryQuestionId: questionId,
    queryUserId: userId,
  });
}

function* doViewTeammates() {
  yield call(doReadNotifications);
  yield call(gotoTeammates);
}

function* doLoadProfileViewNotifications() {
  const online = yield call(isOnline);
  const user = yield select(selectUser);
  if (!online || !user) {
    return;
  }
  try {
    const notifications: Notification[] = yield call(getNotifications, user.id);
    const filteredNotifications = notifications.filter(
      notification =>
        notification.isNew === 'true' &&
        notification.notificationType === NotificationType.PUBLIC_PROFILE_VIEW,
    );

    yield put(actions.onNotificationsLoaded(filteredNotifications));
  } catch (err) {
    console.error(JSON.stringify(err));
    yield put(appActions.setError('Error loading notifications.'));
  }
}

export function* notificationsSliceSaga() {
  yield takeLatest(actions.loadNotifications.type, doLoadNotifications);
  yield takeLatest(actions.readNotifications.type, doReadNotifications);
  yield takeLatest(actions.viewTeammates.type, doViewTeammates);
  yield takeLatest(
    actions.loadProfileViewNotifications.type,
    doLoadProfileViewNotifications,
  );
}
