import React, { useState } from 'react';
import styled from 'styled-components';
import { TextXSmallNormal } from '../Text';

type Props = {
  text: string;
  isExpanded: boolean;
  position?: 'top' | 'left';
  color?: 'light' | 'dark';
};

export const Tooltip = ({
  text,
  isExpanded,
  position = 'top',
  color = 'dark',
}: Props) => {
  const bgColor = color === 'light' ? '#fff' : '#4D4D4D';
  const textColor = color === 'light' ? 'rgba(0, 0, 0, 0.90)' : '#fff';
  return (
    <>
      {isExpanded && (
        <Container
          className="tooltip-container"
          position={position}
          color={bgColor}
        >
          <BubbleContainer>
            <InfoContainer position={position} color={bgColor}>
              <TextXSmallNormal color={textColor} textAlign="center">
                {text}
              </TextXSmallNormal>
              <InfoArrow position={position} color={bgColor} />
            </InfoContainer>
          </BubbleContainer>
        </Container>
      )}
    </>
  );
};

type ContainerProps = {
  position: 'top' | 'left';
  color: string;
};

const Container = styled.div<ContainerProps>`
  position: absolute;
  z-index: 100;
  bottom: 100%;
  display: flex;
  justify-content: space-around;
  width: 100%;
  ${props => props.position == 'left' && 'right: calc(200% + 12px);'}
  ${props => props.position == 'left' && 'top: 0;'}
  ${props =>
    props.position == 'top' &&
    'left: calc(15px - 50%);'} // box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
`;

const BubbleContainer = styled.div`
  padding-bottom: 10px;
`;

const InfoContainer = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 12px;
  ${props => `background: ${props.color};`}
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  color: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
`;

const InfoArrow = styled.div<ContainerProps>`
  position: absolute;
  width: 12px;
  height: 12px;
  ${props => props.position == 'top' && 'bottom: -6px;'}
  ${props => props.position == 'top' && 'left: calc(50% - 6px);'}
  ${props => props.position == 'left' && 'right: -6px;'}
  ${props => props.position == 'left' && 'top: calc(50% - 6px);'}
  ${props => `background: ${props.color};`}
  border-radius: 1px;
  transform: rotate(45deg);
  box-shadow: 4px 4px 4px -2px rgba(0, 0, 0, 0.15);
`;
