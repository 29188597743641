import { Button } from 'app/components/Button';
import { BodyMediumNormal } from 'app/components/Text';
import React from 'react';
import styled from 'styled-components';
import { Modal, ModalHeader } from '../../Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onYesClick: () => void;
}

const BodyContainer = styled.div`
  position: relative;
  padding: 24px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 2em;
  gap: 1em;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const CancelConfirmationModal = ({
  isOpen,
  onClose,
  onYesClick,
}: Props) => {
  return (
    <Modal show={isOpen} maxWidth="600px" marginTop="20%">
      <ModalHeader title={'Are you sure?'} onClose={onClose} />
      <BodyContainer>
        <BodyMediumNormal>
          Are you sure you want leave this page before saving?
        </BodyMediumNormal>
        <ButtonsContainer>
          <Button onClick={onClose} color={'white'}>
            Cancel
          </Button>
          <Button onClick={onYesClick}>Yes</Button>
        </ButtonsContainer>
      </BodyContainer>
    </Modal>
  );
};
