import React from 'react';

export const AppsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_15181_25712)">
        <path
          d="M11 4V8C11 8.55228 10.5523 9 10 9H4C3.44772 9 3 8.55228 3 8V4C3 3.44772 3.44772 3 4 3H10C10.5523 3 11 3.44772 11 4ZM10 11H4C3.44772 11 3 11.4477 3 12V20C3 20.5523 3.44772 21 4 21H10C10.5523 21 11 20.5523 11 20V12C11 11.4477 10.5523 11 10 11ZM20 15H14C13.4477 15 13 15.4477 13 16V20C13 20.5523 13.4477 21 14 21H20C20.5523 21 21 20.5523 21 20V16C21 15.4477 20.5523 15 20 15ZM20 3H14C13.4477 3 13 3.44772 13 4V12C13 12.5523 13.4477 13 14 13H20C20.5523 13 21 12.5523 21 12V4C21 3.44772 20.5523 3 20 3Z"
          fill="white"
          fill-opacity="0.9"
        />
      </g>
      <defs>
        <clipPath id="clip0_15181_25712">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
