/**
 *
 * Asynchronously loads the component for IceBreakerAnswers
 *
 */

import { lazyLoad } from 'utils/loadable';

export const IceBreakerAnswers = lazyLoad(
  () => import('./index'),
  module => module.IceBreakerAnswers,
);
