/**
 *
 * Asynchronously loads the component for InstallationInstructions
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CreateIntrosTemplate = lazyLoad(
  () => import('./index'),
  module => module.CreateIntrosTemplate,
);
