import { MapItem } from 'app/library/LocationAPI';

export const DEMO_WORKSPACE_ID = 'demo';

export const searchDemoMapItems = (searchTerm: string): MapItem[] => {
  return demoMapItems.filter(mapItem => {
    const user = mapItem.user;
    return (
      user.firstName.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
      user.lastName.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
  });
};

export const demoProfilePicture = (): string => {
  const randomIndex = Math.floor(Math.random() * demoMapItems.length);
  const mapItem = demoMapItems[randomIndex];
  return mapItem.user.image!;
};

export const demoMapItems: MapItem[] = [
  {
    user: {
      id: 'demo:0',
      firstName: 'Olivia',
      lastName: 'Rhye',
      title: 'Technical Support Representative',
      image: '/maps/demo/profile-picture-0.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'San Franciso, CA, USA',
          geoLocation: [37.7749, -122.4194],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'San Franciso, CA, USA',
      geoLocation: [37.7749, -122.4194],
    },
  },
  {
    user: {
      id: 'demo:1',
      firstName: 'Phoenix',
      lastName: 'Baker',
      title: 'Web Designer',
      image: '/maps/demo/profile-picture-1.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'San Franciso, CA, USA',
          geoLocation: [37.7749, -122.4194],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'San Franciso, CA, USA',
      geoLocation: [37.7749, -122.4194],
    },
  },
  {
    user: {
      id: 'demo:2',
      firstName: 'Lana',
      lastName: 'Steiner',
      title: 'Network Engineer',
      image: '/maps/demo/profile-picture-2.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'San Franciso, CA, USA',
          geoLocation: [37.7749, -122.4194],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'San Franciso, CA, USA',
      geoLocation: [37.7749, -122.4194],
    },
  },
  {
    user: {
      id: 'demo:3',
      firstName: 'Demi',
      lastName: 'Wilkinson',
      title: 'Software Engineer',
      image: '/maps/demo/profile-picture-3.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'San Franciso, CA, USA',
          geoLocation: [37.7749, -122.4194],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'San Franciso, CA, USA',
      geoLocation: [37.7749, -122.4194],
    },
  },
  {
    user: {
      id: 'demo:4',
      firstName: 'Candice',
      lastName: 'Wu',
      title: 'Database Administrator',
      image: '/maps/demo/profile-picture-4.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'San Franciso, CA, USA',
          geoLocation: [37.7749, -122.4194],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'San Franciso, CA, USA',
      geoLocation: [37.7749, -122.4194],
    },
  },
  {
    user: {
      id: 'demo:5',
      firstName: 'Natali',
      lastName: 'Craig',
      title: 'Systems Administrator',
      image: '/maps/demo/profile-picture-5.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'San Franciso, CA, USA',
          geoLocation: [37.7749, -122.4194],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'San Franciso, CA, USA',
      geoLocation: [37.7749, -122.4194],
    },
  },
  {
    user: {
      id: 'demo:6',
      firstName: 'Drew',
      lastName: 'Cano',
      title: 'Quality Assurance Analyst',
      image: '/maps/demo/profile-picture-6.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'San Franciso, CA, USA',
          geoLocation: [37.7749, -122.4194],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'San Franciso, CA, USA',
      geoLocation: [37.7749, -122.4194],
    },
  },
  {
    user: {
      id: 'demo:7',
      firstName: 'Orlando',
      lastName: 'Diggs',
      title: 'IT Project Manager',
      image: '/maps/demo/profile-picture-7.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'New York City, NY, USA',
          geoLocation: [40.7128, -74.006],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'New York City, NY, USA',
      geoLocation: [40.7128, -74.006],
    },
  },
  {
    user: {
      id: 'demo:8',
      firstName: 'Andi',
      lastName: 'Lane',
      title: 'Network Architect',
      image: '/maps/demo/profile-picture-8.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'New York City, NY, USA',
          geoLocation: [40.7128, -74.006],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'New York City, NY, USA',
      geoLocation: [40.7128, -74.006],
    },
  },
  {
    user: {
      id: 'demo:9',
      firstName: 'Kate',
      lastName: 'Morrison',
      title: 'DevOps Engineer',
      image: '/maps/demo/profile-picture-9.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'New York City, NY, USA',
          geoLocation: [40.7128, -74.006],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'New York City, NY, USA',
      geoLocation: [40.7128, -74.006],
    },
  },
  {
    user: {
      id: 'demo:10',
      firstName: 'Koray',
      lastName: 'Okumus',
      title: 'System Analyst',
      image: '/maps/demo/profile-picture-10.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'New York City, NY, USA',
          geoLocation: [40.7128, -74.006],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'New York City, NY, USA',
      geoLocation: [40.7128, -74.006],
    },
  },
  {
    user: {
      id: 'demo:11',
      firstName: 'Ava',
      lastName: 'Wright',
      title: 'IT Security Consultant',
      image: '/maps/demo/profile-picture-11.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'New York City, NY, USA',
          geoLocation: [40.7128, -74.006],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'New York City, NY, USA',
      geoLocation: [40.7128, -74.006],
    },
  },
  {
    user: {
      id: 'demo:12',
      firstName: 'Eve',
      lastName: 'Leroy',
      title: 'Business Analyst',
      image: '/maps/demo/profile-picture-12.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Austin, TX, USA',
          geoLocation: [30.2672, -97.7431],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Austin, TX, USA',
      geoLocation: [30.2672, -97.7431],
    },
  },
  {
    user: {
      id: 'demo:13',
      firstName: 'Zahir',
      lastName: 'Mays',
      title: 'Solutions Architect',
      image: '/maps/demo/profile-picture-13.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Austin, TX, USA',
          geoLocation: [30.2672, -97.7431],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Austin, TX, USA',
      geoLocation: [30.2672, -97.7431],
    },
  },
  {
    user: {
      id: 'demo:14',
      firstName: 'Joshua',
      lastName: 'Wilson',
      title: 'Software Developer',
      image: '/maps/demo/profile-picture-14.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Austin, TX, USA',
          geoLocation: [30.2672, -97.7431],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Austin, TX, USA',
      geoLocation: [30.2672, -97.7431],
    },
  },
  {
    user: {
      id: 'demo:15',
      firstName: 'Rene',
      lastName: 'Wells',
      title: 'Technical Writer',
      image: '/maps/demo/profile-picture-15.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Austin, TX, USA',
          geoLocation: [30.2672, -97.7431],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Austin, TX, USA',
      geoLocation: [30.2672, -97.7431],
    },
  },
  {
    user: {
      id: 'demo:16',
      firstName: 'Lori',
      lastName: 'Bryson',
      title: 'Computer Systems Analyst',
      image: '/maps/demo/profile-picture-16.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Austin, TX, USA',
          geoLocation: [30.2672, -97.7431],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Austin, TX, USA',
      geoLocation: [30.2672, -97.7431],
    },
  },
  {
    user: {
      id: 'demo:17',
      firstName: 'Anaiah',
      lastName: 'Whitten',
      title: 'IT Consultant',
      image: '/maps/demo/profile-picture-17.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Austin, TX, USA',
          geoLocation: [30.2672, -97.7431],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Austin, TX, USA',
      geoLocation: [30.2672, -97.7431],
    },
  },
  {
    user: {
      id: 'demo:18',
      firstName: 'Noah',
      lastName: 'Pierre',
      title: 'Software Tester',
      image: '/maps/demo/profile-picture-18.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Austin, TX, USA',
          geoLocation: [30.2672, -97.7431],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Austin, TX, USA',
      geoLocation: [30.2672, -97.7431],
    },
  },
  {
    user: {
      id: 'demo:19',
      firstName: 'Katherine',
      lastName: 'Moss',
      title: 'Information Security Engineer',
      image: '/maps/demo/profile-picture-19.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Austin, TX, USA',
          geoLocation: [30.2672, -97.7431],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Austin, TX, USA',
      geoLocation: [30.2672, -97.7431],
    },
  },
  {
    user: {
      id: 'demo:20',
      firstName: 'Mollie',
      lastName: 'Hall',
      title: 'Cyber Security Analyst',
      image: '/maps/demo/profile-picture-20.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Austin, TX, USA',
          geoLocation: [30.2672, -97.7431],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Austin, TX, USA',
      geoLocation: [30.2672, -97.7431],
    },
  },
  {
    user: {
      id: 'demo:21',
      firstName: 'Eduard',
      lastName: 'Franz',
      title: 'AI Engineer',
      image: '/maps/demo/profile-picture-21.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Chicago, IL, USA',
          geoLocation: [41.8781, -87.6298],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Chicago, IL, USA',
      geoLocation: [41.8781, -87.6298],
    },
  },
  {
    user: {
      id: 'demo:22',
      firstName: 'Alec',
      lastName: 'Whitten',
      title: 'Data Scientist',
      image: '/maps/demo/profile-picture-22.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Chicago, IL, USA',
          geoLocation: [41.8781, -87.6298],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Chicago, IL, USA',
      geoLocation: [41.8781, -87.6298],
    },
  },
  {
    user: {
      id: 'demo:23',
      firstName: 'Eva',
      lastName: 'Bond',
      title: 'Technical Support Specialist',
      image: '/maps/demo/profile-picture-23.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Chicago, IL, USA',
          geoLocation: [41.8781, -87.6298],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Chicago, IL, USA',
      geoLocation: [41.8781, -87.6298],
    },
  },
  {
    user: {
      id: 'demo:24',
      firstName: 'Sophia',
      lastName: 'Perez',
      title: 'Mobile App Developer',
      image: '/maps/demo/profile-picture-24.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Chicago, IL, USA',
          geoLocation: [41.8781, -87.6298],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Chicago, IL, USA',
      geoLocation: [41.8781, -87.6298],
    },
  },
  {
    user: {
      id: 'demo:25',
      firstName: 'Lyle',
      lastName: 'Kauffman',
      title: 'Data Architect',
      image: '/maps/demo/profile-picture-25.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Nashville, TN, USA',
          geoLocation: [36.1627, -86.7816],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Nashville, TN, USA',
      geoLocation: [36.1627, -86.7816],
    },
  },
  {
    user: {
      id: 'demo:26',
      firstName: 'Kelly',
      lastName: 'Williams',
      title: 'Cloud Engineer',
      image: '/maps/demo/profile-picture-26.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Nashville, TN, USA',
          geoLocation: [36.1627, -86.7816],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Nashville, TN, USA',
      geoLocation: [36.1627, -86.7816],
    },
  },
  {
    user: {
      id: 'demo:27',
      firstName: 'Lucy',
      lastName: 'Bond',
      title: 'IT Director',
      image: '/maps/demo/profile-picture-27.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Nashville, TN, USA',
          geoLocation: [36.1627, -86.7816],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Nashville, TN, USA',
      geoLocation: [36.1627, -86.7816],
    },
  },
  {
    user: {
      id: 'demo:28',
      firstName: 'Alisa',
      lastName: 'Hester',
      title: 'Product Manager',
      image: '/maps/demo/profile-picture-28.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Washington, D.C.',
          geoLocation: [38.9072, -77.0369],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Washington, D.C.',
      geoLocation: [38.9072, -77.0369],
    },
  },
  {
    user: {
      id: 'demo:29',
      firstName: 'Rosalee',
      lastName: 'Melvin',
      title: 'Help Desk Technician',
      image: '/maps/demo/profile-picture-29.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Washington, D.C.',
          geoLocation: [38.9072, -77.0369],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Washington, D.C.',
      geoLocation: [38.9072, -77.0369],
    },
  },
  {
    user: {
      id: 'demo:30',
      firstName: 'Loki',
      lastName: 'Bright',
      title: 'Web Developer',
      image: '/maps/demo/profile-picture-30.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Washington, D.C.',
          geoLocation: [38.9072, -77.0369],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Washington, D.C.',
      geoLocation: [38.9072, -77.0369],
    },
  },
  {
    user: {
      id: 'demo:31',
      firstName: 'Aliah',
      lastName: 'Lane',
      title: 'Cloud Solutions Architect',
      image: '/maps/demo/profile-picture-31.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'London, United Kingdom',
          geoLocation: [51.5074, -0.1278],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'London, United Kingdom',
      geoLocation: [51.5074, -0.1278],
    },
  },
  {
    user: {
      id: 'demo:32',
      firstName: 'Anita',
      lastName: 'Cruz',
      title: 'Network Technician',
      image: '/maps/demo/profile-picture-32.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'London, United Kingdom',
          geoLocation: [51.5074, -0.1278],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'London, United Kingdom',
      geoLocation: [51.5074, -0.1278],
    },
  },
  {
    user: {
      id: 'demo:33',
      firstName: 'Kari',
      lastName: 'Rasmussen',
      title: 'Business Intelligence Analyst',
      image: '/maps/demo/profile-picture-33.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'London, United Kingdom',
          geoLocation: [51.5074, -0.1278],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'London, United Kingdom',
      geoLocation: [51.5074, -0.1278],
    },
  },
  {
    user: {
      id: 'demo:34',
      firstName: 'Nala',
      lastName: 'Goins',
      title: 'User Experience Designer',
      image: '/maps/demo/profile-picture-34.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'London, United Kingdom',
          geoLocation: [51.5074, -0.1278],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'London, United Kingdom',
      geoLocation: [51.5074, -0.1278],
    },
  },
  {
    user: {
      id: 'demo:35',
      firstName: 'Alisa',
      lastName: 'Hester',
      title: 'Network Security Engineer',
      image: '/maps/demo/profile-picture-35.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Newark, NJ, USA',
          geoLocation: [40.7357, -74.1724],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Newark, NJ, USA',
      geoLocation: [40.7357, -74.1724],
    },
  },
  {
    user: {
      id: 'demo:36',
      firstName: 'Rich',
      lastName: 'Wilson',
      title: 'System Designer',
      image: '/maps/demo/profile-picture-36.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Newark, NJ, USA',
          geoLocation: [40.7357, -74.1724],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Newark, NJ, USA',
      geoLocation: [40.7357, -74.1724],
    },
  },
  {
    user: {
      id: 'demo:37',
      firstName: 'Annie',
      lastName: 'Stanley',
      title: 'Database Developer',
      image: '/maps/demo/profile-picture-37.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Dallas, TX, USA',
          geoLocation: [32.7767, -96.797],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Dallas, TX, USA',
      geoLocation: [32.7767, -96.797],
    },
  },
  {
    user: {
      id: 'demo:38',
      firstName: 'Johnny',
      lastName: 'Bell',
      title: 'Developer Advocate',
      image: '/maps/demo/profile-picture-38.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Dallas, TX, USA',
          geoLocation: [32.7767, -96.797],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Dallas, TX, USA',
      geoLocation: [32.7767, -96.797],
    },
  },
  {
    user: {
      id: 'demo:39',
      firstName: 'Mia',
      lastName: 'Ward',
      title: 'Software Engineer',
      image: '/maps/demo/profile-picture-39.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Dallas, TX, USA',
          geoLocation: [32.7767, -96.797],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Dallas, TX, USA',
      geoLocation: [32.7767, -96.797],
    },
  },
  {
    user: {
      id: 'demo:40',
      firstName: 'Archie',
      lastName: 'Young',
      title: 'Systems Administrator',
      image: '/maps/demo/profile-picture-40.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Palo Alto, CA, USA',
          geoLocation: [37.4419, -122.143],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Palo Alto, CA, USA',
      geoLocation: [37.4419, -122.143],
    },
  },
  {
    user: {
      id: 'demo:41',
      firstName: 'Amanda',
      lastName: 'Carr',
      title: 'Database Administrator',
      image: '/maps/demo/profile-picture-41.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Palo Alto, CA, USA',
          geoLocation: [37.4419, -122.143],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Palo Alto, CA, USA',
      geoLocation: [37.4419, -122.143],
    },
  },
  {
    user: {
      id: 'demo:42',
      firstName: 'Megan',
      lastName: 'Sims',
      title: 'Front End Developer',
      image: '/maps/demo/profile-picture-42.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'San Jose, CA, USA',
          geoLocation: [37.3382, -121.8863],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'San Jose, CA, USA',
      geoLocation: [37.3382, -121.8863],
    },
  },
  {
    user: {
      id: 'demo:43',
      firstName: 'Ryan',
      lastName: 'Fox',
      title: 'UX/UI Designer',
      image: '/maps/demo/profile-picture-43.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'San Jose, CA, USA',
          geoLocation: [37.3382, -121.8863],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'San Jose, CA, USA',
      geoLocation: [37.3382, -121.8863],
    },
  },
  {
    user: {
      id: 'demo:44',
      firstName: 'Mason',
      lastName: 'Phillips',
      title: 'Programmer Analyst',
      image: '/maps/demo/profile-picture-44.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Munich, Germany',
          geoLocation: [48.1351, 11.582],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Munich, Germany',
      geoLocation: [48.1351, 11.582],
    },
  },
  {
    user: {
      id: 'demo:45',
      firstName: 'Zoe',
      lastName: 'Silva',
      title: 'Network Engineer',
      image: '/maps/demo/profile-picture-45.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Munich, Germany',
          geoLocation: [48.1351, 11.582],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Munich, Germany',
      geoLocation: [48.1351, 11.582],
    },
  },
  {
    user: {
      id: 'demo:46',
      firstName: 'Annie',
      lastName: 'Simmons',
      title: 'Data Scientist',
      image: '/maps/demo/profile-picture-46.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Munich, Germany',
          geoLocation: [48.1351, 11.582],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Munich, Germany',
      geoLocation: [48.1351, 11.582],
    },
  },
  {
    user: {
      id: 'demo:47',
      firstName: 'Harley',
      lastName: 'White',
      title: 'IT Project Manager',
      image: '/maps/demo/profile-picture-47.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'New Dehli, India',
          geoLocation: [28.6139, 77.209],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'New Dehli, India',
      geoLocation: [28.6139, 77.209],
    },
  },
  {
    user: {
      id: 'demo:48',
      firstName: 'Amelie',
      lastName: 'Cooper',
      title: 'Technical Support Engineer',
      image: '/maps/demo/profile-picture-48.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'New Dehli, India',
          geoLocation: [28.6139, 77.209],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'New Dehli, India',
      geoLocation: [28.6139, 77.209],
    },
  },
  {
    user: {
      id: 'demo:49',
      firstName: 'Evelyn',
      lastName: 'Harrison',
      title: 'Cloud Engineer',
      image: '/maps/demo/profile-picture-49.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Taipei, Taiwan',
          geoLocation: [25.033, 121.5654],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Taipei, Taiwan',
      geoLocation: [25.033, 121.5654],
    },
  },
  {
    user: {
      id: 'demo:50',
      firstName: 'Abby',
      lastName: 'Smith',
      title: 'Security Engineer',
      image: '/maps/demo/profile-picture-50.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Taipei, Taiwan',
          geoLocation: [25.033, 121.5654],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Taipei, Taiwan',
      geoLocation: [25.033, 121.5654],
    },
  },
  {
    user: {
      id: 'demo:51',
      firstName: 'Isla',
      lastName: 'Anderson',
      title: 'DevOps Engineer',
      image: '/maps/demo/profile-picture-51.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Tokyo, Japan',
          geoLocation: [35.6895, 139.6917],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Tokyo, Japan',
      geoLocation: [35.6895, 139.6917],
    },
  },
  {
    user: {
      id: 'demo:52',
      firstName: 'Alex',
      lastName: 'Holland',
      title: 'Application Developer',
      image: '/maps/demo/profile-picture-52.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Tokyo, Japan',
          geoLocation: [35.6895, 139.6917],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Tokyo, Japan',
      geoLocation: [35.6895, 139.6917],
    },
  },
  {
    user: {
      id: 'demo:53',
      firstName: 'Zachary',
      lastName: 'Collins',
      title: 'Software Architect',
      image: '/maps/demo/profile-picture-53.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Tokyo, Japan',
          geoLocation: [35.6895, 139.6917],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Tokyo, Japan',
      geoLocation: [35.6895, 139.6917],
    },
  },
  {
    user: {
      id: 'demo:54',
      firstName: 'Nic',
      lastName: 'Davidson',
      title: 'Quality Assurance Analyst',
      image: '/maps/demo/profile-picture-54.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Lagos, Nigeria',
          geoLocation: [6.5244, 3.3792],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Lagos, Nigeria',
      geoLocation: [6.5244, 3.3792],
    },
  },
  {
    user: {
      id: 'demo:55',
      firstName: 'Josh',
      lastName: 'Knight',
      title: 'Software Tester',
      image: '/maps/demo/profile-picture-55.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Dubai, United Arab Emirates',
          geoLocation: [25.276987, 55.296249],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Dubai, United Arab Emirates',
      geoLocation: [25.276987, 55.296249],
    },
  },
  {
    user: {
      id: 'demo:56',
      firstName: 'Kelly',
      lastName: 'Myer',
      title: 'Web Developer',
      image: '/maps/demo/profile-picture-56.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Tulsa, OK, USA',
          geoLocation: [36.153982, -95.992775],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Tulsa, OK, USA',
      geoLocation: [36.153982, -95.992775],
    },
  },
  {
    user: {
      id: 'demo:57',
      firstName: 'Laura',
      lastName: 'Palmer',
      title: 'Software Developer',
      image: '/maps/demo/profile-picture-57.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Miami, FL, USA',
          geoLocation: [25.76168, -80.19179],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Miami, FL, USA',
      geoLocation: [25.76168, -80.19179],
    },
  },
  {
    user: {
      id: 'demo:58',
      firstName: 'Mollie',
      lastName: 'Morgan',
      title: 'Business Analyst',
      image: '/maps/demo/profile-picture-58.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Jakarta, Indonesia',
          geoLocation: [-6.208763, 106.845599],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Jakarta, Indonesia',
      geoLocation: [-6.208763, 106.845599],
    },
  },
  {
    user: {
      id: 'demo:59',
      firstName: 'Heather',
      lastName: 'Kelly',
      title: 'IT Security Analyst',
      image: '/maps/demo/profile-picture-59.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Manila, Phillipines',
          geoLocation: [14.599512, 120.984219],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Manila, Phillipines',
      geoLocation: [14.599512, 120.984219],
    },
  },
  {
    user: {
      id: 'demo:60',
      firstName: 'James',
      lastName: 'Dunn',
      title: 'IT Manager',
      image: '/maps/demo/profile-picture-60.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Melbourne, Australia',
          geoLocation: [-37.813628, 144.963058],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Melbourne, Australia',
      geoLocation: [-37.813628, 144.963058],
    },
  },
  {
    user: {
      id: 'demo:61',
      firstName: 'Elijah',
      lastName: 'Johnson',
      title: 'Business Intelligence Analyst',
      image: '/maps/demo/profile-picture-61.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Riga, Latvia',
          geoLocation: [56.949649, 24.105186],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Riga, Latvia',
      geoLocation: [56.949649, 24.105186],
    },
  },
  {
    user: {
      id: 'demo:62',
      firstName: 'Sara',
      lastName: 'Woodman',
      title: 'Big Data Analyst',
      image: '/maps/demo/profile-picture-62.jpg',
      locations: [
        {
          type: 'HOME_CURRENT',
          name: 'Porto Alegre, Brazil',
          geoLocation: [-30.034647, -51.217659],
        },
      ],
    },
    location: {
      type: 'HOME_CURRENT',
      name: 'Porto Alegre, Brazil',
      geoLocation: [-30.034647, -51.217659],
    },
  },
];
