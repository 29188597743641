import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.profileHighlights || initialState;

export const selectLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectHighlights = createSelector(
  [selectSlice],
  state => state.highlights,
);

export const selectLastEvaluatedKey = createSelector(
  [selectSlice],
  state => state.lastEvaluatedKey,
);

export const selectHighlightStateChanging = createSelector(
  [selectSlice],
  state => state.highlightStateChanging,
);

export const selectProfile = createSelector(
  [selectSlice],
  state => state.profile,
);
