import { Apollo } from 'app/library/Apollo';
import { RichMedia } from 'types/RichMedia';

export function getUploadUrl(
  type,
  company: Company,
  entity?,
): Promise<RichMedia> {
  const data = {
    query: `
      mutation create($mimeType: MimeType!, $companyId: CompanyId!) {
        createRichMedia(
          input: { mimeType: $mimeType, companyId: $companyId }
        ) {
          id
          url
          uploadUrl
        } 
      }
    `,
    variables: { mimeType: type, companyId: company.id },
  };

  return Apollo.post(data)
    .then(response =>
      Object.assign(response.data.data.createRichMedia, { entity: entity }),
    )
    .catch(err => Apollo.handleError(err, data));
}

export function createRichMedia(
  company: Company,
  source?: string,
  mimeType?: string,
): Promise<RichMedia> {
  const type = mimeType || decideMimeType(source);
  const data = {
    query: `
      mutation create($mimeType: MimeType!, $companyId: CompanyId, $source: URL) {
        createRichMedia(
          input: { mimeType: $mimeType, companyId: $companyId, source: $source }
        ) {
          id
          url
          uploadUrl
        }
      }
    `,
    variables: {
      mimeType: type,
      companyId: company.id,
      source: source ?? null,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.createRichMedia)
    .catch(err => Apollo.handleError(err, data));
}

export function getRichMedia(
  richMediaId: string,
): Promise<RichMedia | undefined> {
  const data = {
    query: `
      query($id: RichMediaId!) {
        richMedia(id: $id) {
          id
          url
          mimeType
        } 
      }
    `,
    variables: {
      id: richMediaId,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.richMedia)
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve(undefined);
    });
}

// TODO fix hacky way of getting mime type
function decideMimeType(source?: string): string {
  return source?.includes('giphy') ? 'image/gif' : 'image/jpeg';
}
