import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.searchBar || initialState;

export const selectSearchText = createSelector(
  [selectSlice],
  state => state.searchText,
);

export const selectSearchResults = createSelector(
  [selectSlice],
  state => state.searchResults,
);

export const selectSelection = createSelector(
  [selectSlice],
  state => state.selection,
);
