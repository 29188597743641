import { AuthType } from 'app/library/Authenticator';
import { SlackbotType } from 'app/pages/InstallationChannelSelector/slice/type';

export type SlackAppType =
  | 'celebrations'
  | 'icebreakers'
  | 'coffee-talk'
  | 'intros'
  | 'maps'
  | 'shoutouts';

export const appNames: SlackAppType[] = [
  'celebrations',
  'icebreakers',
  'coffee-talk',
  'intros',
  'maps',
  'shoutouts',
];

export interface OnboardingMetadata {
  type: SlackbotType;
  appId: string;
  queryParam: SlackAppType;
  authType: AuthType;
  workspaceParam: string;
  productPage: string;
  installationPage: string;
  onChannelAdd: (channelId: string, channelLabel: string) => string;
  onChannelCreated: (channelId: string, channelLabel: string) => string;
  skipChannelToUrl?: string;
  emoji: string;
  name: string;
  description: string;
}

export const CelebrationsMetadata: OnboardingMetadata = {
  type: SlackbotType.BIRTHDAY,
  appId: process.env.REACT_APP_CELEBRATIONS_APP_ID!,
  queryParam: 'celebrations',
  authType: AuthType.SLACK_CELEBRATIONS,
  workspaceParam: '__BIRTHDAY__',
  onChannelAdd: () => '/web-onboarding/celebrations-dates',
  onChannelCreated: () => `/web-onboarding/celebrations-dates`,
  productPage: 'https://www.getairspeed.com/product/celebrations/',
  installationPage: '/installation/instructions?app=celebrations',
  skipChannelToUrl: '/web-onboarding/celebrations-dates',
  emoji: '🎉',
  name: 'Celebrations',
  description:
    'Never forget a birthday, work anniversary or special occasion again.',
};

export const IcebreakersMetadata: OnboardingMetadata = {
  type: SlackbotType.ICE_BREAKERS,
  appId: process.env.REACT_APP_ICEBREAKERS_APP_ID!,
  queryParam: 'icebreakers',
  authType: AuthType.SLACK_ICEBREAKERS,
  workspaceParam: '__ICE_BREAKERS__',
  onChannelAdd: (channelId: string) =>
    `/web-onboarding/icebreakers-slack?channel=${channelId}`,
  onChannelCreated: (channelId: string) =>
    `/web-onboarding/icebreakers-slack?channel=${channelId}`,
  productPage: 'https://www.getairspeed.com/product/icebreakers/',
  installationPage: '/installation/instructions?app=icebreakers',
  skipChannelToUrl: '/web-onboarding/icebreakers-slack',
  emoji: '🧊',
  name: 'Icebreakers',
  description: 'Automate team-building with icebreaker questions.',
};

export const CoffeeTalkMetadata: OnboardingMetadata = {
  type: SlackbotType.COFFEE_TALK,
  appId: process.env.REACT_APP_COFFEE_TALK_APP_ID!,
  queryParam: 'coffee-talk',
  authType: AuthType.SLACK_COFFEE_TALK,
  onChannelAdd: (channelId: string) => `/web-onboarding/coffee-talk-done`,
  onChannelCreated: (channelId: string) => `/web-onboarding/coffee-talk-done`,
  workspaceParam: '__COFFEE_TALK__',
  productPage: 'https://www.getairspeed.com/product/coffeetalk/',
  installationPage: '/installation/instructions?app=coffee-talk',
  emoji: '☕️',
  name: 'Coffee Talk',
  description:
    'Connect intentionally with your team for virtual chats using AI.',
};

export const IntrosMetadata: OnboardingMetadata = {
  type: SlackbotType.INTROS,
  appId: process.env.REACT_APP_INTROS_APP_ID!,
  queryParam: 'intros',
  authType: AuthType.SLACK_INTROS,
  onChannelAdd: (channelId: string) =>
    `/web-onboarding/create-intros?channel=${channelId}`,
  onChannelCreated: (channelId: string) =>
    `/web-onboarding/create-intros?channel=${channelId}`,
  workspaceParam: '__INTROS__',
  productPage: 'https://www.getairspeed.com/product/intros/',
  installationPage: '/installation/instructions?app=intros',
  emoji: '👋  ',
  name: 'Intros',
  description: 'Automatically introduce new members of your team or group.',
};

export const MapsMetadata: OnboardingMetadata = {
  type: SlackbotType.MAPS,
  appId: process.env.REACT_APP_MAPS_APP_ID!,
  queryParam: 'maps',
  authType: AuthType.SLACK_MAPS,
  workspaceParam: '__MAPS__',
  onChannelAdd: () => '/web-onboarding/maps-location',
  onChannelCreated: () => `/web-onboarding/maps-location`,
  productPage: 'https://www.getairspeed.com/product/maps/',
  installationPage: '/installation/instructions?app=maps',
  skipChannelToUrl: '/web-onboarding/maps-location',
  emoji: '📍',
  name: 'Maps',
  description:
    'Easily see where your teammates are located on a map and connect based on location.',
};
export const ShoutoutMetadata: OnboardingMetadata = {
  type: SlackbotType.SHOUTOUT,
  appId: process.env.REACT_APP_SHOUTOUTS_APP_ID!,
  queryParam: 'shoutouts',
  authType: AuthType.SLACK_SHOUTOUTS,
  workspaceParam: '__SHOUTOUT__',
  onChannelAdd: () => '/web-onboarding/shoutout-values',
  onChannelCreated: () => `/web-onboarding/shoutout-values`,
  productPage: 'https://www.getairspeed.com/product/shoutouts/',
  installationPage: '/installation/instructions?app=shoutouts',
  skipChannelToUrl: `/web-onboarding/shoutout-values`,
  emoji: '🏆',
  name: 'Shoutouts',
  description: 'Easily send kudos and recognize teammates with shoutouts.',
};

const ONBOARDING_METADATA: Record<SlackbotType, OnboardingMetadata | null> = {
  BIRTHDAY: CelebrationsMetadata,
  ICE_BREAKERS: IcebreakersMetadata,
  COFFEE_TALK: CoffeeTalkMetadata,
  INTROS: IntrosMetadata,
  SHOUTOUT: ShoutoutMetadata,
  MAPS: MapsMetadata,
};

export function getOnboardingMetadata(appType: SlackbotType) {
  const metadata = ONBOARDING_METADATA[appType];
  if (!metadata) {
    throw new Error(`Invalid app type: ${appType}`);
  }

  return metadata;
}
export function getMetadataFromQuery(queryParam: string): OnboardingMetadata {
  const metadata = Object.values(ONBOARDING_METADATA).find(
    metadata => metadata?.queryParam === queryParam,
  );
  if (!metadata) {
    throw new Error(`Invalid query param: ${queryParam}`);
  }
  return metadata;
}

export function extractTeamIdFromWorkspaceName(name: string) {
  const parts = name.split('__');
  return parts[2];
}
