/**
 *
 * Asynchronously loads the component for ProfileCompletionQuestionPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ProfileCompletionQuestionPage = lazyLoad(
  () => import('./index'),
  module => module.ProfileCompletionQuestionPage,
);
