import { Button } from 'app/components/Button';
import { CelebrationType } from 'app/library/AppConstants';
import { updateAppCustomizations } from 'app/library/SlackAPI';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { CustomizationMenuItem } from '../Components/CustomizationMenuItem';
import { Wrapper } from '../Wrapper';
import { useAppCustomizationsPanelSlice } from '../slice';
import { AppCustomizationsState } from '../slice/types';
import { BirthdayCustomization } from './BirthdayCustomization';
import { CustomCelebrationsCustomization } from './CustomCelebrationsCustomization';
import { WorkAnniversaryCustomization } from './WorkAnniversaryCustomization';
import { useSelector } from 'react-redux';
import {
  selectActiveSlackbotId,
  selectCustomizationStateCopy,
  selectIfDataChanged,
} from '../slice/selectors';
import { selectLoading, selectSlackWorkspace } from 'app/slice/selectors';
import { CancelConfirmationModal } from '../Components/CancelConfirmationModal';
import { AnalyticsClientContext } from 'app/library/Analytics';

const Container = styled.div`
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
  align-self: stretch;
  width: 100%;
  display: flex;
`;

const FooterContainer = styled.div`
  position: sticky;
  bottom: 0px;
  z-index: 110;
  width: 100%;
  display: flex;
  background-color: white;
  padding: 16px;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
`;

const LoaderContainer = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  top: 0;
`;
interface Props {
  onClose: () => void;
}

enum PageState {
  INITIAL = 'INITIAL',
  BIRTHDAY = 'BIRTHDAY',
  WORK_ANNIVERSARY = 'WORK_ANNIVERSARY',
  CUSTOM_CELEBRATION = 'CUSTOM_CELEBRATION',
}

const initialTitle = '🎉 Celebrations App Customizations';

export const CelebrationsCustomizations = ({ onClose }: Props) => {
  const stateCopy = useSelector(selectCustomizationStateCopy);
  const loading = useSelector(selectLoading);
  const currentSlackbotId = useSelector(selectActiveSlackbotId);
  const isDataChanged = useSelector(selectIfDataChanged);
  const { actions } = useAppCustomizationsPanelSlice();
  const dispatch = useDispatch();
  const [updatedAppCustomisationData, setUpdatedAppCustomisationData] =
    useState<AppCustomizationsState>();
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>();
  const [panelTitle, setPanelTitle] = useState(initialTitle);
  const [pageState, setPageState] = useState<PageState>(PageState.INITIAL);
  const onMenuItemClick = (celebrationType: CelebrationType) => {
    const newPageState: PageState = celebrationType as unknown as PageState;
    setPageState(newPageState);
    setPanelTitle(
      newPageState === PageState.BIRTHDAY
        ? 'Birthday Card'
        : newPageState === PageState.WORK_ANNIVERSARY
        ? 'Work Anniversary'
        : 'Custom Celebrations',
    );
  };
  const slackWorkspace = useSelector(selectSlackWorkspace);
  const analyticsClient = useContext(AnalyticsClientContext);

  useEffect(() => {
    analyticsClient.logEvent({
      eventType: 'Admin console celebrations customize app opened',
      properties: {
        Workspace: slackWorkspace?.id,
      },
    });
  }, []);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);

  const onGoBack = () => {
    setIsConfirmationModalOpen(true);
  };

  const onCancelGoBack = () => {
    setIsConfirmationModalOpen(false);
  };

  const onConfirmGoBack = () => {
    setPageState(PageState.INITIAL);
    setPanelTitle(initialTitle);
    dispatch(actions.resetStateCopy());
    setIsConfirmationModalOpen(false);
  };

  const menuItems = () => {
    return (
      <>
        <CustomizationMenuItem
          title="Work Anniversary"
          description="Add a work anniversary title and custom banner image to fit your culture needs, such as an ACMEversary!"
          buttonText="Customize"
          onButtonClick={() =>
            onMenuItemClick(CelebrationType.WORK_ANNIVERSARY)
          }
          withPaidBadge={
            !slackWorkspace?.entitlements.celebrations.customBanner
          }
        />

        <CustomizationMenuItem
          title="Birthday Card"
          description="Make birthdays extra special for your teammates with a banner image unique to your organization."
          buttonText="Customize"
          onButtonClick={() => onMenuItemClick(CelebrationType.BIRTHDAY)}
          withPaidBadge={
            !slackWorkspace?.entitlements.celebrations.customBanner
          }
        />

        <CustomizationMenuItem
          title="Custom Celebrations"
          description="Add a unique banner image for custom celebrations, like celebrating a newborn, wedding, or promotion."
          buttonText="Customize"
          onButtonClick={() =>
            onMenuItemClick(CelebrationType.CUSTOM_CELEBRATION)
          }
          withPaidBadge={
            !slackWorkspace?.entitlements.celebrations.customBanner
          }
        />
      </>
    );
  };

  const onSaveBirthday = async () => {
    try {
      const slackbotId = currentSlackbotId;
      if (!slackbotId) {
        return;
      }
      const ifUpdatedSuccessfully: boolean = await updateAppCustomizations(
        slackbotId,
        { birthdayBanner: stateCopy?.birthdayBanner },
      );
      if (ifUpdatedSuccessfully) {
        dispatch(actions.setBirthdayBanner(stateCopy?.birthdayBanner || []));
        dispatch(actions.setIsDataChanged(false));
        setPageState(PageState.INITIAL);
        const ifCustomBannerEnabled = !!stateCopy?.birthdayBanner?.find(
          item => item.isActive,
        );
        analyticsClient.logEvent({
          eventType: `Admin console celebrations app customization ${
            ifCustomBannerEnabled ? 'enabled' : 'disabled'
          }`,
          properties: {
            Workspace: slackWorkspace?.id,
            type: 'Custom birthday card image',
            imageCount: ifCustomBannerEnabled
              ? stateCopy?.birthdayBanner?.length
              : undefined,
          },
        });
      }
    } catch (err) {}
  };

  const onSaveWorkAnniversary = async () => {
    try {
      const slackbotId = currentSlackbotId;
      if (!slackbotId) {
        return;
      }
      const ifUpdatedSuccessfully: boolean = await updateAppCustomizations(
        slackbotId,
        {
          workAnniversaryBanner: stateCopy?.workAnniversaryBanner,
          workAnniversaryTitle: stateCopy.workAnniversaryTitle,
        },
      );
      if (ifUpdatedSuccessfully) {
        dispatch(
          actions.setWorkAnniversaryBanner(
            stateCopy?.workAnniversaryBanner || [],
          ),
        );
        dispatch(
          actions.setWorkAnniversaryTitle(
            stateCopy?.workAnniversaryTitle || '',
          ),
        );
        dispatch(actions.setIsDataChanged(false));
        setPageState(PageState.INITIAL);
        const ifCustomBannerEnabled = !!stateCopy?.workAnniversaryBanner?.find(
          item => item.isActive,
        );
        analyticsClient.logEvent({
          eventType: `Admin console celebrations app customization ${
            ifCustomBannerEnabled ? 'enabled' : 'disabled'
          }`,
          properties: {
            Workspace: slackWorkspace?.id,
            type: 'Custom work anniversary card image',
            imageCount: ifCustomBannerEnabled
              ? stateCopy?.workAnniversaryBanner?.length
              : undefined,
          },
        });
      }
    } catch (err) {}
  };

  const onSaveCustomCelebration = async () => {
    try {
      const slackbotId = currentSlackbotId;
      if (!slackbotId) {
        return;
      }
      const ifUpdatedSuccessfully: boolean = await updateAppCustomizations(
        slackbotId,
        { customCelebrationBanner: stateCopy?.customCelebrationBanner },
      );
      if (ifUpdatedSuccessfully) {
        dispatch(
          actions.setCustomCelebrationBanner(
            stateCopy?.customCelebrationBanner || [],
          ),
        );
        dispatch(actions.setIsDataChanged(false));
        setPageState(PageState.INITIAL);
        const ifCustomBannerEnabled =
          !!stateCopy?.customCelebrationBanner?.find(item => item.isActive);
        analyticsClient.logEvent({
          eventType: `Admin console celebrations app customization ${
            ifCustomBannerEnabled ? 'enabled' : 'disabled'
          }`,
          properties: {
            Workspace: slackWorkspace?.id,
            type: 'Custom custom celebration card image',
            imageCount: ifCustomBannerEnabled
              ? stateCopy?.customCelebrationBanner?.length
              : undefined,
          },
        });
      }
    } catch (err) {}
  };
  return (
    <Wrapper
      onGoBack={pageState === PageState.INITIAL ? undefined : onGoBack}
      title={panelTitle}
      onClose={pageState === PageState.INITIAL ? onClose : undefined}
      footer={
        pageState === PageState.INITIAL ? undefined : (
          <FooterContainer>
            <Button
              color="white"
              borderColor="transparent"
              buttonStyles={{ width: 'auto', padding: '12px 16px' }}
              onClick={onGoBack}
            >
              Cancel
            </Button>
            <Button
              disabled={!isDataChanged}
              buttonStyles={{ width: 'auto', padding: '12px 16px' }}
              onClick={
                pageState === PageState.BIRTHDAY
                  ? onSaveBirthday
                  : pageState === PageState.WORK_ANNIVERSARY
                  ? onSaveWorkAnniversary
                  : onSaveCustomCelebration
              }
            >
              Save
            </Button>
          </FooterContainer>
        )
      }
    >
      <Container>
        {pageState === PageState.INITIAL && menuItems()}
        {pageState === PageState.BIRTHDAY && <BirthdayCustomization />}
        {pageState === PageState.CUSTOM_CELEBRATION && (
          <CustomCelebrationsCustomization />
        )}
        {pageState === PageState.WORK_ANNIVERSARY && (
          <WorkAnniversaryCustomization />
        )}
      </Container>
      {loading && (
        <LoaderContainer>
          <div className="smalldot-collision"></div>
        </LoaderContainer>
      )}
      <CancelConfirmationModal
        isOpen={isConfirmationModalOpen}
        onYesClick={onConfirmGoBack}
        onClose={onCancelGoBack}
      />
    </Wrapper>
  );
};
