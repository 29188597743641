import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './index';

const selectSlice = (state: RootState) => state.leaderboard || initialState;

export const selectLeaderboardLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectLeaderboardChannelsLoading = createSelector(
  [selectSlice],
  state => state.channelsLoading,
);

export const selectLeaderboardHasData = createSelector(
  [selectSlice],
  state => state.hasData,
);

export const selectLeaderboardCurrentApp = createSelector(
  [selectSlice],
  state => state.currentApp,
);

export const selectLeaderboardCurrentType = createSelector(
  [selectSlice],
  state => state.currentLeaderboardType,
);

export const selectLeaderboardItems = createSelector(
  [selectSlice],
  state => state.items,
);

export const selectLeaderboardPeriod = createSelector(
  [selectSlice],
  state => state.period,
);

export const selectLeaderboardApps = createSelector(
  [selectSlice],
  state => state.apps,
);

export const selectLeaderboardChannels = createSelector(
  [selectSlice],
  state => state.channels,
);

export const selectLeaderboardFilterChannelIds = createSelector(
  [selectSlice],
  state => state.filterChannelIds,
);

export const selectErrorMsg = createSelector(
  [selectSlice],
  state => state.errorMsg,
);

export const selectShoutouts = createSelector(
  [selectSlice],
  state => state.shoutouts,
);
