import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { EMAIL_REGEX, ZIP_CODE_REGEX } from 'utils/regex';
import { initialState } from '.';
import {
  CreditCardFormData,
  PaymentMethod,
  SelectedDurationType,
} from './type';
import {
  PreviewSubscriptionInput,
  SubscriptionBillingCycle,
  SubscriptionPaymentType,
} from 'app/library/BillingAPI';

const selectSlice = (state: RootState) => state.billingPage || initialState;

export const selectState = createSelector([selectSlice], state => state);

export const selectSelectedDurationType = createSelector(
  [selectSlice],
  state => state.selectedDurationType,
);

export const selectSelectedPlan = createSelector(
  [selectSlice],
  state => state.selectedPlan,
);

export const selectSelectedSubscriptionType = createSelector(
  [selectSlice],
  state => state.subscriptionType,
);

export const selectPageState = createSelector([selectSlice], state => {
  return state.pageState;
});

export const selectSelectedPaymentMethod = createSelector(
  [selectSlice],
  state => {
    return state.selectedPaymentMethod;
  },
);

export const selectCreditCardFormData = createSelector([selectSlice], state => {
  return state.creditCardFormData;
});

export const selectSubscriptionPreview = createSelector(
  [selectSlice],
  state => {
    return state.subscriptionPreview;
  },
);

export const selectChargifySecurityToken = createSelector(
  [selectSlice],
  state => {
    return state.chargifySecurityToken;
  },
);

export const selectIsLoading = createSelector([selectSlice], state => {
  return state.isLoading;
});

export const selectChargifyPaymentToken = createSelector(
  [selectSlice],
  state => {
    return state.chargifyPaymentToken;
  },
);

export const selectSelectedChannelsForSubscription = createSelector(
  [selectSlice],
  state => {
    return state.selectedChannelsForSubscription;
  },
);

export const selectIfFormIsValid = createSelector([selectSlice], state => {
  const { selectedPaymentMethod } = state;
  if (selectedPaymentMethod === PaymentMethod.CREDIT_CARD) {
    const { addressLine1 } = state.creditCardFormData;
    if (!addressLine1) {
      return false;
    }
    return true;
  }
  return false;
});

export const selectIfGotAllInfoForPreview = createSelector(
  [selectSlice],
  state => {
    const { creditCardFormData } = state;
    return (
      creditCardFormData.addressLine1 &&
      creditCardFormData.city &&
      creditCardFormData.state &&
      creditCardFormData.countryOrRegion &&
      // ZIP_CODE_REGEX.test(creditCardFormData.zip) &&
      creditCardFormData.zip &&
      creditCardFormData.firstName &&
      creditCardFormData.lastName &&
      EMAIL_REGEX.test(creditCardFormData.email)
    );
  },
);

export const selectIfGotPersonalInfo = createSelector([selectSlice], state => {
  const { creditCardFormData } = state;
  return checkIfGotPersonalInfo(creditCardFormData);
});

export const selectIfGotAddressInfo = createSelector([selectSlice], state => {
  const { creditCardFormData } = state;
  return checkIfGotAddressInfo(creditCardFormData);
});

const checkIfGotAddressInfo = (creditCardFormData: CreditCardFormData) => {
  return (
    creditCardFormData.addressLine1 &&
    creditCardFormData.city &&
    creditCardFormData.state &&
    creditCardFormData.countryOrRegion &&
    creditCardFormData.zip
  );
};

const checkIfGotPersonalInfo = (creditCardFormData: CreditCardFormData) => {
  return (
    creditCardFormData.countryOrRegion &&
    creditCardFormData.firstName &&
    creditCardFormData.lastName &&
    EMAIL_REGEX.test(creditCardFormData.email)
  );
};

export const selectInvoices = createSelector([selectSlice], state => {
  return state.subscriptionInvoices;
});

export const selectSubscriptionPreviewInput = createSelector(
  [selectSlice],
  state => {
    const { selectedPaymentMethod, creditCardFormData } = state;
    const paymentType =
      selectedPaymentMethod === PaymentMethod.CREDIT_CARD
        ? SubscriptionPaymentType.CREDIT_CARD
        : SubscriptionPaymentType.BANK_ACCOUNT;
    const previewSubscriptionInput: PreviewSubscriptionInput = {
      slackWorkspaceId: '',
      paymentType,
      tier: state.subscriptionPreview?.tier!,
      type: state.subscriptionType,
      billingCycle:
        state.selectedDurationType === SelectedDurationType.ANNUALLY
          ? SubscriptionBillingCycle.ANNUAL
          : SubscriptionBillingCycle.MONTHLY,
      couponCodes: [state.creditCardFormData.couponCode],
      channels: state.selectedChannelsForSubscription.map(item => item.id),
    };
    if (checkIfGotAddressInfo(creditCardFormData)) {
      previewSubscriptionInput.address = {
        address1: creditCardFormData.addressLine1,
        address2: creditCardFormData.addressLine2,
        city: creditCardFormData.city,
        country: creditCardFormData.countryOrRegion,
        state: creditCardFormData.state,
        zip: creditCardFormData.zip,
      };
    }
    if (checkIfGotPersonalInfo(creditCardFormData)) {
      previewSubscriptionInput.contact = {
        email: creditCardFormData.email,
        firstName: creditCardFormData.firstName,
        lastName: creditCardFormData.lastName,
      };
    }
    return previewSubscriptionInput;
  },
);
