import { Apollo } from './Apollo';
import {
  AwardsPeriod,
  AwardType,
} from '../pages/ShoutoutAwards/ShoutoutAwardsGenerate/slice/types';
import { DateTime } from 'luxon';
import { Shoutout } from 'types/Shoutouts';

export function getShoutoutAwards(summaryId: string) {
  const data = {
    query: `
      query($summaryId: ShoutoutAwardSummaryId!) {
        shoutoutAwardSummary(id: $summaryId) {
          id
          generatorType
          periodStart
          periodEnd
          slackbot {
            id
          }
          awards {
            id
            title
            text
            slackbotUser {
              id
              user {
                id
                firstName
                lastName
                title
                image {
                  url
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      summaryId: summaryId,
    },
  };

  return Apollo.post(data)
    .then(response => {
      console.log(response);
      return response.data.data.shoutoutAwardSummary;
    })
    .catch(err => Apollo.handleError(err, data));
}

export function generateShoutoutAwards(
  slackbotId: string,
  period: AwardsPeriod,
  awardType: AwardType,
  channels: string[] = [],
) {
  const data = {
    query: `
      mutation generate($slackbotId: SlackbotId!, $period: ShoutoutAwardPeriod!, $awardType: ShoutoutAwardType!, $channels: [SlackbotChannelId!]) {
        generateAwards(input: {slackbotId: $slackbotId, period: $period, awardType: $awardType, channels: $channels}) {
          id
          generatorType
          periodStart
          periodEnd
        }
      }
    `,
    variables: {
      slackbotId: slackbotId,
      period: period,
      awardType: awardType,
      channels: channels,
    },
  };
  return Apollo.post(data)
    .then(response => {
      console.log(response);
      return response.data.data.shoutoutAwardSummary;
    })
    .catch(err => Apollo.handleError(err, data));
}

export function getShoutoutsReceived(shoutoutAwardId: string) {
  const data = {
    query: `
      query getShoutoutsReceived($shoutoutAwardId: ShoutoutAwardId!) {
        getShoutoutsReceived(id: $shoutoutAwardId) {
          initiator {
            firstName
            lastName
          }
          recognizedFor
          createdAt
        }
      }
    `,
    variables: {
      shoutoutAwardId: shoutoutAwardId,
    },
  };
  return Apollo.post(data)
    .then(response => {
      return response.data.data.getShoutoutsReceived;
    })
    .catch(err => Apollo.handleError(err, data));
}

export function editShoutoutAward(
  shoutoutAwardId: string,
  title?: string,
  text?: string,
) {
  const mutation = `editAward(${title ? 'title: $title' : ''} ${
    text ? 'text: $text' : ''
  })`;
  const data = {
    query: `
      mutation shoutoutAward($shoutoutAwardId: ShoutoutAwardId!, $text: String, $title: String) {
        shoutoutAward(id: $shoutoutAwardId) {
          ${mutation} {
            id
            text
            title
          }
        }
      }
    `,
    variables: {
      shoutoutAwardId: shoutoutAwardId,
      title: title,
      text: text,
    },
  };
  return Apollo.post(data)
    .then(response => {
      return response.data.data.shoutoutAward;
    })
    .catch(err => Apollo.handleError(err, data));
}

export function sentShoutoutAward(input: {
  awardId?: string;
  awardSummaryId?: string;
  date?: Date;
  time?: string;
  channels?: string[];
}) {
  let dt: DateTime;
  if (input.date && input.time) {
    const ts = input.time.split(':');
    dt = DateTime.fromJSDate(input.date).set({
      hour: parseInt(ts[0]),
      minute: parseInt(ts[1]),
    });
  } else if (input.date) {
    dt = DateTime.fromJSDate(input.date);
  } else if (input.time) {
    const ts = input.time.split(':');
    dt = DateTime.now().set({ hour: parseInt(ts[0]), minute: parseInt(ts[1]) });
  } else {
    dt = DateTime.now();
  }
  if (input.awardId) {
    const mutation = `sendToChannels(input: {channels: $channels, deliveryDateTime: $dt})`;
    const data = {
      query: `
        mutation shoutoutAward($shoutoutAwardId: ShoutoutAwardId!, $dt: DateTime, $channels: [SlackbotChannelId!]!) {
          shoutoutAward(id: $shoutoutAwardId) {
            ${mutation} {
              id
              status
              sendAfter
            }
          }
        }
      `,
      variables: {
        shoutoutAwardId: input.awardId,
        dt: dt.toString(),
        channels: input.channels,
      },
    };
    return Apollo.post(data)
      .then(response => {
        return response.data.data.shoutoutAward;
      })
      .catch(err => Apollo.handleError(err, data));
  }
  if (input.awardSummaryId) {
    // TODO: To be implemented for sending out the whole summary vs a single award
  }
}

export function deleteShoutoutAward(shoutoutAwardId: string) {
  const data = {
    query: `
      mutation deleteShoutoutAward($shoutoutAwardId: ShoutoutAwardId!) {
        deleteShoutoutAward(id: $shoutoutAwardId) 
      }
    `,
    variables: {
      shoutoutAwardId: shoutoutAwardId,
    },
  };
  return Apollo.post(data)
    .then(response => {
      return response.data.data.deleteShoutoutAward;
    })
    .catch(err => Apollo.handleError(err, data));
}

export function regenerateShoutoutAwards(input: {
  shoutoutAwardIds?: string[];
  awardType?: AwardType;
  period?: AwardsPeriod;
  channels?: string[];
  summaryId?: string;
  excludeUsers?: string[];
}) {
  const data = {
    query: `
      mutation regenerateAwards($shoutoutAwardIds: [ShoutoutAwardId!], $awardType: ShoutoutAwardType, $period: ShoutoutAwardPeriod, $channels: [SlackbotChannelId!], $summaryId: ShoutoutAwardSummaryId, excludeUserIds: [SlackbotUserId!]) {
        regenerateAwards(input: {awardIds: $shoutoutAwardIds, awardType: $awardType, period: $period, channels: $channels, summaryId: $summaryId, excludeUserIds: $excludeUsers}) 
      }
    `,
    variables: {
      shoutoutAwardIds: input.shoutoutAwardIds,
      awardType: input.awardType,
      period: input.period,
      channels: input.channels,
      summaryId: input.summaryId,
      excludeUsers: input.excludeUsers,
    },
  };
  return Apollo.post(data)
    .then(response => {
      return response.data.data.regenerateAwards;
    })
    .catch(err => Apollo.handleError(err, data));
}

export async function getShoutouts(input: {
  slackbotId: string;
  senderId?: string;
  recipientId?: string;
}): Promise<Shoutout[]> {
  const data = {
    query: `
        query getShoutouts($input: GetShoutoutsInput!) {
          getShoutouts(input: $input) {
            nextToken
            totalCount
            first
            offset
            items {
              recognizedFor
              impact
              values
              createdAt
              sender {
                id
                firstName
                lastName
                title
                image {
                  url
                }
              }
              recipients {
                id
                firstName
                lastName
                title
                image {
                  url
                }
              }
            }
          } 
        }
      `,
    variables: { input },
  };

  try {
    const response = await Apollo.post(data);
    return response.data?.data?.getShoutouts?.items;
  } catch (err) {
    Apollo.handleError(err, data);
    return await Promise.resolve([]);
  }
}
