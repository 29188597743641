import { Button } from 'app/components/Button';
import CheckIcon from 'app/components/Icons/CheckIcon';
import { BodySmallBold, BodySmallNormal } from 'app/components/Text';
import { SubscriptionType } from 'app/library/BillingAPI';
import { GREY_300, LIGHT_BLUE } from 'app/library/Colors';
import {
  Channel,
  addBotToChannel,
  getSlackbotChannelsGroup,
} from 'app/library/SlackAPI';
import { SlackbotType } from 'app/pages/InstallationChannelSelector/slice/type';
import { useAppSlice } from 'app/slice';
import {
  selectActiveSubscription,
  selectAllChannels,
  selectSlackWorkspace,
} from 'app/slice/selectors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import { Slackbot } from 'types/Slack';
import { SlackChannel } from 'types/SlackChannel';
import { channelSelectorBaseStyles } from 'utils/channelSelectorBaseStyle';
import { Modal, ModalHeader } from '../../../../components/Modal';
import { DROPDOWN_ITEM } from '../ChannelsPanel/MultiSelectDropdown';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const Img = styled.img`
  height: auto;
  width: 100%;
  @media (max-width: 768px) {
    height: 15px;
    width: 15px;
  }
`;

const BodyContainer = styled.div`
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const SearchInputBoxContainer = styled.div`
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  display: flex;
  flex: 1;
`;
const SearchInputBox = styled.input`
  border: none;
`;

const InstalledApps = styled.div`
  .app {
    padding: 10px 0 0 0;
    display: flex;
    background-color: white;
    gap: 0.5em;
    cursor: pointer;

    .checkIcon {
      position: absolute;
      top: -2px;
      left: 0px;
    }

    &:hover {
      background-color: rgba(0, 96, 191, 0.05);
    }
  }
`;

const Checkbox = styled.div<{ isChecked: boolean }>`
  height: 16px;
  width: 16px;
  border: ${props =>
    props.isChecked ? `1px solid ${LIGHT_BLUE}` : `1px solid ${GREY_300}`};
  border-radius: 4px;
  position: relative;
  background-color: ${props => (props.isChecked ? LIGHT_BLUE : 'none')};
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 2em;
  gap: 1em;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

export const AddAppToChannel = ({ onClose, isOpen }: Props) => {
  const [installedApps, setInstalledApps] = useState<DROPDOWN_ITEM[]>([]);
  const [selectedChannels, setSelectedChannels] = useState<Array<Channel>>();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const slackWorkspace = useSelector(selectSlackWorkspace);
  const channels = useSelector(selectAllChannels);
  const activeSubscription = useSelector(selectActiveSubscription);
  const subscribedChannels =
    activeSubscription?.channels.map(item => item.id) || [];
  const dispatch = useDispatch();
  const { actions: appActions } = useAppSlice();
  const { slb: initialSlackbotId } = Object.fromEntries(
    new URLSearchParams(location.search),
  );

  const onAppClick = (app: DROPDOWN_ITEM) => {
    const updated_apps = installedApps.map(_app => {
      if (_app.value === app.value) {
        return { ..._app, checked: !_app.checked };
      }
      return _app;
    });
    setInstalledApps(updated_apps);
  };

  const onAddBotToChannel = async () => {
    try {
      setShowLoader(true);
      const botsChannelNeedToAdd = installedApps
        .filter(item => item.checked)
        .map(item => item.value);
      let apiCalls: Array<any> = [];
      botsChannelNeedToAdd.forEach(botId => {
        selectedChannels!.forEach(channel => {
          apiCalls.push(addBotToChannel(channel.id, botId));
        });
      });
      const results = await Promise.all(apiCalls);
      const slackbotChannelsGroupResponse = await getSlackbotChannelsGroup(
        slackWorkspace?.id!,
        10,
        0,
      );
      const slackbotChannelsGroup =
        slackbotChannelsGroupResponse?.slackbotChannelGroups;
      if (slackbotChannelsGroup) {
        dispatch(
          appActions.updateSlackbotChannelsGroupInSlackWorkspace(
            slackbotChannelsGroup,
          ),
        );
        dispatch(appActions.resetChannelsGroupFilters());
      }
      resetState();
      setShowLoader(false);
      onClose();
    } catch (err) {
      setShowLoader(false);
    }
  };

  const getChannelOptions = () => {
    let _channels =
      activeSubscription?.type === SubscriptionType.CHANNEL
        ? channels.filter(item => subscribedChannels?.includes(item.id))
        : channels;
    if (
      (slackWorkspace?.slackbotChannelGroups.totalCount ?? 0) >=
      (slackWorkspace?.entitlements.channelAllocationLimit ?? 1)
    ) {
      const currentChannels = slackWorkspace?.slackbotChannelGroups?.items?.map(
        _ => _.name,
      );
      _channels = _channels.filter(item =>
        currentChannels?.find(_ => _ === item.name),
      );
    }

    return _channels.map((item: SlackChannel) => ({
      value: item.id,
      label: item.name,
    }));
  };

  const initiateInstalledAppData = (slackbots: Slackbot[]) => {
    let installedBots = slackbots.map(slb => {
      return slb;
    });
    let apps: Array<DROPDOWN_ITEM> = [];
    installedBots.forEach(item => {
      switch (item.type) {
        case SlackbotType.SHOUTOUT:
          apps.push({
            value: item.id,
            title: '🏆 Shoutouts',
            checked: item.id === initialSlackbotId,
          });
          break;
        case SlackbotType.INTROS:
          apps.push({
            value: item.id,
            title: '👋 Intros',
            checked: item.id === initialSlackbotId,
          });
          break;
        case SlackbotType.BIRTHDAY:
          apps.push({
            value: item.id,
            title: '🎉 Celebrations',
            checked: item.id === initialSlackbotId,
          });
          break;
        case SlackbotType.ICE_BREAKERS:
          apps.push({
            value: item.id,
            title: '🧊 Icebreakers',
            checked: item.id === initialSlackbotId,
          });
          break;
        case SlackbotType.MAPS:
          apps.push({
            value: item.id,
            title: '📍 Maps',
            checked: item.id === initialSlackbotId,
          });
          break;
        case SlackbotType.COFFEE_TALK:
          apps.push({
            value: item.id,
            title: '☕️ Coffee Talk',
            checked: item.id === initialSlackbotId,
          });
          break;
        default:
      }
    });
    setInstalledApps(apps);
  };

  const resetState = () => {
    setSelectedChannels([]);
    initiateInstalledAppData(slackWorkspace?.slackbots || []);
  };

  useEffect(() => {
    const slackbots = slackWorkspace?.slackbots ?? [];
    initiateInstalledAppData(slackbots);
  }, [slackWorkspace?.slackbots]);

  useEffect(() => {
    if (!!slackWorkspace) {
      dispatch(appActions.loadSlackChannels());
    }
  }, [slackWorkspace]);

  useEffect(() => {
    const { slb: newSlackbotId } = Object.fromEntries(
      new URLSearchParams(location.search),
    );
    if (newSlackbotId) {
      onAppClick({
        value: newSlackbotId,
        title: '',
        checked: true,
      });
    }
  }, [location.search]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal show={true} maxWidth="640px" marginTop="5%">
      <ModalHeader
        title={'Add apps to channels'}
        onClose={() => {
          resetState();
          onClose();
        }}
      />
      <BodyContainer>
        <div>
          <BodySmallBold>
            1. What Slack channels would you like to install the apps in?
          </BodySmallBold>
          <Select
            isMulti={true}
            isSearchable
            options={getChannelOptions()}
            onInputChange={data => console.log('onInputChange', data)}
            placeholder="Select Slack Channel"
            onChange={data =>
              setSelectedChannels(
                data.map(item => {
                  return {
                    id: item.value,
                    name: item.label,
                  };
                }),
              )
            }
            styles={channelSelectorBaseStyles}
            isLoading={false}
            noOptionsMessage={() => {
              return 'No channel found';
            }}
            menuShouldScrollIntoView={false}
          />
        </div>
        <InstalledApps>
          <BodySmallBold>2. Select the apps to install:</BodySmallBold>
          {installedApps.map(app => (
            <div
              className="app"
              key={app.value}
              onClick={() => onAppClick(app)}
            >
              <Checkbox isChecked={app.checked}>
                {app.checked && (
                  <CheckIcon className="checkIcon" height={13} width={12} />
                )}
              </Checkbox>
              <BodySmallNormal>{app.title}</BodySmallNormal>
            </div>
          ))}
        </InstalledApps>
        <div>
          <ButtonsContainer>
            <Button
              onClick={() => {
                resetState();
                onClose();
              }}
              color={'white'}
            >
              Cancel
            </Button>
            <Button
              disabled={
                !selectedChannels ||
                selectedChannels.length === 0 ||
                !installedApps.find(item => item.checked)
              }
              onClick={onAddBotToChannel}
            >
              Add
            </Button>
          </ButtonsContainer>
        </div>
        {showLoader && (
          <LoadingContainer>
            <div className="dot-collision"></div>
          </LoadingContainer>
        )}
      </BodyContainer>
    </Modal>
  );
};
