/**
 *
 * Asynchronously loads the component for ReserveUsernameForm
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AnswerIceBreaker = lazyLoad(
  () => import('./index'),
  module => module.AnswerIceBreaker,
);
