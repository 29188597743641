/**
 *
 * Asynchronously loads the component for InterestPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const InterestPage = lazyLoad(
  () => import('./index'),
  module => module.InterestPage,
);
