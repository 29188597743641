/**
 *
 * StepIndicator
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import CompletedStep from 'app/assets/completed-step.svg';

interface Props {
  stepCount: number;
  currentStep: number;
}

interface CircleProps {
  selected: boolean;
}

export const StepIndicator = memo(({ stepCount, currentStep }: Props) => {
  return (
    <Div stepCount={stepCount} currentStep={currentStep}>
      {_.range(stepCount).map(step => {
        if (step + 1 < currentStep) {
          return (
            <StepDiv key={step}>
              <CompletedStepImg src={CompletedStep} key={step} />
            </StepDiv>
          );
        }
        return (
          <StepDiv key={step}>
            <Circle selected={Number(step + 1) === currentStep}>
              {step + 1}
            </Circle>
          </StepDiv>
        );
      })}
    </Div>
  );
});

const Div = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1em;
  width: ${props => (props.stepCount > 2 ? '350px' : '230px')};
  @media (max-width: 768px) {
    width: 300px;
  }
`;

const CompletedStepImg = styled.img`
  width: 48px;
  height: 48px;
  z-index: 5;
`;

const Circle = styled.div<CircleProps>`
  background: #ffffff;
  position: relative;
  width: 48px;
  height: 48px;
  line-height: 1.5em;
  border-radius: 100%;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  box-shadow: ${props =>
    props.selected ? '0 0 0 2px #36A9E0' : '0 0 0 2px rgba(0, 0, 0, 0.25)'};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;

const StepDiv = styled.div`
  &:after {
    position: absolute;
    content: '';
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 24px;
    left: 50%;
    z-index: 2;
  }
  &:last-child::after {
    content: none;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;
`;
