import Checkbox from 'app/components/CheckBox';
import TrashIcon from 'app/components/Icons/TrashIcon';
import PaidBadge from 'app/components/PaidBadge';
import {
  BodyMediumBold,
  BodyMediumNormal,
  BodySmallBold,
  BodySmallNormal,
} from 'app/components/Text';
import {
  createCompanyValues,
  deleteCompanyValue,
  updateCompanyValueRequired,
} from 'app/library/SlackAPI';
import { SlackbotType } from 'app/pages/InstallationChannelSelector/slice/type';
import { useAppSlice } from 'app/slice';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  selectCompanyValues,
  selectSlackWorkspace,
} from '../../../slice/selectors';
import { useAppCustomizationsPanelSlice } from '../slice';
import {
  selectActiveSlackbotId,
  selectIfCompanyValueRequired,
} from '../slice/selectors';
import { sortByProperty } from 'utils/arrays';
import { ConfirmationModal } from 'app/components/Modal/ConfirmationModal';

const Title = styled.span<{}>`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

const ValuesContainer = styled.div`
  border-radius: 8px;
  padding: 16px;
  background-color: rgba(241, 243, 244, 1);
  width: 100%;
`;

const ValuesInput = styled.input`
  padding: 10px 14px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--borders-color-border, rgba(0, 0, 0, 0.25));
  background: var(--surface-color-surface, #fff);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
  font-size: 15px;
`;

const AddButton = styled.button`
  padding: 6px 16px;
  border-radius: 100px;
  border: none;
  background-color: rgba(1, 80, 158, 1);
  color: white;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

const InputContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const Values = styled.div`
  margin-top: 8px;
`;

const ValueItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
`;

export enum BANNER_TYPE {
  CUSTOM,
  DEFAULT,
}

const CheckBoxContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: 0.75em;
  align-items: flex-start;
  line-height: 1;
  margin-top: 0.5em;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'cursor')};
`;

const CheckboxDetailsContainer = styled.div``;

const CheckboxDetailsTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const CompanyValuesCustomization = () => {
  const currentValues = useSelector(selectCompanyValues);
  const ifCompanyValueRequired = useSelector(selectIfCompanyValueRequired);
  const dispatch = useDispatch();
  const { actions } = useAppCustomizationsPanelSlice();
  const slackWorkspace = useSelector(selectSlackWorkspace);
  const [values, setValues] = useState('');
  const { actions: appActions } = useAppSlice();
  const currentSlackbotId = useSelector(selectActiveSlackbotId);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState<boolean>(false);
  const [valueSelectedToDelete, setValueSelectedToDelete] = useState<string>();

  const shoutoutSlackbot = slackWorkspace?.slackbots.find(
    item => item.type === SlackbotType.SHOUTOUT,
  );

  const checkIfAddingDuplicate = (values: Array<string>) => {
    const existingValues = [...(currentValues || [])].map(item => item.value);
    return values.some(item => existingValues.includes(item));
  };

  const onAddCompanyValues = async () => {
    if (checkIfAddingDuplicate(values.split(','))) {
      dispatch(
        appActions.setError('This company value has already been added.'),
      );
      return;
    }
    try {
      if (shoutoutSlackbot) {
        dispatch(appActions.setLoading(true));
        const shoutoutSlackbotId = shoutoutSlackbot.id;
        await createCompanyValues(values, shoutoutSlackbotId);
        setValues('');
        dispatch(appActions.refreshCompanyValues(shoutoutSlackbotId));
        dispatch(appActions.setLoading(false));
      }
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(dispatch(appActions.setError((err as Error).message)));
    }
  };

  const onDeleteCompanyValue = async (id: string) => {
    setValueSelectedToDelete(id);
    setShowDeleteConfirmationModal(true);
  };

  const onDeleteConfirmation = async () => {
    setShowDeleteConfirmationModal(false);
    if (valueSelectedToDelete) {
      try {
        dispatch(appActions.setLoading(true));
        await deleteCompanyValue(valueSelectedToDelete);
        dispatch(
          appActions.setCompanyValues(
            currentValues?.filter(item => item.id !== valueSelectedToDelete) ||
              [],
          ),
        );
        dispatch(appActions.setLoading(false));
        dispatch(appActions.setSuccess('Company value deleted successfully.'));
        setValueSelectedToDelete(undefined);
      } catch (err) {
        dispatch(appActions.setLoading(false));
        dispatch(dispatch(appActions.setError((err as Error).message)));
      }
    }
  };

  const onCompanyValueRequiredChange = async () => {
    try {
      dispatch(appActions.setLoading(true));
      await updateCompanyValueRequired(
        currentSlackbotId!,
        !ifCompanyValueRequired,
      );
      dispatch(actions.setIfCompanyValueRequired(!ifCompanyValueRequired));
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(dispatch(appActions.setError((err as Error).message)));
    }
  };

  return (
    <>
      {/* <UpgradeBanner
        enabled={!slackWorkspace?.entitlements.celebrations.customBanner}
      /> */}
      <div>
        <Title>Edit values</Title>
        <br />
        <BodySmallNormal color="rgba(0, 0, 0, 0.6)">
          Add your company values here, such as Passion, Learning, Results, to
          encourage your team to tag them in a Shoutout. You can also delete
          values.
        </BodySmallNormal>
      </div>
      <ValuesContainer>
        <InputContainer>
          <ValuesInput
            placeholder="Passion, Teamwork, Results, Leadership... (Comma separated)"
            type="text"
            value={values}
            onChange={e => setValues(e.target.value)}
          />
          <AddButton disabled={!values} onClick={onAddCompanyValues}>
            <BodyMediumBold color="#fff">Add</BodyMediumBold>
          </AddButton>
        </InputContainer>

        <Values>
          {sortByProperty([...(currentValues || [])], 'value')?.map(
            companyValue => (
              <ValueItem key={companyValue.id}>
                <BodySmallBold>{companyValue.value}</BodySmallBold>
                <div onClick={() => onDeleteCompanyValue(companyValue.id)}>
                  <TrashIcon />
                </div>
              </ValueItem>
            ),
          )}
        </Values>
      </ValuesContainer>

      <CheckBoxContainer
        disabled={!slackWorkspace?.entitlements.shoutouts.requireValues}
        onClick={
          slackWorkspace?.entitlements.shoutouts.requireValues
            ? onCompanyValueRequiredChange
            : undefined
        }
      >
        <Checkbox checked={ifCompanyValueRequired} />
        <CheckboxDetailsContainer>
          <CheckboxDetailsTitleContainer>
            <BodyMediumBold>Make company values mandatory</BodyMediumBold>
            <PaidBadge
              disabled={slackWorkspace?.entitlements.shoutouts.requireValues}
            />
          </CheckboxDetailsTitleContainer>
          <BodyMediumNormal>
            Require users to select at least one when giving a Shoutout.
          </BodyMediumNormal>
        </CheckboxDetailsContainer>
      </CheckBoxContainer>
      <ConfirmationModal
        isOpen={showDeleteConfirmationModal}
        onClose={() => setShowDeleteConfirmationModal(false)}
        onYesClick={onDeleteConfirmation}
        title="Are you sure?"
        message="Are you sure you want delete this company value?"
      />
    </>
  );
};
