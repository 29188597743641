import React from 'react';

function ChevronRightIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '16'}
      height={props.width || '16'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_9155_11018)">
        <path
          fill={props.color || '#000'}
          fillOpacity="0.9"
          d="M10.52 7.52a.5.5 0 01.146.353v.254a.514.514 0 01-.146.353L7.093 11.9a.333.333 0 01-.473 0l-.474-.473a.327.327 0 010-.467L9.113 8 6.146 5.04a.333.333 0 010-.473L6.62 4.1a.333.333 0 01.473 0l3.427 3.42z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_9155_11018">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ChevronRightIcon;
