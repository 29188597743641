/**
 *
 * LogoContent
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';

interface Props {
  localFile?: File;
  placeholderLogo?: string;
  children?: any;
  placeholderColor?: string;
  placeholderLabelColor?: string;
  url?: string;
  circle?: boolean;
  square?: boolean;
  width?: string;
  height?: string;
  border?: string;
  placeholderSize?: string;
  divWidth?: string;
}

interface PlaceholderProps {
  placeholderLabelColor?: string;
  placeholderColor?: string;
  borderRadius?: string;
  width?: string;
  height?: string;
  border?: string;
  placeholderSize?: string;
}

interface LogoContainerProps {
  borderRadius?: string;
  width?: string;
  height?: string;
  border?: string;
}

interface DivProps {
  width?: string;
}

const Div = styled.div<DivProps>`
  display: flex;
  flex-direction: row;
  width: ${props => (!!props.width ? props.width : '100%')} !important;
`;

const LogoContainer = styled.img<LogoContainerProps>`
  width: ${props => (!!props.width ? props.width : '48px')};
  height: ${props => (!!props.height ? props.height : '48px')};
  border-radius: ${props => props.borderRadius ?? '0'};
  object-fit: cover;
  border: ${props => (!!props.border ? props.border : 'none')};
`;

const Placeholder = styled.div<PlaceholderProps>`
  background-color: ${props => props.placeholderColor};
  width: ${props => (!!props.width ? props.width : '48px')};
  height: ${props => (!!props.height ? props.height : '48px')};
  border-radius: ${props => props.borderRadius ?? '0'};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props =>
    props.placeholderLabelColor ? props.placeholderLabelColor : 'white'};
  font-weight: bold;
  font-size: ${props =>
    !!props.placeholderSize ? props.placeholderSize : '24px'};
  line-height: 120%;
  border: ${props => (!!props.border ? props.border : 'none')};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  flex-grow: 1;
`;

// if there is a local file, we ignore url and placeholder stuff
// else if there is a url, we ignore placeholder stuff
// else we show placeholder stuff
export const LogoContent = memo(
  ({
    localFile,
    placeholderLogo,
    children,
    placeholderColor,
    placeholderLabelColor,
    url,
    circle,
    square = false,
    width,
    height,
    border,
    placeholderSize,
    divWidth,
  }: Props) => {
    const [error, setError] = React.useState(false);
    let borderRadius = '';
    if (!square) {
      borderRadius = circle ? '50%' : '8px';
    }
    return (
      <Div width={divWidth}>
        {!!localFile ? (
          <LogoContainer
            src={URL.createObjectURL(localFile)}
            borderRadius={borderRadius}
            width={width}
            border={border}
          />
        ) : !!url && !error ? (
          <LogoContainer
            src={url}
            borderRadius={borderRadius}
            width={width}
            height={height ?? width}
            border={border}
            onError={() => setError(true)}
          />
        ) : !!placeholderLogo ? (
          <Placeholder
            placeholderColor={placeholderColor}
            placeholderLabelColor={placeholderLabelColor}
            borderRadius={borderRadius}
            width={width}
            height={height ?? width}
            border={border}
            placeholderSize={placeholderSize}
          >
            <span>{placeholderLogo}</span>
          </Placeholder>
        ) : null}
        {!!children ? <Content>{children}</Content> : null}
      </Div>
    );
  },
);
