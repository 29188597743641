import { Apollo } from 'app/library/Apollo';
import { v4 as uuidv4 } from 'uuid';
import { Channel } from './SlackAPI';

export enum SubscriptionType {
  CHANNEL = 'CHANNEL',
  WORKSPACE = 'WORKSPACE',
}

export enum SubscriptionTier {
  ECONOMY = 'ECONOMY',
  BUSINESS = 'BUSINESS',
  FIRST_CLASS = 'FIRST_CLASS',
  FREE_TRIAL = 'FREE_TRIAL',
}

export enum SubscriptionPaymentType {
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum SubscriptionBillingCycle {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
}

export enum SubscriptionState {
  Active = 'ACTIVE',
  AwaitingSignup = 'AWAITING_SIGNUP',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  OnHold = 'ON_HOLD',
  PastDue = 'PAST_DUE',
  SoftFailure = 'SOFT_FAILURE',
  Suspended = 'SUSPENDED',
  Trialing = 'TRIALING',
  TrialEnded = 'TRIAL_ENDED',
  Unpaid = 'UNPAID',
}

export type SubscriptionPlan = {
  id: string;
  tier: SubscriptionTier;
  type: SubscriptionType;
  billingCycle: SubscriptionBillingCycle;
  adminUser: {
    id: string;
    email: string;
    image?: {
      url?: string;
    };
    firstName: string;
    lastName: string;
  };
  state: SubscriptionState;
  seats: number;
  nextBillingDate: string;
  contacts: [SubscriptionContact];
  creditBalanceInCents: number;
  trialEndedAt: string;
  channels: Array<Channel>;
  createdAt: number;
};

export type SubscriptionContact = {
  id: string;
  image?: {
    url?: string;
  };
  email: string;
  firstName: string;
  lastName: string;
};

export enum SubscriptionPaymentType {
  BankAccount = 'BANK_ACCOUNT',
  CreditCard = 'CREDIT_CARD',
  Invoice = 'INVOICE',
}

export type SubscriptionPlanConnection = {
  first: number;
  items: Array<SubscriptionPlan>;
  nextToken?: number;
  offset: number;
  totalCount: number;
};

export type BankAccountDetail = {
  accountNumber: string;
  bankName: string;
  id: string;
  paymentType: SubscriptionPaymentType;
  routingNumber: string;
  subscriptions: SubscriptionPlanConnection;
};

export type CreditCardDetail = {
  cardNumber: string;
  cardType: string;
  expirationMonth: number;
  expirationYear: number;
  id: string;
  paymentType: SubscriptionPaymentType;
  subscriptions: SubscriptionPlanConnection;
};

export async function validateCouponCode(code: string) {
  const data = {
    query: `
        query  validateCoupon($code: String!) {
          validateCoupon(code: $code) {
            __typename
            ... on Coupon {
              name
              code
              description
              percentage
              recurring
              amountInCents
              recurringScheme
            }
            ... on InvalidCouponError {
              message
            }
          }
        }
      `,
    variables: { code },
  };

  try {
    const response = await Apollo.post(data);
    return response.data.data.validateCoupon;
  } catch (err) {
    return [];
    //return Apollo.handleError(err, data);
  }
}

export async function getSubscriptionsPlan(slackWorkspaceId: string) {
  const data = {
    query: `
        query listSubscriptionPlans($slackWorkspaceId: SlackWorkspaceId!) {
          listSubscriptionPlans(input: { slackWorkspaceId: $slackWorkspaceId }) {
            id
            tier
            type
            adminUser {
              id
              email
              image {
                url
              }
              email
              firstName
              lastName
            }
            state
            seats
            billingCycle
            nextBillingDate
            trialEndedAt
            channels {
              name
              id
            }
            contacts {
              image {
                url
              }
              id
              email
              firstName
              lastName
              userId
            }
            creditBalanceInCents
            trialEndedAt
            createdAt
          }
        }
      `,
    variables: { slackWorkspaceId },
  };

  try {
    const response = await Apollo.post(data);
    return response.data.data.listSubscriptionPlans;
  } catch (err) {
    return [];
    //return Apollo.handleError(err, data);
  }
}

export async function getChargifySecurityToken(slackWorkspaceId: string) {
  const data = {
    query: `
        query chargifyJsToken($slackWorkspaceId: SlackWorkspaceId!, $jti: String) {
          chargifyJsToken(input: { slackWorkspaceId: $slackWorkspaceId, jti: $jti }) {
            securityToken
          }
        }
      `,
    variables: {
      slackWorkspaceId,
      jti: uuidv4(),
    },
  };

  try {
    const response = await Apollo.post(data);
    return response.data.data.chargifyJsToken.securityToken;
  } catch (err) {
    return Apollo.handleError(err, data);
  }
}

export type CreateSubscriptionInput = {
  slackWorkspaceId: string;
  type: SubscriptionType;
  tier: SubscriptionTier;
  paymentType?: SubscriptionPaymentType;
  paymentToken?: string;
  billingCycle: SubscriptionBillingCycle;
  couponCodes?: Array<string>;
  contact?: {
    email: string;
    firstName: string;
    lastName: string;
  };
  address?: {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  channels?: Array<string>;
};

export async function createSubscription(
  createSubscriptionInput: CreateSubscriptionInput,
) {
  const data = {
    query: `
        mutation createSubscriptionPlan($slackWorkspaceId: SlackWorkspaceId!, $type: SubscriptionType!, $tier: SubscriptionTier!, $paymentType: SubscriptionPaymentType, $paymentToken: String, $billingCycle: SubscriptionBillingCycle!, $couponCodes: [String!], $contact: ContactOverrideInput, $address: AddressInput, $channels: [String!]) {
          createSubscriptionPlan(input: { slackWorkspaceId: $slackWorkspaceId, type: $type, tier: $tier, paymentType: $paymentType, paymentToken: $paymentToken, billingCycle: $billingCycle, couponCodes: $couponCodes, contact: $contact,  address: $address, channels: $channels }) {
            id
            tier
            type
            adminUser {
              id
              email
              image {
                url
              }
              email
              firstName
              lastName
            }
            state
            seats
            billingCycle
            nextBillingDate
            contacts {
              image {
                url
              }
              id
              email
              firstName
              lastName
              userId
            }
            channels {
              name
              id
            }
            creditBalanceInCents
            trialEndedAt
          }
        }
        `,
    variables: createSubscriptionInput,
  };

  try {
    const response = await Apollo.post(data);
    if (!!response?.data?.data) {
      return response?.data?.data?.createSubscriptionPlan;
    } else {
      return response?.data?.errors[0];
      //return Apollo.handleError(response?.data?.errors[0], data);
    }
  } catch (err) {
    return Apollo.handleError(err, data);
  }
}

// Preview Subscription
export type PreviewSubscriptionInput = {
  slackWorkspaceId: string;
  type: SubscriptionType;
  tier: SubscriptionTier;
  paymentType: SubscriptionPaymentType;
  paymentToken?: string;
  billingCycle: SubscriptionBillingCycle;
  couponCodes?: Array<string>;
  channels: Array<string>;
  contact?: {
    email: string;
    firstName: string;
    lastName: string;
  };
  address?: {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
};

export async function previewSubscription(
  previewSubscriptionInput: PreviewSubscriptionInput,
) {
  const data = {
    query: `
        mutation previewSubscriptionPlan($slackWorkspaceId: SlackWorkspaceId!, $type: SubscriptionType!, $tier: SubscriptionTier!, $paymentType: SubscriptionPaymentType!, $paymentToken: String, $billingCycle: SubscriptionBillingCycle!, $couponCodes: [String!], $contact: ContactOverrideInput, $address: AddressInput, $channels: [String!]) {
          previewSubscriptionPlan(input: { slackWorkspaceId: $slackWorkspaceId, type: $type, tier: $tier, paymentType: $paymentType, paymentToken: $paymentToken, billingCycle: $billingCycle, couponCodes: $couponCodes, contact: $contact,  address: $address, channels: $channels}) {
            tier
            type
            billingCycle
            seats
            nextBillingDate
            subtotalInCents
            totalInCents
            totalDiscountInCents
            totalTaxInCents
            channels {
              name
              id
            }
            appliedDiscounts {
              discountAmountInCents
              memo
              couponCode
              durationPeriodCount
              durationIntervalUnit
            }
          }
        }
        `,
    variables: previewSubscriptionInput,
  };

  try {
    const response = await Apollo.post(data);
    if (!!response?.data?.data) {
      return response?.data?.data?.previewSubscriptionPlan;
    } else {
      return Apollo.handleError(response?.data?.errors[0], data);
      //return response?.data?.errors[0];
    }
  } catch (err) {
    return Apollo.handleError(err, data);
  }
}

// Edit Subscription
export type EditSubscriptionInput = {
  id: string;
  tier: SubscriptionTier;
  billingCycle: SubscriptionBillingCycle;
  paymentType?: SubscriptionPaymentType;
  paymentToken?: String;
  channels?: Array<string>;
};

export async function editSubscription(
  editSubscriptionInput: EditSubscriptionInput,
) {
  const data = {
    query: `
        mutation editSubscriptionPlan($id: SubscriptionId!, $billingCycle: SubscriptionBillingCycle!, $tier: SubscriptionTier!, $channels: [String!]) {
          editSubscriptionPlan(input: { id: $id, tier: $tier, billingCycle: $billingCycle, channels: $channels }) {
            tier
            type
            billingCycle
            seats
            state
            nextBillingDate
            trialEndedAt
            adminUser {
              id
              email
              image {
                url
              }
              email
              firstName
              lastName
            }
            id
            contacts {
              image {
                url
              }
              id
              email
              firstName
              lastName
              userId
            }
            channels {
              name
              id
            }
          }
        }
        `,
    variables: editSubscriptionInput,
  };
  try {
    const response = await Apollo.post(data);
    if (!!response?.data?.data) {
      return response?.data?.data?.editSubscriptionPlan;
    } else {
      return response?.data?.errors[0];
      //return Apollo.handleError(response?.data?.errors[0], data);
    }
  } catch (err) {
    return Apollo.handleError(err, data);
  }
}

export type AddSubscriptionContactInput = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

export async function addSubscriptionContact(
  addContactInput: AddSubscriptionContactInput,
) {
  const data = {
    query: `
        mutation addSubscriptionPlanContact($id: SubscriptionId!, $email: String!, $firstName: String!, $lastName: String! ) {
          addSubscriptionPlanContact(input: { id: $id, email: $email, firstName: $firstName, lastName: $lastName }) {
            tier
            type
            billingCycle
            seats
            state
            nextBillingDate
            trialEndedAt
            adminUser {
              id
              email
              image {
                url
              }
              email
              firstName
              lastName
            }
            id
            contacts {
              image {
                url
              }
              id
              email
              firstName
              lastName
              userId
            }
          }
        }
        `,
    variables: addContactInput,
  };

  try {
    const response = await Apollo.post(data);
    if (!!response?.data?.data) {
      return response?.data?.data?.addSubscriptionPlanContact;
    } else {
      return response?.data?.errors[0];
      //return Apollo.handleError(response?.data?.errors[0], data);
    }
  } catch (err) {
    return Apollo.handleError(err, data);
  }
}

export type RemoveSubscriptionContactInput = {
  id: string;
  contactId: string;
};

export async function removeSubscriptionContact(
  removeContactInput: RemoveSubscriptionContactInput,
) {
  const data = {
    query: `
        mutation removeSubscriptionPlanContact($id: SubscriptionId!, $contactId: SubscriptionContactId! ) {
          removeSubscriptionPlanContact(input: { id: $id, contactId: $contactId }) {
            tier
            type
            billingCycle
            seats
            state
            nextBillingDate
            trialEndedAt
            adminUser {
              id
              email
              image {
                url
              }
              email
              firstName
              lastName
            }
            id
            contacts {
              image {
                url
              }
              id
              email
              firstName
              lastName
              userId
            }
          }
        }
        `,
    variables: removeContactInput,
  };

  try {
    const response = await Apollo.post(data);
    if (!!response?.data?.data) {
      return response?.data?.data?.removeSubscriptionPlanContact;
    } else {
      return response?.data?.errors[0];
      //return Apollo.handleError(response?.data?.errors[0], data);
    }
  } catch (err) {
    return Apollo.handleError(err, data);
  }
}

export type SubscriptionInvoice = {
  uid: string;
  issueDate: string;
  publicUrl: string;
  productName: string;
  paidDate: string;
  productFamilyName: string;
  totalAmount: string;
  paymentInstruction: string;
  dueDate: string;
};

export async function getSubscriptionsInvoice(slackWorkspaceId: string) {
  const data = {
    query: `
        query listInvoices($id: SlackWorkspaceId!) {
          listInvoices(id: $id) {
            uid
            productName
            issueDate
            dueDate
            publicUrl
            paidDate
            productFamilyName
            totalAmount
          }
        }
      `,
    variables: { id: slackWorkspaceId },
  };

  try {
    const response = await Apollo.post(data);
    return response.data.data.listInvoices;
  } catch (err) {
    return [];
  }
}

export async function addPaymentProfile(
  workSpaceId: string,
  token: string,
): Promise<CreditCardDetail | BankAccountDetail | undefined> {
  const data = {
    query: `
        query slackWorkspace($workSpaceId: SlackWorkspaceId!, $token: String!) {
          slackWorkspace(id: $workSpaceId) {
            addPaymentProfile(input: {token: $token}) {
              ...on CreditCardDetail {
                id
                paymentType
                cardType
                cardNumber
                expirationMonth
                expirationYear
                subscriptions(input: {first: 10, offset: 0}) {
                  first
                  offset
                  nextToken
                  totalCount
                  items {
                    id
                  }
                }
              }
              ...on BankAccountDetail {
                id
                paymentType
                bankName
                routingNumber
                accountNumber
                subscriptions(input: {first: 10, offset: 0}) {
                  first
                  offset
                  nextToken
                  totalCount
                  items {
                    id
                  }
                }
              }
            }
          }
        }
      `,
    variables: { workSpaceId, token },
  };

  try {
    const response = await Apollo.post(data);
    if (response.data.errors) {
      throw new Error(response.data.errors[0].message);
    } else {
      return response.data?.data?.slackWorkspace.addPaymentProfile;
    }
  } catch (err) {
    throw err;
  }
}

export async function updatePaymentProfile(
  workSpaceId: string,
  subscriptionId: string,
  paymentProfileId: string,
): Promise<SubscriptionPlan> {
  const data = {
    query: `
        query slackWorkspace($workSpaceId: SlackWorkspaceId!, $subscriptionId: SubscriptionId!, $paymentProfileId: PaymentProfileId!) {
          slackWorkspace(id: $workSpaceId) {
            subscription(id: $subscriptionId) {
              updatePaymentProfile(paymentProfileId: $paymentProfileId) {
                id
                tier
                type
                adminUser {
                  id
                  email
                  image {
                    url
                  }
                  email
                  firstName
                  lastName
                }
                state
                seats
                billingCycle
                nextBillingDate
                trialEndedAt
                channels {
                  name
                  id
                }
                contacts {
                  image {
                    url
                  }
                  id
                  email
                  firstName
                  lastName
                  userId
                }
                creditBalanceInCents
                trialEndedAt
                createdAt
              }
            }
          }
        }
      `,
    variables: { workSpaceId, subscriptionId, paymentProfileId },
  };

  try {
    const response = await Apollo.post(data);
    if (response.data.errors) {
      throw new Error(response.data.errors[0].message);
    } else {
      return response.data?.data?.slackWorkspace.subscription
        .updatePaymentProfile;
    }
  } catch (err) {
    throw err;
  }
}

export async function deletePaymentProfile(
  workSpaceId: string,
  paymentProfileId: string,
): Promise<CreditCardDetail | BankAccountDetail | undefined> {
  const data = {
    query: `
        query slackWorkspace($workSpaceId: SlackWorkspaceId!, $paymentProfileId: PaymentProfileId!) {
          slackWorkspace(id: $workSpaceId) {
            deletePaymentProfile(paymentDetailId: $paymentProfileId) {
              success
            }
          }
        }
      `,
    variables: { workSpaceId, paymentProfileId },
  };

  try {
    const response = await Apollo.post(data);
    if (response.data.errors) {
      throw new Error(response.data.errors[0].message);
    } else {
      return response.data?.data?.slackWorkspace.deletePaymentProfile;
    }
  } catch (err) {
    throw err;
  }
}
