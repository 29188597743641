import { getAppCustomizations } from 'app/library/SlackAPI';
import { appActions } from 'app/slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { appCustomizationsActions as actions } from '.';
import { AppCustomizationsState } from './types';

function* doGetAppCustomizations(action) {
  yield put(appActions.setLoading(true));
  try {
    const slackbotId = action.payload.slackbotId;
    if (!slackbotId) {
      yield appActions.setError('No workspace found.');
      return;
    }
    const appCustomizations: AppCustomizationsState = yield call(
      getAppCustomizations,
      slackbotId,
    );
    if (!appCustomizations.workAnniversaryTitle) {
      appCustomizations.workAnniversaryTitle = 'Work Anniversary';
    }
    yield put(actions.setAppCustomizations(appCustomizations));
  } catch (err) {
    console.error(`An unexpected error occured ${JSON.stringify(err)}`);
  }
  yield put(appActions.setLoading(false));
}
export function* appCustomizationsPanelSaga() {
  yield takeLatest(actions.loadAppCustomizations.type, doGetAppCustomizations);
}
