/**
 *
 * AlertMessage
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import Success from './images/success_48_v1.svg';
import Error from './images/error_48_v1.svg';

interface Props {
  success?: boolean;
  top?: boolean;
  children: any;
}

interface DivProps {
  success?: boolean;
  top?: boolean;
}

const Div = styled.div<DivProps>`
  background-color: #3e4755;
  border: 1px solid #3e4755;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  border-radius: 8px;
  padding: 0.5em;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  margin-top: 0.25em;
  position: fixed;
  ${props => props.top && 'top: 1em;'}
  ${props => !props.top && 'bottom: 1em;'}
  right: 1em;
  width: 375px;
  min-height: 100px;
  z-index: 99999;
`;

const AlertContainer = styled.img`
  margin-right: 1em;
`;

export const AlertMessage = memo(({ success, top, children }: Props) => {
  return (
    <Div success={success} top={top}>
      <AlertContainer src={success ? Success : Error} alt="Icon" />
      <span>{children}</span>
    </Div>
  );
}) as any;

AlertMessage.defaultProps = {
  success: true,
};
