/**
 *
 * Footer
 *
 */
import * as React from 'react';
import { Body1Link } from '../Body1Link';
import { SocialLinks } from '../SocialLinks';
import './footer.css';

export const Footer = React.memo(() => {
  return (
    <div className="footer">
      <div className="footer-row">
        {/* <div className="footer-about-careers">
          <Body1Link href="/about">About Us</Body1Link>
          <span className="footer-margin-left">
            <Body1Link href="/careers">Careers</Body1Link>
          </span>
          <span className="footer-margin-left">
            <Body1Link>
              <span
                onClick={() =>
                  (window.location.href = 'https://www.getairspeed.com/blog')
                }
              >
                Blog
              </span>
            </Body1Link>
          </span>
        </div> */}
        {/* <div className="footer-social">
          <SocialLinks />
        </div> */}
      </div>
      <div className="footer-row footer-wrap">
        {/* <div className="footer-copyright">
          © {new Date().getFullYear()} Airspeed, Inc. All rights reserved
        </div> */}
        {/* <div className="footer-terms-privacy">
          <Body1Link href="/terms" showUnderline={true}>
            Terms of Service
          </Body1Link>
          <span className="footer-margin-left">
            <Body1Link href="/privacy" showUnderline={true}>
              Privacy Policy
            </Body1Link>
          </span>
        </div> */}
      </div>
    </div>
  );
});
