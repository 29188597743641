import { Apollo } from 'app/library/Apollo';
import { Highlight } from 'types/Highlight';
import {
  convertFeedItemToHighlight,
  getFeedItemsGql,
} from 'app/library/FeedAPI';
import { IceBreakerAnswer } from 'app/pages/AnswerIceBreaker/slice/types';

export function createPost(
  companyId: string,
  text: string,
  richMediaId?: string,
): Promise<Highlight | undefined> {
  const data = {
    query: `
      mutation createPost($companyId: CompanyId!, $text: String!, $image: RichMediaId) {
        createPost(input: { companyId: $companyId, text: $text, image: $image }) ${getFeedItemsGql()}
      }
    `,
    variables: {
      companyId: companyId,
      text: text,
      image: !!richMediaId ? richMediaId : null,
    },
  };

  return Apollo.post(data)
    .then(response => {
      console.log(response);
      console.log(response.data.data.createPost);
      return response.data.data.createPost;
    })
    .then(feedItem => convertFeedItemToHighlight(feedItem))
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve(undefined);
    });
}

export function submitIceBreakerAnswer({
  companyId,
  parentId,
  answer,
  sendSlackNotification = true,
}: {
  companyId: string;
  parentId: string;
  answer: IceBreakerAnswer;
  sendSlackNotification?: boolean;
}) {
  const data = {
    query: `
        mutation createPost($companyId: CompanyId!, $text: String!, $image: RichMediaId, $parentId: FeedItemId, $sendSlackNotification: Boolean) {
          createPost(input: { companyId: $companyId, text: $text, image: $image, parentId: $parentId, sendSlackNotification: $sendSlackNotification }) ${getFeedItemsGql()}
        }
      `,
    variables: {
      companyId,
      text: answer.text || '#icebreaker',
      image: !!answer.richMediaId ? answer.richMediaId : null,
      parentId,
      sendSlackNotification,
    },
  };

  return Apollo.post(data)
    .then(response => {
      return response.data.data.createPost;
    })
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve(undefined);
    });
}
