import { selectError, selectSuccess } from 'app/slice/selectors';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components/macro';
import { AlertMessage } from '../AlertMessage';
import { AdminSidebar } from './AdminSidebar';
import './styles.css';
import { AuthType } from 'app/library/Authenticator';
import useAuth from 'app/library/hooks/useAuth';
import { LoginSlackModal } from 'app/pages/MapPage/components/modals/LoginModal';
import { AdminSidePanels } from 'app/pages/Admin/SidePanels';
import {
  selectIsAddAminModalOpen,
  selectIsAddChannelModalOpen,
  selectIsSuperUserWorkspaceModalOpen,
} from 'app/pages/Admin/AdminAppsPage/slice/selectors';
import { AddAdminModal } from 'app/pages/Admin/AdminAppsPage/Components/AddAdminModal';
import { useAdminAppsPageSlice } from 'app/pages/Admin/AdminAppsPage/slice';
import { useDispatch } from 'react-redux';
import { AddAppToChannel } from 'app/pages/Admin/AdminAppsPage/Components/AddAppToChannel';
import { SuperUserWorkspaceSelector } from 'app/pages/Admin/AdminAppsPage/Components/SuperUserWorkspaceSelector';

interface Props {
  children: any;
}

interface TransitionWrapperProps {
  show: boolean;
}

const TransitionWrapper = styled.div<TransitionWrapperProps>`
  display: ${props => (props.show ? 'block' : 'none')};
`;

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const MenuAndContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const Div = styled.div`
  width: 100%;
`;

const Content = styled.div`
  flex-grow: 1;
  padding-bottom: 2em;
  min-height: calc(100vh - 80px);
  @media (max-width: 480px) {
    padding-bottom: 0;
  }
  height: 100vh;
  overflow: auto;
`;

const Wrapper = styled.div`
  position: relative;
  background-color: white;
  margin: 0;
  overflow: hidden;
`;

const PageWrapper = styled.div`
  display: block;
`;

export const AdminContainer = memo(({ children }: Props) => {
  const dispatch = useDispatch();
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);
  const nodeRef = React.useRef(null);
  const { requireLogin } = useAuth();
  const isAddAdminModalOpen = useSelector(selectIsAddAminModalOpen);
  const isAddChannelModalOpen = useSelector(selectIsAddChannelModalOpen);
  const isSuperUserWorkspaceModalOpen = useSelector(
    selectIsSuperUserWorkspaceModalOpen,
  );
  const { actions: adminAppsPageActions } = useAdminAppsPageSlice();

  return (
    <Container>
      <AddAdminModal
        onClose={() =>
          dispatch(adminAppsPageActions.setIsAddAdminModalOpen(false))
        }
        isOpen={isAddAdminModalOpen}
      />
      <AddAppToChannel
        onClose={() =>
          dispatch(adminAppsPageActions.setIsAddChannelModalOpen(false))
        }
        isOpen={isAddChannelModalOpen}
      />
      <SuperUserWorkspaceSelector
        onClose={() =>
          dispatch(adminAppsPageActions.setIsSuperUserWorkspaceModalOpen(false))
        }
        isOpen={isSuperUserWorkspaceModalOpen}
      />
      <AdminSidebar />
      <AdminSidePanels />
      <Div>
        <Wrapper>
          <PageWrapper>
            <MenuAndContent>
              <Content>{children}</Content>
            </MenuAndContent>
          </PageWrapper>
          <TransitionWrapper show={!!error}>
            <CSSTransition
              in={!!error}
              timeout={500}
              classNames="alertmessage"
              nodeRef={nodeRef}
            >
              <AlertMessage success={false} top={false}>
                {error}
              </AlertMessage>
            </CSSTransition>
          </TransitionWrapper>
          <TransitionWrapper show={!!success}>
            <CSSTransition
              in={!!success}
              timeout={500}
              classNames="alertmessage"
              nodeRef={nodeRef}
            >
              <AlertMessage>{success}</AlertMessage>
            </CSSTransition>
          </TransitionWrapper>
        </Wrapper>
      </Div>
      <LoginSlackModal
        isOpen={requireLogin}
        authType={AuthType.SLACK_AIRSPEED}
        text={"Sign in to access your team's Admin Console."}
        bannerSrc={'/admin/admin-sign-in-modal-banner.webp'}
        bannerAlt={'Admin Sign In Banner'}
      />
    </Container>
  );
});
