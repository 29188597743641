import { Apollo } from 'app/library/Apollo';
import { transformPegasusUser } from './UserAPI';
import { LeaderboardItem } from 'types/Leaderboard';
import { LeaderboardType } from 'app/pages/Leaderboards/LeaderboardPage/slice/types';

export interface GQLQuery {
  query: string;
  variables?: any;
}

export function getLeaderboardChannels(slackbotId: string) {
  let data: GQLQuery = {
    query: `
      query getLeaderboardChannels($input: GetLeaderboardChannelsInput!) {
        getLeaderboardChannels(input: $input) {
          items {
            id
            name
          }
        }
      }
    `,
    variables: {
      input: {
        slackbotId,
      },
    },
  };

  return Apollo.post(data)
    .then(response => {
      return response.data.data?.getLeaderboardChannels;
    })
    .catch(err => Apollo.handleError(err, data));
}

export function getLeaderboard(
  slackbotId: string,
  startDate?: string,
  endDate?: string,
  channelIds?: string[],
  type: LeaderboardType = LeaderboardType.ShoutoutsGiven,
  first: number = 10,
  offset: number = 0,
) {
  let data: GQLQuery = {
    query: `
      query getLeaderboard($input: GetLeaderboardInput!) {
        getLeaderboard(input: $input) {
          hasData
          items {
            score
            user {
              id
              email
              firstName
              lastName
              image {
                url
              }
              title
              timezone
            }
          }
        }
      }
    `,
    variables: {
      input: {
        slackbotId,
        startDate,
        endDate,
        channelIds,
        type,
        first,
        offset,
      },
    },
  };

  return Apollo.post(data)
    .then(response => {
      const items =
        response.data.data?.getLeaderboard?.items?.map(_ => {
          return {
            score: _.score,
            user: transformPegasusUser(_.user),
          };
        }) ?? [];
      return {
        hasData: response.data.data?.getLeaderboard?.hasData ?? false,
        items: transformLeaderboardItems(items),
      };
    })
    .catch(err => Apollo.handleError(err, data));
}

function transformLeaderboardItems(items: LeaderboardItem[]) {
  items.sort((a, b) => b.score - a.score);
  let currentPosition = -1;
  for (let i = 0; i < items.length; i++) {
    const currentScore = items[i].score;
    const previousScore = i > 0 ? items[i - 1].score : 0;
    const isFirstInBracket = currentScore !== previousScore;
    if (isFirstInBracket) {
      currentPosition++;
    }
    items[i].position = currentPosition;
    items[i].isFirstInBracket = isFirstInBracket;
  }
  return items;
}
