import styled from 'styled-components/macro';

export interface Props {
  marginTop?: string;
  marginBottom?: string;
  textAlign?: string;
  color?: string;
  opacity?: number;
  lineHeight?: number;
  fontStyle?: string;
}

export interface LinkProps {
  marginTop?: string;
  marginBottom?: string;
  color?: string;
  textDecoration?: string;
}

export const SubTitle1 = styled.span<Props>`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
  color: ${props => (!!props.color ? props.color : '#000000')};
  opacity: ${props => (!!props.opacity ? props.opacity : '1')};
`;

export const Title1 = styled.span<Props>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
  color: ${props => (!!props.color ? props.color : '#000000')};
`;

export const Title2 = styled.span<Props>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
  color: ${props => (!!props.color ? props.color : '#000000')};
`;

export const Link1 = styled.span<LinkProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
  color: ${props => (!!props.color ? props.color : '#000000')};
  text-decoration: ${props =>
    !!props.textDecoration ? props.textDecoration : 'underline'};
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: ${props => (!!props.color ? props.color : '#000000')};
  }
`;

export const Body1 = styled.span<Props>`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
  color: ${props => (!!props.color ? props.color : '#000000')};
`;

export const Body2 = styled.span<Props>`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
  color: ${props => (!!props.color ? props.color : '#000000')};
`;

export const Body3 = styled.span<Props>`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
  color: ${props => (!!props.color ? props.color : '#000000')};
`;

export const Headline1 = styled.span<Props>`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: ${props => (!!props.lineHeight ? props.lineHeight : '120%')};
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
  color: ${props => (!!props.color ? props.color : 'inherit')};
`;

export const Headline3 = styled.span<Props>`
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: ${props => (!!props.lineHeight ? props.lineHeight : '120%')};
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
  color: ${props => (!!props.color ? props.color : 'inherit')};
`;

export const Headline1Alt = styled.span<Props>`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
  color: ${props => (!!props.color ? props.color : 'inherit')};
`;

export const Headline1Normal = styled.span<Props>`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
  color: ${props => (!!props.color ? props.color : 'inherit')};
`;

export const Headline2 = styled.span<Props>`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
  color: ${props => (!!props.color ? props.color : 'inherit')};
`;

export const Hero3 = styled.span<Props>`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
  color: ${props => (!!props.color ? props.color : '#000000')};
`;

export const ProfileAnswer = styled.span<Props>`
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 44px;
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
  color: ${props => (!!props.color ? props.color : '#000000')};
`;

export const Legal = styled.span<Props>`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
  color: ${props => (!!props.color ? props.color : '#000000')};
`;

export const HeadingLarge = styled.span<Props>`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 125%;
  color: ${props => (!!props.color ? props.color : '#000000')};
`;
export const InstructionHeader = styled.span<Props>`
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 43px;
  color: ${props => (!!props.color ? props.color : 'rgba(0, 0, 0, 0.9)')};
  font-family: 'SF Pro Display', sans-serif;
`;

export const BodyXSmallBold = styled.span<Props>`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'inherit')};
`;

export const BodyXXSmallBold = styled.span<Props>`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'inherit')};
`;

export const BodyXXSmallNormal = styled.span<Props>`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'inherit')};
`;

export const BodySmallNormal = styled.span<Props>`
  font-style: ${props => (!!props.fontStyle ? props.fontStyle : 'normal')};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'inherit')};
`;

export const BodySmallBold = styled.span<Props>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'inherit')};
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (!!props.marginBottom ? props.marginBottom : '0')};
`;

export const BodyLargeBold = styled.span<Props>`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'inherit')};
`;

export const BodyLargeSemiBold = styled.span<Props>`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'inherit')};
`;

export const BodyMediumNormal = styled.span<Props>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  vertical-align: center;
  text-align: ${props => (!!props.textAlign ? props.textAlign : 'inherit')};
  color: ${props => (!!props.color ? props.color : 'rgba(0, 0, 0, 0.9)')};
`;

export const BodyMediumBold = styled.span<Props>`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  vertical-align: center;
  text-align: ${props => (!!props.textAlign ? props.textAlign : 'inherit')};
  color: ${props => (!!props.color ? props.color : 'inherit')};
`;

export const BodyLarge = styled.span<Props>`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'rgba(0, 0, 0, 0.9)')};
`;

export const BodyXLargeBold = styled.span<Props>`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'rgba(0, 0, 0, 0.9)')};
`;

export const HeadingXLarge = styled.span<Props>`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'rgba(0, 0, 0, 0.9)')};
`;

export const HeadingXXLarge = styled.span<Props>`
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'rgba(0, 0, 0, 0.9)')};
`;

export const TextSmallNormal = styled.span<Props>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  vertical-align: center;
  text-align: left;
  color: ${props => (!!props.color ? props.color : 'inherit')};
`;

export const TextSmallMedium = styled.span<Props>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'rgba(0, 0, 0, 0.9)')};
`;

export const TextXSmallNormal = styled.span<Props>`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'inherit')};
  text-align: ${props => (!!props.textAlign ? props.textAlign : 'inherit')};
`;

export const TextXSmallMedium = styled.span<Props>`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'inherit')};
  text-align: ${props => (!!props.textAlign ? props.textAlign : 'inherit')};
`;

export const TextXSmallBold = styled.span<Props>`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'inherit')};
  text-align: ${props => (!!props.textAlign ? props.textAlign : 'inherit')};
`;

export const TextMedium = styled.span<Props>`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: ${props => (!!props.textAlign ? props.textAlign : 'inherit')};
  color: ${props => (!!props.color ? props.color : 'inherit')};
`;

export const DisplaySmall = styled.span<Props>`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'rgba(0, 0, 0, 0.9)')};
`;

export const DisplayMedium = styled.span<Props>`
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'rgba(0, 0, 0, 0.9)')};
`;

export const DisplayMediumBold = styled.span<Props>`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'rgba(0, 0, 0, 0.9)')};
`;

export const DisplayLarge = styled.span<Props>`
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'rgba(0, 0, 0, 0.9)')};
`;

export const DisplayLargeBold = styled.span<Props>`
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'rgba(0, 0, 0, 0.9)')};
`;

export const DisplayXLarge = styled.span<Props>`
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: ${props =>
    !!props.lineHeight ? `${props.lineHeight}px` : '80px'};
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'rgba(0, 0, 0, 0.9)')};
`;

export const ResponsiveHeadingXLarge = styled.span<Props>`
  font-style: normal;
  font-weight: 600;
  //font-size: clamp(18px, 24px, 24px);
  line-height: 30px;
  vertical-align: center;
  color: ${props => (!!props.color ? props.color : 'rgba(0, 0, 0, 0.9)')};

  @media screen and (min-width: 601px) {
    font-size: 24px;
  }

  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
`;

export const ResponsiveBodyMediumBold = styled.span<Props>`
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  vertical-align: center;
  text-align: ${props => (!!props.textAlign ? props.textAlign : 'inherit')};
  color: ${props => (!!props.color ? props.color : 'inherit')};

  @media screen and (min-width: 601px) {
    font-size: 16px;
  }

  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const ResponsiveBodyMediumNormal = styled.span<Props>`
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  vertical-align: center;
  text-align: ${props => (!!props.textAlign ? props.textAlign : 'inherit')};
  color: ${props => (!!props.color ? props.color : 'rgba(0, 0, 0, 0.9)')};

  @media screen and (min-width: 601px) {
    font-size: 16px;
  }

  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;
