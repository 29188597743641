import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { settingsPanelSaga } from './saga';
import { SettingsState } from './types';

export const initialState: SettingsState = {
  secondaryEmail: '',
  secondaryEmailError: '',
  secondaryEmailAdded: false,
};

const slice = createSlice({
  name: 'settingsPanel',
  initialState,
  reducers: {
    setSecondaryEmail(state, action: PayloadAction<string>) {
      state.secondaryEmail = action.payload;
    },
    setSecondaryEmailError(state, action: PayloadAction<string | undefined>) {
      state.secondaryEmailError = action.payload;
    },
    setSecondaryEmailAdded(state, action: PayloadAction<boolean>) {
      state.secondaryEmailAdded = action.payload;
    },
    submit() {},
    validateEmail() {},
    load() {},
    reset() {
      return initialState;
    },
  },
});

export const { actions: settingsPanelActions } = slice;

export const useSettingsPanelSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: settingsPanelSaga });
  return { actions: slice.actions };
};
