import React from 'react';
import styled from 'styled-components';
import LogoFullCentralized from 'app/assets/logo-full-centralized.png';
import LogoOnly from 'app/assets/logoOnly.svg';

const StyledSidebarHeader = styled.div`
  padding: 32px 24px;
  display: flex;
  align-items: center;
`;

const LogoImg = styled.img`
  height: 32px;
`;

type Props = {
  collapsed: boolean;
};

export const SidebarHeader = ({ collapsed }: Props) => {
  return (
    <StyledSidebarHeader>
      <LogoImg src={collapsed ? LogoOnly : LogoFullCentralized} />
    </StyledSidebarHeader>
  );
};
