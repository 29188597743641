/**
 *
 * MapTopBar
 *
 */
import LogoBlack from 'app/assets/logoBlack.svg';
import LogoOnly from 'app/assets/logo_48_v1.svg';
import User from 'app/assets/user.svg';
import { ImageContainer } from 'app/components/ProfileMenu';
import { SearchBar } from 'app/components/search/SearchBar';
import history from 'app/library/History';
import { useOutsideAlerter } from 'app/library/OutsideClickHelper';
import { selectUser } from 'app/slice/selectors';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { isMobile } from 'react-device-detect';
import Magnifier from 'app/assets/magnifier.svg';
import { selectIsDemo } from '../../slice/selectors';
import { demoProfilePicture } from '../Map/demo';
import { SlackbotType } from '../../../InstallationChannelSelector/slice/type';

const EDIT_PROFILE_PARAM = 'state=edit_profile';
const USER_ID_PARAM = 'user_id';

interface Props {
  searchOnClick?: () => void;
}

const Div = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  height: 5em;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  z-index: 100;
`;

const TopLeftContainer = styled.div`
  flex: 0 0 180px;
  padding-left: 1em;

  @media (max-width: 1024px) {
    flex: 0 0 120px;
  }

  @media (max-width: 480px) {
    flex: 0 0;
  }
`;

const TopRightIconsContainer = styled.div`
  flex: 0 0 180px;
  display: flex;
  justify-content: flex-end;
  padding-right: 1em;
  align-items: center;

  @media (max-width: 1024px) {
    flex: 0 0 120px;
  }

  @media (max-width: 480px) {
    flex: 0 0;
  }
`;

const MobileNavBarIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  cursor: pointer;
`;

const SearchIconImg = styled.img`
  height: 18px;
  width: 18px;
`;

const SearchContainer = styled.div`
  width: 100%;
  margin: 0 1.5em;
  max-width: 680px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    margin: 0;
  }
`;

type LogoProps = {
  logo: string;
  width: string;
};

const LogoContainer = styled.div<LogoProps>`
  background-image: url(${props => props.logo});
  background-repeat: no-repeat;
  width: ${props => props.width};
  height: 44px;

  @media (max-width: 1024px) {
    background-image: url(${LogoOnly});
    width: 44px;
  }

  @media (max-width: 480px) {
    background-image: url(${props => props.logo});
    width: ${props => props.width};
  }
`;

const LogoPointer = styled.span`
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Badge = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: red;
  color: white;
  height: 0.6em;
  width: 0.6em;
  border-radius: 50%;
`;

const LogoBlock = () => {
  return (
    <Link to="/map?zoom_out=max">
      <LogoPointer>
        <LogoContainer logo={LogoBlack} width={'158px'} />
      </LogoPointer>
    </Link>
  );
};

const TopRightIcons = memo((props: Props) => {
  return (
    <>
      {isMobile && (
        <>
          {/* <Notification /> */}
          <SearchNavButton {...props} />
        </>
      )}
      <ProfileMenu />
    </>
  );
});

const ProfileMenu = memo((props: Props) => {
  const isDemo = useSelector(selectIsDemo);
  const user = useSelector(selectUser);
  const [isFirstVisit, setIsFirstVisit] = useState(false);

  useEffect(() => {
    const isFirstTimeVisit = localStorage.getItem('isFirstTimeVisit');
    if (!isFirstTimeVisit && !isDemo) {
      setIsFirstVisit(true);
    }
  }, []);
  let profileImage = !!user?.image ? user.image : User;
  if (isDemo && !user) {
    profileImage = demoProfilePicture();
  }

  const [selected, setSelected] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setSelected(false);
  });

  const handleClick = () => {
    if (isDemo) {
      history.push({
        search: `?show_install_modal=1`,
      });
    } else {
      setIsFirstVisit(false);
      localStorage.setItem('isFirstTimeVisit', 'true');
      history.push({
        search: `?${USER_ID_PARAM}=${user?.id}&${EDIT_PROFILE_PARAM}`,
      });
    }
  };

  return (
    <Wrapper ref={wrapperRef}>
      <ImageContainer src={profileImage} alt="Profile" onClick={handleClick} width={'48px'} height={'48px'} selected/>
      {!!isFirstVisit && <Badge />}
    </Wrapper>
  );
});

const SearchNavButton = memo((props: Props) => {
  const handleClick = () => {
    props.searchOnClick?.();
  };

  return (
    <MobileNavBarIconContainer>
      <SearchIconImg src={Magnifier} alt="Search" onClick={handleClick} />
    </MobileNavBarIconContainer>
  );
});

export const MapTopBar = memo((props: Props) => {
  const topLeftRef = useRef<HTMLDivElement>(document.createElement('div'));
  const searchBarRef = useRef<HTMLDivElement>(document.createElement('div'));
  const topRightRef = useRef<HTMLDivElement>(document.createElement('div'));

  const toggle = () => {
    searchBarRef.current.style.display = 'block';
    topRightRef.current.style.display = 'none';
    topLeftRef.current.style.display = 'none';
  };

  const toggleOut = () => {
    topRightRef.current.style.removeProperty('display');
    topLeftRef.current.style.removeProperty('display');
  };

  return (
    <Div>
      <TopLeftContainer ref={topLeftRef}>
        <LogoBlock />
      </TopLeftContainer>
      <SearchContainer>
        <SearchBar
          ref={searchBarRef}
          onClose={toggleOut}
          slackApp={SlackbotType.MAPS}
        ></SearchBar>
      </SearchContainer>
      <TopRightIconsContainer ref={topRightRef}>
        <TopRightIcons searchOnClick={toggle} />
      </TopRightIconsContainer>
    </Div>
  );
});
