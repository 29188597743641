import { searchBarActions as actions } from '.';
import { call, put, select, debounce, takeLatest } from 'redux-saga/effects';
import { appActions } from 'app/slice';
import { selectSearchText, selectSelection } from './selectors';
import { searchUsers } from 'app/library/SearchAPI';
import { searchDemoMapItems } from 'app/pages/MapPage/components/Map/demo';
import { selectIsDemo } from 'app/pages/MapPage/slice/selectors';
import { User } from 'types/User';
import { selectUser } from 'app/slice/selectors';
import { getMapsWorkspace } from 'utils/user-workspaces';

function* doSearch() {
  try {
    const searchText = yield select(selectSearchText);
    if (!searchText.length) {
      yield put(actions.setSearchResults([]));
      return;
    }
    const isDemo = yield select(selectIsDemo);
    if (!!isDemo) {
      const searchResults = searchDemoMapItems(searchText);
      yield put(
        actions.setSearchResults(
          searchResults.map(result => {
            return {
              id: result.user.id,
              label: `${result.user.firstName} ${result.user.lastName}`,
              image: result.user.image,
            };
          }),
        ),
      );
    } else {
      const user: User = yield select(selectUser);
      const mapsWorkspace = getMapsWorkspace(user);
      if (!mapsWorkspace) {
        console.log('no user/mapsWorkspace');
        return;
      }
      const searchResults = yield call(
        searchUsers,
        searchText,
        ['slackapp_maps'],
        mapsWorkspace?.id,
      );
      yield put(
        actions.setSearchResults(
          searchResults
            .filter(
              result =>
                !!result.locations?.find(_ => _.type == 'HOME_CURRENT') ||
                !!result.locations?.find(
                  _ =>
                    _.type == 'TRAVEL' &&
                    !!_.endDate &&
                    new Date(_.endDate!) > new Date(),
                ),
            )
            .map(result => {
              return {
                id: result.id,
                label: `${result.firstName} ${result.lastName}`,
                image: result.image?.url,
                hasHomeOffice: !!result.locations.filter(
                  _ => _.type == 'HOME_CURRENT',
                ).length,
              };
            }),
        ),
      );
    }
  } catch (err) {
    console.error(`An unexpected error occur ${err}`);
    yield put(appActions.setError(JSON.stringify(err)));
  }
}

function* doSetSelection() {
  const selection = yield select(selectSelection);
  if (!!selection) {
    yield put(actions.setSearchText(''));
    yield put(actions.setSearchResults([]));
    // yield call(gotoProfile, { queryUserId: selection });
  }
}

export function* searchBarSaga() {
  yield takeLatest(actions.setSelection.type, doSetSelection);
  yield debounce(100, actions.setSearchText.type, doSearch);
}
