import { InstallationInstructionsHeader } from 'app/components/InstallationInstructionsHeader';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'app/components/Button';
import { StepIndicator } from 'app/components/StepIndicator';
import OpenIcon from 'app/assets/open_new.svg';
import { selectUser } from 'app/slice/selectors';
import { useSelector } from 'react-redux';
import Confetti from 'react-confetti';
import { Helmet } from 'react-helmet-async';
import { AnalyticsClientContext } from '../../library/Analytics';
import { SlackbotType } from '../InstallationChannelSelector/slice/type';
import querySearch from 'stringquery';
import { getSlackRedirectUrl } from 'utils/user-workspaces';
import { GoToSlack } from 'app/components/GoToSlack';
import { Headline1Normal } from 'app/components/Text';

interface HideProps {
  hidden: boolean;
}

const MainContent = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s ease-in;
  @media (max-width: 768px) {
    padding-bottom: 10%;
    min-height: 550px;
    padding: 1em;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2em 2em;
  max-width: 800px;
  align-items: center;
  @media (max-width: 768px) {
    padding-bottom: 10%;
    min-height: 550px;
    padding: 0 1em;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  width: 50%;

  margin-bottom: 0.5em;
  @media (max-width: 768px) {
    width: 50%;
  }
`;
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 500px;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SkipText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const Tips = styled.div`
  width: 100%;
  text-align: start;
  color: #00000099;
  padding: 0em 4em;
  margin-bottom: 3em;
`;

const TipsHeading = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 1em;
`;
const TipsText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 0.3em;
`;

const OpenLinkImg = styled.img``;

const HideableContainer = styled.div<HideProps>`
  display: ${props => (props.hidden ? 'none' : 'block')};
`;

export function IcebreakersGotoSlack() {
  const user = useSelector(selectUser);
  const [showConfetti, setShowConfetti] = useState<boolean>(true);
  const analyticsClient = React.useContext(AnalyticsClientContext);
  analyticsClient.setSlackIdentity(user, SlackbotType.ICE_BREAKERS);
  const [channel, setChannel] = useState<string>('');

  useEffect(() => {
    const qs = querySearch(location.search);
    if (qs) {
      const channelId = qs['channel'];
      setChannel(channelId);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);
  const openSlackApp = () => {
    analyticsClient.logEvent({
      eventType: 'Icebreakers onboarding completion page',
      properties: {
        Type: 'Go to Slack',
      },
    });

    window.location.href = getSlackRedirectUrl(SlackbotType.ICE_BREAKERS);
  };

  const openWeb = () => {
    analyticsClient.logEvent({
      eventType: 'Icebreakers onboarding completion page',
      properties: {
        Type: 'Install other Airspeed apps',
      },
    });
    window.location.href = `https://www.getairspeed.com/product/`;
  };

  return (
    <>
      <Helmet>
        <meta property="robots" content="noindex" />
      </Helmet>
      <MainContent>
        <HideableContainer hidden={!showConfetti}>
          <Confetti />
        </HideableContainer>
        <GoToSlack
          appName="Icebreakers"
          openSlackApp={openSlackApp}
          openWeb={openWeb}
          tips={[
            <Headline1Normal>
              You can add the Icebreakers app to as many channels as you’d like.
            </Headline1Normal>,
            <Headline1Normal>
              You can customize Icebreaker frequency or create your own
              question.
            </Headline1Normal>,
            <Headline1Normal>
              Use /icebreaker in the channel to bring up the command center.
            </Headline1Normal>,
          ]}
          stepCount={2}
          currentStepCount={3}
          title="Way to start breaking the ice! 🙌"
          description="Icebreakers is now added to your Slack workspace. Your first Icebreaker has been shared with the channel!"
        />
      </MainContent>
    </>
  );
}
