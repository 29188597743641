import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLeaderboardSlice } from 'app/pages/Leaderboards/LeaderboardPage/slice';
import { selectShoutouts } from 'app/pages/Leaderboards/LeaderboardPage/slice/selectors';
import { ShoutoutsByUserRow } from './ShoutoutsByUserRow';
import { CSSTransition } from 'react-transition-group';
import './styles.css';
import styled from 'styled-components';
import { PanelNavHeader } from '../../PanelNavHeader';
import { pushHistory } from 'app/library/NavHelper';
import { AdminSidePanelState } from 'app/pages/Admin/AdminHomePage/slice/type';

interface Props {
  userId: string;
  state: AdminSidePanelState;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export function ShoutoutsByUserListView({ userId, state }: Props) {
  const dispatch = useDispatch();
  const { actions: leaderboardActions } = useLeaderboardSlice();
  const shoutouts = useSelector(selectShoutouts);
  const nodeRef = useRef(null);
  useEffect(() => {
    if (state === AdminSidePanelState.VIEW_SHOUTOUTS_GIVEN) {
      dispatch(leaderboardActions.loadShoutouts({ senderId: userId }));
    } else {
      dispatch(leaderboardActions.loadShoutouts({ recipientId: userId }));
    }
  }, [userId]);

  return (
    // <CSSTransition timeout={300} classNames="push" nodeRef={nodeRef}>
    <Container>
      <PanelNavHeader onBack={() => pushHistory({ user_id: '' })} />
      {/* <div style={{ width: '100%', backgroundColor: 'red' }}> */}
      {shoutouts.map(_ => (
        <ShoutoutsByUserRow
          key={_.createdAt.toString()}
          shoutout={_}
          state={state}
        />
      ))}
      {/* </div> */}
    </Container>
    // </CSSTransition>
  );
}
