import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.appCustomizationsPanel || initialState;

export const selectAppCustomizations = createSelector(
  [selectSlice],
  state => state,
);

export const selectBirthdayBanner = createSelector(
  [selectSlice],
  state => state.birthdayBanner,
);

export const selectCustomCelebrationsBanner = createSelector(
  [selectSlice],
  state => state.customCelebrationBanner,
);

export const selectWorkAnniversaryBanner = createSelector(
  [selectSlice],
  state => state.workAnniversaryBanner,
);

export const selectWorkAnniversaryTitle = createSelector(
  [selectSlice],
  state => state.workAnniversaryTitle,
);

export const selectShoutBannerMultipleUser = createSelector(
  [selectSlice],
  state => state.shoutOutBannerMultipleUser,
);

export const selectActiveSlackbotId = createSelector(
  [selectSlice],
  state => state.slackbotId,
);

export const selectIfDataChanged = createSelector(
  [selectSlice],
  state => state.isDataChanged,
);

export const selectIfCompanyValueRequired = createSelector(
  [selectSlice],
  state => state.shoutouts_value_required,
);

export const selectCustomizationStateCopy = createSelector(
  [selectSlice],
  state => {
    if (state.stateCopy) {
      return state.stateCopy;
    }
    const tempState = { ...state };
    delete tempState.stateCopy;
    delete tempState.isDataChanged;
    return tempState;
  },
);
