import React, { useEffect } from 'react';
import SlackLogo from 'app/assets/slack.svg';
import styled from 'styled-components';
import { Modal, ModalContentWrapper, ModalHeader } from '../Modal';
import { Panel } from '../Panel';
import { ContainerType } from '../ShareLinkedin';
import { BodyMediumBold, BodyXSmallBold } from '../Text';
import { MapItem, MapItemUser } from 'app/library/LocationAPI';
import { UserRow } from './components/UserRow';
import { Button } from '../Button';
import history from 'app/library/History';
import { DEMO_WORKSPACE_ID } from 'app/pages/MapPage/components/Map/demo';
import { useMapPageSlice } from 'app/pages/MapPage/slice';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Checkbox from '../CheckBox';
import { AnalyticsClientContext } from '../../library/Analytics';
import { User } from 'types/User';

//https://api.slack-gov.com/methods/conversations.open#markdown
//Max number of users that can be part of a MPIM is 9.
//Bot + User + up to 7 selected users
export const MAX_SELECTED_USERS = 7;

interface Props {
  isOpen: boolean;
  as?: ContainerType;
  mapItems: MapItem[];
  workspaceId: string;
  userId?: string;
  user?: User;
  reset: () => void;
}

interface ContainerProps {
  isModal: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  padding-bottom: 75px;
  @media (max-width: 480px) {
    padding-bottom: 120px;
  }
`;

const CTAContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0.5em 0.5em 0.5em;
  gap: 12px;
  position: sticky;
  top: 45px;
  z-index: 999;
  padding: 10px;
  background-color: white;
`;

interface SelectAllProps {
  enabled: boolean;
}

const SelectContainer = styled.div<SelectAllProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 1em;
  gap: 4px;
  cursor: ${props => (props.enabled ? 'pointer' : 'inherit')};
  opacity: ${props => (props.enabled ? '1' : '0.5')};
`;

const SelectButton = styled.div<SelectAllProps>`
  color: #000;
  cursor: ${props => (props.enabled ? 'pointer' : 'inherit')};
  &:hover {
    opacity: ${props => (props.enabled ? '0.8' : '1')};
  }
  padding-bottom: 2px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

function InnerContent({ isModal = false, mapItems, workspaceId, userId }) {
  const { actions } = useMapPageSlice();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectAllChecked, setSelectAllChecked] =
    React.useState<boolean>(false);

  const analyticsClient = React.useContext(AnalyticsClientContext);

  const { users: selectedUsersString } = Object.fromEntries(
    new URLSearchParams(location.search),
  );

  const [selectedUsers, setSelectedUsers] = React.useState<string[]>(
    selectedUsersString?.split(',') ?? [],
  );

  useEffect(() => {
    if (uniqueUsers.length <= 1) {
      return;
    }
    const updatedParams = {
      ...Object.fromEntries(new URLSearchParams(location.search)),
      state: 'users_list',
      users: selectedUsers?.join(','),
    };
    history.push({
      pathname: location.pathname,
      search: `?${new URLSearchParams(updatedParams).toString()}`,
    });
  }, [selectedUsers]);

  useEffect(() => {
    //If list is updated, remove any users that are no longer in the list
    //Only when there are map items in order not to clear the URL user list
    if (!!mapItems.length) {
      setSelectedUsers(
        selectedUsers.filter(id => mapItems.find(item => item.user.id === id)),
      );
    }
  }, [mapItems]);

  const messageInSlack = async () => {
    if (workspaceId == DEMO_WORKSPACE_ID) {
      history.push({
        search: `?show_install_modal=1`,
      });
      return;
    }
    analyticsClient.logEvent({
      eventType: 'Maps message in slack opened',
      properties: {
        Source: parent,
      },
    });
    dispatch(actions.messageInSlack(selectedUsers));
  };

  const getUserRow = (user: MapItemUser) => {
    return (
      <UserRow
        key={`${user.id}`}
        user={user}
        checked={selectedUsers.includes(user.id)}
        showCheckbox={user.id != userId}
        checkboxDisabled={
          selectedUsers.length >= MAX_SELECTED_USERS || user.id == userId
        }
        onToggle={() => {
          if (selectedUsers.includes(user.id)) {
            setSelectedUsers(
              selectedUsers.filter(
                selectedUserId => selectedUserId !== user.id,
              ),
            );
          } else {
            const mapItem = mapItems.find(m => m.user.id == user.id);
            setSelectedUsers([...selectedUsers, mapItem.user.id]);
          }
        }}
        onClick={() => {
          const updatedParams = {
            ...Object.fromEntries(new URLSearchParams(location.search)),
            state: 'user_detail',
            user_id: user.id,
          };
          history.push({
            search: `?${new URLSearchParams(updatedParams).toString()}`,
          });
        }}
      />
    );
  };

  const uniqueUsers = mapItems
    .reduce((acc, mapItem) => {
      const existing = acc.find(u => u.id == mapItem.user.id);
      if (!!existing) {
        existing.locations.push(mapItem.location);
      } else {
        acc.push({ ...mapItem.user, locations: [mapItem.location] });
      }
      return acc;
    }, [])
    .sort((a, b) => {
      //self goes first
      if (b.id == userId) return 1;

      //Travels before home offices
      if (
        !a.locations?.find(l => l.type == 'TRAVEL') &&
        !!b.locations?.find(l => l.type == 'TRAVEL')
      )
        return 1;

      //Fallback to alphabetical
      return `${a.firstName} ${a.lastName}`.localeCompare(
        `${b.firstName} ${b.lastName}`,
      );
    });

  const selectAll = () => {
    setSelectedUsers(uniqueUsers.filter(u => u.id !== userId).map(u => u.id));
  };

  const deselectAll = () => {
    setSelectedUsers([]);
  };

  useEffect(() => {
    const max = uniqueUsers.filter(u => u.id !== userId).length;
    setSelectAllChecked(max > 0 && selectedUsers.length == max);
  }, [selectedUsers, uniqueUsers]);

  const onToggleSelectAll = () => {
    if (uniqueUsers.length > MAX_SELECTED_USERS) {
      return;
    }
    selectAllChecked ? deselectAll() : selectAll();
    setSelectAllChecked(!selectAllChecked);
  };

  return (
    <Container isModal={isModal}>
      <CTAContainer>
        <Button
          size="compact"
          format="pill"
          color={!!selectedUsers.length ? '#fff' : 'rgba(0, 0, 0, 0.1)'}
          opacity={!!selectedUsers.length ? 1 : 0.45}
          onClick={messageInSlack}
          icon={SlackLogo}
          borderColor="#000"
          disabled={!selectedUsers.length}
        >
          <BodyMediumBold>
            {selectedUsers.length > 1
              ? 'Message this group'
              : 'Message in Slack'}
          </BodyMediumBold>
        </Button>
        <BodyXSmallBold color={'rgba(0, 0, 0, 0.6)'}>
          You can select up to {MAX_SELECTED_USERS} people to message directly
          in Slack.
        </BodyXSmallBold>
      </CTAContainer>
      <SelectContainer
        onClick={onToggleSelectAll}
        enabled={uniqueUsers.length <= MAX_SELECTED_USERS}
      >
        <Checkbox checked={selectAllChecked} onChange={onToggleSelectAll} />
        <SelectButton enabled={uniqueUsers.length <= MAX_SELECTED_USERS}>
          <BodyXSmallBold>Select all</BodyXSmallBold>
        </SelectButton>
      </SelectContainer>
      {!!uniqueUsers.length && <>{uniqueUsers.map(getUserRow)}</>}
    </Container>
  );
}

function PanelContent(props) {
  return (
    <Panel
      canConfirm={false}
      title="📍 Teammates in this area"
      onClose={props.handleReset}
      emptyLeftIcon
    >
      <InnerContent {...props} />
    </Panel>
  );
}

function ModalContent(props) {
  return (
    <Modal show={props.isOpen} maxWidth="700px" marginTop="5%">
      <ModalHeader
        justifyContent="center"
        title="📍 Teammates in this area"
        onClose={props.handleReset}
      />
      <ModalContentWrapper>
        <InnerContent isModal {...props} />
      </ModalContentWrapper>
    </Modal>
  );
}

export function UsersListPanel({
  isOpen,
  mapItems,
  reset,
  as = ContainerType.Panel,
  workspaceId,
  userId,
}: Props): JSX.Element {
  // const analyticsClient = React.useContext(AnalyticsClientContext);
  const isModal = as === ContainerType.Modal;
  if (isModal) {
    return (
      <ModalContent
        isOpen={isOpen}
        mapItems={mapItems}
        workspaceId={workspaceId}
        userId={userId}
        handleReset={reset}
      />
    );
  }

  return (
    <PanelContent
      mapItems={mapItems}
      workspaceId={workspaceId}
      userId={userId}
      handleReset={reset}
    />
  );
}
