import { PayloadAction } from '@reduxjs/toolkit';
import { Highlight } from 'types/Highlight';
import { User } from 'types/User';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { profileHighlightsSaga } from './saga';
import { ProfileHighlightsState } from './types';

export const noDataState: ProfileHighlightsState = {
  loading: true,
  highlights: [],
  lastEvaluatedKey: undefined,
  highlightStateChanging: false,
};

export const initialState: ProfileHighlightsState = Object.assign(
  Object.assign({}, noDataState),
  {
    profile: undefined,
  },
);

// TODO replaced appendHighlights w/ setHighlights in order to quickly fix prd bug
// Need to revert and debug further in order for profile to support pagination
const slice = createSlice({
  name: 'profileHighlights',
  initialState,
  reducers: {
    loadHighlights() {},
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setHighlights(state, action: PayloadAction<Highlight[]>) {
      state.highlights = action.payload;
    },
    setLastEvaluatedKey(state, action: PayloadAction<number | undefined>) {
      state.lastEvaluatedKey = action.payload;
    },
    deleteHighlight(state, action: PayloadAction<string>) {
      state.highlights = state.highlights.filter(
        highlight => highlight.createdAt !== action.payload,
      );
    },
    reset(state) {
      Object.assign(state, initialState);
    },
    resetData(state) {
      Object.assign(state, noDataState);
    },
    setHighlightStateChanging(state, action: PayloadAction<boolean>) {
      state.highlightStateChanging = action.payload;
    },
    moveUp(state, action: PayloadAction<string>) {},
    moveDown(state, action: PayloadAction<string>) {},
    onMoveUpSuccess(state, action: PayloadAction<string>) {
      onMoveSuccess(state, action, true)
    },
    onMoveDownSuccess(state, action: PayloadAction<string>) {
      onMoveSuccess(state, action, false)
    },
    addToTheTop(state, action: PayloadAction<Highlight>) {
      state.highlights.unshift(action.payload);
    },
    setProfile(state, action: PayloadAction<User>) {
      Object.assign(Object.assign(state, noDataState), {
        profile: action.payload,
      });
    },
    updateHighlight(state, action: PayloadAction<Highlight>) {
      const updatedHighlight = action.payload;
      const userId = updatedHighlight.userId;
      const createdAt = updatedHighlight.createdAt;
      const index = state.highlights.findIndex(
        highlight =>
          highlight.userId === userId && highlight.createdAt === createdAt,
      );
      if (index >= 0) {
        state.highlights[index].likes = updatedHighlight.likes;
        state.highlights[index].comments = updatedHighlight.comments;
      } else {
        console.warn('Did not find highlight to update');
      }
    },
  },
});

function onMoveSuccess(state, action: PayloadAction<string>, up) {
  let currentHighlightIndex;
  let nextHighlightIndex;
  state.highlights.forEach((highlight, index) => {
    if (highlight.answerId === action.payload) {
      currentHighlightIndex = index;
      nextHighlightIndex = index + (up ? -1 : 1);
    }
  });

  const currentHighlight = state.highlights[currentHighlightIndex];
  const nextHighlight = state.highlights[nextHighlightIndex];

  if (!!nextHighlight) {
    state.highlights = state.highlights
      .map(highlight => {
        if (highlight.createdAt === currentHighlight.createdAt) {
          return { ...highlight, order: nextHighlight.order };
        }
        if (highlight.createdAt === nextHighlight.createdAt) {
          return { ...highlight, order: currentHighlight.order };
        }
        return highlight;
      })
      .sort((highlight1, highlight2) => highlight2.order - highlight1.order);
  }
}

export const { actions: profileHighlightsActions } = slice;

export const useProfileHighlightsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: profileHighlightsSaga });
  return { actions: slice.actions };
};
