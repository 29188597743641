import { leaderboardActions as actions } from './index';
import { appActions } from 'app/slice';
import { selectSlackWorkspace } from 'app/slice/selectors';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  selectLeaderboardCurrentApp,
  selectLeaderboardPeriod,
  selectLeaderboardFilterChannelIds,
  selectLeaderboardCurrentType,
} from './selectors';
import { SlackWorkspace } from 'types/Slack';
import {
  getLeaderboard,
  getLeaderboardChannels,
} from 'app/library/LeaderboardAPI';
import { Leaderboard } from 'types/Leaderboard';
import {
  LEADERBOARD_ENABLED_APPS,
  LeaderboardApp,
  LeaderboardPeriod,
} from './types';
import { getDateRangeForPeriod } from 'utils/date';
import { SlackbotType } from 'app/pages/InstallationChannelSelector/slice/type';
import { Shoutout } from 'types/Shoutouts';
import { getShoutouts } from 'app/library/ShoutoutsAPI';
import { PayloadAction } from '@reduxjs/toolkit';

function* doLoad(action?: PayloadAction<{ first?: number }>) {
  try {
    const slackWorkspace: SlackWorkspace = yield select(selectSlackWorkspace);
    const apps = LEADERBOARD_ENABLED_APPS.map(type => ({
      type,
      slackbotId: slackWorkspace.slackbots.find(_ => _.type == type)?.id,
    }));
    yield put(actions.setLeaderboardApps(apps));

    let currentApp: LeaderboardApp = yield select(selectLeaderboardCurrentApp);
    if (!currentApp) {
      const { app: appFromUrl } = Object.fromEntries(
        new URLSearchParams(location.search),
      );
      const appType = LEADERBOARD_ENABLED_APPS.includes(
        appFromUrl as SlackbotType,
      )
        ? appFromUrl
        : SlackbotType.SHOUTOUT;
      currentApp = apps.find(_ => _.type == appType) ?? apps[0];
      yield put(actions.setLeaderboardCurrentApp(currentApp));
    }
    yield put(actions.refresh({ ...action?.payload }));
    if (!!currentApp.slackbotId) {
      yield put(actions.loadChannels());
    }
  } catch (e) {
    console.error(`An unexpected error occurred: ${e}`);
  }
}

function* doRefresh(action?: PayloadAction<{ first?: number }>) {
  const period: LeaderboardPeriod = yield select(selectLeaderboardPeriod);
  yield put(actions.setLeaderboardLoading(true));
  try {
    let currentApp: LeaderboardApp = yield select(selectLeaderboardCurrentApp);
    if (!currentApp) {
      throw new Error('No app selected.');
    }
    if (!currentApp.slackbotId) {
      //not installed
      yield put(actions.setLeaderboardItems([]));
      yield put(actions.setLeaderboardHasData(false));
      return;
    }
    const dateRange = getDateRangeForPeriod(period);
    const channelIds = yield select(selectLeaderboardFilterChannelIds);
    const type = yield select(selectLeaderboardCurrentType);
    const leaderboard: Leaderboard = yield call(
      getLeaderboard,
      currentApp.slackbotId,
      dateRange?.startDate,
      dateRange?.endDate,
      channelIds,
      type,
      action?.payload?.first,
    );
    yield put(actions.setLeaderboardItems(leaderboard.items));
    yield put(actions.setLeaderboardHasData(leaderboard.hasData));
  } catch (e) {
    console.error(`An unexpected error occurred: ${e}`);
  } finally {
    yield put(actions.setLeaderboardLoading(false));
  }
}

function* doLoadChannels() {
  const currentApp: LeaderboardApp = yield select(selectLeaderboardCurrentApp);
  if (!currentApp?.slackbotId) {
    yield put(appActions.setError('Slackbot id not found.'));
    return;
  }
  try {
    yield put(actions.setLeaderboardChannelsLoading(true));
    yield put(actions.setLeaderboardChannels([]));
    yield put(actions.setLeaderboardFilterChannels([]));
    const leaderboardChannels = yield call(
      getLeaderboardChannels,
      currentApp.slackbotId,
    );
    const channels = leaderboardChannels.items;
    channels.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    yield put(actions.setLeaderboardChannels(channels));
    yield put(actions.setLeaderboardFilterChannels(channels.map(_ => _.id)));
  } catch (e) {
    console.error(`An unexpected error occurred: ${e}`);
    yield put(actions.setLeaderboardChannels([]));
    yield put(appActions.setError('Failed to load channels.'));
  } finally {
    yield put(actions.setLeaderboardChannelsLoading(false));
  }
}

function* doLoadShoutouts(action) {
  const senderId = action.payload.senderId;
  console.log('senderId: ', senderId);
  const recipientId = action.payload.recipientId;
  console.log('recipientId: ', recipientId);
  yield put(actions.setLeaderboardLoading(true));
  yield put(actions.setShoutouts([]));
  try {
    let currentApp: LeaderboardApp = yield select(selectLeaderboardCurrentApp);
    if (!currentApp) {
      throw new Error('No app selected.');
    }
    if (!currentApp.slackbotId) {
      //not installed
      return;
    }
    const shoutouts: Shoutout[] = yield call(getShoutouts, {
      slackbotId: currentApp.slackbotId,
      senderId,
      recipientId,
    });
    yield put(actions.setShoutouts(shoutouts));
  } catch (e) {
    console.error(`An unexpected error occurred: ${e}`);
  } finally {
    yield put(actions.setLeaderboardLoading(false));
  }
}

export function* leaderboardSaga() {
  yield takeLatest(actions.load.type, doLoad);
  yield takeLatest(actions.refresh.type, doRefresh);
  yield takeLatest(actions.loadChannels.type, doLoadChannels);
  yield takeLatest(actions.loadShoutouts.type, doLoadShoutouts);
}
