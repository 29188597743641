export const DARK_BLUE = '#151062';
export const MID_BLUE = '#0060BF';
export const LIGHT_BLUE = '#36A9E0';
export const COFFEE = '#4D4D4D';
export const AMBER = '#F9B233';
export const RED = '#FE5F42';
export const GREY = '#C6C6C6';
export const BLACK_0_25 = 'rgba(0, 0, 0, 0.25)';
export const BLACK_0_6 = 'rgba(0, 0, 0, 0.6)';
export const BLACK_0_9 = 'rgba(0, 0, 0, 0.9)';
export const GREY_300 = 'rgba(208, 213, 221, 1)';

export const COLORS = [
  DARK_BLUE,
  MID_BLUE,
  LIGHT_BLUE,
  COFFEE,
  AMBER,
  RED,
  GREY,
];
