import { createSelector } from '@reduxjs/toolkit';
import { getCompanyName } from 'app/library/UserAPI';
import { RootState } from 'types';
import { initialState } from '.';
import { SubscriptionState, SubscriptionTier } from 'app/library/BillingAPI';
import { SlackbotType } from 'app/pages/InstallationChannelSelector/slice/type';

const activeSubscriptionStates = [
  SubscriptionState.Active,
  SubscriptionState.AwaitingSignup,
  SubscriptionState.OnHold,
  SubscriptionState.SoftFailure,
  SubscriptionState.Trialing,
];

const selectSlice = (state: RootState) => state.app || initialState;

export const selectLoading = createSelector(
  [selectSlice],
  state => state.loading,
);
export const selectInitialLoading = createSelector(
  [selectSlice],
  state => state.initialLoading,
);

export const selectError = createSelector([selectSlice], state => state.error);

export const selectSuccess = createSelector(
  [selectSlice],
  state => state.success,
);

/**
 * @deprecated use selectCurrentUser instead
 * used for legacy code, public profile, old onboarding, anything else should be migrated to currentUser when possible
 */
export const selectUser = createSelector([selectSlice], state => state.user);

/**
 *
 * Slimmed down version of user. Should not load anything that's app specific (locations, shoutouts, interests, dates, etc.)
 */
export const selectCurrentUser = createSelector(
  [selectSlice],
  state => state.currentUser,
);

export const selectSlackWorkspace = createSelector(
  [selectSlice],
  state => state.slackWorkspace,
);

export const selectShoutoutsSlackbot = createSelector([selectSlice], state =>
  state.slackWorkspace?.slackbots.find(_ => _.type === SlackbotType.SHOUTOUT),
);

export const selectContainerType = createSelector(
  [selectSlice],
  state => state.containerInfo?.containerType,
);

export const selectContainerParameters = createSelector(
  [selectSlice],
  state => state.containerInfo?.containerParameters,
);

export const selectCompanyName = createSelector([selectSlice], state =>
  getCompanyName(state.user),
);

export const selectCompanyImage = createSelector(
  [selectSlice],
  state => state.user?.company?.image,
);

export const selectAuthenticated = createSelector(
  [selectSlice],
  state => state.authenticated,
);

export const selectAuthorized = createSelector(
  [selectSlice],
  state => state.authorized,
);

export const selectShowLeftNav = createSelector(
  [selectSlice],
  state => state.showLeftNav,
);

export const selectAllSubscriptions = createSelector(
  [selectSlice],
  state => state.subscriptions,
);

export const selectSubscriptions = createSelector([selectSlice], state => {
  const activeSubscriptions = state.subscriptions?.filter(item =>
    activeSubscriptionStates.includes(item.state),
  );
  return activeSubscriptions;
});

export const selectActiveSubscription = createSelector([selectSlice], state =>
  state.subscriptions?.find(item => item.state === 'ACTIVE'),
);

export const selectAllChannels = createSelector(
  [selectSlice],
  state => state.channels,
);

export const selectAllPublicChannels = createSelector([selectSlice], state =>
  state.channels.filter(item => !item.isPrivate),
);

export const selectAllSlackUsers = createSelector(
  [selectSlice],
  state => state.slackUsers,
);

export const selectChannelsGroupFilters = createSelector(
  [selectSlice],
  state => state.channelsGroupFilters,
);

export const selectCompanyValues = createSelector(
  [selectSlice],
  state => state.companyValues,
);

export const selectTrialEndingIn = createSelector([selectSlice], state => {
  const activeSubscriptions = state.subscriptions?.filter(item =>
    activeSubscriptionStates.includes(item.state),
  );
  if (
    activeSubscriptions &&
    activeSubscriptions[activeSubscriptions?.length - 1]?.trialEndedAt
  ) {
    const trailEndingAt = new Date(
      activeSubscriptions[activeSubscriptions?.length - 1]?.trialEndedAt,
    ).getTime();
    const d = new Date();
    let timezoneDiff = d.getTimezoneOffset() * 60000;
    const now = new Date().getTime();
    return Math.floor(
      Math.abs(trailEndingAt + timezoneDiff - now) / (1000 * 60 * 60 * 24),
    );
  }

  return null;
});

export const selectCurrentSubscriptionPlan = createSelector(
  [selectSlice],
  state => {
    const activeSubscriptions = state.subscriptions?.filter(item =>
      activeSubscriptionStates.includes(item.state),
    );
    return activeSubscriptions && activeSubscriptions.length
      ? activeSubscriptions[0].tier
      : undefined;
  },
);

export const selectIfSubscribedToPaidPlan = createSelector(
  [selectSlice],
  state => {
    const activeSubscriptions = state.subscriptions?.filter(item =>
      activeSubscriptionStates.includes(item.state),
    );
    return activeSubscriptions && activeSubscriptions.length
      ? [SubscriptionTier.BUSINESS, SubscriptionTier.FIRST_CLASS].includes(
          activeSubscriptions[activeSubscriptions.length - 1].tier,
        )
      : false;
  },
);

export const selectPaymentProfiles = createSelector([selectSlice], state => {
  return state.slackWorkspace?.listPaymentProfiles?.items || [];
});
