import styled from 'styled-components';
import React from 'react';
import ClearWhiteIcon from '../../Icons/ClearWhiteIcon';
import PaidBadge from '../../PaidBadge';
import ArrowRight from 'app/components/Icons/ArrowRight';
import ChevronRightIcon from 'app/components/Icons/ChevronRightIcon';

const Item = styled.div`
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
`;

const ItemTitle = styled.span<{}>`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
`;

const ItemDescription = styled.span<{}>`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const ButtonElement = styled.button<{}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  color: rgba(0, 0, 0, 0.9);
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.9);
  cursor: pointer;
  white-space: nowrap;
  background: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-top: 1rem;
`;

interface Props {
  title: string;
  description: string;
  buttonText: string;
  onButtonClick: () => void;
  withPaidBadge?: boolean;
}

export const CustomizationMenuItem = ({
  title,
  description,
  buttonText,
  onButtonClick,
  withPaidBadge,
}: Props) => {
  return (
    <Item>
      <ItemTitle>
        {title} <PaidBadge disabled={!withPaidBadge} />
      </ItemTitle>
      <ItemDescription>{description}</ItemDescription>
      <ButtonElement onClick={onButtonClick}>
        {buttonText}
        <ChevronRightIcon />
      </ButtonElement>
    </Item>
  );
};
