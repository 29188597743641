/**
 *
 * Modal
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { CSSTransition } from 'react-transition-group';
import './styles.css';

interface Props {
  show?: boolean;
  maxWidth?: string;
  onClose: any;
  padding?: string;
  marginTop?: string;
  ignoreMobile?: boolean;
  children: any;
  overflow?: string;
  overflowY?: string;
}

interface DivProps {
  show?: boolean;
}

interface WrapperProps {
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
  marginTop?: string;
  ignoreMobile: boolean;
  overflow?: string;
  overflowY?: string;
}

const Div = styled.div<DivProps>`
  display: ${props => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 200;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.75);
  overflow: auto;
`;

const BlurryLayer = styled.div`
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 199;
`;

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  overflow: ${props => (!!props.overflow ? props.overflow : 'hidden')};
  overflow-y: ${props => (!!props.overflowY ? props.overflowY : 'auto')};
  max-width: ${props => props.maxWidth};
  margin: auto;
  margin-top: ${props => (!!props.marginTop ? props.marginTop : '10%')};

  @media (max-width: ${props => (props.ignoreMobile ? '0' : '768')}px) {
    border-radius: 0;
    margin-top: 0;
    max-width: none;
    height: 100%;
    width: 100%;
  }
  overflow: hidden;
`;

export const Modal = memo(
  ({
    show,
    maxWidth,
    marginTop,
    ignoreMobile,
    children,
    overflow,
    overflowY,
  }: Props) => {
    const nodeRef = React.useRef(null);

    return (
      <>
        {show ? <BlurryLayer /> : null}
        <Div show={show}>
          <CSSTransition
            in={show}
            timeout={500}
            classNames="modal"
            nodeRef={nodeRef}
          >
            <Wrapper
              maxWidth={maxWidth}
              marginTop={marginTop}
              ignoreMobile={ignoreMobile ? ignoreMobile : false}
              overflow={overflow}
              overflowY={overflowY}
            >
              {children}
            </Wrapper>
          </CSSTransition>
        </Div>
      </>
    );
  },
) as any;

Modal.defaultProps = {
  show: false,
  maxWidth: '640px',
};

export * from './ModalHeader';
export * from './ModalContentWrapper';
