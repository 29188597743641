import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import 'react-sliding-side-panel/lib/index.css';
import styled from 'styled-components';
import SlidingPanel from 'react-sliding-side-panel';
import history from 'app/library/History';
import 'app/components/Panel/styles.css';
import { ViewShoutoutsPanel } from './ViewShoutoutsPanel';
import { AdminSidePanelState } from '../AdminHomePage/slice/type';
import {
  CelebrationsCustomizationPanel,
  ShoutoutsCustomizationPanel,
} from 'app/components/AppCustomizationsPanel';

type ParamTypes = {
  state: string;
};

const PANEL_COMPONENTS = {
  [AdminSidePanelState.VIEW_SHOUTOUTS_GIVEN]: ViewShoutoutsPanel,
  [AdminSidePanelState.VIEW_SHOUTOUTS_RECEIVED]: ViewShoutoutsPanel,
  [AdminSidePanelState.CUSTOMIZE_SHOUTOUTS]: ShoutoutsCustomizationPanel,
  [AdminSidePanelState.CUSTOMIZE_CELEBRATIONS]: CelebrationsCustomizationPanel,
};

const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 0.25em;
`;

export function AdminSidePanels() {
  const location = useLocation();
  const [state, setState] = useState<AdminSidePanelState | undefined>();
  const { state: currentState } = Object.fromEntries(
    new URLSearchParams(location.search),
  );

  useEffect(() => {
    setState(currentState as AdminSidePanelState);
  }, [currentState, location]);

  const reset = () => {
    const currentParams = Object.fromEntries(
      new URLSearchParams(location.search),
    );
    const updatedParams = {};
    history.push({
      search: `?${new URLSearchParams(updatedParams).toString()}`,
    });
  };

  const renderPanels = () => {
    return Object.entries(PANEL_COMPONENTS).map(([key, Component]) => {
      return (
        <SlidingPanel
          key={key}
          type={'right'}
          isOpen={state === key}
          size={window.innerWidth > 768 ? (520 / window.innerWidth) * 100 : 100}
          noBackdrop
          panelContainerClassName="panel-shadow"
        >
          <Component reset={reset} isOpen={state === key} state={state} />
        </SlidingPanel>
      );
    });
  };

  return <>{renderPanels()}</>;
}
