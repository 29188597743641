import OpenIcon from 'app/assets/open_new.svg';
import {
  CreateSubscriptionInput,
  SubscriptionBillingCycle,
  SubscriptionTier,
  SubscriptionType,
  createSubscription, SubscriptionState,
} from 'app/library/BillingAPI';
import {
  selectAllSubscriptions,
  selectSlackWorkspace,
} from 'app/slice/selectors';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button } from './Button';
import { StepIndicator } from './StepIndicator';
import {
  BodySmallNormal,
  DisplayLargeBold,
  DisplayXLarge,
  Headline1,
  Headline1Normal,
  Headline3,
} from './Text';

const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  padding: 10px;
  background-color: rgba(0, 96, 191, 1);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const RowTopAligned = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  line-height: 0.5;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 14rem;
  gap: 1.5rem;
  position: relative;
  @media (max-width: 480px) {
    padding: 3rem 0;
  }
`;

const Number = styled.span`
  font-size: 28px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
`;

const Div = styled.div``;

const Tips = styled.div`
  margin-top: 2rem;
`;

const ButtonContainer = styled.div`
  text-align: center;
  width: 100%;

  margin-bottom: 0.5em;
`;

const SkipText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OpenLinkImg = styled.img``;

const Footer = styled.div`
  background-color: rgba(54, 169, 224, 1);
  width: 100%;
  padding: 1.5rem;
  text-align: justify;
  margin-top: 1rem;
  position: absolute;
  bottom: 0;
`;

type Props = {
  appName: string;
  openSlackApp: () => void;
  openWeb: () => void;
  tips?: Array<any>;
  stepCount: number;
  currentStepCount: number;
  title: string;
  description: any;
  cta?: Array<any>;
};

export const GoToSlack = ({
  appName,
  openSlackApp,
  openWeb,
  tips,
  stepCount,
  currentStepCount,
  title,
  description,
  cta,
}: Props) => {
  const subscriptions = useSelector(selectAllSubscriptions);

  const slackWorkspace = useSelector(selectSlackWorkspace);

  useEffect(() => {
    if (slackWorkspace?.id && subscriptions && subscriptions.length === 0) {
      createFreeTrialSubscription();
    }
  }, [slackWorkspace, subscriptions]);

  const createFreeTrialSubscription = async () => {
    try {
      const createSubscriptionInput: CreateSubscriptionInput = {
        slackWorkspaceId: slackWorkspace?.id!,
        tier: SubscriptionTier.FREE_TRIAL,
        type: SubscriptionType.WORKSPACE,
        billingCycle: SubscriptionBillingCycle.MONTHLY,
      };
      await createSubscription(createSubscriptionInput);
    } catch (err: any) {
      // error case
    }
  };

  const FIVE_MINUTES = 5 * 60 * 1000;
  // sc-9755: backend would create a new free-trial during the oauth process
  const hasNewFreeTrialSubscription =
      subscriptions?.find(_ => _.tier === SubscriptionTier.FREE_TRIAL &&
          (new Date().getTime() - subscriptions[0].createdAt <= FIVE_MINUTES));

  // if backend didn't create one, unicorn would call the API to create one here.
  const hasNoSubscription = subscriptions && subscriptions.length === 0
  return (
    <Container>
      {(hasNewFreeTrialSubscription || hasNoSubscription) && (
        <Header>
          <Row>
            <DisplayXLarge>⏳</DisplayXLarge>
            <Headline3 color="white">
              Your 30-day free trial of First Class has started.
            </Headline3>
          </Row>
        </Header>
      )}
      <Content>
        <StepIndicator stepCount={stepCount} currentStep={currentStepCount} />
        <Div style={{ textAlign: 'center' }}>
          <DisplayLargeBold>{title}</DisplayLargeBold>
          <br />
          <Headline1Normal color="rgba(0, 0, 0, 0.6)">
            {description}
          </Headline1Normal>
        </Div>
        {tips && tips.length > 0 && (
          <Div style={{ textAlign: 'justify' }}>
            <Headline1 color="rgba(0, 0, 0, 0.6)" lineHeight={2}>
              Quick tips:
            </Headline1>
            {tips.map((tip, index) => (
              <RowTopAligned>
                <Number>{index + 1}. </Number>
                {tip}
              </RowTopAligned>
            ))}
          </Div>
        )}

        <Bottom>
          {!!cta && cta.map(item => item)}
          {!cta && (
            <ButtonContainer>
              <Button onClick={openSlackApp}>Go to Slack</Button>
            </ButtonContainer>
          )}
          <Row style={{ cursor: 'pointer' }}>
            <SkipText onClick={openWeb}>Install other Airspeed apps</SkipText>
            <OpenLinkImg src={OpenIcon} />
          </Row>
        </Bottom>
      </Content>
      <Footer>
        <BodySmallNormal color="white">
          © {new Date().getFullYear()} Airspeed, Inc. All rights reserved
        </BodySmallNormal>
        <br />
        <BodySmallNormal color="white">
          We have updated our{' '}
          <a
            target="_blank"
            style={{
              cursor: 'pointer',
              color: 'white',
              textDecoration: 'underline',
            }}
            href="https://www.getairspeed.com/terms/"
          >
            Terms of Service
          </a>
          . By continuing to use Airspeed's apps, you’re agreeing to the updated
          Terms of Service.
        </BodySmallNormal>
      </Footer>
    </Container>
  );
};
