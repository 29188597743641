/**
 *
 * RoundedDiv
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';

interface Props {
  children?: any;
  margin?: string;
  color?: string;
  noShadow?: boolean;
}

export const RoundedDiv = React.memo(
  ({ children, margin, color, noShadow }: Props) => {
    return (
      <Div margin={margin} color={color} noShadow={noShadow}>
        {children}
      </Div>
    );
  },
);

const Div = styled.div<Props>`
  background: ${props => (!!props.color ? props.color : '#ffffff')};
  box-shadow: ${props =>
    !props.noShadow ? '0px -1px 24px rgba(0, 0, 0, 0.25)' : 'none'};
  border-radius: 20px;
  margin-top: ${props => (!!props.margin ? props.margin : '0')};

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;
