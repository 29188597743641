import React from 'react';
import styled from 'styled-components';

interface Props {
  loading: boolean;
}

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 100px;
  align-items: center;
  justify-content: center;
`;

export function Loader({ loading }: Props) {
  if (!loading) {
    return <></>;
  }
  return (
    <Container>
      <div className="dot-collision"></div>
    </Container>
  );
}
