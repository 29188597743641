const TWITTER_URL_REGEX = /^(http(s)?:\/\/twitter.com\/)/gim;
const INSTAGRAM_URL_REGEX =
  /^(http(s)?:\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\//gim;
const LINKEDIN_URL_REGEX =
  /^(http(s)?:\/\/)([\w]+\.)?linkedin\.com\/(pub|in|profile)\//gim;
const FACEBOOK_URL_REGEX = /^(http(s)?:\/\/)?(?:www.)?facebook.com\//gim;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ZIP_CODE_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/;

export const getProfileFromURL = (url: string, name: string): string => {
  const regex = {
    instagram: INSTAGRAM_URL_REGEX,
    linkedin: LINKEDIN_URL_REGEX,
    twitter: TWITTER_URL_REGEX,
    facebook: FACEBOOK_URL_REGEX,
  };
  if (!!regex[name]) {
    return url.replace(regex[name], '');
  }
  return url;
};

export function isValidEmail(email: string): boolean {
  // Regular expression for a basic email validation
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}
