import {
  SubscriptionType,
  getChargifySecurityToken,
  getSubscriptionsInvoice,
  previewSubscription, SubscriptionPlan, SubscriptionTier,
} from 'app/library/BillingAPI';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Channel } from "../../../../library/SlackAPI";
import { billingActions as actions } from './index';
import { Authenticator } from 'app/library/Authenticator';
import { getCurrentSlackIdentity } from 'utils/user-workspaces';
import { getUser } from 'app/library/UserAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  selectSubscriptionPreview,
  selectSubscriptionPreviewInput,
} from './selectors';
import { selectSlackWorkspace } from 'app/slice/selectors';
import { SlackChannel } from 'types/SlackChannel';
import { SubscriptionPreview } from "./type";

function* doRefreshChargifySecurityToken() {
  try {
    const user = yield call(getUser);
    const slackIdentity = getCurrentSlackIdentity(user);
    if (!!slackIdentity) {
      const chargifySecurityToken = yield call(
        getChargifySecurityToken,
        slackIdentity.slackWorkspaceId,
      );
      yield put(actions.setChargifySecurityToken(chargifySecurityToken));
    }
  } catch (err) {
    console.error(err);
  }
}

function* doLoadInvoices() {
  yield put(actions.setIsLoading(true));
  if (Authenticator.isGoodToken()) {
    try {
      const user = yield call(getUser);
      const slackIdentity = getCurrentSlackIdentity(user);
      if (!!slackIdentity) {
        const invoices = yield call(
          getSubscriptionsInvoice,
          slackIdentity.slackWorkspaceId,
        );
        yield put(actions.setSubscriptionInvoices(invoices));
      }
    } catch (err) {
      console.error(JSON.stringify(err));
    }
  } else {
  }
  yield put(actions.setIsLoading(false));
}

function* doSetSubscriptionType(action: PayloadAction<SubscriptionType>) {
  yield put(actions.updateSubscriptionType(action.payload));
  const preview = yield select(selectSubscriptionPreview);
  // If there is already preview available for channel type subscription
  // And then user switch to workspace type then fetch the subscription preview again
  if (
    preview &&
    preview.type === SubscriptionType.CHANNEL &&
    action.payload === SubscriptionType.WORKSPACE
  ) {
    const workspace = yield select(selectSlackWorkspace);
    const subscriptionPreviewInput = yield select(
      selectSubscriptionPreviewInput,
    );
    if (subscriptionPreviewInput) {
      subscriptionPreviewInput.slackWorkspaceId = workspace.id;
      subscriptionPreviewInput.type = SubscriptionType.WORKSPACE;
      subscriptionPreviewInput.channels = [];
      const subscriptionPreview = yield call(
        previewSubscription,
        subscriptionPreviewInput,
      );
      yield put(actions.setSubscriptionPreview(subscriptionPreview));
    }
  }

  // if (
  //   preview &&
  //   preview.type === SubscriptionType.WORKSPACE &&
  //   action.payload === SubscriptionType.CHANNEL
  // ) {
  //   const workspace = yield select(selectSlackWorkspace);
  //   const subscriptionPreviewInput = yield select(
  //     selectSubscriptionPreviewInput,
  //   );
  //   if (subscriptionPreviewInput) {
  //     subscriptionPreviewInput.slackWorkspaceId = workspace.id;
  //     subscriptionPreviewInput.type = SubscriptionType.CHANNEL;
  //     subscriptionPreviewInput.channels = [];
  //     const subscriptionPreview = yield call(
  //       previewSubscription,
  //       subscriptionPreviewInput,
  //     );
  //     yield put(actions.setSubscriptionPreview(subscriptionPreview));
  //   }
  // }
}

function* doSetSelectedChannelsForSubscription(
  action: PayloadAction<Array<SlackChannel>>,
) {
  yield put(actions.updateSelectedChannelsForSubscription(action.payload));
  const preview: SubscriptionPreview = yield select(selectSubscriptionPreview);
  // If there is already preview available for channel type subscription
  // And then user switch to workspace type then fetch the subscription preview again
  if (preview) {
    const workspace = yield select(selectSlackWorkspace);
    const subscriptionPreviewInput = yield select(
      selectSubscriptionPreviewInput,
    );
    if (subscriptionPreviewInput && action.payload.length > 0) {
      subscriptionPreviewInput.slackWorkspaceId = workspace.id;
      subscriptionPreviewInput.type = SubscriptionType.CHANNEL;
      subscriptionPreviewInput.channels = action.payload.map(item => item.id);
      const subscriptionPreview = yield call(
        previewSubscription,
        subscriptionPreviewInput,
      );
      yield put(actions.setSubscriptionPreview(subscriptionPreview));
    } else if (subscriptionPreviewInput) {
      // because the pegasus API won't accept the empty channel selection.
      // we have to create a fake zero channel subscriptionPreview here.
      const subscriptionPreview: SubscriptionPreview = {
        ...preview,
        seats: 0,
        subtotalInCents: 0,
        totalDiscountInCents: 0,
        totalInCents: 0,
        totalTaxInCents: 0,
        channels: [],
        appliedDiscounts: [],
      }
      yield put(actions.setSubscriptionPreview(subscriptionPreview));
    }
  }
}
export function* billingPageSaga() {
  yield takeLatest(
    actions.refreshChargifySecurityToken.type,
    doRefreshChargifySecurityToken,
  );
  yield takeLatest(actions.loadInvoices.type, doLoadInvoices);
  yield takeLatest(actions.setSubscriptionType.type, doSetSubscriptionType);
  yield takeLatest(
    actions.setSelectedChannelsForSubscription.type,
    doSetSelectedChannelsForSubscription,
  );
}
