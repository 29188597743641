import { Modal, ModalContentWrapper, ModalHeader } from 'app/components/Modal';
import { HeadingLarge } from 'app/components/Text';
import { AuthType, Authenticator } from 'app/library/Authenticator';
import React from 'react';
import styled from 'styled-components';
import SlackLogo from 'app/assets/slack.svg';

interface Props {
  isOpen: boolean;
  authType: AuthType;
  bannerSrc?: string;
  bannerAlt?: string;
  text?: string;
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  width: 100%;
`;

const Button = styled.button`
  padding: 0.5em 1em;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  align-items: center;
  color: #000;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-flex;
  font-family: Lato, sans-serif;
  font-size: 15px;
  font-weight: 600;
  height: 36px;
  justify-content: center;
  text-decoration: none;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const BannerImg = styled.img`
  width: 95%;
  filter: drop-shadow(0px 0px 3.14667px rgba(0, 0, 0, 0.15));
  border-radius: 9.44px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const SlackImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export function LoginSlackModal({
  isOpen,
  authType,
  bannerSrc,
  bannerAlt,
  text,
}: Props) {
  const handleSlackLogin = () => {
    Authenticator.authorize({
      connection: authType,
    });
  };

  return (
    <Modal show={isOpen} maxWidth="700px" marginTop="5%">
      <ModalHeader />
      <ModalContentWrapper>
        <Container>
          {bannerSrc && (
            <ImageContainer>
              <BannerImg src={bannerSrc} alt={bannerAlt} />
            </ImageContainer>
          )}
          {!!text && <HeadingLarge textAlign={'center'}>{text}</HeadingLarge>}
          <ButtonContainer>
            <Button onClick={handleSlackLogin}>
              <SlackImg src={SlackLogo} alt={'slack logo'} />
              Sign in with Slack
            </Button>
          </ButtonContainer>
        </Container>
      </ModalContentWrapper>
    </Modal>
  );
}
