import { Button } from 'app/components/Button';
import { searchSlackWorkspaces } from 'app/library/SlackAPI';
import { SlackbotType } from 'app/pages/InstallationChannelSelector/slice/type';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import { SlackWorkspace, Slackbot } from 'types/Slack';
import { channelSelectorBaseStyles } from 'utils/channelSelectorBaseStyle';
import { Modal, ModalHeader } from '../../../../components/Modal';
import { debounce } from 'lodash';
import { selectIsLoading } from '../../Billing/slice/selectors';
import { setSuperUserSlackIdentity } from 'utils/user-workspaces';
import { SlackIdentity } from 'types/User';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const BodyContainer = styled.div`
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 2em;
  gap: 1em;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

export const SuperUserWorkspaceSelector = ({ onClose, isOpen }: Props) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [workspaces, setWorkspaces] = useState<SlackWorkspace[]>([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState<
    SlackWorkspace | undefined
  >(undefined);
  const isLoading = useSelector(selectIsLoading);

  const onConfirm = () => {
    if (!selectedWorkspace) {
      return;
    }
    const identity: SlackIdentity = {
      teamId: selectedWorkspace.slackId,
      workspaceName: selectedWorkspace.name,
      slackWorkspaceId: selectedWorkspace.id,
      //placeholders
      type: SlackbotType.SHOUTOUT,
      slackUserId: '',
      username: '',
    };
    setSuperUserSlackIdentity(identity);
    onClose();
    window.location.reload();
  };

  const onSearchWorkspaces = async (search: string) => {
    if (search.length < 3) {
      return;
    }
    try {
      setShowLoader(true);
      const retWorkspaces = await searchSlackWorkspaces(search);
      //   .filter(item => item.checked)
      setWorkspaces(retWorkspaces);
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
    }
  };
  const debouncedSearch = debounce(onSearchWorkspaces, 1000);

  const getOptions = () => {
    return workspaces.map(item => ({
      value: item.id,
      label: item.name,
    }));
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal show={true} maxWidth="640px" marginTop="5%">
      <ModalHeader
        title={'Change workspace'}
        onClose={() => {
          onClose();
        }}
      />
      <BodyContainer>
        <div>
          <Select
            isMulti={false}
            isSearchable
            options={getOptions()}
            onInputChange={input => {
              debouncedSearch(input);
            }}
            placeholder="Select Slack Workspace"
            onChange={item => {
              setSelectedWorkspace(workspaces.find(_ => item.value == _.id));
            }}
            styles={channelSelectorBaseStyles}
            isLoading={isLoading}
            noOptionsMessage={() => {
              return 'No workspace found';
            }}
            menuShouldScrollIntoView={false}
          />
        </div>
        <div>
          <ButtonsContainer>
            <Button
              onClick={() => {
                onClose();
              }}
              color={'white'}
            >
              Cancel
            </Button>
            <Button disabled={!selectedWorkspace} onClick={onConfirm}>
              Confirm
            </Button>
          </ButtonsContainer>
        </div>
        {showLoader && (
          <LoadingContainer>
            <div className="dot-collision"></div>
          </LoadingContainer>
        )}
      </BodyContainer>
    </Modal>
  );
};
