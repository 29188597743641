import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.profileQuestionModal || initialState;

export const selectExistingHighlights = createSelector(
  [selectSlice],
  state => state.existingHighlights,
);

export const selectShow = createSelector([selectSlice], state => state.show);

export const selectCurrentQuestion = createSelector(
  [selectSlice],
  state => state.currentQuestion,
);

export const selectAnswer = createSelector(
  [selectSlice],
  state => state.answer,
);

export const selectElaborate = createSelector(
  [selectSlice],
  state => state.elaborate,
);

export const selectHighlightEdit = createSelector(
  [selectSlice],
  state => state.highlightEdit,
);

export const selectIsPost = createSelector(
  [selectSlice],
  state => state.isPost,
);

export const selectError = createSelector([selectSlice], state => state.error);
