import { Apollo } from 'app/library/Apollo';
import { UserLocation } from 'types/User';

export interface GQLQuery {
  query: string;
  variables?: any;
}
export interface MapItemUser {
  id: string;
  firstName: string;
  lastName: string;
  image?: string;
  title?: string;
  locations?: UserLocation[];
}

export interface MapItem {
  user: MapItemUser;
  location: UserLocation;
}

export function getUsersLocations(companyId): Promise<MapItem[]> {
  const data = {
    query: `
      query listUsersGeolocation($input: NearByUsersInput!) {
        nearByUsers(input: $input) {
          items {
            id
            firstName
            lastName
            title
            image {
              url
            }
            locations {
                id
                type
                geoLocation
                name
                startDate
                endDate
                primaryCompanyId
                updateTs
                createTs
            }
            integrations {
              slack {
                integrationId
              }
            }
          }
          first
          offset
          nextToken
          totalCount
        }
      }
    `,
    variables: {
      input: {
        //a company w/ over 1MM user_company records might have users left out of the map
        first: 1000000,
        offset: 0,
        companyId,
        distanceInMeter: 100000000, //100,000km hack to include everyone
      },
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.nearByUsers.items)
    .then(items => items.flatMap(item => transformMapItem(item, companyId)))
    .catch(err => Apollo.handleError(err, data));
}

function transformMapItem(item: any, companyId: string): MapItem[] {
  /*
    We're only interested in the user's current home office location for now
  */
  const companyLocations = (item.locations ?? []).filter(
    location => location.primaryCompanyId === companyId,
  );
  const home_current = companyLocations.find(
    location => location.type == 'HOME_CURRENT',
  );
  const travels = companyLocations
    .filter(location => location.type == 'TRAVEL')
    .filter(location => !!location.startDate && !!location.endDate)
    .filter(location => new Date(location.endDate!) > new Date())
    .sort(
      (a, b) =>
        new Date(a.startDate!).getTime() - new Date(b.startDate!).getTime(),
    );
  const locations = !!home_current ? [home_current, ...travels] : [...travels];
  return locations.map(location => {
    return {
      user: {
        id: item.id || '',
        firstName: item.firstName || '',
        lastName: item.lastName || '',
        title: item.title || undefined,
        image: item.image?.url,
        locations,
      },
      location: {
        ...location,
      },
    };
  });
}
