import { MAX_SELECTED_USERS } from 'app/components/UsersListPanel';
import { getUsersLocations, MapItem } from 'app/library/LocationAPI';
import {
  goToMapUserEditableProfile,
  goToSlackConversation,
} from 'app/library/NavHelper';
import {
  getConversationURL,
  removeAndAddLocation,
  removeLocations,
  updateLocation,
} from 'app/library/UserAPI';
import { appActions } from 'app/slice';
import { selectCurrentUser, selectUser } from 'app/slice/selectors';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { User } from 'types/User';
import { mapPageActions as actions } from '.';
import { demoMapItems, DEMO_WORKSPACE_ID } from '../components/Map/demo';
import { selectIsDemo } from './selectors';
import { AmplitudeClient } from 'app/library/Analytics';
import { SlackbotType } from 'app/pages/InstallationChannelSelector/slice/type';
import { getMapsWorkspace } from 'utils/user-workspaces';

function* doLoad() {
  yield put(appActions.setLoading(true));
  try {
    const isDemo = yield select(selectIsDemo);
    if (!!isDemo) {
      yield put(actions.setMapItems(demoMapItems));
      yield put(appActions.setLoading(false));
      return;
    }

    const user: User = yield select(selectCurrentUser);
    const mapsWorkspace = getMapsWorkspace(user);
    if (!mapsWorkspace) {
      yield put(appActions.setError('Workspace not found. Sign in required.'));
      return;
    }

    const analyticsClient = AmplitudeClient.getInstance();
    const mapsIdentity = user.slackIdentities.find(
      _ => _.type === SlackbotType.MAPS,
    );
    if (!!mapsIdentity) {
      analyticsClient.setUser(mapsIdentity.slackUserId);
      analyticsClient.setCompany(mapsIdentity.teamId);
    }

    const mapItems: MapItem[] = yield call(getUsersLocations, mapsWorkspace.id);
    if (mapItems.length > 0) {
      yield put(actions.setMapItems(mapItems));
    }
  } catch (err) {
    console.error(err);
    yield put(appActions.setError('Error loading locations.'));
  }
  yield put(appActions.setLoading(false));
}

function* doSubmitLocation(action) {
  yield put(appActions.setLoading(true));
  try {
    const user: User = yield select(selectUser);
    const mapsWorkspace = getMapsWorkspace(user);
    if (!mapsWorkspace || action.payload == DEMO_WORKSPACE_ID) {
      return;
    }

    yield call(updateLocation, action.payload, mapsWorkspace.id);
    yield call(goToMapUserEditableProfile, user.id);
    yield put(appActions.setLoading(false));
    const mapItems: MapItem[] = yield call(getUsersLocations, mapsWorkspace.id);
    if (mapItems.length > 0) {
      yield put(actions.setMapItems(mapItems));
    }

    yield put(
      appActions.setSuccess(
        `Your ${
          locationTypeString[action.payload.type]
        } was successfully added.`,
      ),
    );
  } catch (err) {
    yield put(
      appActions.setError(
        `Sorry, an error occurred while saving your ${
          locationTypeString[action.payload.type]
        }.`,
      ),
    );
    console.error(`An unexpected error occured ${JSON.stringify(err)}`);
  }
  yield put(appActions.setLoading(false));
}

function* doMessageInSlack(action) {
  if (action.payload.length > MAX_SELECTED_USERS) {
    yield put(
      appActions.setError(
        `You can't message more than ${MAX_SELECTED_USERS} people at a time.`,
      ),
    );
    return;
  }

  yield put(appActions.setLoading(true));
  try {
    const user: User = yield select(selectUser);
    const mapsWorkspace = getMapsWorkspace(user);
    if (!mapsWorkspace) {
      yield put(
        appActions.setError("Sorry, no current user. Can't message in Slack"),
      );
      return;
    }

    const conversationURL = yield call(
      getConversationURL,
      action.payload,
      mapsWorkspace.id,
    );
    if (!conversationURL) {
      yield put(appActions.setError('Sorry, conversation URL not found.'));
      return;
    }
    yield put(appActions.setLoading(false));
    yield call(goToSlackConversation, conversationURL);
  } catch (err) {
    yield put(
      appActions.setError(
        'Sorry, an error occurred while creating you conversation.',
      ),
    );
    console.error(`An unexpected error occured ${JSON.stringify(err)}`);
  }
  yield put(appActions.setLoading(false));
}

function* doEditLocation(action) {
  yield put(appActions.setLoading(true));
  try {
    const user: User = yield select(selectUser);
    const mapsWorkspace = getMapsWorkspace(user);
    if (!mapsWorkspace) {
      return;
    }

    yield call(removeAndAddLocation, mapsWorkspace.id, action.payload);
    yield call(goToMapUserEditableProfile, user.id);
    yield put(appActions.setLoading(false));
    const mapItems: MapItem[] = yield call(getUsersLocations, mapsWorkspace.id);
    if (mapItems.length > 0) {
      yield put(actions.setMapItems(mapItems));
    }
    yield put(
      appActions.setSuccess(
        `Your ${
          locationTypeString[action.payload.type]
        } was successfully updated.`,
      ),
    );
  } catch (err) {
    yield put(
      appActions.setError(
        `Sorry, an error occurred while updating your ${
          locationTypeString[action.payload.type]
        }.`,
      ),
    );
    console.error(`An unexpected error occured ${JSON.stringify(err)}`);
  }
  yield put(appActions.setLoading(false));
}

function* doDeleteLocation(action) {
  yield put(appActions.setLoading(true));
  try {
    const user: User = yield select(selectUser);
    const mapsWorkspace = getMapsWorkspace(user);
    if (!mapsWorkspace) {
      return;
    }

    yield call(removeLocations, [action.payload]);
    yield call(goToMapUserEditableProfile, user.id);
    yield put(appActions.setLoading(false));

    const mapItems: MapItem[] = yield call(getUsersLocations, mapsWorkspace.id);
    yield put(actions.setMapItems(mapItems));

    yield put(
      appActions.setSuccess(
        `Your ${
          locationTypeString[action.payload.type]
        } was successfully removed.`,
      ),
    );
  } catch (err) {
    yield put(
      appActions.setError(
        `Sorry, an error occurred while removing your ${
          locationTypeString[action.payload.type]
        }.`,
      ),
    );
    console.error(`An unexpected error occured ${JSON.stringify(err)}`);
  }
  yield put(appActions.setLoading(false));
}

const locationTypeString = {
  TRAVEL: 'travel',
  HOME_CURRENT: 'home location',
};

export function* mapPageSaga() {
  yield takeLatest(actions.load.type, doLoad);
  yield takeLatest(actions.submitLocation.type, doSubmitLocation);
  yield takeLatest(actions.messageInSlack.type, doMessageInSlack);
  yield takeLatest(actions.editLocation.type, doEditLocation);
  yield takeLatest(actions.deleteLocation.type, doDeleteLocation);
}
