import { SlackIdentity, User } from '../../types/User';

let amplitude;
if (typeof window === 'undefined') {
} else {
  amplitude = require('@amplitude/analytics-browser');
}

import React from 'react';
import { Location } from 'history';
import { SlackbotType } from '../pages/InstallationChannelSelector/slice/type';

export interface AnalyticsEvent {
  eventType: string;
  properties?: Object;
}

export interface SlackAnalyticsEvent extends AnalyticsEvent {
  user?: User;
  app: SlackbotType;
}

export const AnalyticsAppNames: Record<SlackbotType, string> = {
  [SlackbotType.INTROS]: 'Intros',
  [SlackbotType.BIRTHDAY]: 'Celebrations',
  [SlackbotType.ICE_BREAKERS]: 'Icebreakers',
  [SlackbotType.SHOUTOUT]: 'Shoutouts',
  [SlackbotType.MAPS]: 'Maps',
  [SlackbotType.COFFEE_TALK]: 'Coffee Talk',
};

export interface AnalyticsClient {
  setUser(userId: string | null | undefined);
  setCompany(companyId: string | null | undefined);
  logEvent(event: AnalyticsEvent, location?: Location);

  /**
   * NODE:
   *  1. this method will change UserId from pegasus userId to slackUserId.
   *  2. You only need to call this method on the Page(s). The compnents in
   *  the page will inherit the Slack Identity from the page.
   *
   * @param user
   * @param slackBotType
   */
  setSlackIdentity(user: User | undefined, slackBotType: SlackbotType);
}

export class AmplitudeClient implements AnalyticsClient {
  private slackIdentity: SlackIdentity | undefined;

  setUser(userId: string | null | undefined) {
    if (typeof window === 'undefined') {
    } else {
      amplitude.setUserId(userId);
    }
  }

  setSlackIdentity(user: User | undefined, slackBotType: SlackbotType) {
    if (this.slackIdentity) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('setSlackIdentity should only be called once per page');
      }
    }
    if (user) {
      this.slackIdentity = user.slackIdentities.find(
        _ => _.type === slackBotType,
      );
      if (this.slackIdentity) {
        amplitude.setUserId(this.slackIdentity.slackUserId);
        // const identifyObj = new amplitude.Identify();
        //
        // identifyObj.set({
        //   slackUserId: this.slackIdentity.slackUserId,
        //   slackTeamId: this.slackIdentity.teamId,
        //   workspaceName: this.slackIdentity.workspaceName
        // });
        // amplitude.identify(identifyObj)
      }
    }
  }

  setCompany(companyId: string | null | undefined) {
    const identifyObj = new amplitude.Identify();
    identifyObj.set({ companyId });
    amplitude.identify(identifyObj);
  }

  logEvent(event: AnalyticsEvent, location?: Location) {
    if (event.properties && !event.properties?.hasOwnProperty('path')) {
      event.properties!['path'] = location?.pathname || 'unknown';
    }
    if (this.slackIdentity) {
      if (!event.properties) {
        event.properties = {};
      }
      event.properties['workspaceName'] = this.slackIdentity.workspaceName;
    }

    if (typeof window === 'undefined') {
    } else {
      amplitude.track(event.eventType, event.properties);
    }
  }

  private static instance: AmplitudeClient;

  public static getInstance(): AnalyticsClient {
    if (!AmplitudeClient.instance) {
      AmplitudeClient.instance = new AmplitudeClient();
    }

    return AmplitudeClient.instance;
  }

  private constructor() {
    if (typeof window === 'undefined') {
    } else {
      amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY!);
    }
  }
}

export const AnalyticsClientContext = React.createContext(
  AmplitudeClient.getInstance(),
);

export function checkAndSendChange(
  analyticsClient: AnalyticsClient,
  reactLocation: Location,
  original,
  current,
  fieldName,
) {
  if (original !== current) {
    if (!!current && !!original) {
      analyticsClient.logEvent(
        { eventType: `updated ${fieldName}` },
        reactLocation,
      );
    } else if (!!original) {
      analyticsClient.logEvent(
        { eventType: `removed ${fieldName}` },
        reactLocation,
      );
    } else {
      analyticsClient.logEvent(
        { eventType: `added ${fieldName}` },
        reactLocation,
      );
    }
  }
}
