/**
 *
 * Asynchronously loads the component for InstallationInstructions
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CoffeeTalkMatching = lazyLoad(
  () => import('./index'),
  module => module.CoffeeTalkMatching,
);
