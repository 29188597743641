/**
 *
 * AppContainer
 *
 */
import { ContainerType } from 'app/library/AppConstants';
import React, { memo } from 'react';
import { BubbleContainer } from '../BubbleContainer';
import { MainContainer } from '../MainContainer';
// import CookieConsent from 'react-cookie-consent';
import { Body1Link } from 'app/components/Body1Link';
import styled from 'styled-components/macro';
import { AdminContainer } from '../AdminContainer';

interface Props {
  containerType: string;
  containerParameters: any;
  children: any;
}

interface CookieProps {
  cookieStyle: any;
  declineStyle: any;
}

const cookieStyle = {
  background: '#0060bf',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: '70px',
};

const cookieStyleMobile = {
  background: '#0060bf',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1em',
};

const declineStyle = {
  background: '#ffffff',
  height: '32px',
  boxShadow: '0px -1px 24px rgba(87, 88, 113, 0.13)',
  borderRadius: '50%',
  color: '#0060bf',
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 500,
  width: '32px',
};

const declineStyleMobile = {
  background: '#ffffff',
  height: '32px',
  boxShadow: '0px -1px 24px rgba(87, 88, 113, 0.13)',
  borderRadius: '50%',
  color: '#0060bf',
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 500,
  width: '32px',
};

const CookieNoticeContainer = styled.span`
  display: block;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const CookieNoticeMobileContainer = styled.span`
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

export const AppContainer = memo(
  ({ containerType, containerParameters, children }: Props) => {
    return (
      <>
        {containerType === ContainerType.NONE ? (
          children
        ) : containerType === ContainerType.BUBBLE ? (
          <BubbleContainer {...containerParameters}>{children}</BubbleContainer>
        ) : containerType === ContainerType.MAIN ? (
          <MainContainer {...containerParameters}>{children}</MainContainer>
        ) : containerType === ContainerType.ADMIN ? (
          <AdminContainer {...containerParameters}>{children}</AdminContainer>
        ) : (
          `containerType '${containerType}' is not supported`
        )}
        {/* <CookieNoticeContainer>
          <CookieNotice cookieStyle={cookieStyle} declineStyle={declineStyle} />
        </CookieNoticeContainer>
        <CookieNoticeMobileContainer>
          <CookieNotice
            cookieStyle={cookieStyleMobile}
            declineStyle={declineStyleMobile}
          />
        </CookieNoticeMobileContainer> */}
      </>
    );
  },
);

// const CookieNotice = (props: CookieProps) => {
//   return (
//     <CookieConsent
//       containerClasses="cookie-container"
//       style={props.cookieStyle}
//       contentStyle={{
//         flex: 'inherit',
//         flexGrow: 'inherit',
//         flexShrink: 'inherit',
//         margin: 0,
//         fontSize: '14px',
//         lineHeight: '17px',
//       }}
//       buttonStyle={{
//         background: '#ffffff',
//         height: '50px',
//         boxShadow: '0px -1px 24px rgba(87, 88, 113, 0.13)',
//         borderRadius: '58px',
//         color: '#0060bf',
//         fontSize: '16px',
//         lineHeight: '20px',
//         fontWeight: 500,
//         width: '180px',
//         margin: 0,
//         marginLeft: '15px',
//       }}
//       declineButtonStyle={props.declineStyle}
//       buttonText="Accept Cookies"
//       enableDeclineButton
//       flipButtons={true}
//       declineButtonText="X"
//     >
//       We uses cookies to offer you a better user experience.{' '}
//       <Body1Link showUnderline={true} href="/terms" color="white">
//         Click here to see our terms of service.
//       </Body1Link>
//     </CookieConsent>
//   )
// }