import React from 'react';
import Close from 'app/assets/close-white.svg';
import NotificationIcon from 'app/assets/notification-white.svg';
import SettingsIcon from 'app/assets/settings-white.svg';
import ShareIcon from 'app/assets/share-white.svg';
import styled from 'styled-components';
import { HeadingXLarge } from '../Text';
import { useDispatch } from 'react-redux';
import { useAppSlice } from 'app/slice';
import history from 'app/library/History';

// interface Props {
//   children: any;
//   showSearchBar?: boolean;
//   showProfile?: boolean;
// }
interface LeftNavContainerProps {
  visible: boolean;
}
const LeftNavContainer = styled.div<LeftNavContainerProps>`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(54, 169, 224, 1);
  width: 80%;
  z-index: 100;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
  opacity: ${props => (props.visible ? 1 : 0)};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  transition-delay: 0s;
`;
const Icon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 24px;
  right: 24px;
`;

interface IconContainerProps {
  width?: string;
  height?: string;
}
const IconContainer = styled.img<IconContainerProps>`
  width: ${props => (!!props.width ? props.width : '24px')};
  height: ${props => (!!props.width ? props.width : '24px')};
`;

const Container = styled.div`
  margin-top: 6em;
  padding-left: 1.5em;
`;
const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75em;
`;
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25em;
`;

interface Props {
  visible: boolean;
}

export const LeftNav = ({ visible }: Props) => {
  const dispatch = useDispatch();
  const { actions } = useAppSlice();
  const handleMenuItemClick = (queryString: string) => {
    //dispatch(actions.setShowLeftNav(false))
    history.push({
      search: queryString,
    });
  };
  return (
    <LeftNavContainer visible={visible}>
      <Icon
        src={Close}
        alt="close"
        onClick={() => dispatch(actions.setShowLeftNav(false))}
      />
      <Container>
        <MenuItemContainer>
          <MenuItem onClick={() => handleMenuItemClick('?state=notifications')}>
            <IconContainer src={NotificationIcon} alt="notification" />
            <HeadingXLarge color="white">Notifications</HeadingXLarge>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('?state=settings')}>
            <IconContainer src={SettingsIcon} alt="notification" />
            <HeadingXLarge color="white">Settings</HeadingXLarge>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('?state=share')}>
            <IconContainer src={ShareIcon} alt="notification" />
            <HeadingXLarge color="white">Sharing my profile</HeadingXLarge>
          </MenuItem>
        </MenuItemContainer>
      </Container>
    </LeftNavContainer>
  );
};
