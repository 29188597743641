import ShareIcon from 'app/assets/share.svg';
import history from 'app/library/History';
import React, { memo, useRef } from 'react';
import { Wrapper } from '../PageContainers';
import { ImageContainer } from '../ProfileMenu';

interface Props {}

export const Share = memo((props: Props) => {
  const wrapperRef = useRef(null);
  const handleClick = () => {
    history.push({
      search: `?state=share`,
    });
  };
  return (
    <Wrapper ref={wrapperRef}>
      <ImageContainer
        selected={false}
        src={ShareIcon}
        alt="Profile"
        onClick={handleClick}
        height="32px"
        width="32px"
      />
    </Wrapper>
  );
});
