/// <reference path="types.d.ts" />

import { useState } from 'react';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success
type CopyHtmlFn = (html: string) => Promise<boolean>;

function useCopyToClipboard(): [CopiedValue, CopyFn, CopyHtmlFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async text => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      setCopiedText(null);
      return false;
    }
  };

  const copyHtml: CopyHtmlFn = async html => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    try {
      const blob: Blob = new Blob([html], { type: 'text/html' });
      const data = new ClipboardItem({ 'text/html': blob });
      await navigator.clipboard.write([data]);
      setCopiedText(html);
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy, copyHtml];
}

export default useCopyToClipboard;
