import { PayloadAction } from '@reduxjs/toolkit';
import { HighlightQuestion } from 'types/Highlight';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { shuffleQuestionSaga } from './saga';
import { ShuffleQuestionState } from './types';

export const initialState: ShuffleQuestionState = {
  questionBank: [],
  questionIndex: 0,
  shuffled: false,
};

const slice = createSlice({
  name: 'shuffleQuestion',
  initialState,
  reducers: {
    setShuffled(state, action: PayloadAction<boolean>) {
      state.shuffled = action.payload;
    },
    setQuestion(
      state,
      action: PayloadAction<
        [HighlightQuestion[], HighlightQuestion | undefined]
      >,
    ) {
      state.questionBank = action.payload[0];
      const currentQuestion = action.payload[1];
      if (!!currentQuestion) {
        const findIndex = state.questionBank.findIndex(
          question => question.label === currentQuestion.label,
        );
        if (findIndex >= 0) {
          state.questionIndex = findIndex;
        } else {
          state.questionBank.push(currentQuestion);
          state.questionIndex = state.questionBank.length - 1;
        }
      } else {
        state.questionIndex = Math.floor(
          Math.random() * state.questionBank.length,
        );
      }
    },
    nextQuestion(state) {
      const plusOne = state.questionIndex + 1;
      state.questionIndex = plusOne >= state.questionBank.length ? 0 : plusOne;
    },
  },
});

export const { actions: shuffleQuestionActions } = slice;

export const useShuffleQuestionSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: shuffleQuestionSaga });
  return { actions: slice.actions };
};
