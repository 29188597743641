import { Apollo } from 'app/library/Apollo';
import { FeedItem, PaginatedFeedItems } from 'types/FeedItem';
import {
  Highlight,
  HighlightLike,
  HighlightComment,
  PaginatedHighlights,
} from 'types/Highlight';
import { QuestionCategories } from './AppConstants';

const PAGE_SIZE = 20;

export function addLike(highlight: Highlight): Promise<Highlight | undefined> {
  const data = getLikeData(highlight, 'add');

  return Apollo.post(data)
    .then(response => response.data.data.feedItem.addLike)
    .then(feedItem => convertFeedItemToHighlight(feedItem))
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve(undefined);
    });
}

export function deleteLike(
  highlight: Highlight,
): Promise<Highlight | undefined> {
  const data = getLikeData(highlight, 'remove');

  return Apollo.post(data)
    .then(response => response.data.data.feedItem.removeLike)
    .then(feedItem => convertFeedItemToHighlight(feedItem))
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve(undefined);
    });
}

function getLikeData(highlight: Highlight, action: string) {
  return {
    query: `
      mutation($id: FeedItemId!) {
        feedItem(id: $id) {
          ${action}Like ${getFeedItemsGql()}
        }
      }    
    `,
    variables: {
      id: highlight.feedId,
    },
  };
}

export function addComment(
  highlight: Highlight,
  text: string,
): Promise<Highlight | undefined> {
  const data = {
    query: `
      mutation($id: FeedItemId!, $text: String) {
        feedItem(id: $id) {
          addComment(text: $text) ${getFeedItemsGql()}
        }
      }
    `,
    variables: {
      id: highlight.feedId,
      text: text,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.feedItem.addComment)
    .then(feedItem => convertFeedItemToHighlight(feedItem))
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve(undefined);
    });
}

export function editComment(
  highlight: Highlight,
  commentId: string,
  text: string,
): Promise<Highlight | undefined> {
  const data = {
    query: `
      mutation($id: FeedItemCommentId!, $text: String) {
        feedItemComment(id: $id) {
          updateText(text: $text) {
            id
          }
        }
      }
    `,
    variables: {
      id: commentId,
      text: text,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.feedItemComment.updateText)
    .then(_ => getFeedItem(highlight.feedId))
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve(undefined);
    });
}

export function deleteComment(
  highlight: Highlight,
  commentId: string,
): Promise<Highlight | undefined> {
  const data = {
    query: `
      mutation($id: FeedItemId!, $feedItemCommentId: FeedItemCommentId) {
        feedItem(id: $id) {
          removeComment(feedItemCommentId: $feedItemCommentId) ${getFeedItemsGql()}
        }
      }
    `,
    variables: {
      id: highlight.feedId,
      feedItemCommentId: commentId,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.feedItem.removeComment)
    .then(feedItem => convertFeedItemToHighlight(feedItem))
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve(undefined);
    });
}

export function getFeed(
  offset: number,
  pageSize: number = PAGE_SIZE,
  filter: { parentId?: string } = {},
): Promise<PaginatedHighlights> {
  const data = {
    query: `
      query($first: Int, $offset: Int, $filter : { $parentId: String }) {
        getFeedItems(first: $first, offset: $offset, filter: $filter) {
          items ${getFeedItemsGql()}
          totalCount
          nextToken
        }
      }
    `,
    variables: {
      first: pageSize,
      offset: offset,
      filter,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.getFeedItems)
    .then(result => {
      const items = result.items.filter(
        item => item.type !== 'QUESTION_OF_THE_WEEK',
      );
      const highlights: Highlight[] = items.map(item =>
        convertFeedItemToHighlight(item),
      );
      const nextToken =
        result.nextToken != null ? +result.nextToken : undefined;
      return {
        highlights: highlights,
        nextToken: nextToken,
      };
    })
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve({
        highlights: [],
        nextToken: undefined,
      });
    });
}

export function getFeedItemsGql(): string {
  return `
    {
      id
      createdAt
      type
      order
      user {
        id
        firstName
        lastName
        title
        location
        image {
          url
        }
        events {
          birthday
          workAnniversary
        }
      }
      content {
        __typename
        ... on ProfileElement {
          profileElementId: id
          displayContent
          sourceId
        }
        ... on ProfileQuestionAnswer {
          answerId: id
          question {
            id
            questionText
            category
          }
          order
          answerText
          image {
            url
          }
        }
      }
      displayContent
      likes(first: 100, offset: 0) {
        items {
          id
          user {
            id
            firstName
            lastName
            image {
              url
            }
            title
            location
          }
        }
      }
      comments(first: 100, offset: 0) {
        items {
          id
          user {
            id
            firstName
            lastName
            image {
              url
            }
          }
          text
        }
      }
    }
  `;
}

export function getFeedItems(
  offset: number,
  pageSize: number = PAGE_SIZE,
  filter: { parentId?: string } = {},
): Promise<PaginatedFeedItems> {
  const data = {
    query: `
      query($first: Int, $offset: Int, $filter: GetFeedItemsFilterInput) {
        getFeedItems(first: $first, offset: $offset, filter: $filter) {
          items ${getFeedItemsGql()}
          totalCount
          nextToken
        }
      }
    `,
    variables: {
      first: pageSize,
      offset: offset,
      filter: {
        parentId: filter.parentId,
      },
    },
  };
  return Apollo.post(data)
    .then(response => response.data.data.getFeedItems)
    .then(result => {
      const feedItems: FeedItem[] = result.items;
      const nextToken =
        result.nextToken != null ? +result.nextToken : undefined;
      return {
        feedItems,
        nextToken: nextToken,
      };
    })
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve({
        feedItems: [],
        nextToken: undefined,
      });
    });
}

function createContentForMilestone(createdAt, type, user) {
  return {
    question: {
      id:
        type === QuestionCategories.BIRTHDAY
          ? user.events.birthday
          : type === QuestionCategories.ANNIVERSARY
          ? user.events.workAnniversary
          : '',
      category: type,
    },
  };
}

export function convertFeedItemToHighlight(feedItem): Highlight {
  const user = feedItem.user;
  const content = !!feedItem.content
    ? feedItem.content
    : createContentForMilestone(feedItem.createdAt, feedItem.type, user);
  const type: string = feedItem.type;
  let image: string;
  let answer: string;
  let questionLabel: string;
  let categoryId: string;
  let questionId: string;
  let answerId: string | undefined;
  if (type == 'PROFILE_QUESTION_UPDATE') {
    const displayContent = JSON.parse(content.displayContent);
    questionLabel = displayContent['questionText'];
    [image, answer] = supportLegacyGif(
      content.image?.url,
      displayContent['freeTextAnswer'],
    );
    categoryId = 'PROFILE';
    questionId = content.sourceId;
    answerId = content.profileElementId;
  } else {
    const question = content.question;
    questionLabel = question.questionText;
    [image, answer] = supportLegacyGif(content.image?.url, content.answerText);
    categoryId = question.category;
    questionId = question.id;
    answerId = content.answerId;
  }
  const displayContent = JSON.parse(feedItem.displayContent);
  const createdAt: string = feedItem.createdAt;
  const order: number | undefined = content.order;
  const profileFirstName: string = user.firstName;
  const profileLastName: string = user.lastName;
  const profileName: string = `${user.firstName} ${user.lastName}`;
  const profileImage: string = user.image?.url;
  const profileTitle: string | undefined = user.title;
  const profileLocation: string | undefined = user.location;
  const likes = convertFeedItemLikes(feedItem.likes);
  const comments = convertFeedItemComments(feedItem.comments);
  const userId: string = user.id;
  const feedId: string = feedItem.id;
  const endsAt: string | undefined =
    content.endsAt !== null ? content.endsAt : undefined;

  return {
    createdAt: createdAt,
    questionLabel: questionLabel,
    answer: answer,
    image: image,
    order: order,
    displayContent: displayContent,
    profileFirstName: profileFirstName,
    profileLastName: profileLastName,
    profileName: profileName,
    profileImage: profileImage,
    profileTitle: profileTitle,
    profileLocation: profileLocation,
    likes: likes,
    comments: comments,
    userId: userId,
    categoryId: categoryId,
    questionId: questionId,
    answerId: answerId,
    feedId: feedId,
    type: type,
    endsAt: endsAt,
  };
}

function convertFeedItemLikes(likes): HighlightLike[] {
  if (!!likes) {
    return likes.items.map(like => {
      const user = like.user;
      return {
        uid: user.id,
        profileFirstName: user.firstName,
        profileLastName: user.lastName,
        profileImage: user.image?.url,
        profileTitle: user.title,
        profileLocation: user.location,
      };
    });
  } else {
    return [];
  }
}

function convertFeedItemComments(comments): HighlightComment[] {
  if (!!comments) {
    return comments.items.map(comment => {
      const user = comment.user;
      return {
        uid: user.id,
        id: comment.id,
        text: comment.text,
        profileName: `${user.firstName} ${user.lastName}`,
        profileImage: user.image?.url,
      };
    });
  } else {
    return [];
  }
}

function getFeedItem(feedItemId: string): Promise<Highlight | undefined> {
  const data = {
    query: `
      query($id: FeedItemId!) {
        feedItem(id: $id) ${getFeedItemsGql()}
      }
    `,
    variables: {
      id: feedItemId,
    },
  };

  return Apollo.post(data)
    .then(response => response.data.data.feedItem)
    .then(feedItem => convertFeedItemToHighlight(feedItem))
    .catch(err => {
      Apollo.handleError(err, data);
      return Promise.resolve(undefined);
    });
}

export function convertProfileQuestionAnswerToHighlight(
  profileQuestionAnswer,
): Highlight {
  const question = profileQuestionAnswer.question;
  const user = profileQuestionAnswer.user;
  const createdAt: string = profileQuestionAnswer.createdAt;
  const questionLabel: string = question.questionText;
  const order: number | undefined = profileQuestionAnswer.order;
  const profileFirstName: string = user.firstName;
  const profileLastName: string = user.lastName;
  const profileName: string = `${user.firstName} ${user.lastName}`;
  const profileImage: string = user.image?.url;
  const profileTitle: string | undefined = user.title;
  const profileLocation: string | undefined = user.location;
  const likes = [];
  const comments = [];
  const userId: string = user.id;
  const categoryId: string = question.category;
  const questionId: string = question.id;
  const answerId: string | undefined = profileQuestionAnswer.id;
  const [image, answer] = supportLegacyGif(
    profileQuestionAnswer.image?.url,
    profileQuestionAnswer.answerText,
  );

  // TODOTODO these should just be undefined
  const feedId: string = '';
  const type: string = '';
  const endsAt: string | undefined = undefined;

  return {
    createdAt: createdAt,
    questionLabel: questionLabel,
    answer: answer,
    image: image,
    order: order,
    displayContent: '',
    profileFirstName: profileFirstName,
    profileLastName: profileLastName,
    profileName: profileName,
    profileImage: profileImage,
    profileTitle: profileTitle,
    profileLocation: profileLocation,
    likes: likes,
    comments: comments,
    userId: userId,
    categoryId: categoryId,
    questionId: questionId,
    answerId: answerId,
    feedId: feedId,
    type: type,
    endsAt: endsAt,
  };
}

function supportLegacyGif(image, answer) {
  if (!image) {
    if (!!answer) {
      const pattern = new RegExp(/(https:\/\/giphy.com\/embed\/[^ /]+)$/);
      const match = pattern.exec(answer);
      if (!!match) {
        const embedUrl = match[0];
        const giphyUrl = `https://media4.giphy.com/media/${embedUrl.slice(
          embedUrl.lastIndexOf('/') + 1,
        )}/giphy.gif`;
        return [giphyUrl, answer.replace(embedUrl, '').trim()];
      }
    }
  }

  return [image, answer];
}
