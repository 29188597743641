import * as React from 'react';
type Props = {
  color?: string;
  height?: number;
  width?: number;
};
const GraduationHatIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.height || 24}
    height={props.width || 24}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke={props.color || '#D0D5DD'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 14.5v-3.006c0-.18 0-.27-.027-.348a.5.5 0 00-.116-.187c-.058-.06-.139-.1-.3-.18L12 8.498m-8 1v6.807c0 .372 0 .558.058.72a1 1 0 00.244.382c.124.12.293.198.631.353l6.4 2.933c.246.112.368.169.496.19.113.02.229.02.342 0 .128-.021.25-.078.496-.19l6.4-2.933c.338-.155.507-.233.63-.353a1 1 0 00.245-.381c.058-.163.058-.349.058-.72V9.5m-18-1l9.642-4.822c.131-.065.197-.098.266-.11a.5.5 0 01.184 0c.069.012.135.045.266.11L22 8.5l-9.642 4.82a1.087 1.087 0 01-.266.112.501.501 0 01-.184 0c-.069-.013-.135-.046-.266-.111L2 8.5z"
    ></path>
  </svg>
);
export default GraduationHatIcon;
