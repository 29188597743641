/**
 *
 * Asynchronously loads the component for LoginCodePage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const LoginCodePage = lazyLoad(
  () => import('./index'),
  module => module.LoginCodePage,
);
