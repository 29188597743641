import { Button } from 'app/components/Button';
import {
  BodyMediumBold,
  BodyMediumNormal,
  BodySmallBold,
} from 'app/components/Text';
import { addAdmin, getSlackbotAdmins } from 'app/library/SlackAPI';
import { useAppSlice } from 'app/slice';
import { selectAllSlackUsers, selectSlackWorkspace } from 'app/slice/selectors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import { SlackRawUser } from 'types/SlackChannel';
import { Modal, ModalHeader } from '../../../../components/Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const Img = styled.img`
  height: auto;
  width: 100%;
  @media (max-width: 768px) {
    height: 15px;
    width: 15px;
  }
`;

const BodyContainer = styled.div`
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 2em;
  gap: 1em;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

export const AddAdminModal = ({ onClose, isOpen }: Props) => {
  const [selectedUser, setSelectedUser] = useState<SlackRawUser>();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const slackWorkspace = useSelector(selectSlackWorkspace);
  const slackUsers = useSelector(selectAllSlackUsers);
  const dispatch = useDispatch();
  const { actions: appActions } = useAppSlice();

  useEffect(() => {
    if (!!slackWorkspace && isOpen) {
      loadSlackUsers();
    }
  }, [slackWorkspace, isOpen]);

  if (!isOpen) {
    return null;
  }

  const loadSlackUsers = async () => {
    dispatch(appActions.loadSlackUsers());
  };

  const onAddAdmin = async () => {
    try {
      setShowLoader(true);
      const addAdminResponse = await addAdmin(
        slackWorkspace?.id!,
        selectedUser?.id!,
      );
      if (addAdminResponse) {
        const slackbotAdminsResponse = await getSlackbotAdmins(
          slackWorkspace?.id!,
          0,
        );
        const updatedItems = slackbotAdminsResponse?.admin.items || [];
        dispatch(appActions.updateSlackbotAdminInSlackWorkspace(updatedItems));
      }
      dispatch(
        appActions.setSuccess(
          `You’ve successfully added ${selectedUser?.name} as an Airspeed admin.`,
        ),
      );
      setShowLoader(false);
      onClose();
    } catch (err) {
      setShowLoader(false);
    }
  };

  return (
    <Modal show={true} maxWidth="640px" marginTop="5%">
      <ModalHeader title={'Add Admin'} onClose={onClose} />
      <BodyContainer>
        <div>
          <BodySmallBold>Search for user in your Slack workspace</BodySmallBold>
          <Select
            isMulti={false}
            isSearchable
            options={slackUsers.map((item: SlackRawUser) => ({
              value: item.id,
              label: item.real_name,
            }))}
            onInputChange={data => console.log('onInputChange', data)}
            placeholder="Add user..."
            onChange={data =>
              setSelectedUser(slackUsers.find(item => item.id === data?.value))
            }
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                textAlign: 'left',
              }),
              option: (
                styles,
                { data, isDisabled, isFocused, isSelected },
              ) => ({
                ...styles,
                textAlign: 'left',
              }),
            }}
            isLoading={false}
            noOptionsMessage={() => {
              return 'No channel found';
            }}
            menuShouldScrollIntoView={false}
          />
        </div>
        <div>
          <BodyMediumBold>Admin</BodyMediumBold>
          <br />
          <BodyMediumNormal>
            Admins can assign other Admins, configure apps, access billing and
            enable integrations.{' '}
          </BodyMediumNormal>
        </div>
        <div>
          <ButtonsContainer>
            <Button onClick={onClose} color={'white'}>
              Cancel
            </Button>
            <Button disabled={!selectedUser} onClick={onAddAdmin}>
              Add
            </Button>
          </ButtonsContainer>
        </div>
        {showLoader && (
          <LoadingContainer>
            <div className="dot-collision"></div>
          </LoadingContainer>
        )}
      </BodyContainer>
    </Modal>
  );
};
