import history from 'app/library/History';
import useCopyToClipboard from 'app/library/hooks/useCopyToClipboard';
import { useSettingsPanelSlice } from 'app/pages/ProfileHomePage/slice';
import {
  selectSecondaryEmail,
  selectSecondaryEmailAdded,
  selectSecondaryEmailError,
} from 'app/pages/ProfileHomePage/slice/selectors';
import { appActions } from 'app/slice';
import { selectUser } from 'app/slice/selectors';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CalendarImage from '../../assets/linkedin-share.svg';
import { Modal, ModalContentWrapper, ModalHeader } from '../Modal';
import { Panel } from '../Panel';
import { ShareButton } from '../SharePanel';
import { Body1, Body2 } from '../Text';
import { AnalyticsClientContext } from 'app/library/Analytics';
import { useLocation } from 'react-router-dom';

export enum ContainerType {
  'Panel' = 'Panel',
  'Modal' = 'Modal',
}

interface Props {
  isOpen: boolean;
  as?: ContainerType;
  reset: () => void;
}

interface ModalProps {
  isModal: boolean;
}

const Container = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: ${({ isModal }) => (isModal ? '0' : '2em')};
`;

const Image = styled.img`
  width: 100%;
`;

const ImageContainer = styled.div`
  background-color: #f1f3f4;
  display: flex;
  justify-content: center;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0em 2em;
`;

const OrderedList = styled.ol`
  padding-left: 1em;
`;
const ListItem = styled.li`
  padding: 0.5em 0em;
`;

function InnerContent({
  isModal,
  handleCopy,
}: {
  isModal: boolean;
  handleCopy: () => void;
}) {
  return (
    <Container isModal={isModal}>
      <ImageContainer>
        <Image src={CalendarImage} />
      </ImageContainer>
      <Footer>
        <Body1>How to Update Your LinkedIn Profile</Body1>
        <OrderedList>
          <ListItem>
            <Body2>Copy your Airspeed profile URL.</Body2>
            <ShareButton handleCopy={handleCopy} />
          </ListItem>
          <ListItem>
            <Body2>Open your LinkedIn profile.</Body2>
          </ListItem>
          <ListItem>
            <Body2>
              Go to the “Resources” section of your profile and open “Creator
              mode.”
            </Body2>
          </ListItem>
          <ListItem>
            <Body2>Turn on “Creator mode.”</Body2>
          </ListItem>
          <ListItem>
            <Body2>Open “Edit profile” via the pencil icon.</Body2>
          </ListItem>
          <ListItem>
            <Body2>
              Scroll down to the bottom of the “Edit profile” window and add
              your link in the “Website” section of the window.
            </Body2>
          </ListItem>
          <ListItem>
            <Body2>Save your changes.</Body2>
          </ListItem>
          <ListItem>
            <Body2>
              Open "Edit profile" again via the pencil icon, then under Link
              Text add "See my Airspeed profile"
            </Body2>
          </ListItem>
        </OrderedList>
      </Footer>
    </Container>
  );
}

function PanelContent({ canConfirm, handleCopy, reset, goBack }) {
  return (
    <Panel
      canConfirm={canConfirm}
      title="Share your Airspeed profile"
      onGoBack={goBack}
      onClose={reset}
    >
      <InnerContent isModal={false} handleCopy={handleCopy} />
    </Panel>
  );
}

function ModalContent({ canConfirm, handleCopy, reset, goBack, isOpen }) {
  return (
    <Modal show={isOpen} maxWidth="700px" marginTop="5%">
      <ModalHeader title="Share your Airspeed profile" onClose={reset} />
      <ModalContentWrapper>
        <InnerContent isModal handleCopy={handleCopy} />
      </ModalContentWrapper>
    </Modal>
  );
}

export function ShareLinkedin({
  isOpen,
  as = ContainerType.Panel,
  reset,
}: Props) {
  const analyticsClient = React.useContext(AnalyticsClientContext);
  const reactLocation = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const secondaryEmail = useSelector(selectSecondaryEmail);
  const secondaryEmailError = useSelector(selectSecondaryEmailError);
  const secondaryEmailAdded = useSelector(selectSecondaryEmailAdded);
  const { actions } = useSettingsPanelSlice();

  const handleGoBack = () => {
    history.back();
  };

  const handleReset = useCallback(() => {
    dispatch(actions.reset());
    reset();
  }, [dispatch, actions, reset]);

  useEffect(() => {
    if (secondaryEmailAdded) {
      handleReset();
    }
  }, [handleReset, secondaryEmailAdded]);

  const inviteUrl = `${process.env.REACT_APP_PUBLIC_PROFILE_URL}/fun/${user?.username}`;
  const [_, copy] = useCopyToClipboard();

  const handleCopy = () => {
    analyticsClient.logEvent(
      {
        eventType: 'Public profile link copied',
        properties: {
          Source: 'ProfileLinkForLinkedIn',
          'User ID': user?.id,
        },
      },
      reactLocation,
    );
    copy(inviteUrl);
    dispatch(appActions.setSuccess('Successfully copied to clipboard.'));
  };

  const isModal = as === ContainerType.Modal;
  const canConfirm = !secondaryEmailError && secondaryEmail;

  if (isModal) {
    return (
      <ModalContent
        canConfirm={canConfirm}
        handleCopy={handleCopy}
        reset={handleReset}
        goBack={handleGoBack}
        isOpen={isOpen}
      />
    );
  }

  return (
    <PanelContent
      canConfirm={canConfirm}
      handleCopy={handleCopy}
      reset={handleReset}
      goBack={handleGoBack}
    />
  );
}
