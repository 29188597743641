import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { ContainerType } from 'app/components/ShareLinkedin';
import { AnalyticsClientContext } from 'app/library/Analytics';
import { BodyLargeBold } from 'app/components/Text';
import { Panel } from 'app/components/Panel';
import { Modal, ModalContentWrapper, ModalHeader } from 'app/components/Modal';
import { useLeaderboardSlice } from 'app/pages/Leaderboards/LeaderboardPage/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLeaderboardItems,
  selectLeaderboardLoading,
} from 'app/pages/Leaderboards/LeaderboardPage/slice/selectors';
import { selectSlackWorkspace } from 'app/slice/selectors';
import { ShoutoutsUserRow } from './ShoutoutsUserRow';
import { pushHistory } from 'app/library/NavHelper';
import { ShoutoutsByUserListView } from './ShoutoutsByUserListView';
import { LeaderboardType } from 'app/pages/Leaderboards/LeaderboardPage/slice/types';
import { AdminSidePanelState } from '../../AdminHomePage/slice/type';
import { Loader } from '../Loader';

export const TITLE: Map<AdminSidePanelState, string> = new Map([
  [AdminSidePanelState.VIEW_SHOUTOUTS_GIVEN, '🏆 Shoutouts given'],
  [AdminSidePanelState.VIEW_SHOUTOUTS_RECEIVED, '😊 Shoutouts received'],
]);

export const LEADERBOARD_TYPE: Map<AdminSidePanelState, LeaderboardType> =
  new Map([
    [AdminSidePanelState.VIEW_SHOUTOUTS_GIVEN, LeaderboardType.ShoutoutsGiven],
    [
      AdminSidePanelState.VIEW_SHOUTOUTS_RECEIVED,
      LeaderboardType.ShoutoutsReceived,
    ],
  ]);

interface Props {
  isOpen: boolean;
  as?: ContainerType;
  reset: () => void;
  state?: AdminSidePanelState;
}

interface InnerProps extends Props {
  isModal: boolean;
}

interface ContainerProps {
  isModal: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  padding-bottom: 75px;
  @media (max-width: 480px) {
    padding-bottom: 120px;
  }
`;

function InnerContent({ isModal = false, state }: InnerProps) {
  const location = useLocation();
  const analyticsClient = React.useContext(AnalyticsClientContext);
  const { actions: leaderboardActions } = useLeaderboardSlice();
  const dispatch = useDispatch();
  const leaderboardLoading = useSelector(selectLeaderboardLoading);
  const leaderboardItems = useSelector(selectLeaderboardItems);
  const slackWorkspace = useSelector(selectSlackWorkspace);
  const [userId, setUserId] = useState<string | undefined>();

  useEffect(() => {
    const { user_id, state: newState } = Object.fromEntries(
      new URLSearchParams(location.search),
    );
    setUserId(user_id);
  }, [location]);

  useEffect(() => {
    dispatch(
      leaderboardActions.setLeaderboardCurrentType(
        LEADERBOARD_TYPE.get(state!),
      ),
    );
  }, [state]);

  useEffect(() => {
    if (!slackWorkspace) return;
    dispatch(leaderboardActions.load({ first: 1000 })); //todo: add proper pagination
  }, [slackWorkspace?.id]);

  return (
    <Container isModal={isModal}>
      {!!userId && <ShoutoutsByUserListView userId={userId} state={state!} />}
      {!userId &&
        !leaderboardLoading &&
        leaderboardItems.map((item, index) => {
          return (
            <ShoutoutsUserRow
              key={index}
              name={item.user.firstName + ' ' + item.user.lastName}
              imageUrl={item.user.image!}
              title={item.user.title}
              detailText={`${item.score}`}
              onClick={() => {
                pushHistory({ user_id: item.user.id });
              }}
            />
          );
        })}
      <Loader loading={leaderboardLoading} />
    </Container>
  );
}

function PanelContent(props) {
  return (
    <Panel
      canConfirm={false}
      title={TITLE.get(props.state)!}
      onClose={props.handleReset}
      emptyLeftIcon
    >
      <InnerContent {...props} />
    </Panel>
  );
}

function ModalContent(props) {
  return (
    <Modal show={props.isOpen} maxWidth="700px" marginTop="5%">
      <ModalHeader
        justifyContent="center"
        title={TITLE.get(props.state)}
        onClose={props.handleReset}
      />
      <ModalContentWrapper>
        <InnerContent isModal {...props} />
      </ModalContentWrapper>
    </Modal>
  );
}

export function ViewShoutoutsPanel({
  isOpen,
  reset,
  as = ContainerType.Panel,
  state,
}: Props): JSX.Element {
  // const analyticsClient = React.useContext(AnalyticsClientContext);
  const isModal = as === ContainerType.Modal;
  if (isModal) {
    return <ModalContent isOpen={isOpen} handleReset={reset} state={state} />;
  }

  return <PanelContent handleReset={reset} state={state} />;
}
