/**
 *
 * Button
 *
 */
import React, { memo, MouseEventHandler, useState } from 'react';
import styled from 'styled-components/macro';
import { Tooltip } from '../Tooltip';

type ButtonFormat = 'pill' | 'circle';
type ButtonSize = 'normal' | 'compact';
type ButtonColor = 'blue' | 'white' | 'red' | 'transparent';
type BorderColor = 'black' | 'transparent' | string;

interface ButtonProps {
  color?: ButtonColor;
  size?: ButtonSize;
  format?: ButtonFormat;
  borderColor?: BorderColor;
  border?: string;
  speechBubble: boolean;
  textColor?: string;
  onClick: MouseEventHandler;
  disabled?: boolean;
  buttonStyles?: Record<string, string>;
  iconStyles?: Record<string, string>;
  userInteractionDisabled?: boolean;
}

interface ImgProps {
  format?: ButtonFormat;
  size?: ButtonSize;
  src?: string;
  isLeft?: boolean;
}

interface Props extends ButtonProps {
  children: string;
  icon?: string;
  leftIcon?: string;
  rightIcon?: string;
  type?: 'submit' | 'button';
  tooltipText?: string;
}

const ButtonElement = styled.button<ButtonProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${props =>
    props.format === 'circle'
      ? '0'
      : props.size === 'compact'
      ? '6px 14px'
      : '14px 16px'};
  height: ${props => (props.size === 'compact' ? '32px' : '48px')};
  width: ${props =>
    props.format === 'pill'
      ? '100%'
      : props.size === 'compact'
      ? '32px'
      : '48px'};
  background-color: ${props =>
    props.color === 'blue'
      ? '#0060BF'
      : props.color === 'red'
      ? '#EB5757'
      : props.color === 'transparent'
      ? 'transparent'
      : !!props.color
      ? props.color
      : '#FFFFFF'} !important;
  color: ${props =>
    !!props.textColor
      ? props.textColor
      : props.color === 'blue' || props.color === 'red'
      ? '#FFFFFF'
      : '#000000'};
  border-radius: ${props =>
    !!props.speechBubble ? '0px 48px 48px 48px' : '48px'};
  font-weight: ${props => (props.size === 'normal' ? '500' : '600')};
  font-size: ${props => (props.size === 'normal' ? '1.125em' : '1em')};
  line-height: ${props => (props.size === 'normal' ? '1.125em' : '1em')};
  border: ${props =>
    props.color === 'blue' ||
    props.color === 'red' ||
    props.borderColor === 'transparent'
      ? 'none'
      : !!props.borderColor
      ? `1px solid ${props.borderColor}`
      : 'none'};
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
  pointer-events: ${props =>
    !!props.userInteractionDisabled ? 'none' : undefined};
  .tooltip-container {
    display: none;
  }
  &:hover {
    background: ${props => decideHover(props.color)};
    .tooltip-container {
      display: inherit;
    }
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  @media (max-width: 768px) {
    font-size: 0.875em;
  }
`;

const decideHover = (color?: string) => {
  switch (color) {
    case 'blue':
      return 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #0060BF;';
    case 'red':
      return 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #EB5757;';
    case 'transparent':
      return 'rgba(255, 255, 255, 0.1);';
    default:
      return 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFFFFF;';
  }
};

const Img = styled.img<ImgProps>`
  width: ${props =>
    props.format === 'pill'
      ? '1em'
      : props.size === 'compact'
      ? '1.3em'
      : '1.5em'};
  height: ${props =>
    props.format === 'pill'
      ? '1em'
      : props.size === 'compact'
      ? '1.3em'
      : '1.5em'};
  margin-right: ${props =>
    props.isLeft && props.format === 'pill' ? '0.5em' : '0'};
  margin-left: ${props =>
    !props.isLeft && props.format === 'pill' ? '0.5em' : '0'};
`;

export const Button = memo(
  ({
    children,
    color,
    icon,
    leftIcon,
    rightIcon,
    borderColor,
    textColor,
    onClick,
    border,
    size,
    format,
    speechBubble,
    disabled,
    buttonStyles,
    iconStyles,
    userInteractionDisabled,
    type,
    tooltipText,
  }: Props) => {
    return (
      <ButtonElement
        type={type || 'button'}
        color={color}
        onClick={onClick}
        textColor={textColor}
        border={border}
        size={size}
        format={format}
        borderColor={borderColor}
        speechBubble={speechBubble}
        disabled={disabled}
        style={buttonStyles}
        userInteractionDisabled={userInteractionDisabled}
      >
        {!!tooltipText?.length && (
          <Tooltip text={tooltipText} isExpanded={true} color={'light'} />
        )}
        {!!icon ? (
          <Img
            src={icon}
            format={format}
            size={size}
            isLeft={true}
            style={iconStyles}
          />
        ) : null}
        {!!leftIcon ? (
          <Img src={leftIcon} format={format} size={size} isLeft={true} />
        ) : null}
        {children}
        {!!rightIcon ? (
          <Img src={rightIcon} format={format} size={size} />
        ) : null}
      </ButtonElement>
    );
  },
) as any;

Button.defaultProps = {
  icon: undefined,
  color: 'blue',
  size: 'normal',
  format: 'pill',
  borderColor: 'black',
  speechBubble: false,
};
