import { selectLastEvaluatedKey, selectProfile } from './selectors';
import { appActions } from 'app/slice';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { profileHighlightsActions as actions } from '.';
import { User } from 'types/User';
import { moveUp, moveDown } from 'app/library/ProfileQuestionAPI';
import { getAnswersByUser } from 'app/library/ProfileElementAPI';
import { selectUser } from 'app/slice/selectors';
import { PaginatedHighlights } from 'types/Highlight';

// TODO replaced appendHighlights w/ setHighlights in order to quickly fix prd bug
// Need to revert and debug further in order for profile to support pagination
function* doLoadHighlights() {
  const profile: User = yield select(selectProfile);
  const user: User = yield select(selectUser);
  const lastEvaluatedKey = yield select(selectLastEvaluatedKey);
  yield put(actions.setHighlightStateChanging(true));

  if (!lastEvaluatedKey) {
    yield put(actions.setLoading(true));
  }

  try {
    const userId = !!profile ? profile.id : user.id;
    const paginatedHighlights: PaginatedHighlights = yield call(
      getAnswersByUser,
      userId,
      lastEvaluatedKey,
    );
    yield all([
      put(actions.setHighlights(paginatedHighlights.highlights)),
      put(actions.setLastEvaluatedKey(paginatedHighlights.nextToken)),
    ]);
  } catch (err) {
    yield put(
      appActions.setError(
        'An unexpected error occurred while loading the highlights: ' +
          JSON.stringify(err),
      ),
    );
    console.error(
      'An unexpected error occurred while loading the highlights: ' +
        JSON.stringify(err),
    );
  }

  yield all([
    put(actions.setHighlightStateChanging(false)),
    put(actions.setLoading(false)),
  ]);
}

function* doMoveUp(action) {
  yield put(actions.setHighlightStateChanging(true));
  try {
    yield call(moveUp, action.payload);
    yield put(actions.onMoveUpSuccess(action.payload));
  } catch (err) {
    yield put(
      appActions.setError(
        'An unexpected error occurred while moving the highlight up: ' +
          JSON.stringify(err),
      ),
    );
    console.error(
      'An unexpected error occurred while moving the highlight up: ' +
        JSON.stringify(err),
    );
  }
  yield put(actions.setHighlightStateChanging(false));
}

function* doMoveDown(action) {
  yield put(actions.setHighlightStateChanging(true));
  try {
    yield call(moveDown, action.payload);
    yield put(actions.onMoveDownSuccess(action.payload));
  } catch (err) {
    yield put(
      appActions.setError(
        'An unexpected error occurred while moving the highlight down: ' +
          JSON.stringify(err),
      ),
    );
    console.error(
      'An unexpected error occurred while moving the highlight down: ' +
        JSON.stringify(err),
    );
  }
  yield put(actions.setHighlightStateChanging(false));
}

export function* profileHighlightsSaga() {
  yield takeLatest(actions.setProfile.type, doLoadHighlights);
  yield takeLatest(actions.resetData.type, doLoadHighlights);
  yield takeLatest(actions.loadHighlights.type, doLoadHighlights);
  yield takeLatest(actions.moveUp.type, doMoveUp);
  yield takeLatest(actions.moveDown.type, doMoveDown);
}
