/**
 *
 * TopRightIcons
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { Notification } from '../Notification';
import { ProfileMenu } from '../ProfileMenu';
import { Settings } from '../Settings';
import { Share } from '../Share';
import { isMobile } from 'react-device-detect';

interface Props {
  showNotifications?: boolean;
  showSettings?: boolean;
  showShare?: boolean;
}

const Div = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TopRightIcons = memo((props: Props) => {
  return (
    <Div>
      {!isMobile && (
        <>
          {props.showNotifications && <Notification />}
          {props.showSettings && <Settings />}
          {props.showShare && <Share />}
        </>
      )}
      <ProfileMenu />
    </Div>
  );
});
