import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { invitePeopleModalSaga } from './saga';
import { InvitePeopleModalState } from './types';

export const initialState: InvitePeopleModalState = {
  show: false,
  error: undefined,
  componentEmailData: []
};

const slice = createSlice({
  name: 'invitePeopleModal',
  initialState,
  reducers: {
    setComponentEmailData(state, action: PayloadAction<any[]>) {
      state.componentEmailData = action.payload;
    },
    setError(state, action: PayloadAction<string | undefined>) {
      state.error = action.payload;
    },
    submit(){},
    reset(state) {
      Object.assign(state, initialState)
    },
    show(state) {
      state.show = true;
    },
    hide(state) {
      state.show = false;
    }
  },
});

export const { actions: invitePeopleModalActions } = slice;

export const useInvitePeopleModalSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: invitePeopleModalSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useInvitePeopleModalSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
