import { SlackbotType } from 'app/pages/InstallationChannelSelector/slice/type';
import { LeaderboardItem } from 'types/Leaderboard';
import { Shoutout } from 'types/Shoutouts';
import { SlackChannel } from 'types/SlackChannel';

export const LEADERBOARD_ENABLED_APPS = [
  SlackbotType.BIRTHDAY,
  SlackbotType.COFFEE_TALK,
  SlackbotType.ICE_BREAKERS,
  SlackbotType.SHOUTOUT,
];

export enum LeaderboardType {
  ShoutoutsGiven = 'SHOUTOUTS_GIVEN',
  ShoutoutsReceived = 'SHOUTOUTS_RECEIVED',
  CelebrationsCardsSigned = 'CELEBRATIONS_CARDS_SIGNED',
  IcebreakersAnswered = 'ICEBREAKERS_ANSWERED',
  CoffeeTalksAttended = 'COFFEE_TALKS_ATTENDED',

  //Not implemented
  IntrosAnswered = 'INTROS_ANSWERED',
  MapsPinsAdded = 'MAPS_PINS_ADDED',
}

export const LEADERBOARD_TYPE: Map<SlackbotType, LeaderboardType> = new Map([
  [SlackbotType.BIRTHDAY, LeaderboardType.CelebrationsCardsSigned],
  [SlackbotType.ICE_BREAKERS, LeaderboardType.IcebreakersAnswered],
  [SlackbotType.SHOUTOUT, LeaderboardType.ShoutoutsGiven],
  [SlackbotType.COFFEE_TALK, LeaderboardType.CoffeeTalksAttended],

  //Not implemented
  [SlackbotType.MAPS, LeaderboardType.MapsPinsAdded],
  [SlackbotType.INTROS, LeaderboardType.IntrosAnswered],
]);

export enum LeaderboardPeriod {
  LastWeek = 'last_week',
  LastMonth = 'last_month',
  LastYear = 'last_year',
  LastQuarter = 'last_quarter',
  AllTime = 'all_time',
}

export type LeaderboardApp = {
  slackbotId?: string;
  type: SlackbotType;
};

export interface LeaderboardState {
  apps: LeaderboardApp[];
  currentApp?: LeaderboardApp;
  currentLeaderboardType?: LeaderboardType;
  channels: SlackChannel[];
  filterChannelIds: string[];
  items: LeaderboardItem[];
  period: LeaderboardPeriod;
  errorMsg: string;
  loading: boolean;
  channelsLoading: boolean;
  hasData: boolean;
  shoutouts: Shoutout[];
}
