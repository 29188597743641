export function shuffleArray<T>(inutArray: T[]) {
  const array = [...inutArray];
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
}

export function sortByProperty(arr, prop) {
  return arr.sort((a, b) => {
    // Convert property values to lowercase for case-insensitive comparison
    const propA = a[prop].toLowerCase();
    const propB = b[prop].toLowerCase();

    if (propA < propB) {
      return -1;
    } else if (propA > propB) {
      return 1;
    } else {
      return 0;
    }
  });
}
