import _ from 'lodash';
import { DateTime } from 'luxon';

export const MONTHS = [
  {
    label: 'January',
    id: 1,
  },
  {
    label: 'February',
    id: 2,
  },
  {
    label: 'March',
    id: 3,
  },
  {
    label: 'April',
    id: 4,
  },
  {
    label: 'May',
    id: 5,
  },
  {
    label: 'June',
    id: 6,
  },
  {
    label: 'July',
    id: 7,
  },
  {
    label: 'August',
    id: 8,
  },
  {
    label: 'September',
    id: 9,
  },
  {
    label: 'October',
    id: 10,
  },
  {
    label: 'November',
    id: 11,
  },
  {
    label: 'December',
    id: 12,
  },
];

export function getYears(limit = 100) {
  const date = new Date();
  const year = date.getFullYear();
  return _.range(-1, limit)
    .map(age => year - age)
    .map(i => {
      return {
        label: i,
        value: i,
      };
    });
}

export function getMaxDay(month, year) {
  return new Date(year, month, 0).getDate();
}

export function getMaxDayFromMonth(month) {
  return getMaxDay(month, 2000);
}

export function getDays(month, year) {
  if (!month || !year) {
    return [];
  }

  return _.range(getMaxDay(month, year)).map(i => {
    return {
      label: i + 1,
      value: i + 1,
    };
  });
}

export function getDaysFromMonth(month) {
  return getDays(month, 2000);
}

function removeLeadingZero(number) {
  return number.startsWith('0') ? number.substring(1, number.length) : number;
}

export function extractMonthDayFromString(str) {
  if (!str) {
    return [undefined, undefined];
  }
  const elements = str.split('-');
  if (elements.length !== 2) {
    throw new Error(
      `MonthDay string has more than two elements. Should have the format month-day. Actual: ${str}`,
    );
  }
  return elements.map(element => removeLeadingZero(element));
}

export function extractYearMonthDayFromString(str) {
  if (!str) {
    return [undefined, undefined, undefined];
  }
  const elements = str.split('-');
  if (elements.length !== 3) {
    throw new Error(
      `YearMonthDay string has more than two elements. Should have the format year-month-day. Actual: ${str}`,
    );
  }
  return elements.map(element => removeLeadingZero(element));
}

export function formatBirthday(birthday) {
  if (!birthday) return undefined;
  const [month, day] = extractMonthDayFromString(birthday);
  const monthString = MONTHS.filter(m => m.id === parseInt(month))[0].label;
  return `${monthString} ${day}`;
}

export function formatStartDate(startDate) {
  if (!startDate) return undefined;
  const [year, month, day] = extractYearMonthDayFromString(startDate);
  const monthString = MONTHS.filter(m => m.id === parseInt(month))[0].label;
  return `${monthString} ${day}, ${year}`;
}

export function formatStartDateWithNoDay(startDate) {
  if (!startDate) return undefined;
  const [year, month, day] = extractYearMonthDayFromString(startDate);
  const monthString = MONTHS.filter(m => m.id === parseInt(month))[0].label;
  return `${monthString}, ${year}`;
}

export function formatWorkAnniversary(startDate) {
  if (!startDate) return undefined;
  const [_, month, day] = extractYearMonthDayFromString(startDate);
  const monthString = MONTHS.filter(m => m.id === parseInt(month))[0].label;
  return `${monthString} ${day}`;
}

/**
 * @description This method will return the difference of two dates in a label format
 * @param date1 start date
 * @param date2 end date
 * @returns string
 */
export const dateDiffLabel = (date1: number, date2: number) => {
  try {
    const end = DateTime.fromMillis(date1);
    const start = DateTime.fromMillis(date2);
    const seconds = Math.floor(
      end.diff(start, 'seconds').toObject().seconds || 0,
    );
    if (seconds < 60) {
      return 'just now';
    }
    const minutes = Math.floor(
      end.diff(start, 'minutes').toObject().minutes || 0,
    );
    if (minutes && minutes < 60) {
      return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`;
    }

    const hours = Math.floor(end.diff(start, 'hours').toObject().hours || 0);
    if (hours && hours < 24) {
      return `${hours} ${hours > 1 ? 'hours' : 'hour'}`;
    }
    const days = Math.floor(end.diff(start, 'days').toObject().days || 0);
    if (days && days < 30) {
      return `${days} ${days > 1 ? 'days' : 'day'}`;
    }
    const months = Math.floor(end.diff(start, 'months').toObject().months || 0);
    if (months && months < 12) {
      return `${months} ${months > 1 ? 'months' : 'month'}`;
    }
    const years = Math.floor(end.diff(start, 'years').toObject().years || 0);
    if (years) {
      return `${years} ${years > 1 ? 'years' : 'year'}`;
    }
    return 'just now';
  } catch (e) {
    console.error('error', e);
  }
};

export const nth = function (d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export function formattedStringFromAnniversaryString(
  str?: string,
  unknownStartDate: boolean = false,
) {
  if (!str) {
    return undefined;
  }
  const [_, month, day] = extractYearMonthDayFromString(str);
  const monthStr = MONTHS[month - 1].label;
  const dayStr = `${day}${nth(day)}`;

  if (unknownStartDate) {
    return monthStr;
  }

  return `${monthStr} ${dayStr}`;
}

export function formattedStringFromBirthdayString(str) {
  if (!str) {
    return undefined;
  }
  const [month, day] = extractMonthDayFromString(str);
  const monthStr = MONTHS[month - 1].label;
  const dayStr = `${day}${nth(day)}`;
  return `${monthStr} ${dayStr}`;
}

export function formatTravelStartDate(date) {
  if (!date) return undefined;
  const dt = DateTime.fromISO(date);
  return dt.toFormat(`LLL d'${nth(dt.day)}'`);
}

export function formatTravelEndDate(date) {
  if (!date) return undefined;
  const dt = DateTime.fromISO(date);
  return dt.toFormat(`LLL d'${nth(dt.day)}' yyyy`);
}

export function formatLastSyncedDate(date) {
  if (!date) return undefined;
  const dt = DateTime.fromISO(date);
  return dt.toFormat("DDDD 'at' t");
}

export function formatShoutoutDate(date) {
  if (!date) return undefined;
  const dt = DateTime.fromISO(date);
  return dt.toFormat(`LLLL d'${nth(dt.day)}', yyyy`);
}
