import React, { memo, useRef } from 'react';
import styled from 'styled-components/macro';
import { Wrapper } from '../PageContainers';
import { ImageContainer } from '../ProfileMenu';
import SettingsIcon from '../../assets/settings.svg';
import history from 'app/library/History';
interface Props {}

export const Settings = memo((props: Props) => {
  const wrapperRef = useRef(null);
  const handleClick = () => {
    history.push({
      search: `?state=settings`,
    });
  };
  return (
    <Wrapper ref={wrapperRef}>
      <ImageContainer
        selected={false}
        src={SettingsIcon}
        alt="Profile"
        onClick={handleClick}
        height="32px"
        width="32px"
      />
    </Wrapper>
  );
});
